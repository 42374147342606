import { logout } from '../firebase';
import { Flex, Heading, Text, Button } from '@chakra-ui/react';
import mixpanel from 'mixpanel-browser';
import { useNavigate, useLocation } from 'react-router-dom';

const LogoutButton = () => {
  const navigate = useNavigate();

  const handleLogoutClick = async () => {
    // TODO: handle other logout logic
    await logout();
    if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
      mixpanel.reset();
    }
    navigate('/login');
    window.location.reload(true);
  };

  return (
    <Button
      mt="20px"
      mb="20px"
      variant="solid"
      colorScheme="red"
      onClick={handleLogoutClick}
    >
      Logout
    </Button>
  );
};

export default LogoutButton;
