import React from 'react';
import { ChakraProvider, theme, Center, Spinner } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Estimator from './components/Estimator';
import History from './components/History';
import Pricing from './components/Pricing';
import NavBar from './components/NavBar';
import Labor from './components/Labor';
import Settings from './components/Settings';
import LoginPage from './components/Login';
import SignUp from './components/SignUp';
import PasswordReset from './components/PasswordReset';
import SmallDeviceMessage from './components/SmallDeviceMessage';
import useUser from './hooks/useUser';
import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { useMediaQuery } from '@chakra-ui/react';

function App() {
  const { user, isUserLoading } = useUser();
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)');

  useEffect(() => {
    if (user && process.env.REACT_APP_ENVIRONMENT === 'prod') {
      mixpanel.identify(user.email);
    }
  }, [user]);

  return (
    <ChakraProvider theme={theme}>
      {isUserLoading ? (
        <Center height="100vh">
          <Spinner size="xl" color="blue.500" />
        </Center>
      ) : (
        <Router>
          {user && isLargerThan800 && <NavBar />}
          <Routes>
            {user && user?.profile !== 'salesperson' ? (
              <>
                <Route path="/estimator" element={<Estimator />} />
                <Route path="/history" element={<History />} />
                <Route path="/prices" element={<Pricing />} />
                <Route path="/labor" element={<Labor />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="*" element={<Estimator />} />
              </>
            ) : user ? (
              <>
                <Route path="/estimator" element={<Estimator />} />
                <Route path="/history" element={<History />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="*" element={<Estimator />} />
              </>
            ) : (
              <>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/reset" element={<PasswordReset />} />
                <Route path="*" element={<LoginPage />} />
              </>
            )}
          </Routes>
        </Router>
      )}
    </ChakraProvider>
  );
}

export default App;
