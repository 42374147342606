import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Text,
  HStack,
  Flex,
  Divider,
  Checkbox,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Select,
  Spinner,
  useDisclosure,
  Alert,
  AlertIcon,
  VStack,
  AlertTitle,
  TableContainer,
} from '@chakra-ui/react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  addToSkyLightsList,
  removeFromSkyLightsList,
  addToCorniceReturnsList,
  removeFromCorniceReturnsList,
  addToBayWindowsList,
  removeFromBayWindowsList,
  addToRoofMountsList,
  removeFromRoofMountsList,
  updateSkyLightsList,
  updateCorniceReturnsList,
  updateBayWindowsList,
  updateRoofMountsList,
  clearSkyLightsList,
  clearCorniceReturnsList,
  updateFeaturesIsCompleted,
  clearBayWindowsList,
  clearRoofMountsList,
} from '../features/calculator/calculatorSlice';
import useUser from '../hooks/useUser';
import mixpanel from 'mixpanel-browser';

const Features = ({
  checkFeaturesCompletion,
  isFeaturesComplete,
  setCalculateButtonClicked,
  isMobileDevice,
}) => {
  const { user } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();

  const features = useSelector(state => state.calculator.features);
  const skyLightsList = useSelector(
    state => state.calculator.features.skyLightsList
  );
  const corniceReturnsList = useSelector(
    state => state.calculator.features.corniceReturnsList
  );
  const bayWindowsList = useSelector(
    state => state.calculator.features.bayWindowsList
  );
  const roofMountsList = useSelector(
    state => state.calculator.features.roofMountsList
  );
  const [openLoading, setOpenLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [unsavedChanged, setUnsavedChanges] = useState(false);

  const handleAddSkyLightsRow = () => {
    setUnsavedChanges(true);
    let tempNumber;
    if (skyLightsList.length > 0) {
      tempNumber = skyLightsList[skyLightsList.length - 1].lightNumber + 1;
    } else {
      tempNumber = 1;
    }
    const newRow = {
      lightNumber: tempNumber,
      count: '',
      lightType: '',
      size: '',
      flashing: '',
      isReplaceCompleteUnit: false,
      isRemoveFillIn: false,
    };
    dispatch(addToSkyLightsList(newRow));
  };

  const handleDeleteSkyLightRow = id => {
    setUnsavedChanges(true);
    dispatch(removeFromSkyLightsList(id));
  };

  const handleAddCorniceReturnsRow = () => {
    setUnsavedChanges(true);
    let tempNumber;
    if (corniceReturnsList.length > 0) {
      tempNumber =
        corniceReturnsList[corniceReturnsList.length - 1].corniceNumber + 1;
    } else {
      tempNumber = 1;
    }
    const newRow = {
      corniceNumber: tempNumber,
      count: '',
      stories: '',
      size: '',
      story: '',
    };
    dispatch(addToCorniceReturnsList(newRow));
  };

  const handleDeleteCorniceReturnRow = id => {
    setUnsavedChanges(true);
    dispatch(removeFromCorniceReturnsList(id));
  };

  const handleAddBayWindowsRow = () => {
    setUnsavedChanges(true);
    let tempNumber;
    if (bayWindowsList.length > 0) {
      tempNumber = bayWindowsList[bayWindowsList.length - 1].windowNumber + 1;
    } else {
      tempNumber = 1;
    }
    const newRow = {
      windowNumber: tempNumber,
      count: '',
      stories: '',
      size: '',
      story: '',
    };
    dispatch(addToBayWindowsList(newRow));
  };

  const handleDeleteBayWindowRow = id => {
    setUnsavedChanges(true);
    dispatch(removeFromBayWindowsList(id));
  };

  const handleAddRoofMountsRow = () => {
    setUnsavedChanges(true);
    let tempNumber;
    if (roofMountsList.length > 0) {
      tempNumber = roofMountsList[roofMountsList.length - 1].mountNumber + 1;
    } else {
      tempNumber = 1;
    }
    const newRow = {
      mountNumber: tempNumber,
      count: '',
      isReset: false,
      isRemove: false,
      mountType: '',
    };
    dispatch(addToRoofMountsList(newRow));
  };

  const handleDeleteRoofMountRow = id => {
    setUnsavedChanges(true);
    dispatch(removeFromRoofMountsList(id));
  };

  const handleOpen = async () => {
    setOpenLoading(true);
    // if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    //   mixpanel.track('User opened the Features modal.');
    // }
    onOpen();
  };

  const handleSave = async () => {
    setSaveLoading(true);
    try {
      const token = await user.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/roofs/features`,
        {
          username: user.email,
          features,
          isCompleted: false,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      setUnsavedChanges(false);
      checkFeaturesCompletion(features);
      setCalculateButtonClicked(true);
      onClose();
    } catch (error) {
      console.log('error: ' + error);
    }
    setOpenLoading(false);
    setSaveLoading(false);
  };

  return (
    <Box
      minW={isMobileDevice ? '90vw' : ''}
      maxW="sm"
      maxH="70px"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={isMobileDevice ? '3' : '6'}
      boxShadow="base"
      borderColor="gray.400"
      bg={isFeaturesComplete ? 'green.300' : 'yellow.200'}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      position="relative"
      cursor="pointer"
      onClick={isMobileDevice ? handleOpen : null}
    >
      <Flex alignItems="center">
        <Text fontWeight="bold" fontSize="l" mr="4">
          Features
        </Text>
        {!isMobileDevice && (
          <Button
            size="sm"
            border="1px"
            borderColor="gray.400"
            ml="30px"
            minWidth="53px"
            onClick={handleOpen}
          >
            {false ? (
              <Spinner
                thickness="2px"
                speed="1.25s"
                size="sm"
                emptyColor="gray.200"
                color="black.500"
              />
            ) : (
              <Text>Edit</Text>
            )}
          </Button>
        )}
        <Modal isOpen={isOpen} size="custom" onClose={onClose}>
          <ModalOverlay />
          <ModalContent maxW={isMobileDevice ? '100vw' : '80vw'}>
            <ModalHeader>Features</ModalHeader>
            <ModalBody>
              <Box>
                <HStack align="center" marginBottom="10px">
                  <Text
                    fontSize="sm"
                    fontWeight="bold"
                    mb="2"
                    marginRight="50px"
                  >
                    Sky Lights
                  </Text>
                  <Button
                    size="sm"
                    colorScheme="blue"
                    variant={skyLightsList.length > 0 ? 'outline' : 'solid'}
                    onClick={() => {
                      setUnsavedChanges(true);
                      if (skyLightsList.length > 0) {
                        dispatch(clearSkyLightsList());
                      } else {
                        handleAddSkyLightsRow();
                      }
                    }}
                  >
                    None
                  </Button>
                </HStack>
                {skyLightsList.length > 0 && (
                  <Box overflowX="auto">
                    <TableContainer>
                      <Table size="xs">
                        <Thead>
                          <Tr>
                            <Th fontSize="xs">Count</Th>
                            <Th fontSize="xs">Type</Th>
                            <Th fontSize="xs">Size</Th>
                            <Th fontSize="xs">Flashing</Th>
                            <Th fontSize="xs">Replace unit</Th>
                            <Th fontSize="xs">Remove/fill in</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {skyLightsList.map((row, index) => (
                            <Tr key={index}>
                              <Td>
                                <NumberInput
                                  size="xs"
                                  width={isMobileDevice ? '70px' : '100px'}
                                  value={row.count ?? ''}
                                  onChange={value => {
                                    setUnsavedChanges(true);
                                    dispatch(
                                      updateSkyLightsList({
                                        newValue: value,
                                        lightNumber: row.lightNumber,
                                        fieldToUpdate: 'count',
                                      })
                                    );
                                  }}
                                >
                                  <NumberInputField />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </Td>
                              <Td>
                                <Select
                                  size="xs"
                                  width={isMobileDevice ? '100px' : '130px'}
                                  value={row.lightType}
                                  onChange={event => {
                                    setUnsavedChanges(true);
                                    dispatch(
                                      updateSkyLightsList({
                                        newValue: event.target.value,
                                        lightNumber: row.lightNumber,
                                        fieldToUpdate: 'lightType',
                                      })
                                    );
                                  }}
                                >
                                  <option value=""></option>
                                  <option value="curb-mount">Curb Mount</option>
                                  <option value="deck-mount">Deck Mount</option>
                                  <option value="bubble">Bubble</option>
                                </Select>
                              </Td>
                              <Td>
                                <Select
                                  size="xs"
                                  width={isMobileDevice ? '100px' : '130px'}
                                  value={row.size}
                                  onChange={event => {
                                    setUnsavedChanges(true);
                                    dispatch(
                                      updateSkyLightsList({
                                        newValue: event.target.value,
                                        lightNumber: row.lightNumber,
                                        fieldToUpdate: 'size',
                                      })
                                    );
                                  }}
                                >
                                  <option value=""></option>
                                  <option value="small">Small 24x24</option>
                                  <option value="medium">Medium 36x36</option>
                                  <option value="large">Large 48x48</option>
                                </Select>
                              </Td>
                              <Td>
                                <Select
                                  size="xs"
                                  width={isMobileDevice ? '100px' : '130px'}
                                  variant={
                                    row.isRemoveFillIn ||
                                    row.isReplaceCompleteUnit
                                      ? 'filled'
                                      : 'outline'
                                  }
                                  css={
                                    row.isRemoveFillIn ||
                                    row.isReplaceCompleteUnit
                                      ? { pointerEvents: 'none' }
                                      : {}
                                  }
                                  value={row.flashing}
                                  onChange={event => {
                                    setUnsavedChanges(true);
                                    if (event.target.value === 'replace') {
                                      dispatch(
                                        updateSkyLightsList({
                                          newValue: false,
                                          lightNumber: row.lightNumber,
                                          fieldToUpdate:
                                            'isReplaceCompleteUnit',
                                        })
                                      );
                                    }
                                    dispatch(
                                      updateSkyLightsList({
                                        newValue: event.target.value,
                                        lightNumber: row.lightNumber,
                                        fieldToUpdate: 'flashing',
                                      })
                                    );
                                  }}
                                >
                                  <option value=""></option>
                                  <option value="replace">Replace</option>
                                  <option value="reuse">Reuse</option>
                                </Select>
                              </Td>
                              <Td>
                                <HStack align="center" justify="center">
                                  <Checkbox
                                    isChecked={row.isReplaceCompleteUnit}
                                    disabled={row.isRemoveFillIn}
                                    onChange={event => {
                                      setUnsavedChanges(true);
                                      if (event.target.checked) {
                                        dispatch(
                                          updateSkyLightsList({
                                            newValue: '',
                                            lightNumber: row.lightNumber,
                                            fieldToUpdate: 'flashing',
                                          })
                                        );
                                      }
                                      dispatch(
                                        updateSkyLightsList({
                                          newValue: event.target.checked,
                                          lightNumber: row.lightNumber,
                                          fieldToUpdate:
                                            'isReplaceCompleteUnit',
                                        })
                                      );
                                    }}
                                  ></Checkbox>
                                </HStack>
                              </Td>
                              <Td>
                                <HStack align="center" justify="center">
                                  <Checkbox
                                    isChecked={row.isRemoveFillIn}
                                    disabled={row.isReplaceCompleteUnit}
                                    onChange={event => {
                                      setUnsavedChanges(true);
                                      if (event.target.checked) {
                                        dispatch(
                                          updateSkyLightsList({
                                            newValue: '',
                                            lightNumber: row.lightNumber,
                                            fieldToUpdate: 'flashing',
                                          })
                                        );
                                      }
                                      dispatch(
                                        updateSkyLightsList({
                                          newValue: event.target.checked,
                                          lightNumber: row.lightNumber,
                                          fieldToUpdate: 'isRemoveFillIn',
                                        })
                                      );
                                    }}
                                  ></Checkbox>
                                </HStack>
                              </Td>
                              <Td>
                                <Button
                                  size="xs"
                                  variant="outline"
                                  onClick={() =>
                                    handleDeleteSkyLightRow(row.lightNumber)
                                  }
                                >
                                  Delete
                                </Button>
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
                <Button mt="2" size="xs" onClick={handleAddSkyLightsRow}>
                  Add
                </Button>
              </Box>
              <Divider mt="5" mb="5" borderColor="gray.600" />
              <Box>
                <HStack align="center" marginBottom="10px">
                  <Text
                    fontSize="sm"
                    fontWeight="bold"
                    mb="2"
                    marginRight="50px"
                  >
                    Cornice Returns
                  </Text>
                  <Button
                    size="sm"
                    colorScheme="blue"
                    variant={
                      corniceReturnsList.length > 0 ? 'outline' : 'solid'
                    }
                    onClick={() => {
                      setUnsavedChanges(true);
                      if (corniceReturnsList.length > 0) {
                        dispatch(clearCorniceReturnsList());
                      } else {
                        handleAddCorniceReturnsRow();
                      }
                    }}
                  >
                    None
                  </Button>
                </HStack>
                {corniceReturnsList.length > 0 && (
                  <Box overflowX="auto">
                    <TableContainer>
                      <Table size="xs">
                        <Thead>
                          <Tr>
                            <Th fontSize="xs">Count</Th>
                            <Th fontSize="xs">Size</Th>
                            <Th fontSize="xs">Stories</Th>
                            <Th fontSize="xs">Include</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {corniceReturnsList.map((row, index) => (
                            <Tr key={index}>
                              <Td>
                                <NumberInput
                                  size="xs"
                                  width={isMobileDevice ? '70px' : '100px'}
                                  value={row.count ?? ''}
                                  onChange={value => {
                                    setUnsavedChanges(true);
                                    dispatch(
                                      updateCorniceReturnsList({
                                        newValue: value,
                                        corniceNumber: row.corniceNumber,
                                        fieldToUpdate: 'count',
                                      })
                                    );
                                  }}
                                >
                                  <NumberInputField />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </Td>
                              <Td>
                                <Select
                                  size="xs"
                                  width={isMobileDevice ? '100px' : '130px'}
                                  value={row.size}
                                  onChange={event => {
                                    setUnsavedChanges(true);
                                    dispatch(
                                      updateCorniceReturnsList({
                                        newValue: event.target.value,
                                        corniceNumber: row.corniceNumber,
                                        fieldToUpdate: 'size',
                                      })
                                    );
                                  }}
                                >
                                  <option value=""></option>
                                  <option value="small">Small 1-2'</option>
                                  <option value="medium">Medium 2-4'</option>
                                  <option value="large">Large 4-8'</option>
                                </Select>
                              </Td>
                              <Td>
                                <HStack>
                                  <NumberInput
                                    size="xs"
                                    width={isMobileDevice ? '70px' : '100px'}
                                    value={row.stories ?? ''}
                                    onChange={value => {
                                      setUnsavedChanges(true);
                                      dispatch(
                                        updateCorniceReturnsList({
                                          newValue: value,
                                          corniceNumber: row.corniceNumber,
                                          fieldToUpdate: 'stories',
                                        })
                                      );
                                    }}
                                  >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                      <NumberIncrementStepper />
                                      <NumberDecrementStepper />
                                    </NumberInputStepper>
                                  </NumberInput>
                                </HStack>
                              </Td>
                              <Td>
                                <Select
                                  size="xs"
                                  width="150px"
                                  value={row.story}
                                  onChange={event => {
                                    setUnsavedChanges(true);
                                    dispatch(
                                      updateCorniceReturnsList({
                                        newValue: event.target.value,
                                        corniceNumber: row.corniceNumber,
                                        fieldToUpdate: 'story',
                                      })
                                    );
                                  }}
                                >
                                  <option value=""></option>
                                  <option value="include">
                                    Include in Estimate
                                  </option>
                                  <option value="not-include">
                                    Not Included in Estimate
                                  </option>
                                </Select>
                              </Td>
                              <Td>
                                <Button
                                  size="xs"
                                  variant="outline"
                                  onClick={() =>
                                    handleDeleteCorniceReturnRow(
                                      row.corniceNumber
                                    )
                                  }
                                >
                                  Delete
                                </Button>
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
                <Button mt="2" size="xs" onClick={handleAddCorniceReturnsRow}>
                  Add
                </Button>
              </Box>
              <Divider mt="5" mb="5" borderColor="gray.600" />
              <Box>
                <HStack marginBottom="10px">
                  <Text
                    fontSize="sm"
                    fontWeight="bold"
                    mb="2"
                    marginRight="50px"
                  >
                    Bay Windows
                  </Text>
                  <Button
                    size="sm"
                    colorScheme="blue"
                    variant={bayWindowsList.length > 0 ? 'outline' : 'solid'}
                    onClick={() => {
                      setUnsavedChanges(true);
                      if (bayWindowsList.length > 0) {
                        dispatch(clearBayWindowsList());
                      } else {
                        handleAddBayWindowsRow();
                      }
                    }}
                  >
                    None
                  </Button>
                </HStack>
                {bayWindowsList.length > 0 && (
                  <Box overflowX="auto">
                    <TableContainer>
                      <Table size="xs">
                        <Thead>
                          <Tr>
                            <Th fontSize="xs">Count</Th>
                            <Th fontSize="xs">Size</Th>
                            <Th fontSize="xs">Stories</Th>
                            <Th fontSize="xs">Include</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {bayWindowsList.map((row, index) => (
                            <Tr key={index}>
                              <Td>
                                <NumberInput
                                  size="xs"
                                  width={isMobileDevice ? '70px' : '100px'}
                                  value={row.count ?? ''}
                                  onChange={value => {
                                    setUnsavedChanges(true);
                                    dispatch(
                                      updateBayWindowsList({
                                        newValue: value,
                                        windowNumber: row.windowNumber,
                                        fieldToUpdate: 'count',
                                      })
                                    );
                                  }}
                                >
                                  <NumberInputField />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </Td>
                              <Td>
                                <Select
                                  size="xs"
                                  width={isMobileDevice ? '100px' : '130px'}
                                  value={row.size}
                                  onChange={event => {
                                    setUnsavedChanges(true);
                                    dispatch(
                                      updateBayWindowsList({
                                        newValue: event.target.value,
                                        windowNumber: row.windowNumber,
                                        fieldToUpdate: 'size',
                                      })
                                    );
                                  }}
                                >
                                  <option value=""></option>
                                  <option value="small">Small 1-2'</option>
                                  <option value="medium">Medium 2-4'</option>
                                  <option value="large">Large 4-8'</option>
                                </Select>
                              </Td>
                              <Td>
                                <HStack>
                                  <NumberInput
                                    size="xs"
                                    width={isMobileDevice ? '70px' : '100px'}
                                    value={row.stories ?? ''}
                                    onChange={value => {
                                      setUnsavedChanges(true);
                                      dispatch(
                                        updateBayWindowsList({
                                          newValue: value,
                                          windowNumber: row.windowNumber,
                                          fieldToUpdate: 'stories',
                                        })
                                      );
                                    }}
                                  >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                      <NumberIncrementStepper />
                                      <NumberDecrementStepper />
                                    </NumberInputStepper>
                                  </NumberInput>
                                </HStack>
                              </Td>
                              <Td>
                                <Select
                                  size="xs"
                                  width="150px"
                                  value={row.story}
                                  onChange={event => {
                                    setUnsavedChanges(true);
                                    dispatch(
                                      updateBayWindowsList({
                                        newValue: event.target.value,
                                        windowNumber: row.windowNumber,
                                        fieldToUpdate: 'story',
                                      })
                                    );
                                  }}
                                >
                                  <option value=""></option>
                                  <option value="include">
                                    Include in Estimate
                                  </option>
                                  <option value="not-include">
                                    Not Included in Estimate
                                  </option>
                                </Select>
                              </Td>
                              <Td>
                                <Button
                                  size="xs"
                                  variant="outline"
                                  onClick={() =>
                                    handleDeleteBayWindowRow(row.windowNumber)
                                  }
                                >
                                  Delete
                                </Button>
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
                <Button mt="2" size="xs" onClick={handleAddBayWindowsRow}>
                  Add
                </Button>
              </Box>
              <Divider mt="5" mb="5" borderColor="gray.600" />
              <Box>
                <HStack marginBottom="10px">
                  <Text
                    fontSize="sm"
                    fontWeight="bold"
                    mb="2"
                    paddingRight="50px"
                  >
                    Roof Mounted Accessories
                  </Text>
                  <Button
                    size="sm"
                    colorScheme="blue"
                    variant={roofMountsList.length > 0 ? 'outline' : 'solid'}
                    onClick={() => {
                      setUnsavedChanges(true);
                      if (roofMountsList.length > 0) {
                        dispatch(clearRoofMountsList());
                      } else {
                        handleAddRoofMountsRow();
                      }
                    }}
                  >
                    None
                  </Button>
                </HStack>
                {roofMountsList.length > 0 && (
                  <Box overflowX="auto">
                    <TableContainer>
                      <Table size="xs">
                        <Thead>
                          <Tr>
                            <Th fontSize="xs">Count</Th>
                            <Th fontSize="xs">Type</Th>
                            <Th fontSize="xs">Detach/Reset</Th>
                            <Th fontSize="xs">Remove</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {roofMountsList.map((row, index) => (
                            <Tr key={index}>
                              <Td>
                                <NumberInput
                                  size="xs"
                                  width={isMobileDevice ? '70px' : '100px'}
                                  value={row.count ?? ''}
                                  onChange={value => {
                                    setUnsavedChanges(true);
                                    dispatch(
                                      updateRoofMountsList({
                                        newValue: value,
                                        mountNumber: row.mountNumber,
                                        fieldToUpdate: 'count',
                                      })
                                    );
                                  }}
                                >
                                  <NumberInputField />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </Td>
                              <Td>
                                <Select
                                  size="xs"
                                  width={isMobileDevice ? '120px' : '150px'}
                                  value={row.mountType}
                                  onChange={event => {
                                    setUnsavedChanges(true);
                                    dispatch(
                                      updateRoofMountsList({
                                        newValue: event.target.value,
                                        mountNumber: row.mountNumber,
                                        fieldToUpdate: 'mountType',
                                      })
                                    );
                                  }}
                                >
                                  <option value=""></option>
                                  <option value="satellite-dish">
                                    Satellite Dish
                                  </option>
                                  <option value="ac-unit">A/C Unit</option>
                                  <option value="antenna">Antenna</option>
                                  <option value="solar-panels">
                                    Solar Panels
                                  </option>
                                </Select>
                              </Td>
                              <Td>
                                <HStack align="center" justify="center">
                                  <Checkbox
                                    isChecked={row.isReset}
                                    onChange={event => {
                                      setUnsavedChanges(true);
                                      dispatch(
                                        updateRoofMountsList({
                                          newValue: event.target.checked,
                                          mountNumber: row.mountNumber,
                                          fieldToUpdate: 'isReset',
                                        })
                                      );
                                    }}
                                  ></Checkbox>
                                </HStack>
                              </Td>
                              <Td>
                                <HStack align="center" justify="center">
                                  <Checkbox
                                    isChecked={row.isRemove}
                                    onChange={event => {
                                      setUnsavedChanges(true);
                                      dispatch(
                                        updateRoofMountsList({
                                          newValue: event.target.checked,
                                          mountNumber: row.mountNumber,
                                          fieldToUpdate: 'isRemove',
                                        })
                                      );
                                    }}
                                  ></Checkbox>
                                </HStack>
                              </Td>
                              <Td>
                                <Button
                                  size="xs"
                                  variant="outline"
                                  onClick={() =>
                                    handleDeleteRoofMountRow(row.mountNumber)
                                  }
                                >
                                  Delete
                                </Button>
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
                <Button mt="2" size="xs" onClick={handleAddRoofMountsRow}>
                  Add
                </Button>
              </Box>
            </ModalBody>
            <ModalFooter>
              {/* {unsavedChanged && (
                <Box
                  width="30%"
                  mr="200px"
                  mb="10px"
                  position="fixed"
                  bottom="10px"
                  right="10px"
                  backgroundColor="white"
                  p="10px"
                >
                  <Alert status="warning">
                    <AlertIcon />
                    <AlertTitle>You have unsaved changes!</AlertTitle>
                  </Alert>
                </Box>
              )}
              <Box
                position="fixed"
                bottom="10px" // Adjust the positioning as per your requirement
                right="10px" // Adjust the positioning as per your requirement
                zIndex="999" // Set a higher z-index to ensure the button stays on top of other content
                backgroundColor="white" // Set the background color for the container
                padding="1rem" // Add padding to create some space around the button
                borderRadius="md" // Add border radius for rounded corners
                ml="60px"
              >
                <Button onClick={handleSave} colorScheme="blue" ml="5px">
                  {saveLoading ? (
                    <Spinner
                      thickness="4px"
                      speed="1.25s"
                      size="md"
                      emptyColor="gray.200"
                      color="gray.800"
                    />
                  ) : (
                    <Text>Save</Text>
                  )}
                </Button>
              </Box> */}
              {unsavedChanged && (
                <Box>
                  <Alert status="warning" maxWidth="sm">
                    <AlertIcon />
                    <AlertTitle>You have unsaved changes!</AlertTitle>
                  </Alert>
                </Box>
              )}
              <Button onClick={handleSave} colorScheme="blue" ml="60px">
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </Box>
  );
};

export default Features;
