import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  username: '',
  hasBeenSaved: [],
  loading: false,
  hipRidgeCap: {
    manufacturer: '',
    hipRidgeCapType: '',
    line: '',
    description: '',
    unit: '',
    pricePer: '',
  },
  shingles: {
    manufacturer: '',
    shingleType: '',
    line: '',
    unit: '',
    pricePer: '',
    color: '',
  },
  warranty: {
    isPerJob: false,
    isPerSq: false,
    price: '',
    name: '',
  },
  starterStrip: {
    manufacturer: '',
    starterStripType: '',
    line: '',
    description: '',
    unit: '',
    pricePer: '',
  },
  roofDeckProtection: {
    iceWaterShield: {
      manufacturer: '',
      iceWaterShieldType: '',
      line: '',
      description: '',
      unit: '',
      pricePer: '',
    },
    underlayment: {
      manufacturer: '',
      underlaymentType: '',
      line: '',
      description: '',
      unit: '',
      pricePer: '',
    },
  },
  venting: {
    roofLouvers: {
      manufacturer: '',
      roofLouverType: '',
      line: '',
      description: '',
      pricePer: '',
    },
    turbineVents: {
      manufacturer: '',
      turbineVentType: '',
      line: '',
      description: '',
      pricePer: '',
    },
    powerVents: {
      manufacturer: '',
      powerVentType: '',
      line: '',
      description: '',
      pricePer: '',
      color: '',
    },
    ridgeVents: {
      manufacturer: '',
      line: '',
      description: '',
      unit: '',
      pricePer: '',
    },
    midRoofIntakeVents: {
      manufacturer: '',
      line: '',
      description: '',
      unit: '',
      pricePer: '',
    },
  },
  flashing: {
    exhaust: {
      smallManufacturer: '',
      smallPricePer: '',
      largeManufacturer: '',
      largePricePer: '',
      smallColor: '',
      largeColor: '',
    },
    pipe: {
      neoprene: {
        color: '',
        onePricePer: '',
        oneAndHalfPricePer: '',
        twoPricePer: '',
        twoAndHalfPricePer: '',
        threePricePer: '',
        fourPricePer: '',
      },
      frostFree: {
        color: '',
        onePricePer: '',
        oneAndHalfPricePer: '',
        twoPricePer: '',
        twoAndHalfPricePer: '',
        threePricePer: '',
        fourPricePer: '',
      },
      splitBoot: {
        color: '',
        onePricePer: '',
        oneAndHalfPricePer: '',
        twoPricePer: '',
        twoAndHalfPricePer: '',
        threePricePer: '',
        fourPricePer: '',
      },
    },
    metalPipeRoof: {
      manufacturer: '',
      color: '',
      fourPricePer: '',
      fivePricePer: '',
      sixPricePer: '',
      eightPricePer: '',
      tenPricePer: '',
      twelvePricePer: '',
    },
    gutters: {
      dripEdge: {
        manufacturer: '',
        dripEdgeType: '',
        unit: '',
        pricePer: '',
      },
      apron: {
        manufacturer: '',
        apronType: '',
        unit: '',
        whitePricePer: '',
        brownPricePer: '',
      },
    },
    step: {
      manufacturer: '',
      size: '',
      description: '',
      unit: '',
      galvanizedPricePer: '',
      blackPricePer: '',
      brownPricePer: '',
    },
    reglet: {
      manufacturer: '',
      size: '',
      unit: '',
      pricePerBrown: '',
      pricePerBlack: '',
      pricePerGalvanized: '',
    },
    oneByFour: {
      manufacturer: '',
      size: '',
      unit: '',
      pricePerPine: '',
      pricePerAzek: '',
      pricePerSmartside: '',
    },
    dormer: {
      manufacturer: '',
      size: '',
      unit: '',
      pricePer: '',
    },
    valley: {
      wManufacturer: '',
      wSize: '',
      wUnit: '',
      wPricePer: '',
      rollManufacturer: '',
      rollSize: '',
      rollUnit: '',
      rollPricePer: '',
    },
  },
  other: {
    trimCoil: {
      size: '',
      unit: '',
      pricePer: '',
    },
    skylightFlashing: {
      smallPricePer: '',
      mediumPricePer: '',
      largePricePer: '',
    },
    nails: {
      coil: {
        shouldInclude: false,
        manufacturer: '',
        size: '',
        perBox: '',
        pricePer: '',
      },
      hand: {
        shouldInclude: false,
        manufacturer: '',
        size: '',
        perBox: '',
        pricePer: '',
      },
      cap: {
        shouldInclude: false,
        manufacturer: '',
        size: '',
        perBox: '',
        pricePer: '',
      },
    },
    staples: {
      shouldInclude: false,
      manufacturer: '',
      stapleType: '',
      size: '',
      perBox: '',
      pricePer: '',
    },
    caulking: {
      manufacturer: '',
      line: '',
      color: '',
      tubesPer: '',
      pricePer: '',
    },
    decking: {
      oneBySixRoofBoards: '',
      eightFootTwoByFour: '',
      plugPricePer: '',
      plywood: {
        threeEighthsPerSheet: '',
        threeEighthsPricePer: '',
        oneHalfPerSheet: '',
        oneHalfPricePer: '',
        fiveEighthsPerSheet: '',
        fiveEighthsPricePer: '',
        threeFourthsPerSheet: '',
        threeFourthsPricePer: '',
        onePerSheet: '',
        onePricePer: '',
      },
      osb: {
        threeEighthsPerSheet: '',
        threeEighthsPricePer: '',
        oneHalfPerSheet: '',
        oneHalfPricePer: '',
        fiveEighthsPerSheet: '',
        fiveEighthsPricePer: '',
        threeFourthsPerSheet: '',
        threeFourthsPricePer: '',
        onePerSheet: '',
        onePricePer: '',
      },
    },
    paint: {
      manufacturer: '',
      color: '',
      cansPer: '',
      pricePer: '',
    },
    skylights: {
      manufacturer: '',
      curbMount: {
        smallPricePer: '',
        mediumPricePer: '',
        largePricePer: '',
      },
      deckMount: {
        smallPricePer: '',
        mediumPricePer: '',
        largePricePer: '',
      },
      bubble: {
        smallPricePer: '',
        mediumPricePer: '',
        largePricePer: '',
      },
    },
  },
  price: {
    username: '',
  },
};

export const priceSlice = createSlice({
  name: 'price',
  initialState,
  reducers: {
    increment: state => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: state => {
      state.value -= 1;
    },
    updateHasBeenSaved: (state, action) => {
      if (!state.hasBeenSaved.includes(action.payload)) {
        state.hasBeenSaved.push(action.payload);
      }
    },
    updatePrice: (state, action) => {
      if (action.payload?.hasBeenSaved) {
        state.hasBeenSaved = action.payload.hasBeenSaved;
      }
      if (action.payload?.shingles) {
        state.shingles = action.payload.shingles;
      }
      if (action.payload?.hipRidgeCap) {
        state.hipRidgeCap = action.payload.hipRidgeCap;
      }
      if (action.payload?.starterStrip) {
        state.starterStrip = action.payload.starterStrip;
      }
      if (action.payload?.roofDeckProtection) {
        state.roofDeckProtection = action.payload.roofDeckProtection;
      }
      if (action.payload?.venting) {
        state.venting = action.payload.venting;
      }
      if (action.payload?.flashing) {
        state.flashing = action.payload.flashing;
      }
      if (action.payload?.other) {
        state.other = action.payload.other;
      }
      if (action.payload?.warranty) {
        state.warranty = action.payload.warranty;
      }
    },
    updateShingles: (state, action) => {
      state.shingles = action.payload;
    },
    updateShinglesField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.shingles[fieldToUpdate] = newValue;
    },
    updateWarrantyField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      switch (fieldToUpdate) {
        case 'isPerJob':
          state.warranty.isPerJob = newValue;
          state.warranty.isPerSq = !newValue;
          break;
        case 'isPerSq':
          state.warranty.isPerJob = !newValue;
          state.warranty.isPerSq = newValue;
          break;
        case 'price':
          state.warranty.price = newValue;
          break;
        case 'name':
          state.warranty.name = newValue;
          break;
        default:
          break;
      }
    },
    updateHipRidgeCapField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.hipRidgeCap[fieldToUpdate] = newValue;
    },
    updateStarterStripField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.starterStrip[fieldToUpdate] = newValue;
    },
    updateIceWaterShieldField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.roofDeckProtection.iceWaterShield[fieldToUpdate] = newValue;
    },
    updateUnderlaymentField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.roofDeckProtection.underlayment[fieldToUpdate] = newValue;
    },
    updateTurbineVentsField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.venting.turbineVents[fieldToUpdate] = newValue;
    },
    updateRoofLouversField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.venting.roofLouvers[fieldToUpdate] = newValue;
    },
    updatePowerVentsField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.venting.powerVents[fieldToUpdate] = newValue;
    },
    updateRidgeVentsField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.venting.ridgeVents[fieldToUpdate] = newValue;
    },
    updateMidRoofIntakeVentsField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.venting.midRoofIntakeVents[fieldToUpdate] = newValue;
    },
    updateExhaustFlashingField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.flashing.exhaust[fieldToUpdate] = newValue;
    },
    updateNeoprenePipeFlashingField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.flashing.pipe.neoprene[fieldToUpdate] = newValue;
    },
    updateFrostFreePipeFlashingField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.flashing.pipe.frostFree[fieldToUpdate] = newValue;
    },
    updateSplitBootPipeFlashingField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.flashing.pipe.splitBoot[fieldToUpdate] = newValue;
    },
    updateMetalPipeRoofFlashingField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.flashing.metalPipeRoof[fieldToUpdate] = newValue;
    },
    updateGuttersDripEdgeField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.flashing.gutters.dripEdge[fieldToUpdate] = newValue;
    },
    updateGuttersApronField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.flashing.gutters.apron[fieldToUpdate] = newValue;
    },
    updateStepFlashingField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.flashing.step[fieldToUpdate] = newValue;
    },
    updateRegletFlashingField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.flashing.reglet[fieldToUpdate] = newValue;
    },
    updateOneByFourFlashingField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.flashing.oneByFour[fieldToUpdate] = newValue;
    },
    updateDormerFlashingField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.flashing.dormer[fieldToUpdate] = newValue;
    },
    updateValleyFlashingField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.flashing.valley[fieldToUpdate] = newValue;
    },
    updateTrimCoilField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.other.trimCoil[fieldToUpdate] = newValue;
    },
    updateSkylightFlashingField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.other.skylightFlashing[fieldToUpdate] = newValue;
    },
    updateCoilNailsField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.other.nails.coil[fieldToUpdate] = newValue;
    },
    updateHandNailsField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.other.nails.hand[fieldToUpdate] = newValue;
    },
    updateCapNailsField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.other.nails.cap[fieldToUpdate] = newValue;
    },
    updateStaplesField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.other.staples[fieldToUpdate] = newValue;
    },
    updateCaulkingField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.other.caulking[fieldToUpdate] = newValue;
    },
    updateDeckingField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.other.decking[fieldToUpdate] = newValue;
    },
    updatePlywoodField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.other.decking.plywood[fieldToUpdate] = newValue;
    },
    updateOsbField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.other.decking.osb[fieldToUpdate] = newValue;
    },
    updatePaintField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.other.paint[fieldToUpdate] = newValue;
    },
    updateCurbMountSkylightField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.other.skylights.curbMount[fieldToUpdate] = newValue;
    },
    updateDeckMountSkylightField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.other.skylights.deckMount[fieldToUpdate] = newValue;
    },
    updateBubbleSkylightField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      if (fieldToUpdate === 'manufacturer') {
        state.other.skylights.manufacturer = newValue;
      } else {
        state.other.skylights.bubble[fieldToUpdate] = newValue;
      }
    },
  },
});

export const {
  increment,
  decrement,
  updatePrice,
  updateShinglesField,
  updateWarrantyField,
  updateHipRidgeCapField,
  updateStarterStripField,
  updateIceWaterShieldField,
  updateUnderlaymentField,
  updateRoofLouversField,
  updateTurbineVentsField,
  updatePowerVentsField,
  updateRidgeVentsField,
  updateMidRoofIntakeVentsField,
  updateExhaustFlashingField,
  updateNeoprenePipeFlashingField,
  updateFrostFreePipeFlashingField,
  updateSplitBootPipeFlashingField,
  updateMetalPipeRoofFlashingField,
  updateGuttersDripEdgeField,
  updateGuttersApronField,
  updateStepFlashingField,
  updateRegletFlashingField,
  updateOneByFourFlashingField,
  updateDormerFlashingField,
  updateValleyFlashingField,
  updateTrimCoilField,
  updateSkylightFlashingField,
  updateCoilNailsField,
  updateHandNailsField,
  updateCapNailsField,
  updateStaplesField,
  updateCaulkingField,
  updatePlywoodField,
  updateOsbField,
  updatePaintField,
  updateCurbMountSkylightField,
  updateDeckMountSkylightField,
  updateBubbleSkylightField,
  updateHipRidgeCapFieldTwo,
  updateShingles,
  updateHasBeenSaved,
  updateDeckingField,
} = priceSlice.actions;

export default priceSlice.reducer;
