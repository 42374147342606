import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Heading,
  Text,
  Link,
} from '@chakra-ui/react';
import { sendPasswordReset } from '../firebase';
import { useNavigate } from 'react-router-dom';

function PasswordReset() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async event => {
    event.preventDefault();
    await sendPasswordReset(email);
    navigate('/login');
  };

  return (
    <Box>
      <Box bg="gray.200" maxW="sm" mx="auto" mt={200} p={4} borderRadius={20}>
        <Box textAlign="center">
          <Heading mb={4}>Reset Password</Heading>
        </Box>
        <form onSubmit={handleSubmit}>
          <FormControl id="email" isRequired>
            <FormLabel>Email address</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="Enter your email"
              bg="white"
            />
          </FormControl>
          <Box textAlign="center">
            <Button type="submit" colorScheme="blue" mt={4} w="100%">
              Send Email
            </Button>
          </Box>
        </form>
        <Text mt={4} textAlign="center">
          <Link href="/login" color="blue.600">
            Already have an account? Log in
          </Link>
        </Text>
      </Box>
    </Box>
  );
}

export default PasswordReset;
