import {
  Box,
  NumberInput,
  NumberInputField,
  Text,
  HStack,
  Checkbox,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  VStack,
  Center,
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Tooltip,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';
import { useState } from 'react';
import { QuestionIcon } from '@chakra-ui/icons';

const Overhead = ({ openModal, setOpenModal }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [overhead, setOverhead] = useState('');
  const [goal, setGoal] = useState('');
  const [profitMargin, setProfitMargin] = useState('');
  const [taxPercent, setTaxPercent] = useState('');
  const [onSelectMode, setOnSelectMode] = useState(false);

  const handleClose = () => {
    onClose();
    setOpenModal(false);
  };

  const handleBack = () => {
    setOnSelectMode(false);
  };

  const handleCalculate = () => {
    setOnSelectMode(true);
  };

  const handleIncrease = () => {};

  const handleDecrease = () => {};

  return (
    <Modal isOpen={openModal} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Overhead %</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing="20px" align="flex-start">
            {!onSelectMode && (
              <>
                <HStack justify="space-between" width="100%">
                  <HStack>
                    <Text fontSize="sm">What is your overhead?</Text>
                    <Tooltip
                      hasArrow
                      label={
                        <Box>
                          <Text fontWeight="bold">Overhead</Text>
                          <Text>Estimate your fixed costs for the year</Text>
                        </Box>
                      }
                      bg="gray.300"
                      color="black"
                      fontSize="sm"
                    >
                      <QuestionIcon />
                    </Tooltip>
                  </HStack>
                  <HStack>
                    <Text as="b">$</Text>
                    <NumberInput
                      size="sm"
                      width="120px"
                      value={overhead ?? ''}
                      onChange={value => {
                        setOverhead(value);
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </HStack>
                </HStack>
                <HStack justify="space-between" width="100%">
                  <HStack>
                    <Text fontSize="sm">What is your goal for the year?</Text>
                    <Tooltip
                      hasArrow
                      label={
                        <Box>
                          <Text>
                            How much money do you want to take home after taxes?
                          </Text>
                        </Box>
                      }
                      bg="gray.300"
                      color="black"
                      fontSize="sm"
                    >
                      <QuestionIcon />
                    </Tooltip>
                  </HStack>
                  <HStack>
                    <Text as="b">$</Text>
                    <NumberInput
                      size="sm"
                      width="120px"
                      value={goal ?? ''}
                      onChange={value => {
                        setGoal(value);
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </HStack>
                </HStack>
                <HStack justify="space-between" width="100%">
                  <HStack>
                    <Text fontSize="sm">
                      What is your target profit margin %?
                    </Text>
                    <Tooltip
                      hasArrow
                      label={
                        <Box>
                          <Text>
                            How much profit do you want to make per job?
                          </Text>
                        </Box>
                      }
                      bg="gray.300"
                      color="black"
                      fontSize="sm"
                    >
                      <QuestionIcon />
                    </Tooltip>
                  </HStack>
                  <HStack>
                    <NumberInput
                      size="sm"
                      width="100px"
                      value={profitMargin ?? ''}
                      onChange={value => {
                        setProfitMargin(value);
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                    <Text as="b">%</Text>
                  </HStack>
                </HStack>
                <HStack justify="space-between" width="100%">
                  <HStack>
                    <Text fontSize="sm">What is your income tax %?</Text>
                    <Tooltip
                      hasArrow
                      label={
                        <Box>
                          <Text>What is the tax rate in your state?</Text>
                        </Box>
                      }
                      bg="gray.300"
                      color="black"
                      fontSize="sm"
                    >
                      <QuestionIcon />
                    </Tooltip>
                  </HStack>
                  <HStack>
                    <NumberInput
                      size="sm"
                      width="100px"
                      value={taxPercent ?? ''}
                      onChange={value => {
                        setTaxPercent(value);
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                    <Text as="b">%</Text>
                  </HStack>
                </HStack>
              </>
            )}
            {onSelectMode && (
              <>
                <HStack justify="center" width="100%">
                  <Text>Select a profit margin %:</Text>
                </HStack>
                <HStack justify="center" width="100%">
                  <Button size="sm" visibility="hidden">
                    <Text as="b">+</Text>
                  </Button>
                  <Box
                    display="flex"
                    borderWidth="1px"
                    borderRadius="lg"
                    overflow="hidden"
                    p="8px"
                    boxShadow="base"
                    borderColor="gray.400"
                    bg={'gray.100'}
                    cursor="pointer"
                    _hover={{
                      bg: 'gray.500',
                    }}
                  >
                    <TableContainer>
                      <HStack justify="space-between">
                        <Text fontSize="xs">20% Profit Margin</Text>
                        <Text fontSize="xs">20% Overhead</Text>
                      </HStack>
                      <Table size="sm" variant="unstyled">
                        <Thead>
                          <Tr>
                            <Th></Th>
                            <Th>Sales</Th>
                            <Th>Profit</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          <Tr>
                            <Th>Per Month</Th>
                            <Td>$200,000.00</Td>
                            <Td>$20,000.00</Td>
                          </Tr>
                          <Tr>
                            <Th>Per Year</Th>
                            <Td>$200,000.00</Td>
                            <Td>$20,000.00</Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                </HStack>
                <HStack justify="center" width="100%">
                  <VStack>
                    <Button
                      size="sm"
                      backgroundColor="green.500"
                      onClick={handleIncrease}
                    >
                      <Text color="white" as="b">
                        +
                      </Text>
                    </Button>
                    <Button
                      size="sm"
                      backgroundColor="red.500"
                      onClick={handleDecrease}
                    >
                      <Text color="white" as="b">
                        -
                      </Text>
                    </Button>
                  </VStack>
                  <Box
                    display="flex"
                    borderWidth="1px"
                    borderRadius="lg"
                    overflow="hidden"
                    p="8px"
                    boxShadow="base"
                    borderColor="gray.400"
                    bg={'gray.100'}
                    cursor="pointer"
                    _hover={{
                      bg: 'gray.500',
                    }}
                  >
                    <TableContainer>
                      <HStack justify="space-between">
                        <Text fontSize="xs">30% Profit Margin</Text>
                        <Text fontSize="xs">30% Overhead</Text>
                      </HStack>
                      <Table size="sm" variant="unstyled">
                        <Thead>
                          <Tr>
                            <Th></Th>
                            <Th>Sales</Th>
                            <Th>Profit</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          <Tr>
                            <Th>Per Month</Th>
                            <Td>$200,000.00</Td>
                            <Td>$20,000.00</Td>
                          </Tr>
                          <Tr>
                            <Th>Per Year</Th>
                            <Td>$200,000.00</Td>
                            <Td>$20,000.00</Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                </HStack>
                <HStack justify="center" width="100%">
                  <Button size="sm" visibility="hidden">
                    <Text as="b">+</Text>
                  </Button>
                  <Box
                    display="flex"
                    borderWidth="1px"
                    borderRadius="lg"
                    overflow="hidden"
                    p="8px"
                    boxShadow="base"
                    borderColor="gray.400"
                    bg={'gray.100'}
                    cursor="pointer"
                    _hover={{
                      bg: 'gray.500',
                    }}
                  >
                    <TableContainer>
                      <HStack justify="space-between">
                        <Text fontSize="xs">40% Profit Margin</Text>
                        <Text fontSize="xs">40% Overhead</Text>
                      </HStack>
                      <Table size="sm" variant="unstyled">
                        <Thead>
                          <Tr>
                            <Th></Th>
                            <Th>Sales</Th>
                            <Th>Profit</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          <Tr>
                            <Th>Per Month</Th>
                            <Td>$200,000.00</Td>
                            <Td>$20,000.00</Td>
                          </Tr>
                          <Tr>
                            <Th>Per Year</Th>
                            <Td>$200,000.00</Td>
                            <Td>$20,000.00</Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                </HStack>
              </>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack
            justify={onSelectMode ? 'flex-start' : 'flex-end'}
            width="100%"
          >
            {onSelectMode ? (
              <Button colorScheme="red" mr={3} onClick={handleBack}>
                Back
              </Button>
            ) : (
              <Button colorScheme="blue" mr={3} onClick={handleCalculate}>
                Select
              </Button>
            )}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Overhead;
