import {
  Flex,
  Heading,
  Text,
  Button,
  HStack,
  Spacer,
  IconButton,
} from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaCalculator, FaDollarSign, FaHome, FaHammer } from 'react-icons/fa';
import { SettingsIcon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import useUser from '../hooks/useUser';
import mixpanel from 'mixpanel-browser';

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUser();
  const currentPath = location.pathname;
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)');
  const [isLargerThan1000] = useMediaQuery('(min-width: 1000px)');
  const laborHasBeenSaved = useSelector(state => state.labor.hasBeenSaved);
  const priceHasBeenSaved = useSelector(state => state.price.hasBeenSaved);
  const isEstimatorLoading = useSelector(state => state.calculator.loading);
  const [laborBackgroundColor, setLaborBackgroundColor] = useState('green');
  const [priceBackgroundColor, setPriceBackgroundColor] = useState('green');

  const handlePricesButtonClick = () => {
    navigate('/prices');
  };

  const handleEstimatorButtonClick = () => {
    navigate('/estimator');
  };

  const handleLaborButtonClick = () => {
    navigate('/labor');
  };

  const handleHomeClick = () => {
    navigate('/estimator');
  };

  const handleSettingsClick = () => {
    navigate('/settings');
  };

  useEffect(() => {
    if (laborHasBeenSaved.length >= 12) {
      setLaborBackgroundColor('green');
    } else if (!isEstimatorLoading) {
      setLaborBackgroundColor('yellow');
    }
    if (priceHasBeenSaved.length >= 21) {
      setPriceBackgroundColor('green');
    } else if (!isEstimatorLoading) {
      setPriceBackgroundColor('yellow');
    }
  }, [laborHasBeenSaved, priceHasBeenSaved]);

  // if (['/estimator', '/prices', '/labor'].includes(currentPath)) {
  if (isLargerThan800) {
    return (
      <Flex as="nav" p="10px" alignItems="center" bg="gray.200">
        {isLargerThan1000 && (
          <>
            <Heading
              _hover={{ cursor: 'pointer' }}
              onClick={handleHomeClick}
              as="h1"
              size="lg"
              p="10px"
            >
              <HStack>
                <FaHome />
                <Spacer />
                <Text>Roof Estimator</Text>
              </HStack>
            </Heading>
          </>
        )}
        <Spacer />
        <HStack spacing="20px">
          {/* <Button
          variant="outline"
          leftIcon={<FaHome />}
          onClick={handleHomeClick}
        >
          Home
        </Button> */}
          <Button
            leftIcon={<FaCalculator />}
            colorScheme={currentPath === '/estimator' ? 'purple' : 'facebook'}
            onClick={handleEstimatorButtonClick}
          >
            Estimator
          </Button>
          {user &&
            user?.profile !== 'salesperson' &&
            isEstimatorLoading === false && (
              <>
                <Button
                  leftIcon={<FaDollarSign />}
                  colorScheme={
                    currentPath === '/prices' ? 'purple' : priceBackgroundColor
                  }
                  onClick={handlePricesButtonClick}
                >
                  Material
                </Button>
                <Button
                  leftIcon={<FaHammer />}
                  colorScheme={
                    currentPath === '/labor' ? 'purple' : laborBackgroundColor
                  }
                  onClick={handleLaborButtonClick}
                >
                  Labor
                </Button>
              </>
            )}
          {user &&
            user?.profile !== 'salesperson' &&
            isEstimatorLoading === true && (
              <>
                <Button leftIcon={<FaDollarSign />} colorScheme="gray">
                  Material
                </Button>
                <Button leftIcon={<FaHammer />} colorScheme="gray">
                  Labor
                </Button>
              </>
            )}
          <IconButton
            variant={currentPath === '/settings' ? 'solid' : 'outline'}
            colorScheme={currentPath === '/settings' ? 'purple' : 'facebook'}
            icon={<SettingsIcon />}
            onClick={handleSettingsClick}
          ></IconButton>
        </HStack>
      </Flex>
    );
  } else {
    return (
      <>
        <Flex p="10px" alignItems="center" bg="gray.200">
          <Spacer />
        </Flex>
      </>
    );
  }
};

export default NavBar;
