import {
  Box,
  Button,
  Text,
  HStack,
  Flex,
  Stack,
  NumberInput,
  NumberInputField,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Radio,
  RadioGroup,
  Spacer,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateBaseIceWaterShield,
  updateBaseHasEaves,
  updateBaseCoursesFromEave,
  updateBaseEavesLengthNot,
  updateBaseHasValleys,
  updateBaseHasHipsAndRidges,
  updateBaseHasRakeEdges,
  updateBaseHasSidewall,
  updateBaseAdditionalLength,
  updateBaseStarterStrip,
  updateBaseIsCompleted,
  updateBaseAdditionalSqFt,
} from '../features/calculator/calculatorSlice';
import useUser from '../hooks/useUser';
import { LockIcon, UnlockIcon } from '@chakra-ui/icons';
import mixpanel from 'mixpanel-browser';

const Base = ({
  checkBaseCompletion,
  isBaseComplete,
  isBaseLocked,
  isBaseLockedList,
  setCalculateButtonClicked,
  isMobileDevice,
}) => {
  const { user } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const base = useSelector(state => state.calculator.base);
  const [unsavedChanged, setUnsavedChanges] = useState(false);
  const [isLocked, setIsLocked] = useState(isBaseLocked);
  const [isLockedList, setIsLockedList] = useState(isBaseLockedList);

  const handleIceWaterShieldRadioChange = value => {
    setUnsavedChanges(true);
    dispatch(updateBaseIceWaterShield(value));
  };

  const handleHasEavesRadioChange = value => {
    setUnsavedChanges(true);
    dispatch(updateBaseHasEaves(value === 'true'));
  };

  const handleOpen = () => {
    // if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    //   mixpanel.track('User opened the Base modal.');
    // }
    onOpen();
  };

  const handleSave = async () => {
    try {
      const token = await user.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/roofs/base`,
        {
          username: user.email,
          base,
          isCompleted: false,
          isLocked: isLocked,
          isLockedList: isLockedList,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      setUnsavedChanges(false);
      checkBaseCompletion(base);
      setCalculateButtonClicked(true);
      onClose();
    } catch (error) {
      console.log('error: ' + error);
    }
  };

  return (
    <Box
      maxW="sm"
      minW={isMobileDevice ? '90vw' : ''}
      maxH="70px"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={isMobileDevice ? '3' : '6'}
      boxShadow="base"
      borderColor="gray.400"
      bg={isBaseComplete ? 'green.300' : 'yellow.200'}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      position="relative"
      cursor="pointer"
      onClick={isMobileDevice ? handleOpen : null}
    >
      <Flex alignItems="center">
        {isLockedList?.length > 0 && !isMobileDevice && (
          <LockIcon boxSize={4} color="gray.900" />
        )}
        <Text fontWeight="bold" fontSize="l" mr="4" ml="20px">
          Base
        </Text>
        {!isMobileDevice && (
          <Button
            size="sm"
            border="1px"
            borderColor="gray.400"
            ml="30px"
            onClick={handleOpen}
          >
            Edit
          </Button>
        )}
        <Modal isOpen={isOpen} size="xl" onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <HStack justify="space-between">
                <Text>Base</Text>
                {isLockedList?.length > 0 ? (
                  <LockIcon
                    boxSize={5}
                    color="gray.500"
                    onClick={() => {
                      setUnsavedChanges(true);
                      setIsLockedList([]);
                    }}
                  />
                ) : (
                  <UnlockIcon
                    boxSize={5}
                    color="gray.500"
                    onClick={() => {
                      setUnsavedChanges(true);
                      let tempList = [];
                      if (
                        base.iceWaterShield !== '' &&
                        base.iceWaterShield != null
                      ) {
                        tempList.push('iceWaterShield');
                      }
                      if (base.hasEaves !== '' && base.hasEaves != null) {
                        tempList.push('hasEaves');
                      }
                      if (
                        base.coursesFromEave !== '' &&
                        base.coursesFromEave != null
                      ) {
                        tempList.push('coursesFromEave');
                      }
                      if (
                        base.eavesLengthNot !== '' &&
                        base.eavesLengthNot != null
                      ) {
                        tempList.push('eavesLengthNot');
                      }
                      if (base.hasValleys !== '' && base.hasValleys != null) {
                        tempList.push('hasValleys');
                      }
                      if (
                        base.hasHipsAndRidges !== '' &&
                        base.hasHipsAndRidges != null
                      ) {
                        tempList.push('hasHipsAndRidges');
                      }
                      if (
                        base.hasRakeEdges !== '' &&
                        base.hasRakeEdges != null
                      ) {
                        tempList.push('hasRakeEdges');
                      }
                      if (base.hasSidewall !== '' && base.hasSidewall != null) {
                        tempList.push('hasSidewall');
                      }
                      if (
                        base.additionalLength !== '' &&
                        base.additionalLength != null
                      ) {
                        tempList.push('additionalLength');
                      }
                      if (
                        base.starterStrip !== '' &&
                        base.starterStrip != null
                      ) {
                        tempList.push('starterStrip');
                      }
                      if (
                        base.additionalSqFt !== '' &&
                        base.additionalSqFt != null
                      ) {
                        tempList.push('additionalSqFt');
                      }

                      setIsLockedList([...isLockedList, ...tempList]);
                    }}
                  />
                )}
              </HStack>
            </ModalHeader>
            <ModalBody>
              <Flex direction="column" gap="5">
                <Box
                  border={isMobileDevice ? '' : '1px solid #e2e8f0'}
                  borderRadius="md"
                  padding={isMobileDevice ? '0' : '3'}
                >
                  <HStack align="center" mb="4" spacing="10">
                    <Text fontSize={isMobileDevice ? 'xs' : 'sm'} as="b">
                      Ice and Water Shield
                    </Text>
                    <Button
                      size={isMobileDevice ? 'xs' : 'sm'}
                      colorScheme="blue"
                      isDisabled={isLockedList?.includes('iceWaterShield')}
                      variant={
                        base.iceWaterShield === 'none' ? 'solid' : 'outline'
                      }
                      onClick={() => {
                        setUnsavedChanges(true);
                        if (base.iceWaterShield === 'none') {
                          handleIceWaterShieldRadioChange('');
                          dispatch(updateBaseCoursesFromEave(''));
                          dispatch(updateBaseHasEaves(null));
                          dispatch(updateBaseEavesLengthNot(''));
                          dispatch(updateBaseHasValleys(null));
                          dispatch(updateBaseHasHipsAndRidges(null));
                          dispatch(updateBaseHasRakeEdges(null));
                          dispatch(updateBaseHasSidewall(null));
                          dispatch(updateBaseAdditionalLength(''));
                          dispatch(updateBaseAdditionalSqFt(''));
                        } else {
                          handleIceWaterShieldRadioChange('none');
                        }
                      }}
                    >
                      <Text fontSize={isMobileDevice ? 'xs' : 'sm'}>None</Text>
                    </Button>
                    <Button
                      size={isMobileDevice ? 'xs' : 'sm'}
                      colorScheme="blue"
                      isDisabled={isLockedList?.includes('iceWaterShield')}
                      variant={
                        base.iceWaterShield === 'complete-roof'
                          ? 'solid'
                          : 'outline'
                      }
                      onClick={() => {
                        setUnsavedChanges(true);
                        if (base.iceWaterShield === 'complete-roof') {
                          handleIceWaterShieldRadioChange('');
                          dispatch(updateBaseCoursesFromEave(''));
                          dispatch(updateBaseHasEaves(null));
                          dispatch(updateBaseEavesLengthNot(''));
                          dispatch(updateBaseHasValleys(null));
                          dispatch(updateBaseHasHipsAndRidges(null));
                          dispatch(updateBaseHasRakeEdges(null));
                          dispatch(updateBaseHasSidewall(null));
                          dispatch(updateBaseAdditionalLength(''));
                          dispatch(updateBaseAdditionalSqFt(''));
                        } else {
                          handleIceWaterShieldRadioChange('complete-roof');
                        }
                      }}
                    >
                      <Text fontSize={isMobileDevice ? 'xs' : 'sm'}>
                        {!isMobileDevice ? 'Complete Roof' : 'Complete'}
                      </Text>
                    </Button>
                  </HStack>
                  {base.iceWaterShield !== 'complete-roof' &&
                    base.iceWaterShield !== 'none' && (
                      <>
                        <HStack marginBottom="5">
                          <RadioGroup
                            isDisabled={isLockedList?.includes('hasEaves')}
                            value={base.hasEaves}
                            onChange={handleHasEavesRadioChange}
                          >
                            <Stack
                              direction="row"
                              gap={isMobileDevice ? '1' : '3'}
                              align="center"
                            >
                              <Text
                                fontSize={isMobileDevice ? 'xs' : 'sm'}
                                mr="3"
                              >
                                Eaves
                              </Text>
                              <Radio value={true}>
                                <Text
                                  fontSize={isMobileDevice ? 'xs' : 'sm'}
                                  as="b"
                                >
                                  Yes
                                </Text>
                              </Radio>
                              <Radio value={false}>
                                <Text
                                  fontSize={isMobileDevice ? 'xs' : 'sm'}
                                  as="b"
                                >
                                  No
                                </Text>
                              </Radio>
                            </Stack>
                          </RadioGroup>
                          <Spacer />
                          <Text fontSize={isMobileDevice ? 'xs' : 'sm'}>
                            Courses From Eave
                          </Text>
                          <NumberInput
                            size={isMobileDevice ? 'xs' : 'sm'}
                            maxWidth={isMobileDevice ? '80px' : '120px'}
                            isReadOnly={isLockedList?.includes(
                              'coursesFromEave'
                            )}
                            variant={
                              isLockedList?.includes('coursesFromEave')
                                ? 'filled'
                                : 'outline'
                            }
                            value={base.coursesFromEave ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(updateBaseCoursesFromEave(value));
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </HStack>
                        <HStack marginBottom="5">
                          <NumberInput
                            size={isMobileDevice ? 'xs' : 'sm'}
                            width="120px"
                            isReadOnly={isLockedList?.includes(
                              'eavesLengthNot'
                            )}
                            variant={
                              isLockedList?.includes('eavesLengthNot')
                                ? 'filled'
                                : 'outline'
                            }
                            value={base.eavesLengthNot ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(updateBaseEavesLengthNot(value));
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                          <Text fontSize={isMobileDevice ? 'xs' : 'sm'}>
                            Linear feet of eaves not using ice and water shield
                          </Text>
                        </HStack>
                        <HStack marginBottom="5">
                          <RadioGroup
                            isDisabled={isLockedList?.includes('hasValleys')}
                            value={base.hasValleys}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(updateBaseHasValleys(value === 'true'));
                            }}
                          >
                            <Stack direction="row" gap="3" align="center">
                              <Text
                                mr="3"
                                fontSize={isMobileDevice ? 'xs' : 'sm'}
                              >
                                Valleys
                              </Text>
                              <Radio value={true}>
                                <Text
                                  as="b"
                                  fontSize={isMobileDevice ? 'xs' : 'sm'}
                                >
                                  Yes
                                </Text>
                              </Radio>
                              <Radio value={false}>
                                <Text
                                  as="b"
                                  fontSize={isMobileDevice ? 'xs' : 'sm'}
                                >
                                  No
                                </Text>
                              </Radio>
                            </Stack>
                          </RadioGroup>
                        </HStack>
                        <HStack marginBottom="5">
                          <RadioGroup
                            isDisabled={isLockedList?.includes(
                              'hasHipsAndRidges'
                            )}
                            value={base.hasHipsAndRidges}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateBaseHasHipsAndRidges(value === 'true')
                              );
                            }}
                          >
                            <Stack direction="row" gap="3" align="center">
                              <Text
                                mr="3"
                                fontSize={isMobileDevice ? 'xs' : 'sm'}
                              >
                                Hips/Ridges
                              </Text>
                              <Radio value={true}>
                                <Text
                                  as="b"
                                  fontSize={isMobileDevice ? 'xs' : 'sm'}
                                >
                                  Yes
                                </Text>
                              </Radio>
                              <Radio value={false}>
                                <Text
                                  as="b"
                                  fontSize={isMobileDevice ? 'xs' : 'sm'}
                                >
                                  No
                                </Text>
                              </Radio>
                            </Stack>
                          </RadioGroup>
                        </HStack>
                        <HStack marginBottom="5">
                          <RadioGroup
                            isDisabled={isLockedList?.includes('hasRakeEdges')}
                            value={base.hasRakeEdges}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateBaseHasRakeEdges(value === 'true')
                              );
                            }}
                          >
                            <Stack direction="row" gap="3" align="center">
                              <Text
                                mr="3"
                                fontSize={isMobileDevice ? 'xs' : 'sm'}
                              >
                                Rake Edges
                              </Text>
                              <Radio value={true}>
                                <Text
                                  as="b"
                                  fontSize={isMobileDevice ? 'xs' : 'sm'}
                                >
                                  Yes
                                </Text>
                              </Radio>
                              <Radio value={false}>
                                <Text
                                  as="b"
                                  fontSize={isMobileDevice ? 'xs' : 'sm'}
                                >
                                  No
                                </Text>
                              </Radio>
                            </Stack>
                          </RadioGroup>
                        </HStack>
                        <HStack marginBottom="5">
                          <RadioGroup
                            isDisabled={isLockedList?.includes('hasSidewall')}
                            value={base.hasSidewall}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(updateBaseHasSidewall(value === 'true'));
                            }}
                          >
                            <Stack direction="row" gap="3" align="center">
                              <Text
                                mr="3"
                                fontSize={isMobileDevice ? 'xs' : 'sm'}
                              >
                                Ice and Water Shield Sidewall
                              </Text>
                              <Radio value={true}>
                                <Text
                                  as="b"
                                  fontSize={isMobileDevice ? 'xs' : 'sm'}
                                >
                                  Yes
                                </Text>
                              </Radio>
                              <Radio value={false}>
                                <Text
                                  as="b"
                                  fontSize={isMobileDevice ? 'xs' : 'sm'}
                                >
                                  No
                                </Text>
                              </Radio>
                            </Stack>
                          </RadioGroup>
                        </HStack>
                        <HStack marginBottom="5">
                          <NumberInput
                            size={isMobileDevice ? 'xs' : 'sm'}
                            width="120px"
                            isReadOnly={isLockedList?.includes(
                              'additionalLength'
                            )}
                            variant={
                              isLockedList?.includes('additionalLength')
                                ? 'filled'
                                : 'outline'
                            }
                            value={base.additionalLength ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(updateBaseAdditionalLength(value));
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                          <Text fontSize={isMobileDevice ? 'xs' : 'sm'}>
                            Additional Linear Feet
                          </Text>
                        </HStack>
                        <HStack>
                          <NumberInput
                            size={isMobileDevice ? 'xs' : 'sm'}
                            width="120px"
                            isReadOnly={isLockedList?.includes(
                              'additionalSqFt'
                            )}
                            variant={
                              isLockedList?.includes('additionalSqFt')
                                ? 'filled'
                                : 'outline'
                            }
                            value={base.additionalSqFt ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(updateBaseAdditionalSqFt(value));
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                          <Text fontSize={isMobileDevice ? 'xs' : 'sm'}>
                            Additional Sq Feet
                          </Text>
                        </HStack>
                      </>
                    )}
                </Box>
                <Box
                  border={isMobileDevice ? '' : '1px solid #e2e8f0'}
                  borderRadius="md"
                  padding={isMobileDevice ? '0' : '3'}
                >
                  <HStack>
                    <Text fontSize={isMobileDevice ? 'xs' : 'sm'} mr="3">
                      Starter Strip
                    </Text>
                    <RadioGroup
                      isDisabled={isLockedList?.includes('starterStrip')}
                      value={base.starterStrip}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(updateBaseStarterStrip(value));
                      }}
                    >
                      <Stack direction="row" gap="3" align="center">
                        <Radio value="edges-eaves">
                          <Text as="b" fontSize={isMobileDevice ? 'xs' : 'sm'}>
                            Rake Edges & Eaves
                          </Text>
                        </Radio>
                        <Radio value="eaves-only">
                          <Text as="b" fontSize={isMobileDevice ? 'xs' : 'sm'}>
                            Eaves Only
                          </Text>
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </HStack>
                </Box>
              </Flex>
            </ModalBody>
            <ModalFooter>
              {unsavedChanged && (
                <Box>
                  <Alert status="warning" maxWidth="sm">
                    <AlertIcon />
                    <AlertTitle>You have unsaved changes!</AlertTitle>
                  </Alert>
                </Box>
              )}
              <Button onClick={handleSave} colorScheme="blue" ml="60px">
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </Box>
  );
};

export default Base;
