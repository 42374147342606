import {
  HStack,
  Text,
  VStack,
  Card,
  CardHeader,
  Select,
  CardBody,
  CardFooter,
  Button,
  Box,
  Heading,
  Center,
  Spinner,
  IconButton,
  ButtonGroup,
  Alert,
  AlertIcon,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDescription,
  AlertTitle,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { useState, useEffect } from 'react';
import useUser from '../hooks/useUser';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import React from 'react';

const History = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [roofToDelete, setRoofToDelete] = useState(null);
  const [roofList, setRoofList] = useState([]);
  const [filteredRoofList, setFilteredRoofList] = useState([]);
  const [salespersonList, setSalespersonList] = useState(['All salespeople']);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const { user } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const token = await user.getIdToken();
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/roofs/history`,
          {
            headers: {
              authorization: `${token}`,
            },
            params: {
              username: user.email,
            },
          }
        );
        setRoofList(response.data);
        setFilteredRoofList(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setIsLoading(false);
    };

    if (user) {
      fetchData();
    }
  }, [user]);

  useEffect(() => {
    const newSalespersonsSet = new Set(salespersonList);
    roofList.forEach(roof => {
      newSalespersonsSet.add(roof.createdBy);
    });
    const newSalespersonsArray = Array.from(newSalespersonsSet).sort();
    setSalespersonList(newSalespersonsArray);
  }, [roofList]);

  // useEffect(() => {
  //   console.log(roofList);
  //   const tempList = [...salespersonList];
  //   roofList.forEach(roof => {
  //     if (!tempList.includes(roof.createdBy)) {
  //       tempList.push(roof.createdBy);
  //     }
  //   });
  //   setSalespersonList(tempList);
  // }, [roofList]);

  const handleOpen = async id => {
    try {
      const token = await user.getIdToken();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/current_estimate`,
        {
          username: user.email,
          estimateId: id,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      navigate('/estimator');
      window.location.reload();
    } catch (error) {
      console.error('Error setting estimate:', error);
    }
  };

  const handleDelete = id => {
    setRoofToDelete(id);
    onOpen();
  };

  const handleFilterRoofsBySalesperson = event => {
    console.log(event.target.value);
    const filteredRoofs = [];
    for (const roof of roofList) {
      if (
        roof.createdBy === event.target.value ||
        event.target.value === 'All salespeople'
      ) {
        filteredRoofs.push(roof);
      }
    }
    setFilteredRoofList(filteredRoofs);
  };

  const onDeleteConfirm = async () => {
    try {
      setIsDeleteLoading(true);
      const token = await user.getIdToken();
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/roofs/delete/${user.email}/${roofToDelete}`,
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      setIsDeleteLoading(false);
      const removedRoof = roofList.find(roof => roof.id === roofToDelete);
      const filteredList = roofList.filter(roof => roof.id !== roofToDelete);
      if (removedRoof.isCurrent) {
        filteredList[0].isCurrent = true;
      }

      setRoofList(filteredList);
      setFilteredRoofList(filteredList);
      onClose();
    } catch (error) {
      console.error(error);
      setIsDeleteLoading(false);
    }
  };

  return (
    <Box h="90vh" overflowY="auto">
      <HStack justify="center" pt="50px">
        {isLoading ? (
          <Center>
            <Spinner
              thickness="7px"
              speed="1.25s"
              size="xl"
              emptyColor="gray.200"
              color="black.500"
              marginTop="100px"
            />
          </Center>
        ) : (
          <VStack spacing="10px">
            {user?.profile === 'admin' && (
              <Select size="sm" onChange={handleFilterRoofsBySalesperson}>
                {salespersonList.map(salesperson => (
                  <option key={salesperson} value={salesperson}>
                    {salesperson}
                  </option>
                ))}
              </Select>
            )}
            {filteredRoofList.map(item => (
              <Card
                minWidth="500px"
                variant={item.isCurrent ? 'filled' : 'outline'}
                key={item.id}
              >
                {/* <CardHeader>
                  <Heading size="sm">{item.name}</Heading>
                </CardHeader> */}
                <CardBody>
                  <HStack justify="space-between" width="100%">
                    <Text fontSize="sm">{item.date ?? '---'}</Text>
                    <VStack align="flex-start">
                      <Text as="b">
                        {(item.number ? '#' + item.number + ' - ' : '') +
                          ' ' +
                          item.name}
                      </Text>
                      {user?.profile === 'admin' && (
                        <Text fontSize="sm">{item.createdBy}</Text>
                      )}
                    </VStack>
                    {/* <Text>$---</Text> */}
                    <ButtonGroup gap="0">
                      <Button
                        size="sm"
                        colorScheme="blackAlpha"
                        onClick={() => {
                          handleOpen(item.id);
                        }}
                      >
                        Open
                      </Button>
                      <IconButton
                        colorScheme="red"
                        size="sm"
                        aria-label="Delete roof"
                        onClick={() => {
                          handleDelete(item.id);
                        }}
                        icon={<DeleteIcon />}
                      />
                    </ButtonGroup>
                  </HStack>
                </CardBody>
              </Card>
            ))}
          </VStack>
        )}
      </HStack>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Roof
            </AlertDialogHeader>
            <AlertDialogBody>
              <VStack align="flex-start" spacing="20px">
                {roofList.length > 1 ? (
                  <Text>Are you sure? You can't undo this action.</Text>
                ) : (
                  <Text>You must have at least one estimate.</Text>
                )}
              </VStack>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              {roofList.length > 1 && (
                <Button colorScheme="red" onClick={onDeleteConfirm} ml={3}>
                  {isDeleteLoading ? (
                    <Spinner
                      thickness="2px"
                      speed="1.25s"
                      size="sm"
                      color="black.500"
                    />
                  ) : (
                    <Text>Delete</Text>
                  )}
                </Button>
              )}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default History;
