import {
  Box,
  Button,
  Text,
  HStack,
  Flex,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Radio,
  RadioGroup,
  Select,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateDripEdgeHasNone,
  updateDripEdgeIsReuse,
  updateDripEdgeIsInstallNew,
  updateDripEdgeHasRakeEdges,
  updateDripEdgeHasEaves,
  updateDripEdgeFasciaColor,
  updateDripEdgeIsCompleted,
} from '../features/calculator/calculatorSlice';
import { LockIcon, UnlockIcon } from '@chakra-ui/icons';
import useUser from '../hooks/useUser';
import mixpanel from 'mixpanel-browser';

const Accessories = ({
  isAccessoriesComplete,
  checkAccessoriesCompletion,
  isDripEdgeLocked,
  isDripEdgeLockedList,
  setCalculateButtonClicked,
  isMobileDevice,
}) => {
  const { user } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dripEdge = useSelector(state => state.calculator.dripEdge);
  const dispatch = useDispatch();
  const [dripEdgeValue, setDripEdgeValue] = useState('');
  const [selectedFasciaColor, setSelectedFasciaColor] = useState('');
  const [unsavedChanged, setUnsavedChanges] = useState(false);
  const [isLocked, setIsLocked] = useState(isDripEdgeLocked);
  const [isLockedList, setIsLockedList] = useState(isDripEdgeLockedList);

  const handleRadioGroupDripEdge = value => {
    setUnsavedChanges(true);
    switch (value) {
      case 'none':
        dispatch(updateDripEdgeHasNone(true));
        dispatch(updateDripEdgeIsReuse(false));
        dispatch(updateDripEdgeIsInstallNew(false));
        dispatch(updateDripEdgeHasRakeEdges(false));
        dispatch(updateDripEdgeHasEaves(false));
        break;
      case 'reuse':
        dispatch(updateDripEdgeHasNone(false));
        dispatch(updateDripEdgeIsReuse(true));
        dispatch(updateDripEdgeIsInstallNew(false));
        dispatch(updateDripEdgeHasRakeEdges(false));
        dispatch(updateDripEdgeHasEaves(false));
        break;
      case 'install-new':
        dispatch(updateDripEdgeHasNone(false));
        dispatch(updateDripEdgeIsReuse(false));
        dispatch(updateDripEdgeIsInstallNew(true));
        break;
      default:
        break;
    }
    setDripEdgeValue(value);
  };

  const handleSelectFasciaColorChange = event => {
    setUnsavedChanges(true);
    const color = event.target.value;
    // console.log(dripEdge.fasciaColor);
    dispatch(updateDripEdgeFasciaColor(color));
    setSelectedFasciaColor(color);
  };

  const handleSave = async () => {
    try {
      const token = await user.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/roofs/accessories`,
        {
          username: user.email,
          hasNone: dripEdge.hasNone,
          isReuse: dripEdge.isReuse,
          isInstallNew: dripEdge.isInstallNew,
          hasRakeEdges: dripEdge.hasRakeEdges,
          hasEaves: dripEdge.hasEaves,
          fasciaColor: dripEdge.fasciaColor,
          isCompleted: false,
          isLocked: isLocked,
          isLockedList: isLockedList,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      setUnsavedChanges(false);
      checkAccessoriesCompletion(dripEdge);
      setCalculateButtonClicked(true);
      onClose();
    } catch (error) {
      console.log('error: ' + error);
    }
  };

  const handleOpen = () => {
    // if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    //   mixpanel.track('User opened the Accessories modal.');
    // }
    onOpen();
  };

  useEffect(() => {
    // TODO: Store the initial state in its own file and import here.
    // What should happen on cancel?
    // Set dripEdge back to its initial state?
    // Or recall the api and set it back to what is stored in the db?
    if (dripEdge.hasNone) {
      setDripEdgeValue('none');
    } else if (dripEdge.isReuse) {
      setDripEdgeValue('reuse');
    } else if (dripEdge.isInstallNew) {
      setDripEdgeValue('install-new');
    } else {
      setDripEdgeValue('');
    }

    setSelectedFasciaColor(dripEdge.fasciaColor);
  }, [isOpen]);

  return (
    <Box
      minW={isMobileDevice ? '90vw' : ''}
      maxH="70px"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={isMobileDevice ? '3' : '6'}
      boxShadow="base"
      borderColor="gray.400"
      bg={isAccessoriesComplete ? 'green.300' : 'yellow.200'}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      position="relative"
      cursor="pointer"
      onClick={isMobileDevice ? handleOpen : null}
    >
      <Flex alignItems="center">
        {isLockedList?.length > 0 && !isMobileDevice && (
          <LockIcon boxSize={4} color="gray.900" />
        )}
        <Text fontWeight="bold" fontSize="l" mr="4" pl="10px">
          Accessories
        </Text>
        {!isMobileDevice && (
          <Button
            size="sm"
            border="1px"
            borderColor="gray.400"
            ml="15px"
            onClick={handleOpen}
          >
            Edit
          </Button>
        )}
        <Modal isOpen={isOpen} size="xl" onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <HStack justify="space-between">
                <Text>Accessories</Text>
                {isLockedList?.length > 0 ? (
                  <LockIcon
                    boxSize={5}
                    color="gray.500"
                    onClick={() => {
                      setUnsavedChanges(true);
                      // setIsLocked(false);
                      setIsLockedList([]);
                    }}
                  />
                ) : (
                  <UnlockIcon
                    boxSize={5}
                    color="gray.500"
                    onClick={() => {
                      setUnsavedChanges(true);
                      // setIsLocked(true);
                      let tempList = [];
                      if (dripEdgeValue !== '' && dripEdgeValue != null) {
                        tempList.push('dripEdge');
                      }
                      if (
                        dripEdge.hasRakeEdges !== '' &&
                        dripEdge.hasRakeEdges != null
                      ) {
                        tempList.push('rakeEdges');
                      }
                      if (
                        dripEdge.hasEaves !== '' &&
                        dripEdge.hasEaves != null
                      ) {
                        tempList.push('eaves');
                      }

                      setIsLockedList([...isLockedList, ...tempList]);
                    }}
                  />
                )}
              </HStack>
            </ModalHeader>
            <ModalBody>
              <Flex direction="column" gap="4">
                <RadioGroup
                  isDisabled={isLockedList?.includes('dripEdge')}
                  value={dripEdgeValue}
                  onChange={handleRadioGroupDripEdge}
                >
                  <Stack direction="row" gap="3" align="center">
                    <Text fontSize={isMobileDevice ? 'xs' : 'sm'}>
                      Drip Edge
                    </Text>
                    <Radio value="none">
                      <Text fontSize={isMobileDevice ? 'xs' : 'sm'} as="b">
                        None
                      </Text>
                    </Radio>
                    <Radio value="reuse">
                      <Text fontSize={isMobileDevice ? 'xs' : 'sm'} as="b">
                        Reuse
                      </Text>
                    </Radio>
                    <Radio value="install-new">
                      <Text fontSize={isMobileDevice ? 'xs' : 'sm'} as="b">
                        Install New
                      </Text>
                    </Radio>
                  </Stack>
                </RadioGroup>
                {dripEdgeValue !== 'reuse' && dripEdgeValue !== 'none' && (
                  <>
                    <RadioGroup
                      isDisabled={isLockedList?.includes('rakeEdges')}
                      value={dripEdge.hasRakeEdges}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(updateDripEdgeHasRakeEdges(value === 'true'));
                      }}
                    >
                      <Stack direction="row" gap="3" align="center">
                        <Text fontSize={isMobileDevice ? 'xs' : 'sm'}>
                          Rake Edges
                        </Text>
                        <Radio value={true}>
                          <Text fontSize={isMobileDevice ? 'xs' : 'sm'} as="b">
                            Yes
                          </Text>
                        </Radio>
                        <Radio value={false}>
                          <Text fontSize={isMobileDevice ? 'xs' : 'sm'} as="b">
                            No
                          </Text>
                        </Radio>
                      </Stack>
                    </RadioGroup>
                    <RadioGroup
                      isDisabled={isLockedList?.includes('eaves')}
                      value={dripEdge.hasEaves}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(updateDripEdgeHasEaves(value === 'true'));
                      }}
                    >
                      <Stack direction="row" gap="3" align="center">
                        <Text fontSize={isMobileDevice ? 'xs' : 'sm'}>
                          Eaves
                        </Text>
                        <Radio value={true}>
                          <Text fontSize={isMobileDevice ? 'xs' : 'sm'} as="b">
                            Yes
                          </Text>
                        </Radio>
                        <Radio value={false}>
                          <Text fontSize={isMobileDevice ? 'xs' : 'sm'} as="b">
                            No
                          </Text>
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </>
                )}
                {/* <HStack>
                  <Text mr="3">Fascia Color:</Text>
                  <Select
                    id="roof-type"
                    name="roof-type"
                    size="sm"
                    value={selectedFasciaColor}
                    onChange={handleSelectFasciaColorChange}
                    width="50%"
                  >
                    <option value=""></option>
                    <option value="white">White</option>
                    <option value="brown">Brown</option>
                    <option value="black">Black</option>
                    <option value="gray">Gray</option>
                    <option value="tan">Tan</option>
                  </Select>
                </HStack> */}
              </Flex>
            </ModalBody>
            <ModalFooter>
              {unsavedChanged && (
                <Box>
                  <Alert status="warning" maxWidth="sm">
                    <AlertIcon />
                    <AlertTitle>You have unsaved changes!</AlertTitle>
                  </Alert>
                </Box>
              )}
              <Button onClick={handleSave} colorScheme="blue" ml="60px">
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </Box>
  );
};

export default Accessories;
