import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  rates: {
    tearOff: '',
    recover: '',
    newConstruction: '',
    shingleLift: '',
    starter: '',
    ridgeCap: '',
    difficultDumpAccess: '',
    cutInRidgeVent: '',
    installRidgeVent: '',
    removeGutters: '',
    resetGutters: '',
    resetSiding: '',
    stepFlashing: '',
    sidewallCounterFlashing: '',
    dormerFlashing: '',
    replaceMidroofVenting: '',
    cutInNewMidroofVenting: '',
    plywoodReplace: '',
    roofBoardRepair: '',
    handSealing: '',
    eightOrLessHandSealing: '',
    nineToThirteenHandSealing: '',
    fourteenPlusHandSealing: '',
    iceAndWater: '',
    story: '',
    layerTearOff: '',
    woodShakeTearOff: '',
    woodshakeDumpFee: '',
    removeChimney: '',
    removeDecking: '',
    newVents: '',
    baseRedeck: '',
    storyRedeck: '',
    closeProximityHomes: '',
    smallChimneyStepFlashing: '',
    mediumChimneyStepFlashing: '',
    largeChimneyStepFlashing: '',
    smallChimneyNewFlashing: '',
    mediumChimneyNewFlashing: '',
    largeChimneyNewFlashing: '',
    smallRemoveChimney: '',
    mediumRemoveChimney: '',
    largeRemoveChimney: '',
    satelliteDish: '',
    acUnit: '',
    antenna: '',
    solarPanel: '',
    smallSkylightFlashing: '',
    mediumSkylightFlashing: '',
    largeSkylightFlashing: '',
    smallChimneyCricket: '',
    mediumChimneyCricket: '',
    largeChimneyCricket: '',
    smallNewSkylight: '',
    mediumNewSkylight: '',
    largeNewSkylight: '',
    smallCorniceReturnPerStory: '',
    mediumCorniceReturnPerStory: '',
    largeCorniceReturnPerStory: '',
    smallCorniceReturn: '',
    mediumCorniceReturn: '',
    largeCorniceReturn: '',
    smallBayWindow: '',
    mediumBayWindow: '',
    largeBayWindow: '',
    includeRidgeAndStarter: false,
    unsavedChanges: false,
  },
  steepPitchRates: {
    tearOff: {
      three: '',
      four: '',
      five: '',
      six: '',
      seven: '',
      eight: '',
      nine: '',
      ten: '',
      eleven: '',
      twelve: '',
      thirteen: '',
      fourteen: '',
      fifteen: '',
      sixteen: '',
      seventeen: '',
      eighteen: '',
    },
    recover: {
      three: '',
      four: '',
      five: '',
      six: '',
      seven: '',
      eight: '',
      nine: '',
      ten: '',
      eleven: '',
      twelve: '',
      thirteen: '',
      fourteen: '',
      fifteen: '',
      sixteen: '',
      seventeen: '',
      eighteen: '',
    },
    redeck: {
      three: '',
      four: '',
      five: '',
      six: '',
      seven: '',
      eight: '',
      nine: '',
      ten: '',
      eleven: '',
      twelve: '',
      thirteen: '',
      fourteen: '',
      fifteen: '',
      sixteen: '',
      seventeen: '',
      eighteen: '',
    },
  },
};

export const rateSlice = createSlice({
  name: 'rate',
  initialState,
  reducers: {
    updateRate: (state, action) => {
      state.rates = action.payload;
      //   state.steepPitchRates = steepPitchRates;
    },
    loadSteepPitchRate: (state, action) => {
      state.steepPitchRates = action.payload;
      //   state.steepPitchRates = steepPitchRates;
    },
    updateRatesField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.rates.unsavedChanges = true;
      state.rates[fieldToUpdate] = newValue;
    },
    updateSteepTearOffRates: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.steepPitchRates.tearOff[fieldToUpdate] = newValue;
    },
    updateSteepRecoverRates: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.steepPitchRates.recover[fieldToUpdate] = newValue;
    },
    updateSteepRedeckRates: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.steepPitchRates.redeck[fieldToUpdate] = newValue;
    },
    updateUnsavedChanges: (state, action) => {
      const { fieldToUpdate } = action.payload;
      if (fieldToUpdate === 'rates') {
        state.rates.unsavedChanges = false;
      }
    },
  },
});

export const {
  updateUnsavedChanges,
  updateRate,
  updateRatesField,
  updateSteepTearOffRates,
  updateSteepRecoverRates,
  updateSteepRedeckRates,
  loadSteepPitchRate,
} = rateSlice.actions;

export default rateSlice.reducer;
