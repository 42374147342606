import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Center,
  Text,
  VStack,
  NumberInput,
  NumberInputField,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Select,
  Alert,
  AlertIcon,
  AlertTitle,
  useToast,
  HStack,
  Button,
  Checkbox,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateTrimCoilField,
  updateSkylightFlashingField,
  updateCoilNailsField,
  updateHandNailsField,
  updateCapNailsField,
  updateStaplesField,
  updateCaulkingField,
  updatePlywoodField,
  updateOsbField,
  updatePaintField,
  updateCurbMountSkylightField,
  updateDeckMountSkylightField,
  updateBubbleSkylightField,
  updateHasBeenSaved,
  updateDeckingField,
} from '../../features/price/priceSlice';
import axios from 'axios';
import useUser from '../../hooks/useUser';
import mixpanel from 'mixpanel-browser';

const OtherMaterials = ({
  checkOtherCompletion,
  isTrimCoilComplete,
  isSkylightKitComplete,
  isNailsComplete,
  isCaulkComplete,
  isDeckingComplete,
  isPaintComplete,
  isSkylightsComplete,
}) => {
  const dispatch = useDispatch();
  const other = useSelector(state => state.price.other);
  const toast = useToast();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const { user } = useUser();
  const savedList = useSelector(state => state.price.hasBeenSaved);
  const [currentTab, setCurrentTab] = useState(0);

  const handleSave = async () => {
    try {
      // if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
      //   mixpanel.track('User saved the Other Materials prices.');
      // }
      let tab;
      if (currentTab === 0) {
        tab = 'skylightFlashingKit';
      } else if (currentTab === 1) {
        tab = 'fasteners';
      } else if (currentTab === 2) {
        tab = 'roofCaulking';
      } else if (currentTab === 3) {
        tab = 'decking';
      } else if (currentTab === 4) {
        tab = 'paint';
      } else if (currentTab === 5) {
        tab = 'skylights';
      }

      const token = await user.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/prices/other`,
        {
          username: user.email,
          other,
          tab,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      dispatch(updateHasBeenSaved(tab));
      setUnsavedChanges(false);
      checkOtherCompletion(other, [...savedList, tab]);
    } catch (error) {
      console.log('error: ' + error);
    }

    toast({
      title: 'Changes saved',
      description: 'Your pricing was updated.',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  const handleTabChange = index => {
    setCurrentTab(index);
  };

  return (
    <Box>
      <Box>
        <Tabs
          align="center"
          variant="soft-rounded"
          colorScheme="blue"
          onChange={handleTabChange}
          index={currentTab}
        >
          <TabList>
            {/* <Tab
              as="b"
              bg={isTrimCoilComplete ? 'green.200' : 'yellow.200'}
              color="black"
              _selected={{
                border: '2px solid black',
                bg: isTrimCoilComplete ? 'green.400' : 'yellow.400',
              }}
            >
              Trim Coil
            </Tab> */}
            <Tab
              as="b"
              bg={isSkylightKitComplete ? 'green.200' : 'yellow.200'}
              color="black"
              _selected={{
                border: '2px solid black',
                bg: isSkylightKitComplete ? 'green.400' : 'yellow.400',
              }}
            >
              Skylight Flashing Kit
            </Tab>
            <Tab
              as="b"
              bg={isNailsComplete ? 'green.200' : 'yellow.200'}
              color="black"
              _selected={{
                border: '2px solid black',
                bg: isNailsComplete ? 'green.400' : 'yellow.400',
              }}
            >
              Fasteners
            </Tab>
            <Tab
              as="b"
              bg={isCaulkComplete ? 'green.200' : 'yellow.200'}
              color="black"
              _selected={{
                border: '2px solid black',
                bg: isCaulkComplete ? 'green.400' : 'yellow.400',
              }}
            >
              Roof Caulking
            </Tab>
            <Tab
              as="b"
              bg={isDeckingComplete ? 'green.200' : 'yellow.200'}
              color="black"
              _selected={{
                border: '2px solid black',
                bg: isDeckingComplete ? 'green.400' : 'yellow.400',
              }}
            >
              Decking
            </Tab>
            <Tab
              as="b"
              bg={isPaintComplete ? 'green.200' : 'yellow.200'}
              color="black"
              _selected={{
                border: '2px solid black',
                bg: isPaintComplete ? 'green.400' : 'yellow.400',
              }}
            >
              Paint
            </Tab>
            <Tab
              as="b"
              bg={isSkylightsComplete ? 'green.200' : 'yellow.200'}
              color="black"
              _selected={{
                border: '2px solid black',
                bg: isSkylightsComplete ? 'green.400' : 'yellow.400',
              }}
            >
              Skylights
            </Tab>
          </TabList>
          <TabPanels>
            {/* <TabPanel>
              <VStack>
                <div>
                  <Center>
                    <Text fontWeight="bold" ml="6">
                      Trim Coil
                    </Text>
                  </Center>
                  <Table size="sm">
                    <Thead>
                      <Tr>
                        <Th fontSize="xs">Size</Th>
                        <Th fontSize="xs">Unit Measurement (LF)</Th>
                        <Th fontSize="xs">Price per unit</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>
                          <Input
                            size="xs"
                            value={other.trimCoil.size}
                            onChange={event => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateTrimCoilField({
                                  newValue: event.target.value,
                                  fieldToUpdate: 'size',
                                })
                              );
                            }}
                          />
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            value={other.trimCoil.unit ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateTrimCoilField({
                                  newValue: value,
                                  fieldToUpdate: 'unit',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            value={other.trimCoil.pricePer ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateTrimCoilField({
                                  newValue: value,
                                  fieldToUpdate: 'pricePer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </div>
              </VStack>
            </TabPanel> */}
            <TabPanel>
              <VStack spacing="7">
                <div>
                  <Center>
                    <Text fontWeight="bold" ml="6">
                      Skylight Flashing Kit
                    </Text>
                  </Center>
                  <Table size="sm">
                    <Thead>
                      <Tr>
                        <Th fontSize="xs">Size</Th>
                        <Th fontSize="xs">Price per unit</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>
                          <Text>Small 24x24</Text>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            maxWidth="250"
                            value={other.skylightFlashing.smallPricePer ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateSkylightFlashingField({
                                  newValue: value,
                                  fieldToUpdate: 'smallPricePer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text>Medium 36x36</Text>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            maxWidth="250"
                            value={other.skylightFlashing.mediumPricePer ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateSkylightFlashingField({
                                  newValue: value,
                                  fieldToUpdate: 'mediumPricePer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text>Large 48x48</Text>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            maxWidth="250"
                            value={other.skylightFlashing.largePricePer ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateSkylightFlashingField({
                                  newValue: value,
                                  fieldToUpdate: 'largePricePer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </div>
              </VStack>
            </TabPanel>
            <TabPanel>
              <VStack>
                <div>
                  <Center>
                    <Text fontWeight="bold" ml="6">
                      Coil Nails
                    </Text>
                  </Center>
                  <Table size="sm">
                    <Thead>
                      <Tr>
                        <Th fontSize="xs">Include in Material List</Th>
                        <Th fontSize="xs">Manufacturer</Th>
                        <Th fontSize="xs">Size</Th>
                        <Th fontSize="xs">Squares per box</Th>
                        <Th fontSize="xs">Price per box</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>
                          <Center>
                            <Checkbox
                              isChecked={other.nails.coil.shouldInclude}
                              onChange={event => {
                                setUnsavedChanges(true);
                                dispatch(
                                  updateCoilNailsField({
                                    newValue: event.target.checked,
                                    fieldToUpdate: 'shouldInclude',
                                  })
                                );
                              }}
                            ></Checkbox>
                          </Center>
                        </Td>
                        <Td>
                          <Input
                            size="xs"
                            value={other.nails.coil.manufacturer}
                            onChange={event => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateCoilNailsField({
                                  newValue: event.target.value,
                                  fieldToUpdate: 'manufacturer',
                                })
                              );
                            }}
                          />
                        </Td>
                        <Td>
                          <Select
                            size="xs"
                            value={other.nails.coil.size}
                            onChange={event => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateCoilNailsField({
                                  newValue: event.target.value,
                                  fieldToUpdate: 'size',
                                })
                              );
                            }}
                          >
                            <option value=""></option>
                            <option value="1">1"</option>
                            <option value="1.25">1 1/4"</option>
                            <option value="1.5">1 1/2"</option>
                            <option value="1.75">1 3/4"</option>
                          </Select>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            maxWidth="80px"
                            value={other.nails.coil.perBox ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateCoilNailsField({
                                  newValue: value,
                                  fieldToUpdate: 'perBox',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            maxWidth="80px"
                            value={other.nails.coil.pricePer ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateCoilNailsField({
                                  newValue: value,
                                  fieldToUpdate: 'pricePer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </div>
                <div>
                  <Center>
                    <Text fontWeight="bold" ml="6">
                      Hand Nails
                    </Text>
                  </Center>
                  <Table size="sm">
                    <Thead>
                      <Tr>
                        <Th fontSize="xs">Include in Material List</Th>
                        <Th fontSize="xs">Manufacturer</Th>
                        <Th fontSize="xs">Size</Th>
                        <Th fontSize="xs">LF Ridge vent per box</Th>
                        <Th fontSize="xs">Price per box</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>
                          <Center>
                            <Checkbox
                              isChecked={other.nails.hand.shouldInclude}
                              onChange={event => {
                                setUnsavedChanges(true);
                                dispatch(
                                  updateHandNailsField({
                                    newValue: event.target.checked,
                                    fieldToUpdate: 'shouldInclude',
                                  })
                                );
                              }}
                            ></Checkbox>
                          </Center>
                        </Td>
                        <Td>
                          <Input
                            size="xs"
                            value={other.nails.hand.manufacturer}
                            onChange={event => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateHandNailsField({
                                  newValue: event.target.value,
                                  fieldToUpdate: 'manufacturer',
                                })
                              );
                            }}
                          />
                        </Td>
                        <Td>
                          <Select
                            size="xs"
                            value={other.nails.hand.size}
                            onChange={event => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateHandNailsField({
                                  newValue: event.target.value,
                                  fieldToUpdate: 'size',
                                })
                              );
                            }}
                          >
                            <option value=""></option>
                            <option value="1.25">1 1/4"</option>
                            <option value="1.5">1 1/2"</option>
                            <option value="1.75">1 3/4"</option>
                            <option value="2">2"</option>
                            <option value="2.5">2 1/2"</option>
                          </Select>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            maxWidth="80px"
                            value={other.nails.hand.perBox ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateHandNailsField({
                                  newValue: value,
                                  fieldToUpdate: 'perBox',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            maxWidth="80px"
                            value={other.nails.hand.pricePer ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateHandNailsField({
                                  newValue: value,
                                  fieldToUpdate: 'pricePer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </div>
                <div>
                  <Center>
                    <Text fontWeight="bold" ml="6">
                      Cap Nails
                    </Text>
                  </Center>
                  <Table size="sm">
                    <Thead>
                      <Tr>
                        <Th fontSize="xs">Include in Material List</Th>
                        <Th fontSize="xs">Manufacturer</Th>
                        <Th fontSize="xs">Size</Th>
                        <Th fontSize="xs">Squares per box</Th>
                        <Th fontSize="xs">Price per box</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>
                          <Center>
                            <Checkbox
                              isChecked={other.nails.cap.shouldInclude}
                              onChange={event => {
                                setUnsavedChanges(true);
                                dispatch(
                                  updateCapNailsField({
                                    newValue: event.target.checked,
                                    fieldToUpdate: 'shouldInclude',
                                  })
                                );
                              }}
                            ></Checkbox>
                          </Center>
                        </Td>
                        <Td>
                          <Input
                            size="xs"
                            value={other.nails.cap.manufacturer}
                            onChange={event => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateCapNailsField({
                                  newValue: event.target.value,
                                  fieldToUpdate: 'manufacturer',
                                })
                              );
                            }}
                          />
                        </Td>
                        <Td>
                          <Select
                            size="xs"
                            value={other.nails.cap.size}
                            onChange={event => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateCapNailsField({
                                  newValue: event.target.value,
                                  fieldToUpdate: 'size',
                                })
                              );
                            }}
                          >
                            <option value=""></option>
                            <option value="1">1"</option>
                            <option value="1.25">1 1/4"</option>
                            <option value="1.5">1 1/2"</option>
                          </Select>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            maxWidth="80px"
                            value={other.nails.cap.perBox ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateCapNailsField({
                                  newValue: value,
                                  fieldToUpdate: 'perBox',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            maxWidth="80px"
                            value={other.nails.cap.pricePer ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateCapNailsField({
                                  newValue: value,
                                  fieldToUpdate: 'pricePer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </div>
                <div>
                  <Center>
                    <Text fontWeight="bold" ml="6">
                      Tacker Staples
                    </Text>
                  </Center>
                  <Table size="sm">
                    <Thead>
                      <Tr>
                        <Th fontSize="xs">Include in Material List</Th>
                        <Th fontSize="xs">Manufacturer</Th>
                        <Th fontSize="xs">Type</Th>
                        <Th fontSize="xs">Size</Th>
                        <Th fontSize="xs">Squares per box</Th>
                        <Th fontSize="xs">Price per box</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>
                          <Center>
                            <Checkbox
                              isChecked={other.staples.shouldInclude}
                              onChange={event => {
                                setUnsavedChanges(true);
                                dispatch(
                                  updateStaplesField({
                                    newValue: event.target.checked,
                                    fieldToUpdate: 'shouldInclude',
                                  })
                                );
                              }}
                            ></Checkbox>
                          </Center>
                        </Td>
                        <Td>
                          <Input
                            size="xs"
                            value={other.staples.manufacturer}
                            onChange={event => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateStaplesField({
                                  newValue: event.target.value,
                                  fieldToUpdate: 'manufacturer',
                                })
                              );
                            }}
                          />
                        </Td>
                        <Td>
                          <Input
                            size="xs"
                            value={other.staples.stapleType}
                            onChange={event => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateStaplesField({
                                  newValue: event.target.value,
                                  fieldToUpdate: 'stapleType',
                                })
                              );
                            }}
                          />
                        </Td>
                        <Td>
                          <Select
                            size="xs"
                            value={other.staples.size}
                            onChange={event => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateStaplesField({
                                  newValue: event.target.value,
                                  fieldToUpdate: 'size',
                                })
                              );
                            }}
                          >
                            <option value=""></option>
                            <option value=".625">5/8"</option>
                            <option value=".75">3/4"</option>
                          </Select>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            maxWidth="80px"
                            value={other.staples.perBox ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateStaplesField({
                                  newValue: value,
                                  fieldToUpdate: 'perBox',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            maxWidth="80px"
                            value={other.staples.pricePer ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateStaplesField({
                                  newValue: value,
                                  fieldToUpdate: 'pricePer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </div>
              </VStack>
            </TabPanel>
            <TabPanel>
              <VStack>
                <div>
                  <Center>
                    <Text fontWeight="bold" ml="6">
                      Roof Caulking
                    </Text>
                  </Center>
                  <Table size="sm">
                    <Thead>
                      <Tr>
                        <Th fontSize="xs">Manufacturer</Th>
                        <Th fontSize="xs">Line</Th>
                        <Th fontSize="xs">Color</Th>
                        <Th fontSize="xs">Tubes per job</Th>
                        <Th fontSize="xs">Price per tube</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>
                          <Input
                            size="xs"
                            value={other.caulking.manufacturer}
                            onChange={event => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateCaulkingField({
                                  newValue: event.target.value,
                                  fieldToUpdate: 'manufacturer',
                                })
                              );
                            }}
                          />
                        </Td>
                        <Td>
                          <Input
                            size="xs"
                            value={other.caulking.line}
                            onChange={event => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateCaulkingField({
                                  newValue: event.target.value,
                                  fieldToUpdate: 'line',
                                })
                              );
                            }}
                          />
                        </Td>
                        <Td>
                          <Input
                            size="xs"
                            value={other.caulking.color}
                            onChange={event => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateCaulkingField({
                                  newValue: event.target.value,
                                  fieldToUpdate: 'color',
                                })
                              );
                            }}
                          />
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            value={other.caulking.tubesPer ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateCaulkingField({
                                  newValue: value,
                                  fieldToUpdate: 'tubesPer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            value={other.caulking.pricePer ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateCaulkingField({
                                  newValue: value,
                                  fieldToUpdate: 'pricePer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </div>
              </VStack>
            </TabPanel>
            <TabPanel>
              <VStack>
                <div>
                  <Center>
                    <Text fontWeight="bold" ml="6">
                      Decking
                    </Text>
                  </Center>
                  <Table size="sm" borderColor="gray.900" borderWidth="2px">
                    <Thead>
                      <Tr>
                        <Th fontSize="xs">Type</Th>
                        <Th fontSize="xs">Size</Th>
                        <Th fontSize="xs">sqs per sheet</Th>
                        <Th fontSize="xs">Price per unit</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td rowSpan={5}>
                          <Text>Plywood</Text>
                        </Td>
                        <Td>
                          <Text>3/8"</Text>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            value={
                              other.decking.plywood.threeEighthsPerSheet ?? ''
                            }
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updatePlywoodField({
                                  newValue: value,
                                  fieldToUpdate: 'threeEighthsPerSheet',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            value={
                              other.decking.plywood.threeEighthsPricePer ?? ''
                            }
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updatePlywoodField({
                                  newValue: value,
                                  fieldToUpdate: 'threeEighthsPricePer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text>1/2"</Text>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            value={other.decking.plywood.oneHalfPerSheet ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updatePlywoodField({
                                  newValue: value,
                                  fieldToUpdate: 'oneHalfPerSheet',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            value={other.decking.plywood.oneHalfPricePer ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updatePlywoodField({
                                  newValue: value,
                                  fieldToUpdate: 'oneHalfPricePer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text>5/8"</Text>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            value={
                              other.decking.plywood.fiveEighthsPerSheet ?? ''
                            }
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updatePlywoodField({
                                  newValue: value,
                                  fieldToUpdate: 'fiveEighthsPerSheet',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            value={
                              other.decking.plywood.fiveEighthsPricePer ?? ''
                            }
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updatePlywoodField({
                                  newValue: value,
                                  fieldToUpdate: 'fiveEighthsPricePer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text>3/4"</Text>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            value={
                              other.decking.plywood.threeFourthsPerSheet ?? ''
                            }
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updatePlywoodField({
                                  newValue: value,
                                  fieldToUpdate: 'threeFourthsPerSheet',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            value={
                              other.decking.plywood.threeFourthsPricePer ?? ''
                            }
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updatePlywoodField({
                                  newValue: value,
                                  fieldToUpdate: 'threeFourthsPricePer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                      <Tr borderBottomColor="gray.900" borderBottomWidth="2px">
                        <Td>
                          <Text>1"</Text>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            value={other.decking.plywood.onePerSheet ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updatePlywoodField({
                                  newValue: value,
                                  fieldToUpdate: 'onePerSheet',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            value={other.decking.plywood.onePricePer ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updatePlywoodField({
                                  newValue: value,
                                  fieldToUpdate: 'onePricePer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td rowSpan={5}>
                          <Text>OSB</Text>
                        </Td>
                        <Td>
                          <Text>3/8"</Text>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            value={other.decking.osb.threeEighthsPerSheet ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateOsbField({
                                  newValue: value,
                                  fieldToUpdate: 'threeEighthsPerSheet',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            value={other.decking.osb.threeEighthsPricePer ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateOsbField({
                                  newValue: value,
                                  fieldToUpdate: 'threeEighthsPricePer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text>1/2"</Text>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            value={other.decking.osb.oneHalfPerSheet ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateOsbField({
                                  newValue: value,
                                  fieldToUpdate: 'oneHalfPerSheet',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            value={other.decking.osb.oneHalfPricePer ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateOsbField({
                                  newValue: value,
                                  fieldToUpdate: 'oneHalfPricePer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text>5/8"</Text>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            value={other.decking.osb.fiveEighthsPerSheet ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateOsbField({
                                  newValue: value,
                                  fieldToUpdate: 'fiveEighthsPerSheet',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            value={other.decking.osb.fiveEighthsPricePer ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateOsbField({
                                  newValue: value,
                                  fieldToUpdate: 'fiveEighthsPricePer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text>3/4"</Text>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            value={other.decking.osb.threeFourthsPerSheet ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateOsbField({
                                  newValue: value,
                                  fieldToUpdate: 'threeFourthsPerSheet',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            value={other.decking.osb.threeFourthsPricePer ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateOsbField({
                                  newValue: value,
                                  fieldToUpdate: 'threeFourthsPricePer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text>1"</Text>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            value={other.decking.osb.onePerSheet ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateOsbField({
                                  newValue: value,
                                  fieldToUpdate: 'onePerSheet',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            value={other.decking.osb.onePricePer ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateOsbField({
                                  newValue: value,
                                  fieldToUpdate: 'onePricePer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </div>
                <div>
                  <Center>
                    <Text fontWeight="bold" ml="6">
                      Roof Deck Patching
                    </Text>
                  </Center>
                  <Table size="sm" borderColor="gray.900" borderWidth="2px">
                    <Thead>
                      <Tr>
                        <Th fontSize="xs"></Th>
                        <Th fontSize="xs">Size</Th>
                        <Th fontSize="xs">Price Per Unit</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td rowSpan={1}>
                          <Text>Plug</Text>
                        </Td>
                        <Td>
                          <Text>12" x 12"</Text>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            maxWidth="100px"
                            value={other.decking.plugPricePer ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateDeckingField({
                                  newValue: value,
                                  fieldToUpdate: 'plugPricePer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </div>
                <div>
                  <Center>
                    <Text fontWeight="bold" ml="6">
                      Dimensional Lumber
                    </Text>
                  </Center>
                  <Table size="sm" borderColor="gray.900" borderWidth="2px">
                    <Thead>
                      <Tr>
                        <Th fontSize="xs"></Th>
                        <Th fontSize="xs">Size</Th>
                        <Th fontSize="xs">Price Per Unit</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td rowSpan={1}>
                          <Text>2 x 4</Text>
                        </Td>
                        <Td>
                          <Text>8'</Text>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            maxWidth="100px"
                            value={other.decking.eightFootTwoByFour ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateDeckingField({
                                  newValue: value,
                                  fieldToUpdate: 'eightFootTwoByFour',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </div>
                <div>
                  <Center>
                    <Text fontWeight="bold" ml="6">
                      Roof Deck Boards
                    </Text>
                  </Center>
                  <Table size="sm" borderColor="gray.900" borderWidth="2px">
                    <Thead>
                      <Tr>
                        <Th fontSize="xs"></Th>
                        <Th fontSize="xs">Size</Th>
                        <Th fontSize="xs">Price Per Unit</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td rowSpan={1}>
                          <Text>Pine</Text>
                        </Td>
                        <Td>
                          <Text>1 x 6 x 8'</Text>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            maxWidth="100px"
                            value={other.decking.oneBySixRoofBoards ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateDeckingField({
                                  newValue: value,
                                  fieldToUpdate: 'oneBySixRoofBoards',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </div>
              </VStack>
            </TabPanel>
            <TabPanel>
              <VStack>
                <div>
                  <Center>
                    <Text fontWeight="bold" ml="6">
                      Accessory Paint
                    </Text>
                  </Center>
                  <Table size="sm">
                    <Thead>
                      <Tr>
                        <Th fontSize="xs">Manufacturer</Th>
                        <Th fontSize="xs">Color</Th>
                        <Th fontSize="xs">Cans per roof</Th>
                        <Th fontSize="xs">Price per can</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>
                          <Input
                            size="xs"
                            value={other.paint.manufacturer}
                            onChange={event => {
                              setUnsavedChanges(true);
                              dispatch(
                                updatePaintField({
                                  newValue: event.target.value,
                                  fieldToUpdate: 'manufacturer',
                                })
                              );
                            }}
                          />
                        </Td>
                        <Td>
                          <Input
                            size="xs"
                            value={other.paint.color}
                            onChange={event => {
                              setUnsavedChanges(true);
                              dispatch(
                                updatePaintField({
                                  newValue: event.target.value,
                                  fieldToUpdate: 'color',
                                })
                              );
                            }}
                          />
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            value={other.paint.cansPer ?? ''}
                            maxWidth="75px"
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updatePaintField({
                                  newValue: value,
                                  fieldToUpdate: 'cansPer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                        <Td>
                          <NumberInput
                            size="xs"
                            maxWidth="75px"
                            value={other.paint.pricePer ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updatePaintField({
                                  newValue: value,
                                  fieldToUpdate: 'pricePer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </div>
              </VStack>
            </TabPanel>
            <TabPanel>
              <VStack>
                <div>
                  <Center>
                    <Text fontWeight="bold" ml="6">
                      Skylights
                    </Text>
                  </Center>
                  <Table size="sm" borderColor="gray.900" borderWidth="2px">
                    <Thead>
                      <Tr>
                        <Th fontSize="xs">Type</Th>
                        <Th fontSize="xs">Size</Th>
                        <Th fontSize="xs">Price</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td rowSpan={3}>
                          <Text>Curb Mount</Text>
                        </Td>
                        <Td>
                          <Text>Small</Text>
                        </Td>
                        <Td>
                          <NumberInput
                            maxWidth="100px"
                            size="xs"
                            value={
                              other.skylights.curbMount.smallPricePer ?? ''
                            }
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateCurbMountSkylightField({
                                  newValue: value,
                                  fieldToUpdate: 'smallPricePer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text>Medium</Text>
                        </Td>
                        <Td>
                          <NumberInput
                            maxWidth="100px"
                            size="xs"
                            value={
                              other.skylights.curbMount.mediumPricePer ?? ''
                            }
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateCurbMountSkylightField({
                                  newValue: value,
                                  fieldToUpdate: 'mediumPricePer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                      <Tr borderBottomColor="gray.900" borderBottomWidth="2px">
                        <Td>
                          <Text>Large</Text>
                        </Td>
                        <Td>
                          <NumberInput
                            maxWidth="100px"
                            size="xs"
                            value={
                              other.skylights.curbMount.largePricePer ?? ''
                            }
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateCurbMountSkylightField({
                                  newValue: value,
                                  fieldToUpdate: 'largePricePer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td rowSpan={3}>
                          <Text>Deck Mount</Text>
                        </Td>
                        <Td>
                          <Text>Small</Text>
                        </Td>
                        <Td>
                          <NumberInput
                            maxWidth="100px"
                            size="xs"
                            value={
                              other.skylights.deckMount.smallPricePer ?? ''
                            }
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateDeckMountSkylightField({
                                  newValue: value,
                                  fieldToUpdate: 'smallPricePer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text>Medium</Text>
                        </Td>
                        <Td>
                          <NumberInput
                            maxWidth="100px"
                            size="xs"
                            value={
                              other.skylights.deckMount.mediumPricePer ?? ''
                            }
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateDeckMountSkylightField({
                                  newValue: value,
                                  fieldToUpdate: 'mediumPricePer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                      <Tr borderBottomColor="gray.900" borderBottomWidth="2px">
                        <Td>
                          <Text>Large</Text>
                        </Td>
                        <Td>
                          <NumberInput
                            maxWidth="100px"
                            size="xs"
                            value={
                              other.skylights.deckMount.largePricePer ?? ''
                            }
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateDeckMountSkylightField({
                                  newValue: value,
                                  fieldToUpdate: 'largePricePer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td rowSpan={3}>
                          <Text>Bubble</Text>
                        </Td>
                        <Td>
                          <Text>Small</Text>
                        </Td>
                        <Td>
                          <NumberInput
                            maxWidth="100px"
                            size="xs"
                            value={other.skylights.bubble.smallPricePer ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateBubbleSkylightField({
                                  newValue: value,
                                  fieldToUpdate: 'smallPricePer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text>Medium</Text>
                        </Td>
                        <Td>
                          <NumberInput
                            maxWidth="100px"
                            size="xs"
                            value={other.skylights.bubble.mediumPricePer ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateBubbleSkylightField({
                                  newValue: value,
                                  fieldToUpdate: 'mediumPricePer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text>Large</Text>
                        </Td>
                        <Td>
                          <NumberInput
                            maxWidth="100px"
                            size="xs"
                            value={other.skylights.bubble.largePricePer ?? ''}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateBubbleSkylightField({
                                  newValue: value,
                                  fieldToUpdate: 'largePricePer',
                                })
                              );
                            }}
                          >
                            <NumberInputField />
                          </NumberInput>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </div>
                <div>
                  <HStack marginTop="10px">
                    <Text>Manufacturer: </Text>
                    <Input
                      size="xs"
                      value={other.skylights.manufacturer}
                      onChange={event => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateBubbleSkylightField({
                            newValue: event.target.value,
                            fieldToUpdate: 'manufacturer',
                          })
                        );
                      }}
                    />
                  </HStack>
                </div>
              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>{' '}
      <Center>
        <VStack>
          {unsavedChanges && (
            <Alert status="warning" marginTop="5">
              <AlertIcon />
              <AlertTitle>You have unsaved changes!</AlertTitle>
            </Alert>
          )}
          {((currentTab === 0 && !savedList.includes('skylightFlashingKit')) ||
            (currentTab === 1 && !savedList.includes('fasteners')) ||
            (currentTab === 2 && !savedList.includes('roofCaulking')) ||
            (currentTab === 3 && !savedList.includes('decking')) ||
            (currentTab === 4 && !savedList.includes('paint')) ||
            (currentTab === 5 && !savedList.includes('skylights'))) && (
            <Alert status="info" marginTop="5">
              <AlertIcon />
              <AlertTitle>Review and Save</AlertTitle>
            </Alert>
          )}
          <Button colorScheme="blue" marginTop="3" onClick={handleSave}>
            Save
          </Button>
        </VStack>
      </Center>
    </Box>
  );
};

export default OtherMaterials;
