import {
  Box,
  Table,
  Tr,
  Thead,
  Tbody,
  Th,
  Td,
  NumberInput,
  NumberInputField,
  Text,
  HStack,
  Checkbox,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  VStack,
  Center,
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Tooltip,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updatePaymentOptions,
  updateGeneralPayment,
  updateCommission,
} from '../../features/labor/paymentSlice';
import { updateHasBeenSaved } from '../../features/labor/laborSlice';

import axios from 'axios';
import useUser from '../../hooks/useUser';
import mixpanel from 'mixpanel-browser';
import { QuestionIcon } from '@chakra-ui/icons';
import Overhead from './../Overhead';

const PaymentOptions = ({
  checkPaymentCompletion,
  isPaymentGeneralComplete,
  isPaymentOptionsComplete,
  isPaymentCommissionComplete,
}) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const payment = useSelector(state => state.payment);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const { user } = useUser();
  const userEmail = user ? user.email : null;

  const savedList = useSelector(state => state.labor.hasBeenSaved);
  const [currentTab, setCurrentTab] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const openHelpModal = () => {
    console.log('help modal');
    setOpenModal(true);
  };

  const handleSave = async () => {
    try {
      // if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
      //   mixpanel.track('User saved the payment labor section.');
      // }
      let tab;
      if (currentTab === 0) {
        tab = 'options';
      } else if (currentTab === 1) {
        tab = 'general';
      } else if (currentTab === 2) {
        tab = 'commission';
      }

      const token = await user.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/labors/payment`,
        {
          username: user.email,
          payment,
          tab,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      dispatch(updateHasBeenSaved(tab));
      setUnsavedChanges(false);
      checkPaymentCompletion(payment, [...savedList, tab]);
    } catch (error) {
      console.log('error: ' + error);
    }

    toast({
      title: 'Changes saved',
      description: 'Your pricing was updated.',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  const handleTabChange = index => {
    setCurrentTab(index);
  };

  return (
    <Box>
      <Tabs
        align="center"
        variant="soft-rounded"
        colorScheme="blue"
        onChange={handleTabChange}
        index={currentTab}
      >
        <TabList>
          <Tab
            as="b"
            bg={isPaymentOptionsComplete ? 'green.200' : 'yellow.200'}
            color="black"
            _selected={{
              border: '2px solid black',
              bg: isPaymentOptionsComplete ? 'green.400' : 'yellow.400',
            }}
          >
            Options
          </Tab>
          <Tab
            as="b"
            bg={isPaymentGeneralComplete ? 'green.200' : 'yellow.200'}
            color="black"
            _selected={{
              border: '2px solid black',
              bg: isPaymentGeneralComplete ? 'green.400' : 'yellow.400',
            }}
          >
            General
          </Tab>
          <Tab
            as="b"
            bg={isPaymentCommissionComplete ? 'green.200' : 'yellow.200'}
            color="black"
            _selected={{
              border: '2px solid black',
              bg: isPaymentCommissionComplete ? 'green.400' : 'yellow.400',
            }}
          >
            Commission
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Option</Th>
                  <Th>Fee</Th>
                  <Th>Years</Th>
                  <Th>Interest Rate</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>
                    <Input
                      size="sm"
                      maxWidth="450px"
                      value={payment.options.oneYearDescription}
                      onChange={event => {
                        setUnsavedChanges(true);
                        dispatch(
                          updatePaymentOptions({
                            newValue: event.target.value,
                            fieldToUpdate: 'oneYearDescription',
                          })
                        );
                      }}
                    ></Input>
                  </Td>
                  <Td>
                    <HStack>
                      <NumberInput
                        size="sm"
                        maxWidth="70px"
                        value={payment.options.oneYearFee ?? ''}
                        onChange={value => {
                          setUnsavedChanges(true);
                          dispatch(
                            updatePaymentOptions({
                              newValue: value,
                              fieldToUpdate: 'oneYearFee',
                            })
                          );
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                      <Text as="b">%</Text>
                    </HStack>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="70px"
                      value={payment.options.oneYearYears ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updatePaymentOptions({
                            newValue: value,
                            fieldToUpdate: 'oneYearYears',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                  <Td>
                    <HStack>
                      <NumberInput
                        size="sm"
                        maxWidth="70px"
                        value={payment.options.oneYearRate ?? ''}
                        onChange={value => {
                          setUnsavedChanges(true);
                          dispatch(
                            updatePaymentOptions({
                              newValue: value,
                              fieldToUpdate: 'oneYearRate',
                            })
                          );
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                      <Text as="b">%</Text>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    {/* <Text>3 year 3.5%</Text> */}
                    <Input
                      size="sm"
                      maxWidth="450px"
                      value={payment.options.threeYearDescription}
                      onChange={event => {
                        setUnsavedChanges(true);
                        dispatch(
                          updatePaymentOptions({
                            newValue: event.target.value,
                            fieldToUpdate: 'threeYearDescription',
                          })
                        );
                      }}
                    ></Input>
                  </Td>
                  <Td>
                    <HStack>
                      <NumberInput
                        size="sm"
                        maxWidth="70px"
                        value={payment.options.threeYearFee ?? ''}
                        onChange={value => {
                          setUnsavedChanges(true);
                          dispatch(
                            updatePaymentOptions({
                              newValue: value,
                              fieldToUpdate: 'threeYearFee',
                            })
                          );
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                      <Text as="b">%</Text>
                    </HStack>
                  </Td>
                  <Td>
                    {/* <Text>3</Text> */}
                    <NumberInput
                      size="sm"
                      maxWidth="70px"
                      value={payment.options.threeYearYears ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updatePaymentOptions({
                            newValue: value,
                            fieldToUpdate: 'threeYearYears',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                  <Td>
                    <HStack>
                      <NumberInput
                        size="sm"
                        maxWidth="70px"
                        value={payment.options.threeYearRate ?? ''}
                        onChange={value => {
                          setUnsavedChanges(true);
                          dispatch(
                            updatePaymentOptions({
                              newValue: value,
                              fieldToUpdate: 'threeYearRate',
                            })
                          );
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                      <Text as="b">%</Text>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    {/* <Text>7 year 6.9%</Text> */}
                    <Input
                      size="sm"
                      maxWidth="450px"
                      value={payment.options.sevenYearDescription}
                      onChange={event => {
                        setUnsavedChanges(true);
                        dispatch(
                          updatePaymentOptions({
                            newValue: event.target.value,
                            fieldToUpdate: 'sevenYearDescription',
                          })
                        );
                      }}
                    ></Input>
                  </Td>
                  <Td>
                    <HStack>
                      <NumberInput
                        size="sm"
                        maxWidth="70px"
                        value={payment.options.sevenYearFee ?? ''}
                        onChange={value => {
                          setUnsavedChanges(true);
                          dispatch(
                            updatePaymentOptions({
                              newValue: value,
                              fieldToUpdate: 'sevenYearFee',
                            })
                          );
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                      <Text as="b">%</Text>
                    </HStack>
                  </Td>
                  <Td>
                    {/* <Text>7</Text> */}
                    <NumberInput
                      size="sm"
                      maxWidth="70px"
                      value={payment.options.sevenYearYears ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updatePaymentOptions({
                            newValue: value,
                            fieldToUpdate: 'sevenYearYears',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                  <Td>
                    <HStack>
                      <NumberInput
                        size="sm"
                        maxWidth="70px"
                        value={payment.options.sevenYearRate ?? ''}
                        onChange={value => {
                          setUnsavedChanges(true);
                          dispatch(
                            updatePaymentOptions({
                              newValue: value,
                              fieldToUpdate: 'sevenYearRate',
                            })
                          );
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                      <Text as="b">%</Text>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    {/* <Text>Cash</Text> */}
                    <Input
                      size="sm"
                      maxWidth="450px"
                      value={payment.options.cashDescription}
                      onChange={event => {
                        setUnsavedChanges(true);
                        dispatch(
                          updatePaymentOptions({
                            newValue: event.target.value,
                            fieldToUpdate: 'cashDescription',
                          })
                        );
                      }}
                    ></Input>
                  </Td>
                  <Td>
                    <HStack>
                      <NumberInput
                        size="sm"
                        maxWidth="70px"
                        value={payment.options.cashFee ?? ''}
                        onChange={value => {
                          setUnsavedChanges(true);
                          dispatch(
                            updatePaymentOptions({
                              newValue: value,
                              fieldToUpdate: 'cashFee',
                            })
                          );
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                      <Text as="b">%</Text>
                    </HStack>
                  </Td>
                  <Td>
                    {/* <Text>0</Text> */}
                    <NumberInput
                      size="sm"
                      maxWidth="70px"
                      value={payment.options.cashYears ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updatePaymentOptions({
                            newValue: value,
                            fieldToUpdate: 'cashYears',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                  <Td>
                    <HStack>
                      <NumberInput
                        size="sm"
                        maxWidth="70px"
                        value={payment.options.cashRate ?? ''}
                        onChange={value => {
                          setUnsavedChanges(true);
                          dispatch(
                            updatePaymentOptions({
                              newValue: value,
                              fieldToUpdate: 'cashRate',
                            })
                          );
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                      <Text as="b">%</Text>
                    </HStack>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
            <Text as="i" fontSize="xs">
              The final option is the default and is always available
            </Text>
          </TabPanel>
          <TabPanel>
            <Overhead openModal={openModal} setOpenModal={setOpenModal} />
            <Table p="10" size="sm">
              <Tbody>
                <Tr>
                  <Td>
                    <Text>User Name</Text>
                  </Td>
                  <Td>
                    <Text>{userEmail}</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>Company Name</Text>
                  </Td>
                  <Td>
                    <HStack>
                      <Input
                        size="sm"
                        maxWidth="450px"
                        value={payment.general.companyName}
                        onChange={event => {
                          setUnsavedChanges(true);
                          dispatch(
                            updateGeneralPayment({
                              newValue: event.target.value,
                              fieldToUpdate: 'companyName',
                            })
                          );
                        }}
                      ></Input>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>Address Line 1</Text>
                  </Td>
                  <Td>
                    <HStack>
                      <Input
                        size="sm"
                        maxWidth="450px"
                        value={payment.general.companyAddressLineOne}
                        onChange={event => {
                          setUnsavedChanges(true);
                          dispatch(
                            updateGeneralPayment({
                              newValue: event.target.value,
                              fieldToUpdate: 'companyAddressLineOne',
                            })
                          );
                        }}
                      ></Input>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>Address Line 2</Text>
                  </Td>
                  <Td>
                    <HStack>
                      <Input
                        size="sm"
                        maxWidth="450px"
                        value={payment.general.companyAddressLineTwo}
                        onChange={event => {
                          setUnsavedChanges(true);
                          dispatch(
                            updateGeneralPayment({
                              newValue: event.target.value,
                              fieldToUpdate: 'companyAddressLineTwo',
                            })
                          );
                        }}
                      ></Input>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>Phone Number</Text>
                  </Td>
                  <Td>
                    <HStack>
                      <Input
                        size="sm"
                        maxWidth="450px"
                        value={payment.general.companyPhoneNumber}
                        onChange={event => {
                          setUnsavedChanges(true);
                          dispatch(
                            updateGeneralPayment({
                              newValue: event.target.value,
                              fieldToUpdate: 'companyPhoneNumber',
                            })
                          );
                        }}
                      ></Input>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>Email</Text>
                  </Td>
                  <Td>
                    <HStack>
                      <Input
                        size="sm"
                        maxWidth="450px"
                        value={payment.general.companyEmail}
                        onChange={event => {
                          setUnsavedChanges(true);
                          dispatch(
                            updateGeneralPayment({
                              newValue: event.target.value,
                              fieldToUpdate: 'companyEmail',
                            })
                          );
                        }}
                      ></Input>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>Sales tax on material</Text>
                  </Td>
                  <Td>
                    <HStack>
                      <NumberInput
                        size="sm"
                        maxWidth="150px"
                        value={payment.general.salesTax ?? ''}
                        onChange={value => {
                          setUnsavedChanges(true);
                          dispatch(
                            updateGeneralPayment({
                              newValue: value,
                              fieldToUpdate: 'salesTax',
                            })
                          );
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                      <Text as="b">%</Text>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>
                      Percentage to Deduct From Material Cost For Labor Only
                    </Text>
                  </Td>
                  <Td>
                    <HStack>
                      <NumberInput
                        size="sm"
                        maxWidth="150px"
                        value={payment.general.laborDeduction ?? ''}
                        onChange={value => {
                          setUnsavedChanges(true);
                          dispatch(
                            updateGeneralPayment({
                              newValue: value,
                              fieldToUpdate: 'laborDeduction',
                            })
                          );
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                      <Text as="b">%</Text>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>
                      Amount To Add To Estimate For Unforeseen Expenses
                    </Text>
                  </Td>
                  <Td>
                    <HStack>
                      <Text as="b">$</Text>
                      <NumberInput
                        size="sm"
                        maxWidth="150px"
                        value={payment.general.unforeseenExpenses ?? ''}
                        onChange={value => {
                          setUnsavedChanges(true);
                          dispatch(
                            updateGeneralPayment({
                              newValue: value,
                              fieldToUpdate: 'unforeseenExpenses',
                            })
                          );
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <HStack>
                      <Text>Overhead %</Text>
                      {/* <Tooltip
                        hasArrow
                        label={
                          <Box>
                            <Text>Click for help</Text>
                          </Box>
                        }
                        bg="gray.300"
                        color="black"
                        fontSize="sm"
                      >
                        <QuestionIcon
                          onClick={() => {
                            openHelpModal();
                          }}
                        ></QuestionIcon>
                      </Tooltip> */}
                    </HStack>
                  </Td>
                  <Td>
                    <HStack>
                      <NumberInput
                        size="sm"
                        maxWidth="150px"
                        value={payment.general.overhead ?? ''}
                        onChange={value => {
                          setUnsavedChanges(true);
                          dispatch(
                            updateGeneralPayment({
                              newValue: value,
                              fieldToUpdate: 'overhead',
                            })
                          );
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                      <Text as="b">%</Text>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <HStack>
                      {/* <QuestionIcon
                        onClick={() => {
                          openHelpModal();
                        }}
                      ></QuestionIcon> */}
                      <Text>Profit Margin %</Text>
                    </HStack>
                  </Td>
                  <Td>
                    <HStack>
                      <NumberInput
                        size="sm"
                        maxWidth="150px"
                        value={payment.general.profitMargin ?? ''}
                        onChange={value => {
                          setUnsavedChanges(true);
                          dispatch(
                            updateGeneralPayment({
                              newValue: value,
                              fieldToUpdate: 'profitMargin',
                            })
                          );
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                      <Text as="b">%</Text>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>Approx. income tax on sales income</Text>
                  </Td>
                  <Td>
                    <HStack>
                      <NumberInput
                        size="sm"
                        maxWidth="150px"
                        value={payment.general.salesIncomeTax ?? ''}
                        onChange={value => {
                          setUnsavedChanges(true);
                          dispatch(
                            updateGeneralPayment({
                              newValue: value,
                              fieldToUpdate: 'salesIncomeTax',
                            })
                          );
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                      <Text as="b">%</Text>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>Approx. income tax on company profit</Text>
                  </Td>
                  <Td>
                    <HStack>
                      <NumberInput
                        size="sm"
                        maxWidth="150px"
                        value={payment.general.profitIncomeTax ?? ''}
                        onChange={value => {
                          setUnsavedChanges(true);
                          dispatch(
                            updateGeneralPayment({
                              newValue: value,
                              fieldToUpdate: 'profitIncomeTax',
                            })
                          );
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                      <Text as="b">%</Text>
                    </HStack>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TabPanel>
          <TabPanel>
            <Table p="10" size="sm">
              <Tbody>
                <Tr>
                  <Td rowSpan={3}>
                    <Text>Sales Commission</Text>
                  </Td>
                  <Td>
                    <Checkbox
                      isChecked={payment.commission.isFlatRate}
                      onChange={event => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateCommission({
                            newValue: event.target.checked,
                            fieldToUpdate: 'isFlatRate',
                          })
                        );
                        dispatch(
                          updateCommission({
                            newValue: false,
                            fieldToUpdate: 'isPercentageOfSale',
                          })
                        );
                        dispatch(
                          updateCommission({
                            newValue: false,
                            fieldToUpdate: 'isPercentageOfProfit',
                          })
                        );
                      }}
                    />
                  </Td>
                  <Td>
                    <Text>Flat Rate</Text>
                  </Td>
                  <Td>
                    <HStack>
                      <Text as="b">$</Text>
                      <NumberInput
                        size="sm"
                        maxWidth="150px"
                        value={payment.commission.flatRate ?? ''}
                        onChange={value => {
                          setUnsavedChanges(true);
                          dispatch(
                            updateCommission({
                              newValue: value,
                              fieldToUpdate: 'flatRate',
                            })
                          );
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Checkbox
                      isChecked={payment.commission.isPercentageOfSale}
                      onChange={event => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateCommission({
                            newValue: event.target.checked,
                            fieldToUpdate: 'isPercentageOfSale',
                          })
                        );
                        dispatch(
                          updateCommission({
                            newValue: false,
                            fieldToUpdate: 'isPercentageOfProfit',
                          })
                        );
                        dispatch(
                          updateCommission({
                            newValue: false,
                            fieldToUpdate: 'isFlatRate',
                          })
                        );
                      }}
                    />
                  </Td>
                  <Td>
                    <Text>Percentage of Sale</Text>
                  </Td>
                  <Td>
                    <HStack>
                      <NumberInput
                        size="sm"
                        maxWidth="150px"
                        value={payment.commission.percentageOfSale ?? ''}
                        onChange={value => {
                          setUnsavedChanges(true);
                          dispatch(
                            updateCommission({
                              newValue: value,
                              fieldToUpdate: 'percentageOfSale',
                            })
                          );
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                      <Text as="b">%</Text>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Checkbox
                      isChecked={payment.commission.isPercentageOfProfit}
                      onChange={event => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateCommission({
                            newValue: event.target.checked,
                            fieldToUpdate: 'isPercentageOfProfit',
                          })
                        );
                        dispatch(
                          updateCommission({
                            newValue: false,
                            fieldToUpdate: 'isFlatRate',
                          })
                        );
                        dispatch(
                          updateCommission({
                            newValue: false,
                            fieldToUpdate: 'isPercentageOfSale',
                          })
                        );
                      }}
                    />
                  </Td>
                  <Td>
                    <Text>Percentage of Net Profit</Text>
                  </Td>
                  <Td>
                    <HStack>
                      <NumberInput
                        size="sm"
                        maxWidth="150px"
                        value={payment.commission.percentageOfProfit ?? ''}
                        onChange={value => {
                          setUnsavedChanges(true);
                          dispatch(
                            updateCommission({
                              newValue: value,
                              fieldToUpdate: 'percentageOfProfit',
                            })
                          );
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                      <Text as="b">%</Text>
                    </HStack>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Center>
        <VStack>
          {unsavedChanges && (
            <Alert status="warning" marginTop="5">
              <AlertIcon />
              <AlertTitle>You have unsaved changes!</AlertTitle>
            </Alert>
          )}
          {((currentTab === 0 && !savedList.includes('options')) ||
            (currentTab === 1 && !savedList.includes('general')) ||
            (currentTab === 2 && !savedList.includes('commission'))) && (
            <Alert status="info" marginTop="5">
              <AlertIcon />
              <AlertTitle>Review and Save</AlertTitle>
            </Alert>
          )}
          <Button colorScheme="blue" marginTop="3" onClick={handleSave}>
            Save
          </Button>
        </VStack>
      </Center>
    </Box>
  );
};

export default PaymentOptions;
