import {
  Box,
  Button,
  Text,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Table,
  HStack,
  Checkbox,
  TableContainer,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useDisclosure,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  Center,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  addToDetailsRoofList,
  removeFromDetailsRoofList,
  updateDetailsIsCompleted,
  updateDetailsRoofListPitch,
  updateDetailsRoofListStories,
  updateDetailsRoofListLayers,
  updateDetailsRoofListShakes,
  updateDetailsRoofListRedeck,
  updateDetailsRoofListHandSeal,
  updateDetailsRoofListSquares,
} from '../features/calculator/calculatorSlice';
import useUser from '../hooks/useUser';
import mixpanel from 'mixpanel-browser';

const Details = ({
  checkDetailsCompletion,
  isDetailsComplete,
  setCalculateButtonClicked,
  totalSquares,
  totalSquaresWithWaste,
  isMobileDevice,
}) => {
  const { user } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();

  const details = useSelector(state => state.calculator.details);
  const roofList = useSelector(state => state.calculator.details.roofList);
  const [saveLoading, setSaveLoading] = useState(false);
  const [unsavedChanged, setUnsavedChanges] = useState(false);

  const handleAddRow = () => {
    setUnsavedChanges(true);
    let tempRoofNumber;
    if (roofList.length > 0) {
      tempRoofNumber = roofList[roofList.length - 1].roofNumber + 1;
    } else {
      tempRoofNumber = 1;
    }
    const newRow = {
      roofNumber: tempRoofNumber,
      squares: '',
      pitch: '',
      stories: '',
      layers: '',
      shakes: false,
      redeck: false,
      handSeal: false,
    };
    dispatch(addToDetailsRoofList(newRow));
  };

  const handleSelectPitchChange = (newValue, roofNumber) => {
    setUnsavedChanges(true);
    dispatch(updateDetailsRoofListPitch({ newValue, roofNumber }));
  };

  const handleStoriesChange = (newValue, roofNumber) => {
    setUnsavedChanges(true);
    dispatch(updateDetailsRoofListStories({ newValue, roofNumber }));
  };

  const handleLayersChange = (newValue, roofNumber) => {
    setUnsavedChanges(true);
    dispatch(updateDetailsRoofListLayers({ newValue, roofNumber }));
  };

  const handleSquaresChange = (newValue, roofNumber) => {
    setUnsavedChanges(true);
    dispatch(updateDetailsRoofListSquares({ newValue, roofNumber }));
  };

  const handleShakesCheckboxChange = (newValue, roofNumber) => {
    setUnsavedChanges(true);
    dispatch(updateDetailsRoofListShakes({ newValue, roofNumber }));
  };

  const handleRedeckCheckboxChange = (newValue, roofNumber) => {
    setUnsavedChanges(true);
    dispatch(updateDetailsRoofListRedeck({ newValue, roofNumber }));
  };

  const handleHandSealCheckboxChange = (newValue, roofNumber) => {
    setUnsavedChanges(true);
    dispatch(updateDetailsRoofListHandSeal({ newValue, roofNumber }));
  };

  const handleDeleteRow = id => {
    setUnsavedChanges(true);
    dispatch(removeFromDetailsRoofList(id));
  };

  const handleOpen = async () => {
    // if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    //   mixpanel.track('User opened the Details modal.');
    // }
    onOpen();
  };

  const handleSave = async () => {
    setSaveLoading(true);
    try {
      const token = await user.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/roofs/details`,
        {
          username: user.email,
          roofList: roofList,
          isCompleted: false,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      setUnsavedChanges(false);
      checkDetailsCompletion({ roofList: roofList });
      setCalculateButtonClicked(true);
      onClose();
    } catch (error) {
      console.log('error: ' + error);
    }
    setSaveLoading(false);
  };

  return (
    <Box
      minW={isMobileDevice ? '90vw' : ''}
      maxH="70px"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={isMobileDevice ? '3' : '6'}
      boxShadow="base"
      borderColor="gray.400"
      bg={isDetailsComplete ? 'green.300' : 'yellow.200'}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      position="relative"
      cursor="pointer"
      onClick={isMobileDevice ? handleOpen : null}
    >
      <Flex alignItems="center">
        <Text fontWeight="bold" fontSize="l" mr="4">
          Details
        </Text>
        {!isMobileDevice && (
          <Button
            size="sm"
            border="1px"
            borderColor="gray.400"
            ml="30px"
            minWidth="53px"
            onClick={handleOpen}
          >
            <Text>Edit</Text>
          </Button>
        )}
        <Modal isOpen={isOpen} size="custom" onClose={onClose}>
          <ModalOverlay />
          <ModalContent maxW={isMobileDevice ? '100vw' : '80vw'}>
            <ModalHeader>
              <HStack spacing={isMobileDevice ? '10px' : '120px'}>
                {!isMobileDevice && <Text>Details</Text>}
                <HStack>
                  <NumberInput
                    size={isMobileDevice ? 'xs' : 'sm'}
                    maxWidth="150px"
                    isReadOnly={true}
                    variant="filled"
                    value={totalSquares ?? ''}
                  >
                    <NumberInputField />
                  </NumberInput>
                  <Text fontSize={isMobileDevice ? 'xs' : 'sm'} as="span">
                    Total squares
                  </Text>
                </HStack>
                <HStack>
                  <NumberInput
                    size={isMobileDevice ? 'xs' : 'sm'}
                    maxWidth="150px"
                    isReadOnly={true}
                    variant="filled"
                    value={totalSquaresWithWaste ?? ''}
                  >
                    <NumberInputField />
                  </NumberInput>
                  <Text fontSize={isMobileDevice ? 'xs' : 'sm'} as="span">
                    Total squares with waste
                  </Text>
                </HStack>
              </HStack>
            </ModalHeader>
            <ModalBody>
              <Box overflowX="auto">
                <TableContainer>
                  <Table variant="simple" size={isMobileDevice ? 'sm' : 'sm'}>
                    <Thead>
                      <Tr>
                        <Th>
                          {!isMobileDevice && <Text fontSize="xs">Roof</Text>}
                          {isMobileDevice && <Text fontSize="xs">##</Text>}
                        </Th>
                        <Th>
                          <Text fontSize="xs">Squares</Text>
                        </Th>
                        <Th>
                          <Text fontSize="xs">Pitch</Text>
                        </Th>
                        <Th>
                          <Text fontSize="xs">Stories</Text>
                        </Th>
                        <Th>
                          <Text fontSize="xs">Layers</Text>
                        </Th>
                        <Th>
                          <HStack justify="center">
                            <Text fontSize="xs">Shakes</Text>
                          </HStack>
                        </Th>
                        <Th>
                          <HStack justify="center">
                            <Text fontSize="xs">Redeck</Text>
                          </HStack>
                        </Th>
                        <Th>
                          <HStack justify="center">
                            <Text fontSize="xs">Hand Seal</Text>
                          </HStack>
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {roofList.map(row => (
                        <Tr key={row.roofNumber}>
                          <Td>{row.roofNumber}</Td>
                          <Td>
                            <NumberInput
                              size={isMobileDevice ? 'xs' : 'md'}
                              value={row.squares ?? ''}
                              onChange={value =>
                                handleSquaresChange(value, row.roofNumber)
                              }
                              maxWidth="120px"
                              minWidth={isMobileDevice ? '50px' : '100px'}
                            >
                              <NumberInputField />
                            </NumberInput>
                          </Td>
                          <Td>
                            <Select
                              value={row.pitch}
                              onChange={event =>
                                handleSelectPitchChange(
                                  event.target.value,
                                  row.roofNumber
                                )
                              }
                              size={isMobileDevice ? 'xs' : 'md'}
                              maxWidth="120px"
                              minWidth={isMobileDevice ? '80px' : '100px'}
                            >
                              <option value=""></option>
                              <option value="3/12">3/12</option>
                              <option value="4/12">4/12</option>
                              <option value="5/12">5/12</option>
                              <option value="6/12">6/12</option>
                              <option value="7/12">7/12</option>
                              <option value="8/12">8/12</option>
                              <option value="9/12">9/12</option>
                              <option value="10/12">10/12</option>
                              <option value="11/12">11/12</option>
                              <option value="12/12">12/12</option>
                              <option value="13/12">13/12</option>
                              <option value="14/12">14/12</option>
                              <option value="15/12">15/12</option>
                              <option value="16/12">16/12</option>
                              <option value="17/12">17/12</option>
                              <option value="18/12">18/12</option>
                            </Select>
                          </Td>
                          <Td>
                            <NumberInput
                              max={5}
                              min={1}
                              value={row.stories ?? ''}
                              onChange={value =>
                                handleStoriesChange(value, row.roofNumber)
                              }
                              size={isMobileDevice ? 'xs' : 'md'}
                              maxWidth="120px"
                              minWidth={isMobileDevice ? '80px' : '100px'}
                            >
                              <NumberInputField />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </Td>
                          <Td>
                            <NumberInput
                              max={5}
                              min={1}
                              value={row.layers ?? ''}
                              onChange={value =>
                                handleLayersChange(value, row.roofNumber)
                              }
                              size={isMobileDevice ? 'xs' : 'md'}
                              maxWidth="120px"
                              minWidth={isMobileDevice ? '80px' : '100px'}
                            >
                              <NumberInputField />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                          </Td>
                          <Td>
                            <HStack align="center" justify="center">
                              <Checkbox
                                isChecked={row.shakes}
                                onChange={event =>
                                  handleShakesCheckboxChange(
                                    event.target.checked,
                                    row.roofNumber
                                  )
                                }
                              ></Checkbox>
                            </HStack>
                          </Td>
                          <Td>
                            <HStack align="center" justify="center">
                              <Checkbox
                                isChecked={row.redeck}
                                onChange={event =>
                                  handleRedeckCheckboxChange(
                                    event.target.checked,
                                    row.roofNumber
                                  )
                                }
                              ></Checkbox>
                            </HStack>
                          </Td>
                          <Td>
                            <HStack align="center" justify="center">
                              <Checkbox
                                isChecked={row.handSeal}
                                onChange={event =>
                                  handleHandSealCheckboxChange(
                                    event.target.checked,
                                    row.roofNumber
                                  )
                                }
                              ></Checkbox>
                            </HStack>
                          </Td>
                          <Td>
                            <IconButton
                              aria-label="Delete Row"
                              size={isMobileDevice ? 'xs' : 'md'}
                              icon={<DeleteIcon />}
                              onClick={() => handleDeleteRow(row.roofNumber)}
                            />
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
                <IconButton
                  mt={4}
                  aria-label="Add Row"
                  icon={<AddIcon />}
                  onClick={handleAddRow}
                />
              </Box>
            </ModalBody>
            <ModalFooter>
              {unsavedChanged && (
                <Box width="30%">
                  <Alert status="warning">
                    <AlertIcon />
                    <AlertTitle>You have unsaved changes!</AlertTitle>
                  </Alert>
                </Box>
              )}
              <Button onClick={handleSave} colorScheme="blue" ml="60px">
                {saveLoading ? (
                  <Spinner
                    thickness="4px"
                    speed="1.25s"
                    size="md"
                    emptyColor="gray.200"
                    color="gray.800"
                  />
                ) : (
                  <Text>Save</Text>
                )}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </Box>
  );
};

export default Details;
