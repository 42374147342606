import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Center,
  Text,
  VStack,
  NumberInput,
  NumberInputField,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Alert,
  AlertIcon,
  AlertTitle,
  useToast,
  Button,
  HStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateExhaustFlashingField,
  updateNeoprenePipeFlashingField,
  updateFrostFreePipeFlashingField,
  updateSplitBootPipeFlashingField,
  updateMetalPipeRoofFlashingField,
  updateGuttersDripEdgeField,
  updateGuttersApronField,
  updateStepFlashingField,
  updateRegletFlashingField,
  updateOneByFourFlashingField,
  updateDormerFlashingField,
  updateValleyFlashingField,
  updateHasBeenSaved,
} from '../../features/price/priceSlice';
import axios from 'axios';
import useUser from '../../hooks/useUser';
import mixpanel from 'mixpanel-browser';

const FlashingPrices = ({
  checkFlashingCompletion,
  isKitchenComplete,
  isPipeFlashingComplete,
  isMetalFlashingComplete,
  isGuttersFlashingComplete,
  isOtherFlashingComplete,
}) => {
  const dispatch = useDispatch();
  const flashing = useSelector(state => state.price.flashing);
  const toast = useToast();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const { user } = useUser();
  const savedList = useSelector(state => state.price.hasBeenSaved);
  const [currentTab, setCurrentTab] = useState(0);

  const handleSave = async () => {
    try {
      // if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
      //   mixpanel.track('User saved the Flashing prices.');
      // }
      let tab;
      if (currentTab === 0) {
        tab = 'bathExhaust';
      } else if (currentTab === 1) {
        tab = 'pipeFlashing';
      } else if (currentTab === 2) {
        tab = 'metalPipeRoofFlashing';
      } else if (currentTab === 3) {
        tab = 'gutters';
      } else if (currentTab === 4) {
        tab = 'otherFlashing';
      }

      const token = await user.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/prices/flashing`,
        {
          username: user.email,
          flashing,
          tab,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      dispatch(updateHasBeenSaved(tab));
      checkFlashingCompletion(flashing, [...savedList, tab]);
      setUnsavedChanges(false);
    } catch (error) {
      console.log('error: ' + error);
    }

    toast({
      title: 'Changes saved',
      description: 'Your pricing was updated.',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  const handleTabChange = index => {
    setCurrentTab(index);
  };

  return (
    // <Center m="20px">
    <Box>
      <Tabs
        align="center"
        variant="soft-rounded"
        colorScheme="blue"
        onChange={handleTabChange}
        index={currentTab}
      >
        <TabList>
          <Tab
            as="b"
            bg={isKitchenComplete ? 'green.200' : 'yellow.200'}
            color="black"
            _selected={{
              border: '2px solid black',
              bg: isKitchenComplete ? 'green.400' : 'yellow.400',
            }}
          >
            Kitchen/Bath Exhaust
          </Tab>
          <Tab
            as="b"
            bg={isPipeFlashingComplete ? 'green.200' : 'yellow.200'}
            color="black"
            _selected={{
              border: '2px solid black',
              bg: isPipeFlashingComplete ? 'green.400' : 'yellow.400',
            }}
          >
            Pipe Flashing
          </Tab>
          <Tab
            as="b"
            bg={isMetalFlashingComplete ? 'green.200' : 'yellow.200'}
            color="black"
            _selected={{
              border: '2px solid black',
              bg: isMetalFlashingComplete ? 'green.400' : 'yellow.400',
            }}
          >
            Metal Pipe Roof Flashing
          </Tab>
          <Tab
            as="b"
            bg={isGuttersFlashingComplete ? 'green.200' : 'yellow.200'}
            color="black"
            _selected={{
              border: '2px solid black',
              bg: isGuttersFlashingComplete ? 'green.400' : 'yellow.400',
            }}
          >
            Gutters
          </Tab>
          <Tab
            as="b"
            bg={isOtherFlashingComplete ? 'green.200' : 'yellow.200'}
            color="black"
            _selected={{
              border: '2px solid black',
              bg: isOtherFlashingComplete ? 'green.400' : 'yellow.400',
            }}
          >
            Other Flashing
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <VStack>
              <div>
                <Center>
                  <Text fontWeight="bold" ml="6">
                    Kitchen/Bath Exhaust
                  </Text>
                </Center>
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th fontSize="xs">Manufacturer</Th>
                      <Th fontSize="xs">Size</Th>
                      <Th fontSize="xs">Price per unit</Th>
                      <Th fontSize="xs">Color</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        <Input
                          size="xs"
                          value={flashing.exhaust.smallManufacturer}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateExhaustFlashingField({
                                newValue: event.target.value,
                                fieldToUpdate: 'smallManufacturer',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Text>Small</Text>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          maxWidth="80px"
                          value={flashing.exhaust.smallPricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateExhaustFlashingField({
                                newValue: value,
                                fieldToUpdate: 'smallPricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                      <Td>
                        <Input
                          size="xs"
                          maxWidth="100px"
                          value={flashing.exhaust.smallColor}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateExhaustFlashingField({
                                newValue: event.target.value,
                                fieldToUpdate: 'smallColor',
                              })
                            );
                          }}
                        />
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Input
                          size="xs"
                          value={flashing.exhaust.largeManufacturer}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateExhaustFlashingField({
                                newValue: event.target.value,
                                fieldToUpdate: 'largeManufacturer',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Text>Large</Text>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          maxWidth="80px"
                          value={flashing.exhaust.largePricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateExhaustFlashingField({
                                newValue: value,
                                fieldToUpdate: 'largePricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                      <Td>
                        <Input
                          size="xs"
                          maxWidth="100px"
                          value={flashing.exhaust.largeColor}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateExhaustFlashingField({
                                newValue: event.target.value,
                                fieldToUpdate: 'largeColor',
                              })
                            );
                          }}
                        />
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </div>
            </VStack>
          </TabPanel>
          <TabPanel>
            <VStack>
              <div>
                <Center>
                  <Text fontWeight="bold" ml="6">
                    Pipe Flashing
                  </Text>
                </Center>
                <Table size="sm" borderWidth="2px" borderColor="gray.800">
                  <Thead>
                    <Tr>
                      <Th fontSize="xs">Type</Th>
                      <Th fontSize="xs">Color</Th>
                      <Th fontSize="xs">Size</Th>
                      <Th fontSize="xs">Price</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td rowSpan={6} borderColor="gray.900">
                        <Text>Neoprene</Text>
                      </Td>
                      <Td rowSpan={6} borderColor="gray.900">
                        <Input
                          borderColor="gray.300"
                          maxWidth="150px"
                          size="sm"
                          value={flashing.pipe.neoprene.color}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateNeoprenePipeFlashingField({
                                newValue: event.target.value,
                                fieldToUpdate: 'color',
                              })
                            );
                          }}
                        ></Input>
                      </Td>
                      <Td>
                        <Text>1"</Text>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          maxWidth="80px"
                          value={flashing.pipe.neoprene.onePricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateNeoprenePipeFlashingField({
                                newValue: value,
                                fieldToUpdate: 'onePricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Text>1 1/2"</Text>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          maxWidth="80px"
                          value={
                            flashing.pipe.neoprene.oneAndHalfPricePer ?? ''
                          }
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateNeoprenePipeFlashingField({
                                newValue: value,
                                fieldToUpdate: 'oneAndHalfPricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Text>2"</Text>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          maxWidth="80px"
                          value={flashing.pipe.neoprene.twoPricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateNeoprenePipeFlashingField({
                                newValue: value,
                                fieldToUpdate: 'twoPricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Text>2 1/2"</Text>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          maxWidth="80px"
                          value={
                            flashing.pipe.neoprene.twoAndHalfPricePer ?? ''
                          }
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateNeoprenePipeFlashingField({
                                newValue: value,
                                fieldToUpdate: 'twoAndHalfPricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Text>3"</Text>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          maxWidth="80px"
                          value={flashing.pipe.neoprene.threePricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateNeoprenePipeFlashingField({
                                newValue: value,
                                fieldToUpdate: 'threePricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td borderColor="gray.800">
                        <Text>4"</Text>
                      </Td>
                      <Td borderColor="gray.800">
                        <NumberInput
                          size="xs"
                          maxWidth="80px"
                          value={flashing.pipe.neoprene.fourPricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateNeoprenePipeFlashingField({
                                newValue: value,
                                fieldToUpdate: 'fourPricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td rowSpan={6} borderColor="gray.900">
                        <Text>Frost Free</Text>
                      </Td>
                      <Td rowSpan={6} borderColor="gray.900">
                        <Input
                          borderColor="gray.300"
                          maxWidth="150px"
                          size="sm"
                          value={flashing.pipe.frostFree.color}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateFrostFreePipeFlashingField({
                                newValue: event.target.value,
                                fieldToUpdate: 'color',
                              })
                            );
                          }}
                        ></Input>
                      </Td>
                      <Td>
                        <Text>1"</Text>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          maxWidth="80px"
                          value={flashing.pipe.frostFree.onePricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateFrostFreePipeFlashingField({
                                newValue: value,
                                fieldToUpdate: 'onePricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Text>1 1/2"</Text>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          maxWidth="80px"
                          value={
                            flashing.pipe.frostFree.oneAndHalfPricePer ?? ''
                          }
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateFrostFreePipeFlashingField({
                                newValue: value,
                                fieldToUpdate: 'oneAndHalfPricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Text>2"</Text>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          maxWidth="80px"
                          value={flashing.pipe.frostFree.twoPricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateFrostFreePipeFlashingField({
                                newValue: value,
                                fieldToUpdate: 'twoPricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Text>2 1/2"</Text>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          maxWidth="80px"
                          value={
                            flashing.pipe.frostFree.twoAndHalfPricePer ?? ''
                          }
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateFrostFreePipeFlashingField({
                                newValue: value,
                                fieldToUpdate: 'twoAndHalfPricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Text>3"</Text>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          maxWidth="80px"
                          value={flashing.pipe.frostFree.threePricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateFrostFreePipeFlashingField({
                                newValue: value,
                                fieldToUpdate: 'threePricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td borderColor="gray.900">
                        <Text>4"</Text>
                      </Td>
                      <Td borderColor="gray.900">
                        <NumberInput
                          size="xs"
                          maxWidth="80px"
                          value={flashing.pipe.frostFree.fourPricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateFrostFreePipeFlashingField({
                                newValue: value,
                                fieldToUpdate: 'fourPricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td rowSpan={6}>
                        <Text>Split Boot</Text>
                      </Td>
                      <Td rowSpan={6} borderColor="gray.900">
                        <Input
                          borderColor="gray.300"
                          maxWidth="150px"
                          size="sm"
                          value={flashing.pipe.splitBoot.color}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateSplitBootPipeFlashingField({
                                newValue: event.target.value,
                                fieldToUpdate: 'color',
                              })
                            );
                          }}
                        ></Input>
                      </Td>
                      <Td>
                        <Text>1"</Text>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          maxWidth="80px"
                          value={flashing.pipe.splitBoot.onePricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateSplitBootPipeFlashingField({
                                newValue: value,
                                fieldToUpdate: 'onePricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Text>1 1/2"</Text>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          maxWidth="80px"
                          value={
                            flashing.pipe.splitBoot.oneAndHalfPricePer ?? ''
                          }
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateSplitBootPipeFlashingField({
                                newValue: value,
                                fieldToUpdate: 'oneAndHalfPricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Text>2"</Text>
                      </Td>
                      <Td>
                        <NumberInput
                          maxWidth="80px"
                          size="xs"
                          value={flashing.pipe.splitBoot.twoPricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateSplitBootPipeFlashingField({
                                newValue: value,
                                fieldToUpdate: 'twoPricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Text>2 1/2"</Text>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          maxWidth="80px"
                          value={
                            flashing.pipe.splitBoot.twoAndHalfPricePer ?? ''
                          }
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateSplitBootPipeFlashingField({
                                newValue: value,
                                fieldToUpdate: 'twoAndHalfPricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Text>3"</Text>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          maxWidth="80px"
                          value={flashing.pipe.splitBoot.threePricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateSplitBootPipeFlashingField({
                                newValue: value,
                                fieldToUpdate: 'threePricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Text>4"</Text>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          maxWidth="80px"
                          value={flashing.pipe.splitBoot.fourPricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateSplitBootPipeFlashingField({
                                newValue: value,
                                fieldToUpdate: 'fourPricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </div>
            </VStack>
          </TabPanel>
          <TabPanel>
            <VStack>
              <div>
                <Center>
                  <Text fontWeight="bold" ml="6">
                    Metal Pipe Roof Flashing
                  </Text>
                </Center>
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th fontSize="xs">Size</Th>
                      <Th fontSize="xs">Price per unit</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        <Text>4"</Text>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          maxWidth="80px"
                          value={flashing.metalPipeRoof.fourPricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateMetalPipeRoofFlashingField({
                                newValue: value,
                                fieldToUpdate: 'fourPricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Text>5"</Text>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          maxWidth="80px"
                          value={flashing.metalPipeRoof.fivePricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateMetalPipeRoofFlashingField({
                                newValue: value,
                                fieldToUpdate: 'fivePricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Text>6"</Text>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          maxWidth="80px"
                          value={flashing.metalPipeRoof.sixPricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateMetalPipeRoofFlashingField({
                                newValue: value,
                                fieldToUpdate: 'sixPricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Text>8"</Text>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          maxWidth="80px"
                          value={flashing.metalPipeRoof.eightPricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateMetalPipeRoofFlashingField({
                                newValue: value,
                                fieldToUpdate: 'eightPricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Text>10"</Text>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          maxWidth="80px"
                          value={flashing.metalPipeRoof.tenPricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateMetalPipeRoofFlashingField({
                                newValue: value,
                                fieldToUpdate: 'tenPricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Text>12"</Text>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          maxWidth="80px"
                          value={flashing.metalPipeRoof.twelvePricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateMetalPipeRoofFlashingField({
                                newValue: value,
                                fieldToUpdate: 'twelvePricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </div>
              <div>
                <HStack paddingTop="5px" paddingBottom="5px">
                  <Text fontSize="sm">Manufacturer:</Text>
                  <Input
                    maxWidth="150px"
                    size="sm"
                    value={flashing.metalPipeRoof.manufacturer}
                    onChange={event => {
                      setUnsavedChanges(true);
                      dispatch(
                        updateMetalPipeRoofFlashingField({
                          newValue: event.target.value,
                          fieldToUpdate: 'manufacturer',
                        })
                      );
                    }}
                  ></Input>
                </HStack>
                <HStack paddingTop="5px">
                  <Text fontSize="sm">Color:</Text>
                  <Input
                    maxWidth="150px"
                    size="sm"
                    value={flashing.metalPipeRoof.color}
                    onChange={event => {
                      setUnsavedChanges(true);
                      dispatch(
                        updateMetalPipeRoofFlashingField({
                          newValue: event.target.value,
                          fieldToUpdate: 'color',
                        })
                      );
                    }}
                  ></Input>
                </HStack>
              </div>
            </VStack>
          </TabPanel>
          <TabPanel>
            <VStack>
              <div>
                <Center>
                  <Text fontWeight="bold" ml="6">
                    Drip Edge
                  </Text>
                </Center>
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th fontSize="xs">Manufacturer</Th>
                      <Th fontSize="xs">Type</Th>
                      <Th fontSize="xs">Unit Measurement (LF)</Th>
                      <Th fontSize="xs">Price per unit</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        <Input
                          size="xs"
                          value={flashing.gutters.dripEdge.manufacturer}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateGuttersDripEdgeField({
                                newValue: event.target.value,
                                fieldToUpdate: 'manufacturer',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          size="xs"
                          value={flashing.gutters.dripEdge.dripEdgeType}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateGuttersDripEdgeField({
                                newValue: event.target.value,
                                fieldToUpdate: 'dripEdgeType',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          value={flashing.gutters.dripEdge.unit ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateGuttersDripEdgeField({
                                newValue: value,
                                fieldToUpdate: 'unit',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          value={flashing.gutters.dripEdge.pricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateGuttersDripEdgeField({
                                newValue: value,
                                fieldToUpdate: 'pricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </div>
              <div>
                <Center>
                  <Text fontWeight="bold" ml="6">
                    Gutter Apron
                  </Text>
                </Center>
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th fontSize="xs">Manufacturer</Th>
                      <Th fontSize="xs">Type</Th>
                      {/* <Th fontSize="xs">Color</Th> */}
                      <Th fontSize="xs">Unit Measurement (LF)</Th>
                      <Th fontSize="xs">Price per unit</Th>
                      {/* <Th fontSize="xs">Price per unit (white)</Th>
                      <Th fontSize="xs">Price per unit (brown)</Th> */}
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        <Input
                          size="xs"
                          value={flashing.gutters.apron.manufacturer}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateGuttersApronField({
                                newValue: event.target.value,
                                fieldToUpdate: 'manufacturer',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          size="xs"
                          value={flashing.gutters.apron.apronType}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateGuttersApronField({
                                newValue: event.target.value,
                                fieldToUpdate: 'apronType',
                              })
                            );
                          }}
                        />
                      </Td>
                      {/* <Td>
                        <Text fontSize="sm">White</Text>
                      </Td> */}
                      <Td>
                        <NumberInput
                          size="xs"
                          value={flashing.gutters.apron.unit ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateGuttersApronField({
                                newValue: value,
                                fieldToUpdate: 'unit',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                      {/* <Td>
                        <NumberInput
                          size="xs"
                          value={flashing.gutters.apron.whitePricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateGuttersApronField({
                                newValue: value,
                                fieldToUpdate: 'whitePricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          value={flashing.gutters.apron.brownPricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateGuttersApronField({
                                newValue: value,
                                fieldToUpdate: 'brownPricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td> */}
                      <Td>
                        <NumberInput
                          size="xs"
                          value={flashing.gutters.apron.brownPricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateGuttersApronField({
                                newValue: value,
                                fieldToUpdate: 'brownPricePer',
                              })
                            );
                            dispatch(
                              updateGuttersApronField({
                                newValue: value,
                                fieldToUpdate: 'whitePricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </div>
            </VStack>
          </TabPanel>
          <TabPanel>
            <VStack>
              <div>
                <Center>
                  <Text fontWeight="bold" ml="6">
                    Step Flashing
                  </Text>
                </Center>
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th fontSize="xs">Manufacturer</Th>
                      <Th fontSize="xs">Size</Th>
                      <Th fontSize="xs">Unit Description</Th>
                      <Th fontSize="xs">Unit Measurement (LF)</Th>
                      <Th fontSize="xs">Price per unit</Th>
                      {/* <Th fontSize="xs">Price per unit (galvanized)</Th>
                      <Th fontSize="xs">Price per unit (black)</Th>
                      <Th fontSize="xs">Price per unit (brown)</Th> */}
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        <Input
                          size="xs"
                          value={flashing.step.manufacturer}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateStepFlashingField({
                                newValue: event.target.value,
                                fieldToUpdate: 'manufacturer',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          size="xs"
                          value={flashing.step.size}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateStepFlashingField({
                                newValue: event.target.value,
                                fieldToUpdate: 'size',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          size="xs"
                          value={flashing.step.description}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateStepFlashingField({
                                newValue: event.target.value,
                                fieldToUpdate: 'description',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          value={flashing.step.unit ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateStepFlashingField({
                                newValue: value,
                                fieldToUpdate: 'unit',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                      {/* <Td>
                        <NumberInput
                          size="xs"
                          value={flashing.step.galvanizedPricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateStepFlashingField({
                                newValue: value,
                                fieldToUpdate: 'galvanizedPricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          value={flashing.step.blackPricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateStepFlashingField({
                                newValue: value,
                                fieldToUpdate: 'blackPricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          value={flashing.step.brownPricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateStepFlashingField({
                                newValue: value,
                                fieldToUpdate: 'brownPricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td> */}
                      <Td>
                        <NumberInput
                          size="xs"
                          value={flashing.step.brownPricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateStepFlashingField({
                                newValue: value,
                                fieldToUpdate: 'brownPricePer',
                              })
                            );
                            dispatch(
                              updateStepFlashingField({
                                newValue: value,
                                fieldToUpdate: 'blackPricePer',
                              })
                            );
                            dispatch(
                              updateStepFlashingField({
                                newValue: value,
                                fieldToUpdate: 'galvanizedPricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </div>
              <div>
                <Center>
                  <Text fontWeight="bold" ml="6">
                    Reglet Flashing
                  </Text>
                </Center>
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th fontSize="xs">Manufacturer</Th>
                      <Th fontSize="xs">Size</Th>
                      <Th fontSize="xs">Unit Measurement (LF)</Th>
                      <Th fontSize="xs">Price per unit</Th>
                      {/* <Th fontSize="xs">Price per unit (Brown)</Th>
                      <Th fontSize="xs">Price per unit (Black)</Th>
                      <Th fontSize="xs">Price per unit (Galvanized)</Th> */}
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        <Input
                          size="xs"
                          value={flashing.reglet.manufacturer}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateRegletFlashingField({
                                newValue: event.target.value,
                                fieldToUpdate: 'manufacturer',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          size="xs"
                          value={flashing.reglet.size}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateRegletFlashingField({
                                newValue: event.target.value,
                                fieldToUpdate: 'size',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          value={flashing.reglet.unit ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateRegletFlashingField({
                                newValue: value,
                                fieldToUpdate: 'unit',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                      {/* <Td>
                        <NumberInput
                          size="xs"
                          value={flashing.reglet.pricePerBrown ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateRegletFlashingField({
                                newValue: value,
                                fieldToUpdate: 'pricePerBrown',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          value={flashing.reglet.pricePerBlack ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateRegletFlashingField({
                                newValue: value,
                                fieldToUpdate: 'pricePerBlack',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          value={flashing.reglet.pricePerGalvanized ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateRegletFlashingField({
                                newValue: value,
                                fieldToUpdate: 'pricePerGalvanized',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td> */}
                      <Td>
                        <NumberInput
                          size="xs"
                          value={flashing.reglet.pricePerBrown ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateRegletFlashingField({
                                newValue: value,
                                fieldToUpdate: 'pricePerGalvanized',
                              })
                            );
                            dispatch(
                              updateRegletFlashingField({
                                newValue: value,
                                fieldToUpdate: 'pricePerBrown',
                              })
                            );
                            dispatch(
                              updateRegletFlashingField({
                                newValue: value,
                                fieldToUpdate: 'pricePerBlack',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </div>
              <div>
                <Center>
                  <Text fontWeight="bold" ml="6">
                    1x4 Flashing
                  </Text>
                </Center>
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th fontSize="xs">Manufacturer</Th>
                      <Th fontSize="xs">Size</Th>
                      <Th fontSize="xs">Unit Measurement (LF)</Th>
                      <Th fontSize="xs">Price per unit</Th>
                      {/* <Th fontSize="xs">Price per unit (Pine)</Th>
                      <Th fontSize="xs">Price per unit (Azek)</Th>
                      <Th fontSize="xs">Price per unit (Smart Side)</Th> */}
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        <Input
                          size="xs"
                          value={flashing.oneByFour.manufacturer}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateOneByFourFlashingField({
                                newValue: event.target.value,
                                fieldToUpdate: 'manufacturer',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          size="xs"
                          value={flashing.oneByFour.size}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateOneByFourFlashingField({
                                newValue: event.target.value,
                                fieldToUpdate: 'size',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          value={flashing.oneByFour.unit ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateOneByFourFlashingField({
                                newValue: value,
                                fieldToUpdate: 'unit',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                      {/* <Td>
                        <NumberInput
                          size="xs"
                          value={flashing.oneByFour.pricePerPine ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateOneByFourFlashingField({
                                newValue: value,
                                fieldToUpdate: 'pricePerPine',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          value={flashing.oneByFour.pricePerAzek ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateOneByFourFlashingField({
                                newValue: value,
                                fieldToUpdate: 'pricePerAzek',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          value={flashing.oneByFour.pricePerSmartside ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateOneByFourFlashingField({
                                newValue: value,
                                fieldToUpdate: 'pricePerSmartside',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td> */}
                      <Td>
                        <NumberInput
                          size="xs"
                          value={flashing.oneByFour.pricePerPine ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateOneByFourFlashingField({
                                newValue: value,
                                fieldToUpdate: 'pricePerSmartside',
                              })
                            );
                            dispatch(
                              updateOneByFourFlashingField({
                                newValue: value,
                                fieldToUpdate: 'pricePerAzek',
                              })
                            );
                            dispatch(
                              updateOneByFourFlashingField({
                                newValue: value,
                                fieldToUpdate: 'pricePerPine',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </div>
              <div>
                <Center>
                  <Text fontWeight="bold" ml="6">
                    Dormer Flashing
                  </Text>
                </Center>
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th fontSize="xs">Manufacturer</Th>
                      <Th fontSize="xs">Size</Th>
                      <Th fontSize="xs">Unit Measurement (LF)</Th>
                      <Th fontSize="xs">Price per unit</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        <Input
                          size="xs"
                          value={flashing.dormer.manufacturer}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateDormerFlashingField({
                                newValue: event.target.value,
                                fieldToUpdate: 'manufacturer',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          size="xs"
                          value={flashing.dormer.size}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateDormerFlashingField({
                                newValue: event.target.value,
                                fieldToUpdate: 'size',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          value={flashing.dormer.unit ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateDormerFlashingField({
                                newValue: value,
                                fieldToUpdate: 'unit',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          value={flashing.dormer.pricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateDormerFlashingField({
                                newValue: value,
                                fieldToUpdate: 'pricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </div>
              <div>
                <Center>
                  <Text fontWeight="bold" ml="6">
                    Valley Flashing
                  </Text>
                </Center>
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th fontSize="xs">Manufacturer</Th>
                      <Th fontSize="xs">Type</Th>
                      <Th fontSize="xs">Unit Measurement (LF)</Th>
                      <Th fontSize="xs">Price per unit</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        <Input
                          size="xs"
                          value={flashing.valley.wManufacturer}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateValleyFlashingField({
                                newValue: event.target.value,
                                fieldToUpdate: 'wManufacturer',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Text>W-Valley</Text>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          value={flashing.valley.wUnit ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateValleyFlashingField({
                                newValue: value,
                                fieldToUpdate: 'wUnit',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          value={flashing.valley.wPricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateValleyFlashingField({
                                newValue: value,
                                fieldToUpdate: 'wPricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Input
                          size="xs"
                          value={flashing.valley.rollManufacturer}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateValleyFlashingField({
                                newValue: event.target.value,
                                fieldToUpdate: 'rollManufacturer',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Text>Roll Valley</Text>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          value={flashing.valley.rollUnit ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateValleyFlashingField({
                                newValue: value,
                                fieldToUpdate: 'rollUnit',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          value={flashing.valley.rollPricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateValleyFlashingField({
                                newValue: value,
                                fieldToUpdate: 'rollPricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </div>
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Center>
        <VStack>
          {unsavedChanges && (
            <Alert status="warning" marginTop="5">
              <AlertIcon />
              <AlertTitle>You have unsaved changes!</AlertTitle>
            </Alert>
          )}
          {((currentTab === 0 && !savedList.includes('bathExhaust')) ||
            (currentTab === 1 && !savedList.includes('pipeFlashing')) ||
            (currentTab === 2 &&
              !savedList.includes('metalPipeRoofFlashing')) ||
            (currentTab === 3 && !savedList.includes('gutters')) ||
            (currentTab === 4 && !savedList.includes('otherFlashing'))) && (
            <Alert status="info" marginTop="5">
              <AlertIcon />
              <AlertTitle>Review and Save</AlertTitle>
            </Alert>
          )}
          <Button colorScheme="blue" marginTop="3" onClick={handleSave}>
            Save
          </Button>
        </VStack>
      </Center>
    </Box>
    // </Center>
  );
};

export default FlashingPrices;
