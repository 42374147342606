import {
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  NumberInput,
  NumberInputField,
  Text,
  HStack,
  VStack,
  Center,
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Checkbox,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateAdditional,
  updateHasBeenSaved,
} from '../../features/labor/laborSlice';
import axios from 'axios';
import useUser from '../../hooks/useUser';
import mixpanel from 'mixpanel-browser';

const AdditionalLabor = ({ checkAdditionalCompletion }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const additional = useSelector(state => state.labor.additional);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const { user } = useUser();
  const savedList = useSelector(state => state.labor.hasBeenSaved);

  const handleSave = async () => {
    try {
      // if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
      //   mixpanel.track('User saved the additional labor section.');
      // }
      const token = await user.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/labors/additional`,
        {
          username: user.email,
          additional,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      dispatch(updateHasBeenSaved('additional'));
      checkAdditionalCompletion(additional, [...savedList, 'additional']);
      setUnsavedChanges(false);
    } catch (error) {
      console.log('error: ' + error);
    }

    toast({
      title: 'Changes saved',
      description: 'Your pricing was updated.',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Box>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th>Description</Th>
            <Th>
              <Center>Type</Center>
            </Th>
          </Tr>
          <Tr>
            <Td>
              <Text>Waste Allowance</Text>
            </Td>
            <Td>
              <VStack>
                <HStack>
                  <Text as="b">Hip: </Text>
                  <NumberInput
                    size="sm"
                    maxWidth="150px"
                    value={additional.hipWasteAllowance ?? ''}
                    onChange={value => {
                      setUnsavedChanges(true);
                      dispatch(
                        updateAdditional({
                          newValue: value,
                          fieldToUpdate: 'hipWasteAllowance',
                        })
                      );
                    }}
                  >
                    <NumberInputField />
                  </NumberInput>
                  <Text as="b">%</Text>
                </HStack>
                <HStack>
                  <Text as="b">Gable: </Text>
                  <NumberInput
                    size="sm"
                    maxWidth="150px"
                    value={additional.gableWasteAllowance ?? ''}
                    onChange={value => {
                      setUnsavedChanges(true);
                      dispatch(
                        updateAdditional({
                          newValue: value,
                          fieldToUpdate: 'gableWasteAllowance',
                        })
                      );
                    }}
                  >
                    <NumberInputField />
                  </NumberInput>
                  <Text as="b">%</Text>
                </HStack>
              </VStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text>Dump Fees</Text>
            </Td>
            <Td>
              <VStack>
                <HStack>
                  <Text as="b">Per Square: </Text>
                  <NumberInput
                    size="sm"
                    maxWidth="150px"
                    value={additional.dumpPerSquareFee ?? ''}
                    onChange={value => {
                      setUnsavedChanges(true);
                      dispatch(
                        updateAdditional({
                          newValue: value,
                          fieldToUpdate: 'dumpPerSquareFee',
                        })
                      );
                    }}
                  >
                    <NumberInputField />
                  </NumberInput>
                </HStack>
                <HStack>
                  <Text as="b">Minimum Fee: </Text>
                  <NumberInput
                    size="sm"
                    maxWidth="150px"
                    value={additional.dumpMinimumFee ?? ''}
                    onChange={value => {
                      setUnsavedChanges(true);
                      dispatch(
                        updateAdditional({
                          newValue: value,
                          fieldToUpdate: 'dumpMinimumFee',
                        })
                      );
                    }}
                  >
                    <NumberInputField />
                  </NumberInput>
                </HStack>
                <HStack>
                  <Text as="b">Dumpster on street permit fee: </Text>
                  <NumberInput
                    size="sm"
                    maxWidth="150px"
                    value={additional.onStreetPermitFee ?? ''}
                    onChange={value => {
                      setUnsavedChanges(true);
                      dispatch(
                        updateAdditional({
                          newValue: value,
                          fieldToUpdate: 'onStreetPermitFee',
                        })
                      );
                    }}
                  >
                    <NumberInputField />
                  </NumberInput>
                </HStack>
              </VStack>
            </Td>
          </Tr>
          {/* <Tr>
            <Td>
              <Text>Permit Fee</Text>
            </Td>
            <Td>
              <VStack>
                <HStack>
                  <Checkbox
                    isChecked={additional.isPercentOfContractPermit}
                    onChange={event => {
                      setUnsavedChanges(true);
                      dispatch(
                        updateAdditional({
                          newValue: false,
                          fieldToUpdate: 'isFlatRatePermit',
                        })
                      );
                      dispatch(
                        updateAdditional({
                          newValue: event.target.checked,
                          fieldToUpdate: 'isPercentOfContractPermit',
                        })
                      );
                    }}
                  />
                  <Text as="b">% of Total Contract: </Text>
                  <NumberInput
                    size="sm"
                    maxWidth="150px"
                    value={additional.percentOfContractPermit ?? ''}
                    min={0}
                    max={100}
                    onChange={value => {
                      setUnsavedChanges(true);
                      dispatch(
                        updateAdditional({
                          newValue: value,
                          fieldToUpdate: 'percentOfContractPermit',
                        })
                      );
                    }}
                  >
                    <NumberInputField />
                  </NumberInput>
                </HStack>
                <HStack>
                  <Checkbox
                    isChecked={additional.isFlatRatePermit}
                    onChange={event => {
                      setUnsavedChanges(true);
                      dispatch(
                        updateAdditional({
                          newValue: false,
                          fieldToUpdate: 'isPercentOfContractPermit',
                        })
                      );
                      dispatch(
                        updateAdditional({
                          newValue: event.target.checked,
                          fieldToUpdate: 'isFlatRatePermit',
                        })
                      );
                    }}
                  />
                  <Text as="b">Flat Rate: </Text>
                  <NumberInput
                    size="sm"
                    maxWidth="150px"
                    value={additional.flatRatePermit ?? ''}
                    onChange={value => {
                      setUnsavedChanges(true);
                      dispatch(
                        updateAdditional({
                          newValue: value,
                          fieldToUpdate: 'flatRatePermit',
                        })
                      );
                    }}
                  >
                    <NumberInputField />
                  </NumberInput>
                </HStack>
              </VStack>
            </Td>
          </Tr> */}
        </Thead>
      </Table>
      <Center>
        <VStack>
          {unsavedChanges && (
            <Alert status="warning" marginTop="5">
              <AlertIcon />
              <AlertTitle>You have unsaved changes!</AlertTitle>
            </Alert>
          )}
          {!savedList.includes('additional') && (
            <Alert status="info" marginTop="5">
              <AlertIcon />
              <AlertTitle>Review and Save</AlertTitle>
            </Alert>
          )}
          <Button colorScheme="blue" marginTop="3" onClick={handleSave}>
            Save
          </Button>
        </VStack>
      </Center>
    </Box>
  );
};

export default AdditionalLabor;
