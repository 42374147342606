import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  username: 'abolsoy@gmail.com',
  additionalLaborCost: '',
  warrantyType: '',
};

export const testSlice = createSlice({
  name: 'test',
  initialState,
  reducers: {
    updateProperty: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state[fieldToUpdate] = newValue;
    },
  },
});

export const { updateProperty } = testSlice.actions;

export default testSlice.reducer;
