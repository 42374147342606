import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  NumberInput,
  NumberInputField,
  Text,
  HStack,
  VStack,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Center,
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Checkbox,
  useToast,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateRatesField,
  updateUnsavedChanges,
} from '../../features/labor/rateSlice';
import { updateHasBeenSaved } from '../../features/labor/laborSlice';
import axios from 'axios';
import { useState } from 'react';
import useUser from '../../hooks/useUser';
import mixpanel from 'mixpanel-browser';

const BaseRates = ({
  checkRatesCompletion,
  isBaseComplete,
  isPartTwoComplete,
  isPartThreeComplete,
  isPartFourComplete,
}) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const rates = useSelector(state => state.rate.rates);
  const unsavedChanges = useSelector(state => state.rate.rates.unsavedChanges);
  const { user } = useUser();
  const savedList = useSelector(state => state.labor.hasBeenSaved);
  const [currentTab, setCurrentTab] = useState(0);

  const handleSave = async () => {
    try {
      // if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
      //   mixpanel.track('User saved the base labor rates.');
      // }
      let tab;
      if (currentTab === 0) {
        tab = 'baserates';
      } else if (currentTab === 1) {
        tab = 'part2';
      } else if (currentTab === 2) {
        tab = 'part3';
      } else if (currentTab === 3) {
        tab = 'part4';
      }

      const token = await user.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/labors/rates`,
        {
          username: user.email,
          rates,
          tab,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      dispatch(updateHasBeenSaved(tab));
      checkRatesCompletion(rates, [...savedList, tab]);
      dispatch(updateUnsavedChanges({ fieldToUpdate: 'rates' }));
    } catch (error) {
      console.log('error: ' + error);
    }

    toast({
      title: 'Changes saved',
      description: 'Your pricing was updated.',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  const handleTabChange = index => {
    setCurrentTab(index);
  };

  return (
    <Box>
      <Tabs
        align="center"
        variant="soft-rounded"
        colorScheme="blue"
        onChange={handleTabChange}
        index={currentTab}
      >
        <TabList>
          <Tab
            as="b"
            bg={isBaseComplete ? 'green.200' : 'yellow.200'}
            color="black"
            _selected={{
              border: '2px solid black',
              bg: isBaseComplete ? 'green.400' : 'yellow.400',
            }}
          >
            Base
          </Tab>
          <Tab
            as="b"
            bg={isPartTwoComplete ? 'green.200' : 'yellow.200'}
            color="black"
            _selected={{
              border: '2px solid black',
              bg: isPartTwoComplete ? 'green.400' : 'yellow.400',
            }}
          >
            Part 2
          </Tab>
          <Tab
            as="b"
            bg={isPartThreeComplete ? 'green.200' : 'yellow.200'}
            color="black"
            _selected={{
              border: '2px solid black',
              bg: isPartThreeComplete ? 'green.400' : 'yellow.400',
            }}
          >
            Part 3
          </Tab>
          <Tab
            as="b"
            bg={isPartFourComplete ? 'green.200' : 'yellow.200'}
            color="black"
            _selected={{
              border: '2px solid black',
              bg: isPartFourComplete ? 'green.400' : 'yellow.400',
            }}
          >
            Part 4
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Table>
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th>Description</Th>
                  <Th>Type</Th>
                  <Th>Pitch</Th>
                  <Th>Stories</Th>
                  <Th>Layers</Th>
                  <Th>Unit of Measure</Th>
                  <Th>Labor Price</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>
                    <Text></Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Base Labor Rate Tear Off</Text>
                  </Td>
                  <Td>Tear Off/Re Roof</Td>
                  <Td>3/12 - 6/12</Td>
                  <Td>1</Td>
                  <Td>1</Td>
                  <Td>Per Square</Td>
                  <Td>
                    <NumberInput
                      maxWidth="150px"
                      value={rates.tearOff ?? ''}
                      onChange={value => {
                        dispatch(
                          updateRatesField({
                            newValue: value,
                            fieldToUpdate: 'tearOff',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text></Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Base Labor Rate Recover</Text>
                  </Td>
                  <Td>Recover</Td>
                  <Td>3/12 - 6/12</Td>
                  <Td>1</Td>
                  <Td>1</Td>
                  <Td>Per Square</Td>
                  <Td>
                    <NumberInput
                      maxWidth="150px"
                      value={rates.recover ?? ''}
                      onChange={value => {
                        dispatch(
                          updateRatesField({
                            newValue: value,
                            fieldToUpdate: 'recover',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text></Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Base Labor Rate New Construction</Text>
                  </Td>
                  <Td>New Construction</Td>
                  <Td>3/12 - 6/12</Td>
                  <Td>1</Td>
                  <Td>1</Td>
                  <Td>Per Square</Td>
                  <Td>
                    <NumberInput
                      maxWidth="150px"
                      value={rates.newConstruction ?? ''}
                      onChange={value => {
                        dispatch(
                          updateRatesField({
                            newValue: value,
                            fieldToUpdate: 'newConstruction',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text></Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      Include Ridge and Starter in Labor Rate
                    </Text>
                  </Td>
                  {/* <Td>3 Bundle/Sq</Td> */}
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td>Per Square</Td>
                  <Td>
                    <Checkbox
                      size="lg"
                      isChecked={rates.includeRidgeAndStarter}
                      onChange={event => {
                        dispatch(
                          updateRatesField({
                            newValue: event.target.checked,
                            fieldToUpdate: 'includeRidgeAndStarter',
                          })
                        );
                      }}
                    />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TabPanel>
          <TabPanel>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Crew Bill Label</Th>
                  <Th>Description</Th>
                  <Th>Unit of Measure</Th>
                  <Th>Labor Price</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Shingle Lift</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Shingle Lift</Text>
                  </Td>
                  <Td>Per Bundle</Td>
                  <Td>
                    <HStack>
                      <Text as="b">$</Text>
                      <NumberInput
                        size="sm"
                        maxWidth="150px"
                        value={rates.shingleLift ?? ''}
                        onChange={value =>
                          dispatch(
                            updateRatesField({
                              newValue: value,
                              fieldToUpdate: 'shingleLift',
                            })
                          )
                        }
                      >
                        <NumberInputField />
                      </NumberInput>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Starter</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      Install starter price per linear foot
                    </Text>
                  </Td>
                  <Td>Linear foot</Td>
                  <Td>
                    <HStack>
                      <Text as="b">$</Text>
                      <NumberInput
                        size="sm"
                        maxWidth="150px"
                        value={rates.starter ?? ''}
                        onChange={value =>
                          dispatch(
                            updateRatesField({
                              newValue: value,
                              fieldToUpdate: 'starter',
                            })
                          )
                        }
                      >
                        <NumberInputField />
                      </NumberInput>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Ridge Cap</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Install ridge cap per linear foot</Text>
                  </Td>
                  <Td>Linear foot</Td>
                  <Td>
                    <HStack>
                      <Text as="b">$</Text>
                      <NumberInput
                        size="sm"
                        maxWidth="150px"
                        value={rates.ridgeCap ?? ''}
                        onChange={value =>
                          dispatch(
                            updateRatesField({
                              newValue: value,
                              fieldToUpdate: 'ridgeCap',
                            })
                          )
                        }
                      >
                        <NumberInputField />
                      </NumberInput>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Difficult Dump Access</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      Difficult dump access per sq to haul debris
                    </Text>
                  </Td>
                  <Td>Per Square</Td>
                  <Td>
                    <HStack>
                      <Text as="b">$</Text>
                      <NumberInput
                        size="sm"
                        maxWidth="150px"
                        value={rates.difficultDumpAccess ?? ''}
                        onChange={value =>
                          dispatch(
                            updateRatesField({
                              newValue: value,
                              fieldToUpdate: 'difficultDumpAccess',
                            })
                          )
                        }
                      >
                        <NumberInputField />
                      </NumberInput>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Install Ridge Vent</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      Install Ridge Vent Price Per Linear Foot
                    </Text>
                  </Td>
                  <Td>Linear foot</Td>
                  <Td>
                    <HStack>
                      <Text as="b">$</Text>
                      <NumberInput
                        size="sm"
                        maxWidth="150px"
                        value={rates.installRidgeVent ?? ''}
                        onChange={value =>
                          dispatch(
                            updateRatesField({
                              newValue: value,
                              fieldToUpdate: 'installRidgeVent',
                            })
                          )
                        }
                      >
                        <NumberInputField />
                      </NumberInput>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Cut In New Ridge Vent</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      Cut In New Ridge Vent Price Per Linear Foot
                    </Text>
                  </Td>
                  <Td>Linear foot</Td>
                  <Td>
                    <HStack>
                      <Text as="b">$</Text>
                      <NumberInput
                        size="sm"
                        maxWidth="150px"
                        value={rates.cutInRidgeVent ?? ''}
                        onChange={value =>
                          dispatch(
                            updateRatesField({
                              newValue: value,
                              fieldToUpdate: 'cutInRidgeVent',
                            })
                          )
                        }
                      >
                        <NumberInputField />
                      </NumberInput>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Remove Gutters</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      Labor to Remove Gutters/Downspouts Per Linear Foot
                    </Text>
                  </Td>
                  <Td>Linear foot</Td>
                  <Td>
                    <HStack>
                      <Text as="b">$</Text>
                      <NumberInput
                        size="sm"
                        maxWidth="150px"
                        value={rates.removeGutters ?? ''}
                        onChange={value =>
                          dispatch(
                            updateRatesField({
                              newValue: value,
                              fieldToUpdate: 'removeGutters',
                            })
                          )
                        }
                      >
                        <NumberInputField />
                      </NumberInput>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Detach/Reset Gutters</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      Labor To Detach/Reset Gutters/Downspouts Per Linear Foot
                    </Text>
                  </Td>
                  <Td>Linear foot</Td>
                  <Td>
                    <HStack>
                      <Text as="b">$</Text>
                      <NumberInput
                        size="sm"
                        maxWidth="150px"
                        value={rates.resetGutters ?? ''}
                        onChange={value =>
                          dispatch(
                            updateRatesField({
                              newValue: value,
                              fieldToUpdate: 'resetGutters',
                            })
                          )
                        }
                      >
                        <NumberInputField />
                      </NumberInput>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Detach/Reset Siding</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      Labor To Detach/Reset Siding For New Step/Dormer Flashing
                    </Text>
                  </Td>
                  <Td>Linear foot</Td>
                  <Td>
                    <HStack>
                      <Text as="b">$</Text>
                      <NumberInput
                        size="sm"
                        maxWidth="150px"
                        value={rates.resetSiding ?? ''}
                        onChange={value =>
                          dispatch(
                            updateRatesField({
                              newValue: value,
                              fieldToUpdate: 'resetSiding',
                            })
                          )
                        }
                      >
                        <NumberInputField />
                      </NumberInput>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Step Flashing</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      Install Step Flashing Price Per Linear Foot
                    </Text>
                  </Td>
                  <Td>Linear foot</Td>
                  <Td>
                    <HStack>
                      <Text as="b">$</Text>
                      <NumberInput
                        size="sm"
                        maxWidth="150px"
                        value={rates.stepFlashing ?? ''}
                        onChange={value =>
                          dispatch(
                            updateRatesField({
                              newValue: value,
                              fieldToUpdate: 'stepFlashing',
                            })
                          )
                        }
                      >
                        <NumberInputField />
                      </NumberInput>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Sidewall Counter Flashing</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      Install Sidewall Counter Flashing Price Per Linear Foot
                    </Text>
                  </Td>
                  <Td>Linear foot</Td>
                  <Td>
                    <HStack>
                      <Text as="b">$</Text>
                      <NumberInput
                        size="sm"
                        maxWidth="150px"
                        value={rates.sidewallCounterFlashing ?? ''}
                        onChange={value =>
                          dispatch(
                            updateRatesField({
                              newValue: value,
                              fieldToUpdate: 'sidewallCounterFlashing',
                            })
                          )
                        }
                      >
                        <NumberInputField />
                      </NumberInput>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Dormer Flashing</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      Install Dormer Flashing Price Per Linear Foot
                    </Text>
                  </Td>
                  <Td>Linear foot</Td>
                  <Td>
                    <HStack>
                      <Text as="b">$</Text>
                      <NumberInput
                        size="sm"
                        maxWidth="150px"
                        value={rates.dormerFlashing ?? ''}
                        onChange={value =>
                          dispatch(
                            updateRatesField({
                              newValue: value,
                              fieldToUpdate: 'dormerFlashing',
                            })
                          )
                        }
                      >
                        <NumberInputField />
                      </NumberInput>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Replace</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      Replace Midroof Venting Price Per Linear Foot
                    </Text>
                  </Td>
                  <Td>Linear foot</Td>
                  <Td>
                    <HStack>
                      <Text as="b">$</Text>
                      <NumberInput
                        size="sm"
                        maxWidth="150px"
                        value={rates.replaceMidroofVenting ?? ''}
                        onChange={value =>
                          dispatch(
                            updateRatesField({
                              newValue: value,
                              fieldToUpdate: 'replaceMidroofVenting',
                            })
                          )
                        }
                      >
                        <NumberInputField />
                      </NumberInput>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Cut In New</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Cut in new midroof intake venting</Text>
                  </Td>
                  <Td>Linear foot</Td>
                  <Td>
                    <HStack>
                      <Text as="b">$</Text>
                      <NumberInput
                        size="sm"
                        maxWidth="150px"
                        value={rates.cutInNewMidroofVenting ?? ''}
                        onChange={value =>
                          dispatch(
                            updateRatesField({
                              newValue: value,
                              fieldToUpdate: 'cutInNewMidroofVenting',
                            })
                          )
                        }
                      >
                        <NumberInputField />
                      </NumberInput>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Plywood Remove/Replace</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      Labor to Remove/Replace Rotten Plywood Per 4X8 Sheet
                    </Text>
                  </Td>
                  <Td>Per Sheet</Td>
                  <Td>
                    <HStack>
                      <Text as="b">$</Text>
                      <NumberInput
                        size="sm"
                        maxWidth="150px"
                        value={rates.plywoodReplace ?? ''}
                        onChange={value =>
                          dispatch(
                            updateRatesField({
                              newValue: value,
                              fieldToUpdate: 'plywoodReplace',
                            })
                          )
                        }
                      >
                        <NumberInputField />
                      </NumberInput>
                    </HStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Roof Board Repair</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Labor to remove/replace boards</Text>
                  </Td>
                  <Td>Linear foot</Td>
                  <Td>
                    <HStack>
                      <Text as="b">$</Text>
                      <NumberInput
                        size="sm"
                        maxWidth="150px"
                        value={rates.roofBoardRepair ?? ''}
                        onChange={value =>
                          dispatch(
                            updateRatesField({
                              newValue: value,
                              fieldToUpdate: 'roofBoardRepair',
                            })
                          )
                        }
                      >
                        <NumberInputField />
                      </NumberInput>
                    </HStack>
                  </Td>
                </Tr>
                {/* <Tr>
                  <Td>
                    <Text fontSize="sm">Hand Sealing</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Hand Seal Shingles</Text>
                  </Td>
                  <Td>Per Square</Td>
                  <Td>
                    <HStack>
                      <Text as="b">$</Text>
                      <NumberInput
                        size="sm"
                        maxWidth="150px"
                        value={rates.handSealing ?? ''}
                        onChange={value =>
                          dispatch(
                            updateRatesField({
                              newValue: value,
                              fieldToUpdate: 'handSealing',
                            })
                          )
                        }
                      >
                        <NumberInputField />
                      </NumberInput>
                    </HStack>
                  </Td>
                </Tr> */}
              </Tbody>
            </Table>
          </TabPanel>
          <TabPanel>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Crew Bill Label</Th>
                  <Th>Description</Th>
                  <Th>Unit of Measure</Th>
                  <Th>Labor Price</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>
                    <Text fontSize="sm">
                      Ice and water complete roof additional charge
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      Additonal cost for complete roof ice/water
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Square</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="100px"
                      value={rates.iceAndWater ?? ''}
                      onChange={value =>
                        dispatch(
                          updateRatesField({
                            newValue: value,
                            fieldToUpdate: 'iceAndWater',
                          })
                        )
                      }
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Story</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      Additional Story Cost Per Square Per Story Above Base
                      Labor Rate
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Square Per Story</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="100px"
                      value={rates.story ?? ''}
                      onChange={value =>
                        dispatch(
                          updateRatesField({
                            newValue: value,
                            fieldToUpdate: 'story',
                          })
                        )
                      }
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Layer Tear Off </Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      Tear Off Additional Layers Labor Cost Per Layer Per Square
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Layer Per Square</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="100px"
                      value={rates.layerTearOff ?? ''}
                      onChange={value =>
                        dispatch(
                          updateRatesField({
                            newValue: value,
                            fieldToUpdate: 'layerTearOff',
                          })
                        )
                      }
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Wood Shake Tear off </Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      Shake Removal Cost, Labor Per Sq Per layer Above Base
                      Labor Rate
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq Per Layer</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="100px"
                      value={rates.woodShakeTearOff ?? ''}
                      onChange={value =>
                        dispatch(
                          updateRatesField({
                            newValue: value,
                            fieldToUpdate: 'woodShakeTearOff',
                          })
                        )
                      }
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm"></Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      Shake Additional Dump Cost Per Sq Per Layer Above Base
                      Dump Fee
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq Per Layer</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="100px"
                      value={rates.woodshakeDumpFee ?? ''}
                      onChange={value =>
                        dispatch(
                          updateRatesField({
                            newValue: value,
                            fieldToUpdate: 'woodshakeDumpFee',
                          })
                        )
                      }
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">
                      Remove Metal/Wood Chimney And Patch in Decking
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      Remove Metal/Wood Chimney and patch in decking
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm"></Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="100px"
                      value={rates.removeChimney ?? ''}
                      onChange={value =>
                        dispatch(
                          updateRatesField({
                            newValue: value,
                            fieldToUpdate: 'removeChimney',
                          })
                        )
                      }
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Remove / Fill in decking</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm" maxWidth="500px">
                      Remove Roof Vents/Turbine Vents/Kitchen-Bath Vents and
                      fill in decking Price per Unit
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Unit</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="100px"
                      value={rates.removeDecking ?? ''}
                      onChange={value =>
                        dispatch(
                          updateRatesField({
                            newValue: value,
                            fieldToUpdate: 'removeDecking',
                          })
                        )
                      }
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Cut in new</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm" maxWidth="500px">
                      Cut in new Roof Vents/Turbine Vents/Kitchen-Bath Vents
                      Price per Unit
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Unit</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="100px"
                      value={rates.newVents ?? ''}
                      onChange={value =>
                        dispatch(
                          updateRatesField({
                            newValue: value,
                            fieldToUpdate: 'newVents',
                          })
                        )
                      }
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Base Labor Rate Redeck</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm" maxWidth="500px">
                      Re Deck LaborBase Cost Per Square
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Square</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="100px"
                      value={rates.baseRedeck ?? ''}
                      onChange={value =>
                        dispatch(
                          updateRatesField({
                            newValue: value,
                            fieldToUpdate: 'baseRedeck',
                          })
                        )
                      }
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Story Redeck</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm" maxWidth="500px">
                      Additional Cost per Story Per Sq Above Re Deck Base Cost
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Story Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="100px"
                      value={rates.storyRedeck ?? ''}
                      onChange={value =>
                        dispatch(
                          updateRatesField({
                            newValue: value,
                            fieldToUpdate: 'storyRedeck',
                          })
                        )
                      }
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Neighboring Homes Close Proximity</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm" maxWidth="500px">
                      Additional fee for protecting neighboring homes in close
                      proximity
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Side</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="100px"
                      value={rates.closeProximityHomes ?? ''}
                      onChange={value =>
                        dispatch(
                          updateRatesField({
                            newValue: value,
                            fieldToUpdate: 'closeProximityHomes',
                          })
                        )
                      }
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TabPanel>
          <TabPanel>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Crew Bill Label</Th>
                  <Th>Description</Th>
                  <Th>Unit of Measure</Th>
                  <Th>Labor Price</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Chimney Step Flashing</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      Wood/Masonry Chimney Step Flashing
                    </Text>
                  </Td>
                  <Td></Td>
                  <Td>
                    <VStack spacing="0" align="flex-start">
                      <HStack>
                        <Text as="b">small: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.smallChimneyStepFlashing ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'smallChimneyStepFlashing',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                      <HStack>
                        <Text as="b">medium: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.mediumChimneyStepFlashing ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'mediumChimneyStepFlashing',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                      <HStack>
                        <Text as="b">large: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.largeChimneyStepFlashing ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'largeChimneyStepFlashing',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                    </VStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Chimney Cricket</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Install new chimney cricket</Text>
                  </Td>
                  <Td></Td>
                  <Td>
                    <VStack spacing="0" align="flex-start">
                      <HStack>
                        <Text as="b">small: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.smallChimneyCricket ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'smallChimneyCricket',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                      <HStack>
                        <Text as="b">medium: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.mediumChimneyCricket ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'mediumChimneyCricket',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                      <HStack>
                        <Text as="b">large: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.largeChimneyCricket ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'largeChimneyCricket',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                    </VStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">
                      Masonry Chimney Install New Flashing/Counter Flashing{' '}
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      Masonry Chimney Install New Flashing/Counterflashing
                    </Text>
                  </Td>
                  <Td></Td>
                  <Td>
                    <VStack spacing="0" align="flex-start">
                      <HStack>
                        <Text as="b">small: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.smallChimneyNewFlashing ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'smallChimneyNewFlashing',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                      <HStack>
                        <Text as="b">medium: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.mediumChimneyNewFlashing ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'mediumChimneyNewFlashing',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                      <HStack>
                        <Text as="b">large: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.largeChimneyNewFlashing ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'largeChimneyNewFlashing',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                    </VStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">
                      Remove Masonry Chimney And Patch in Decking
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      Remove Masonry Chimney And Patch in Decking
                    </Text>
                  </Td>
                  <Td></Td>
                  <Td>
                    <VStack spacing="0" align="flex-start">
                      <HStack>
                        <Text as="b">small: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.smallRemoveChimney ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'smallRemoveChimney',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                      <HStack>
                        <Text as="b">medium: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.mediumRemoveChimney ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'mediumRemoveChimney',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                      <HStack>
                        <Text as="b">large: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.largeRemoveChimney ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'largeRemoveChimney',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                    </VStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Detach/reset</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      Detach/Reset Roof mounted Accessories
                    </Text>
                  </Td>
                  <Td></Td>
                  <Td>
                    <VStack spacing="0" align="flex-start">
                      <HStack>
                        <Text as="b">satellite dish: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.satelliteDish ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'satelliteDish',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                      <HStack>
                        <Text as="b">a/c unit: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.acUnit ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'acUnit',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                      <HStack>
                        <Text as="b">antenna: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.antenna ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'antenna',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                      <HStack>
                        <Text as="b">solar panels: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.solarPanel ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'solarPanel',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                    </VStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Skylight Flashing</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm" mb="5px">
                      Replace Skylight Flashing
                    </Text>
                    <Text fontSize="sm">Remove/Fill In Skylight</Text>
                  </Td>
                  <Td></Td>
                  <Td>
                    <VStack spacing="0" align="flex-start">
                      <HStack>
                        <Text as="b">small: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.smallSkylightFlashing ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'smallSkylightFlashing',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                      <HStack>
                        <Text as="b">medium: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.mediumSkylightFlashing ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'mediumSkylightFlashing',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                      <HStack>
                        <Text as="b">large: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.largeSkylightFlashing ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'largeSkylightFlashing',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                    </VStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Install New Skylight</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Install New Skylight(s)</Text>
                  </Td>
                  <Td></Td>
                  <Td>
                    <VStack spacing="0" align="flex-start">
                      <HStack>
                        <Text as="b">small: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.smallNewSkylight ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'smallNewSkylight',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                      <HStack>
                        <Text as="b">medium: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.mediumNewSkylight ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'mediumNewSkylight',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                      <HStack>
                        <Text as="b">large: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.largeNewSkylight ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'largeNewSkylight',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                    </VStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Cornice Returns/Eyebrows</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Cornice Returns/Eyebrows</Text>
                  </Td>
                  <Td></Td>
                  <Td>
                    <VStack spacing="0" align="flex-start">
                      <HStack>
                        <Text as="b">small: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.smallCorniceReturn ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'smallCorniceReturn',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                      <HStack>
                        <Text as="b">medium: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.mediumCorniceReturn ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'mediumCorniceReturn',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                      <HStack>
                        <Text as="b">large: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.largeCorniceReturn ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'largeCorniceReturn',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                    </VStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">
                      Additional Story Cornice Returns/Eyebrows
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      Additional Stories on Cornice Returns/Eyebrows / Bay
                      Windows Price Per Story
                    </Text>
                  </Td>
                  <Td>Per Story</Td>
                  <Td>
                    <VStack spacing="0" align="flex-start">
                      <HStack>
                        <Text as="b">small: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.smallCorniceReturnPerStory ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'smallCorniceReturnPerStory',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                      <HStack>
                        <Text as="b">medium: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.mediumCorniceReturnPerStory ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'mediumCorniceReturnPerStory',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                      <HStack>
                        <Text as="b">large: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.largeCorniceReturnPerStory ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'largeCorniceReturnPerStory',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                    </VStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Bay Window</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Additional Labor For Bay Windows</Text>
                  </Td>
                  <Td></Td>
                  <Td>
                    <VStack spacing="0" align="flex-start">
                      <HStack>
                        <Text as="b">small: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.smallBayWindow ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'smallBayWindow',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                      <HStack>
                        <Text as="b">medium: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.mediumBayWindow ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'mediumBayWindow',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                      <HStack>
                        <Text as="b">large: </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="100px"
                          value={rates.largeBayWindow ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'largeBayWindow',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                    </VStack>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text fontSize="sm">Hand Sealing</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      Additional Labor For Hand Sealing Shingles
                    </Text>
                  </Td>
                  <Td>Per Square</Td>
                  <Td>
                    <VStack spacing="0" align="flex-start">
                      <HStack>
                        <Text as="b" fontSize="xs">
                          8/12 or less:
                        </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="80px"
                          value={rates.eightOrLessHandSealing ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'eightOrLessHandSealing',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                      <HStack>
                        <Text as="b" fontSize="xs">
                          9/12 to 13/12:
                        </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="80px"
                          value={rates.nineToThirteenHandSealing ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'nineToThirteenHandSealing',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                      <HStack>
                        <Text as="b" fontSize="xs">
                          14/12 or more:
                        </Text>
                        <NumberInput
                          size="xs"
                          maxWidth="80px"
                          value={rates.fourteenPlusHandSealing ?? ''}
                          onChange={value =>
                            dispatch(
                              updateRatesField({
                                newValue: value,
                                fieldToUpdate: 'fourteenPlusHandSealing',
                              })
                            )
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                    </VStack>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Center>
        <VStack>
          {unsavedChanges && (
            <Alert status="warning" marginTop="5">
              <AlertIcon />
              <AlertTitle>You have unsaved changes!</AlertTitle>
            </Alert>
          )}
          {((currentTab === 0 && !savedList.includes('baserates')) ||
            (currentTab === 1 && !savedList.includes('part2')) ||
            (currentTab === 2 && !savedList.includes('part3')) ||
            (currentTab === 3 && !savedList.includes('part4'))) && (
            <Alert status="info" marginTop="5">
              <AlertIcon />
              <AlertTitle>Review and Save</AlertTitle>
            </Alert>
          )}
          <Button colorScheme="blue" marginTop="3" onClick={handleSave}>
            Save
          </Button>
        </VStack>
      </Center>
    </Box>
  );
};

export default BaseRates;
