import {
  Box,
  Button,
  Text,
  Flex,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Modal,
  Select,
  Spacer,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input,
  Stack,
  Radio,
  HStack,
  VStack,
  Checkbox,
  RadioGroup,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react';
import {
  LockIcon,
  UnlockIcon,
  CheckIcon,
  SmallCloseIcon,
} from '@chakra-ui/icons';
import axios from 'axios';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateDumpsterPlacement,
  updateRoofOver,
  updateNewConstruction,
  updateTearOff,
  updateWastePercent,
  updateRoofType,
  updateDiscountPercent,
  updateDifficultDumpsterAccess,
  updateCloseNeighboringHomes,
  updateIsGeneralCompleted,
  updateJobName,
  updateAddressLineOne,
  updateAddressLineTwo,
  updateFinanceOption,
  updatePaymentOptionOne,
  updatePaymentOptionThree,
  updatePaymentOptionSeven,
  updatePaymentOptionCash,
  updatePermitFee,
  updatePermitFeePercent,
  updatePermitFeeFlat,
} from '../features/calculator/calculatorSlice';
import useUser from '../hooks/useUser';
import mixpanel from 'mixpanel-browser';
import { useMediaQuery } from '@chakra-ui/react';

const General = ({
  isGeneralComplete,
  checkGeneralCompletion,
  isGeneralLocked,
  isGeneralLockedList,
  setCalculateButtonClicked,
  isMobileDevice,
  setJobName,
}) => {
  const { user } = useUser();
  const [isMediumDevice] = useMediaQuery('(max-width: 1300px)');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const hipWasteAllowance = useSelector(
    state => state.labor.additional.hipWasteAllowance
  );
  const gableWasteAllowance = useSelector(
    state => state.labor.additional.gableWasteAllowance
  );
  const oneYearDescription = useSelector(
    state => state.payment.options.oneYearDescription
  );
  const threeYearDescription = useSelector(
    state => state.payment.options.threeYearDescription
  );
  const sevenYearDescription = useSelector(
    state => state.payment.options.sevenYearDescription
  );
  const cashDescription = useSelector(
    state => state.payment.options.cashDescription
  );
  const discountPercent = useSelector(
    state => state.calculator.discountPercent
  );
  const financeOption = useSelector(state => state.calculator.financeOption);
  const hasPaymentOptionCash = useSelector(
    state => state.calculator.hasPaymentOptionCash
  );
  const hasPaymentOptionOne = useSelector(
    state => state.calculator.hasPaymentOptionOne
  );
  const hasPaymentOptionThree = useSelector(
    state => state.calculator.hasPaymentOptionThree
  );
  const hasPaymentOptionSeven = useSelector(
    state => state.calculator.hasPaymentOptionSeven
  );
  const jobName = useSelector(state => state.calculator.jobName);
  const addressLineOne = useSelector(state => state.calculator.addressLineOne);
  const addressLineTwo = useSelector(state => state.calculator.addressLineTwo);
  const roofType = useSelector(state => state.calculator.roofType);
  const wastePercent = useSelector(state => state.calculator.wastePercent);
  const tearOff = useSelector(state => state.calculator.tearOff);
  const newConstruction = useSelector(
    state => state.calculator.newConstruction
  );
  const roofOver = useSelector(state => state.calculator.roofOver);
  const dumpsterPlacement = useSelector(
    state => state.calculator.dumpsterPlacement
  );
  const difficultDumpsterAccess = useSelector(
    state => state.calculator.difficultDumpsterAccess
  );
  const closeNeighboringHomes = useSelector(
    state => state.calculator.closeNeighboringHomes
  );
  const permitFee = useSelector(state => state.calculator.permitFee);
  const permitFeePercent = useSelector(
    state => state.calculator.permitFeePercent
  );
  const permitFeeFlat = useSelector(state => state.calculator.permitFeeFlat);
  const options = useSelector(state => state.payment.options);
  const profitMargin = useSelector(state => state.payment.general.profitMargin);
  const [radioSelector, setRadioSelector] = useState(
    tearOff ? 'tear' : newConstruction ? 'new' : roofOver ? 'roof' : ''
  );
  const [availableDiscount, setAvailableDiscount] = useState(0);
  const [financeOptionLocked, setFinanceOptionLocked] = useState(false);
  const [isLocked, setIsLocked] = useState(isGeneralLocked);
  const [isLockedList, setIsLockedList] = useState(isGeneralLockedList);
  const [unsavedChanged, setUnsavedChanges] = useState(false);
  const dispatch = useDispatch();

  const handleInputChange = value => {
    setUnsavedChanges(true);
    dispatch(updateWastePercent(value));
  };

  const handleSelectChange = event => {
    setUnsavedChanges(true);
    if (event.target.value === 'hip') {
      let temp = 0;
      if (hipWasteAllowance > 0) {
        temp = hipWasteAllowance;
      }
      dispatch(updateWastePercent(temp));
    }
    if (event.target.value === 'gable') {
      let temp = 0;
      if (gableWasteAllowance > 0) {
        temp = gableWasteAllowance;
      }
      dispatch(updateWastePercent(gableWasteAllowance));
    }
    dispatch(updateRoofType(event.target.value));
  };

  const handleRadioChange = value => {
    setUnsavedChanges(true);
    dispatch(updateDumpsterPlacement(value));
  };

  const handleOpen = () => {
    // if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    //   mixpanel.track('User opened the General modal.');
    // }
    onOpen();
  };

  // =1-SUM(G354:G358)/(G363*(1-'3_AdminDetEstimate'!F214-'3_AdminDetEstimate'!E16-H360))

  const calculateAvailableDiscount = inputFinanceOption => {
    if (inputFinanceOption === 'cash') {
      setAvailableDiscount(options.cashFee);
    } else if (inputFinanceOption === 'one') {
      setAvailableDiscount(options.oneYearFee);
    } else if (inputFinanceOption === 'three') {
      setAvailableDiscount(options.threeYearFee);
    } else if (inputFinanceOption === 'seven') {
      setAvailableDiscount(options.sevenYearFee);
    } else {
      setAvailableDiscount(0);
    }
  };

  const handleSave = async () => {
    try {
      const token = await user.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/roofs`,
        {
          username: user.email,
          wastePercent: wastePercent,
          tearOff: tearOff,
          newConstruction: newConstruction,
          roofType: roofType,
          roofOver: roofOver,
          dumpsterPlacement: dumpsterPlacement,
          difficultDumpsterAccess: difficultDumpsterAccess,
          closeNeighboringHomes: closeNeighboringHomes,
          jobName: jobName,
          addressLineOne: addressLineOne,
          addressLineTwo: addressLineTwo,
          financeOption: financeOption,
          // discountPercent: discountPercent,
          isGeneralCompleted: false,
          hasPaymentOptionCash: hasPaymentOptionCash,
          hasPaymentOptionOne: hasPaymentOptionOne,
          hasPaymentOptionThree: hasPaymentOptionThree,
          hasPaymentOptionSeven: hasPaymentOptionSeven,
          permitFee: permitFee,
          permitFeePercent: permitFeePercent,
          permitFeeFlat: permitFeeFlat,
          isLocked: isLocked,
          isLockedList: isLockedList,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      setJobName(jobName);
      setUnsavedChanges(false);
      checkGeneralCompletion({
        discountPercent,
        financeOption,
        jobName,
        addressLineOne,
        addressLineTwo,
        roofType,
        wastePercent,
        newConstruction,
        tearOff,
        roofOver,
        dumpsterPlacement,
        difficultDumpsterAccess,
        closeNeighboringHomes,
        permitFee,
        permitFeePercent,
        permitFeeFlat,
      });
      setCalculateButtonClicked(true);
      onClose();
    } catch (error) {
      console.log('error: ' + error);
    }
  };

  return (
    <Box
      minW={isMobileDevice ? '90vw' : ''}
      maxH="70px"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={isMobileDevice ? '3' : '6'}
      boxShadow="base"
      borderColor="gray.400"
      bg={isGeneralComplete ? 'green.300' : 'yellow.200'}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      position="relative"
      cursor="pointer"
      onClick={isMobileDevice ? handleOpen : null}
    >
      <Flex alignItems="center">
        <VStack position="absolute" left="20px">
          {/* {isGeneralComplete && !isMobileDevice && (
            <CheckIcon boxSize={4} color="gray.900" />
          )}
          {!isGeneralComplete && !isMobileDevice && (
            <SmallCloseIcon boxSize={5} color="gray.900" />
          )} */}
          {isLockedList?.length > 0 && !isMobileDevice && (
            <LockIcon boxSize={4} color="gray.900" />
          )}
        </VStack>
        <Text
          fontWeight="bold"
          fontSize="med"
          pr={isMobileDevice ? '0px' : '20px'}
        >
          General
        </Text>
        {!isMobileDevice && (
          <Button
            size="sm"
            position="absolute"
            border="1px"
            right="20px"
            borderColor="gray.400"
            ml="30px"
            minWidth="53px"
            onClick={handleOpen}
          >
            Edit
          </Button>
        )}
        {/* {isLockedList?.length > 0 && !isMobileDevice && (
          <LockIcon
            position="absolute"
            right="5px"
            top="5px"
            boxSize={3}
            color="gray.900"
          />
        )} */}
        <Modal isOpen={isOpen} size="custom" onClose={onClose}>
          <ModalOverlay />
          <ModalContent
            maxW={isMobileDevice ? '100vw' : isMediumDevice ? '80vw' : '50vw'}
          >
            <ModalHeader>
              <HStack justify="space-between">
                <Text>General</Text>
                {isLockedList?.length > 0 ? (
                  <LockIcon
                    boxSize={5}
                    color="gray.500"
                    onClick={() => {
                      setUnsavedChanges(true);
                      // setIsLocked(false);
                      setIsLockedList([]);
                    }}
                  />
                ) : (
                  <UnlockIcon
                    boxSize={5}
                    color="gray.500"
                    onClick={() => {
                      setUnsavedChanges(true);
                      // setIsLocked(true);
                      let tempList = [];
                      tempList.push('paymentOptions');

                      if (financeOption !== '' && financeOption != null) {
                        tempList.push('financeOption');
                      }
                      if (radioSelector !== '' && radioSelector != null) {
                        tempList.push('roofType');
                      }
                      if (
                        dumpsterPlacement !== '' &&
                        dumpsterPlacement != null
                      ) {
                        tempList.push('dumpsterPlacement');
                      }
                      if (
                        difficultDumpsterAccess !== '' &&
                        difficultDumpsterAccess != null
                      ) {
                        tempList.push('difficultDumpsterAccess');
                      }
                      if (
                        closeNeighboringHomes !== '' &&
                        closeNeighboringHomes != null
                      ) {
                        tempList.push('closeNeighboringHomes');
                      }
                      if (permitFee !== '' && permitFee != null) {
                        tempList.push('permitFee');
                      }

                      setIsLockedList([...isLockedList, ...tempList]);
                    }}
                  />
                )}
              </HStack>
            </ModalHeader>
            <ModalBody>
              <Flex direction="column" gap="4">
                <div>
                  <Text fontSize="sm">Name</Text>
                  <Input
                    size="sm"
                    value={jobName}
                    onChange={event => {
                      setUnsavedChanges(true);
                      dispatch(updateJobName(event.target.value));
                    }}
                  ></Input>
                </div>
                <div>
                  <Text fontSize="sm">Address</Text>
                  <VStack spacing="0">
                    <Input
                      size="sm"
                      value={addressLineOne}
                      onChange={event => {
                        setUnsavedChanges(true);
                        dispatch(updateAddressLineOne(event.target.value));
                      }}
                    ></Input>
                    <Input
                      size="sm"
                      value={addressLineTwo}
                      onChange={event => {
                        setUnsavedChanges(true);
                        dispatch(updateAddressLineTwo(event.target.value));
                      }}
                    ></Input>
                  </VStack>
                </div>
                <div>
                  <Text fontSize="sm">Payment Options Available</Text>
                  <HStack>
                    <HStack paddingRight="5">
                      <Checkbox
                        size={isMobileDevice ? 'sm' : 'md'}
                        isChecked={hasPaymentOptionCash}
                        isDisabled={true} // isLockedList?.includes('paymentOptions')
                        onChange={event => {
                          setUnsavedChanges(true);
                          dispatch(
                            updatePaymentOptionCash(event.target.checked)
                          );
                        }}
                      ></Checkbox>
                      <Text fontSize={isMobileDevice ? 'xs' : 'sm'} as="b">
                        {cashDescription}
                      </Text>
                    </HStack>
                    <HStack paddingRight="5">
                      <Checkbox
                        size={isMobileDevice ? 'sm' : 'md'}
                        isChecked={hasPaymentOptionOne}
                        isDisabled={isLockedList?.includes('paymentOptions')}
                        onChange={event => {
                          setUnsavedChanges(true);
                          dispatch(
                            updatePaymentOptionOne(event.target.checked)
                          );
                        }}
                      ></Checkbox>
                      <Text fontSize={isMobileDevice ? 'xs' : 'sm'} as="b">
                        {oneYearDescription}
                      </Text>
                    </HStack>
                    <HStack paddingRight="5">
                      <Checkbox
                        size={isMobileDevice ? 'sm' : 'md'}
                        isChecked={hasPaymentOptionThree}
                        isDisabled={isLockedList?.includes('paymentOptions')}
                        onChange={event => {
                          setUnsavedChanges(true);
                          dispatch(
                            updatePaymentOptionThree(event.target.checked)
                          );
                        }}
                      ></Checkbox>
                      <Text fontSize={isMobileDevice ? 'xs' : 'sm'} as="b">
                        {threeYearDescription}
                      </Text>
                    </HStack>
                    <HStack>
                      <Checkbox
                        size={isMobileDevice ? 'sm' : 'md'}
                        isChecked={hasPaymentOptionSeven}
                        isDisabled={isLockedList?.includes('paymentOptions')}
                        onChange={event => {
                          setUnsavedChanges(true);
                          dispatch(
                            updatePaymentOptionSeven(event.target.checked)
                          );
                        }}
                      ></Checkbox>
                      <Text fontSize={isMobileDevice ? 'xs' : 'sm'} as="b">
                        {sevenYearDescription}
                      </Text>
                    </HStack>
                  </HStack>
                </div>
                {/* <div>
                  <HStack justify="space-between">
                    <Text fontSize="sm">Finance Option</Text>
                  </HStack>
                  <Select
                    size="sm"
                    value={financeOption}
                    variant={
                      isLockedList?.includes('financeOption')
                        ? 'filled'
                        : 'outline'
                    }
                    css={
                      isLockedList?.includes('financeOption')
                        ? { pointerEvents: 'none' }
                        : {}
                    }
                    onChange={event => {
                      setUnsavedChanges(true);
                      dispatch(updateFinanceOption(event.target.value));
                      // calculateAvailableDiscount(event.target.value);
                    }}
                  >
                    <option value=""></option>
                    <option value="cash">{cashDescription}</option>
                    {hasPaymentOptionOne && (
                      <option value="one">{oneYearDescription}</option>
                    )}
                    {hasPaymentOptionThree && (
                      <option value="three">{threeYearDescription}</option>
                    )}
                    {hasPaymentOptionSeven && (
                      <option value="seven">{sevenYearDescription}</option>
                    )}
                  </Select>
                </div> */}
                {/* <div>
                  <HStack justify="space-between">
                    <Text fontSize="sm">Discount %</Text>
                    <Text fontSize="sm" color="green">
                      {availableDiscount}% available
                    </Text>
                  </HStack>
                  <NumberInput
                    min={0}
                    max={availableDiscount}
                    size="sm"
                    value={discountPercent ?? ''}
                    onChange={value => {
                      setUnsavedChanges(true);
                      dispatch(updateDiscountPercent(value));
                    }}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </div> */}
                <div>
                  <Text htmlFor="roof-type" fontSize="sm">
                    Roof
                  </Text>
                  <Select
                    size="sm"
                    id="roof-type"
                    name="roof-type"
                    value={roofType}
                    onChange={handleSelectChange}
                  >
                    <option value=""></option>
                    <option value="hip">Hip</option>
                    <option value="gable">Gable</option>
                  </Select>
                </div>
                <div>
                  <Text fontSize="sm">Waste %</Text>
                  <NumberInput
                    min={0}
                    // isReadOnly={isLocked}
                    // variant={isLocked ? 'filled' : 'outline'}
                    size="sm"
                    name="waste-percent"
                    value={wastePercent ?? ''}
                    onChange={handleInputChange}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </div>
                <div>
                  <RadioGroup
                    value={radioSelector}
                    onChange={value => {
                      setUnsavedChanges(true);
                      if (value === 'new') {
                        dispatch(updateNewConstruction());
                        setRadioSelector('new');
                      } else if (value === 'tear') {
                        dispatch(updateTearOff());
                        setRadioSelector('tear');
                      } else if (value === 'roof') {
                        dispatch(updateRoofOver());
                        setRadioSelector('roof');
                      }
                    }}
                  >
                    <HStack>
                      <Text fontSize={isMobileDevice ? 'xs' : 'med'}>Type</Text>
                      <Spacer />
                      <Radio
                        value="new"
                        pr="10px"
                        isDisabled={isLockedList?.includes('roofType')}
                      >
                        <Text as="b" fontSize={isMobileDevice ? 'xs' : 'med'}>
                          New Construction
                        </Text>
                      </Radio>
                      {/* <Spacer /> */}
                      <Radio
                        value="tear"
                        pr="10px"
                        isDisabled={isLockedList?.includes('roofType')}
                      >
                        <Text as="b" fontSize={isMobileDevice ? 'xs' : 'med'}>
                          Tear-off
                        </Text>
                      </Radio>
                      {/* <Spacer /> */}
                      <Radio
                        value="roof"
                        isDisabled={isLockedList?.includes('roofType')}
                      >
                        <Text as="b" fontSize={isMobileDevice ? 'xs' : 'med'}>
                          Roof Over
                        </Text>
                      </Radio>
                    </HStack>
                  </RadioGroup>
                </div>
                <div>
                  <RadioGroup
                    onChange={handleRadioChange}
                    value={dumpsterPlacement}
                  >
                    <Stack direction="row">
                      <Text fontSize={isMobileDevice ? 'xs' : 'med'}>
                        Dumpster Placement
                      </Text>
                      <Spacer />
                      <Radio
                        value="onstreet"
                        isDisabled={isLockedList?.includes('dumpsterPlacement')}
                      >
                        <Text as="b" fontSize={isMobileDevice ? 'xs' : 'med'}>
                          On-Street
                        </Text>
                      </Radio>
                      <Radio
                        value="offstreet"
                        isDisabled={isLockedList?.includes('dumpsterPlacement')}
                      >
                        <Text as="b" fontSize={isMobileDevice ? 'xs' : 'med'}>
                          Off-Street
                        </Text>
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </div>
                <div>
                  <RadioGroup
                    value={difficultDumpsterAccess}
                    onChange={value => {
                      setUnsavedChanges(true);
                      dispatch(updateDifficultDumpsterAccess(value === 'true'));
                    }}
                  >
                    <Stack direction="row">
                      <Text fontSize={isMobileDevice ? 'xs' : 'med'}>
                        Difficult Dumpster Access
                      </Text>
                      <Spacer />
                      <Radio
                        value={true}
                        isDisabled={isLockedList?.includes(
                          'difficultDumpsterAccess'
                        )}
                      >
                        <Text as="b" fontSize={isMobileDevice ? 'xs' : 'med'}>
                          Yes
                        </Text>
                      </Radio>
                      <Radio
                        value={false}
                        isDisabled={isLockedList?.includes(
                          'difficultDumpsterAccess'
                        )}
                      >
                        <Text as="b" fontSize={isMobileDevice ? 'xs' : 'med'}>
                          No
                        </Text>
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </div>
                <div>
                  <RadioGroup
                    value={closeNeighboringHomes}
                    onChange={value => {
                      console.log(value);
                      setUnsavedChanges(true);
                      dispatch(updateCloseNeighboringHomes(value));
                    }}
                  >
                    <Stack direction="row">
                      <Text fontSize={isMobileDevice ? 'xs' : 'med'}>
                        Close to Neighboring Homes
                      </Text>
                      <Spacer />
                      <Radio
                        value={'none'}
                        isDisabled={isLockedList?.includes(
                          'closeNeighboringHomes'
                        )}
                      >
                        <Text as="b" fontSize={isMobileDevice ? 'xs' : 'med'}>
                          No
                        </Text>
                      </Radio>
                      <Radio
                        value={'one'}
                        isDisabled={isLockedList?.includes(
                          'closeNeighboringHomes'
                        )}
                      >
                        <Text as="b" fontSize={isMobileDevice ? 'xs' : 'med'}>
                          One side
                        </Text>
                      </Radio>
                      <Radio
                        value={'both'}
                        isDisabled={isLockedList?.includes(
                          'closeNeighboringHomes'
                        )}
                      >
                        <Text as="b" fontSize={isMobileDevice ? 'xs' : 'med'}>
                          Both sides
                        </Text>
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </div>
                <div>
                  <RadioGroup
                    value={permitFee}
                    onChange={value => {
                      setUnsavedChanges(true);
                      dispatch(updatePermitFee(value));
                    }}
                  >
                    <HStack justify="space-between">
                      <HStack spacing="30px">
                        <Text fontSize={isMobileDevice ? 'xs' : 'med'}>
                          Permit Fee
                        </Text>
                        <Button
                          size={isMobileDevice ? 'xs' : 'sm'}
                          colorScheme="blue"
                          isDisabled={isLockedList?.includes('permitFee')}
                          variant={permitFee === 'none' ? 'solid' : 'outline'}
                          onClick={() => {
                            if (permitFee === 'none') {
                              dispatch(updatePermitFee(''));
                            } else {
                              dispatch(updatePermitFee('none'));
                            }
                          }}
                        >
                          <Text fontSize={isMobileDevice ? 'xs' : 'sm'}>
                            None
                          </Text>
                        </Button>
                      </HStack>
                      {permitFee !== 'none' && (
                        <HStack justifySelf="start" spacing="30px">
                          <Radio
                            value="percent"
                            isDisabled={isLockedList?.includes('permitFee')}
                          >
                            <Text
                              as="b"
                              fontSize={isMobileDevice ? 'xs' : 'sm'}
                            >
                              Percentage
                            </Text>
                          </Radio>
                          <Radio
                            value="flat"
                            isDisabled={isLockedList?.includes('permitFee')}
                          >
                            <Text
                              as="b"
                              fontSize={isMobileDevice ? 'xs' : 'sm'}
                            >
                              Flat Fee
                            </Text>
                          </Radio>
                          {!isMobileDevice && (
                            <HStack>
                              {permitFee === 'flat' && <Text as="b">$</Text>}
                              <NumberInput
                                size="xs"
                                width="70px"
                                isReadOnly={isLockedList?.includes('permitFee')}
                                variant={
                                  isLockedList?.includes('permitFee')
                                    ? 'filled'
                                    : 'outline'
                                }
                                value={
                                  permitFee === 'percent'
                                    ? permitFeePercent ?? ''
                                    : permitFee === 'flat'
                                    ? permitFeeFlat ?? ''
                                    : ''
                                }
                                onChange={value => {
                                  if (permitFee === 'percent') {
                                    dispatch(updatePermitFeePercent(value));
                                  } else if (permitFee === 'flat') {
                                    dispatch(updatePermitFeeFlat(value));
                                  }
                                  setUnsavedChanges(true);
                                }}
                              >
                                <NumberInputField />
                              </NumberInput>
                              {permitFee === 'percent' && <Text as="b">%</Text>}
                            </HStack>
                          )}
                        </HStack>
                      )}
                    </HStack>
                    {isMobileDevice && (
                      <HStack justify="end" pt="10px">
                        {permitFee === 'flat' && <Text as="b">$</Text>}
                        <NumberInput
                          size="xs"
                          width="70px"
                          isReadOnly={isLockedList?.includes('permitFee')}
                          variant={
                            isLockedList?.includes('permitFee')
                              ? 'filled'
                              : 'outline'
                          }
                          value={
                            permitFee === 'percent'
                              ? permitFeePercent ?? ''
                              : permitFee === 'flat'
                              ? permitFeeFlat ?? ''
                              : ''
                          }
                          onChange={value => {
                            if (permitFee === 'percent') {
                              dispatch(updatePermitFeePercent(value));
                            } else if (permitFee === 'flat') {
                              dispatch(updatePermitFeeFlat(value));
                            }
                            setUnsavedChanges(true);
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                        {permitFee === 'percent' && <Text as="b">%</Text>}
                      </HStack>
                    )}
                  </RadioGroup>
                </div>
              </Flex>
            </ModalBody>
            <ModalFooter padding="10px">
              {unsavedChanged && (
                <Box>
                  <Alert status="warning" maxWidth="sm">
                    <AlertIcon />
                    <AlertTitle>You have unsaved changes!</AlertTitle>
                  </Alert>
                </Box>
              )}
              <Button onClick={handleSave} colorScheme="blue" ml="60px">
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </Box>
  );
};

export default General;
