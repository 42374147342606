import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Heading,
  Text,
  Link,
} from '@chakra-ui/react';
import { logInWithEmailAndPassword } from '../firebase';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { useMediaQuery } from '@chakra-ui/react';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)');

  const handleSubmit = async event => {
    event.preventDefault();
    await logInWithEmailAndPassword(email, password);
    // window.location.reload();
    if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
      mixpanel.identify(email);
      mixpanel.track('Log In');
    }
    navigate('/estimator');
  };

  return (
    <Box>
      <Box
        bg="gray.200"
        maxW="xs"
        mx="auto"
        mt={isLargerThan800 ? 200 : 150}
        p={isLargerThan800 ? 4 : 2}
        px="4"
        borderRadius={20}
      >
        <Box textAlign="center">
          <Heading mb={4} size={isLargerThan800 ? 'lg' : 'sm'}>
            Login
          </Heading>
        </Box>
        <form onSubmit={handleSubmit}>
          <FormControl id="email" isRequired>
            <FormLabel>Email address</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="Enter your email"
              bg="white"
            />
          </FormControl>
          <FormControl id="password" mt={4} isRequired>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder="Enter your password"
              bg="white"
            />
          </FormControl>
          <Box textAlign="center">
            <Button type="submit" colorScheme="blue" mt={4} w="100%">
              Login
            </Button>
          </Box>
        </form>
        {isLargerThan800 && (
          <Text mt={4} textAlign="center">
            <Link href="/reset" color="blue.600">
              Forgot your password?
            </Link>
          </Text>
        )}
        <Text mt={4} textAlign="center">
          <Link href="/signup" color="blue.600">
            Don't have an account? Sign up now!
          </Link>
        </Text>
      </Box>
    </Box>
  );
}

export default LoginPage;
