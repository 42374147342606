import {
  Flex,
  Heading,
  Text,
  Button,
  HStack,
  Spacer,
  IconButton,
  Center,
  Tabs,
  TabList,
  TabPanels,
  Select,
  Tab,
  TabPanel,
  VStack,
  Box,
  TabIndicator,
  Spinner,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Stack,
  UnorderedList,
  ListItem,
  Input,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Tooltip,
  Alert,
  AlertIcon,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDescription,
  AlertTitle,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { SmallCloseIcon } from '@chakra-ui/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import useUser from '../hooks/useUser';
import mixpanel from 'mixpanel-browser';
import { registerWithEmailAndPassword } from '../firebase';
import { useDispatch } from 'react-redux';
import { updateLabor } from '../features/labor/laborSlice';
import { updatePrice } from '../features/price/priceSlice';
import LogoutButton from './LogoutButton';

const Settings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const currentPath = location.pathname;
  const { user, isUserLoading } = useUser();
  const toast = useToast();
  const [upgradeLoading, setUpgradeLoading] = useState(false);
  const [addUserLoading, setAddUserLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isReviewLoading, setIsReviewLoading] = useState(false);
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const [salespersons, setSalespersons] = useState([]);
  const [salespersonEmail, setSalespersonEmail] = useState('');
  const [salespersonPassword, setSalespersonPassword] = useState('');
  const [salespersonIsRestricted, setSalespersonIsRestricted] = useState('');
  const [salespersonToDelete, setSalespersonToDelete] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isReviewOpen,
    onOpen: onReviewOpen,
    onClose: onReviewClose,
  } = useDisclosure();
  const {
    isOpen: isCancelOpen,
    onOpen: onCancelOpen,
    onClose: onCancelClose,
  } = useDisclosure();
  const cancelRef = React.useRef();
  const cancelReviewRef = React.useRef();
  const cancelSubscriptionRef = React.useRef();

  const handleUpgradeClick = async () => {
    setUpgradeLoading(true);
    try {
      const token = await user.getIdToken();
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`,
        {
          username: user.email,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      window.location.href = res.data.url;
    } catch (e) {
      setUpgradeLoading(false);
      console.error(e);
      toast({
        title: 'Error with purchase',
        description: 'Please try again later',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    // window.open(res.data.url, '_blank', 'noreferrer');
  };

  const handleAddUser = async event => {
    event.preventDefault();
    setAddUserLoading(true);
    try {
      const token = await user.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/create_salesperson`,
        {
          administrator: user.email,
          username: salespersonEmail,
          password: salespersonPassword,
          isRestricted: salespersonIsRestricted,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      setSalespersons(prevList => [
        ...prevList,
        {
          email: salespersonEmail,
          password: salespersonPassword,
          isRestricted: salespersonIsRestricted,
        },
      ]);
      setSalespersonEmail('');
      setSalespersonPassword('');
      setSalespersonIsRestricted('');
    } catch (e) {
      console.error(e);
      let tempDescription = 'Please try again later';
      if (e?.response?.data?.error === 'User already exists') {
        tempDescription = 'User with this email already exists';
      }
      toast({
        title: 'Error adding salesperson',
        description: tempDescription,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    setAddUserLoading(false);
  };

  const handleIsRestrictedChange = async (username, isRestricted) => {
    try {
      const token = await user.getIdToken();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/update_salesperson`,
        {
          username: username,
          isRestricted: isRestricted,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      console.log(response);
      await fetchSalespersons();
    } catch (e) {
      console.error(e);
    }
  };

  const fetchSalespersons = async () => {
    setIsLoading(true);
    try {
      const token = await user.getIdToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/salespersons`,
        {
          headers: {
            authorization: `${token}`,
          },
          params: {
            username: user.email,
          },
        }
      );
      setSalespersons(response.data);
      console.log(response);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setIsLoading(false);
  };

  const onDeleteConfirm = async () => {
    if (!salespersonToDelete) {
      onClose();
      return;
    }
    try {
      setIsDeleteLoading(true);
      const token = await user.getIdToken();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/delete_salesperson`,
        {
          username: salespersonToDelete,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      console.log(response);
      setIsDeleteLoading(false);
      onClose();
      await fetchSalespersons();
    } catch (error) {
      console.error(error);
      setIsDeleteLoading(false);
    }
  };

  const wait = n => new Promise(resolve => setTimeout(resolve, n));

  const calculateDaysRemaining = createdAt => {
    if (!createdAt) {
      return '??';
    }
    const createdAtDate = new Date(createdAt);
    const currentDate = new Date();

    const msDifference = currentDate - createdAtDate;
    const dayDifference = Math.floor(msDifference / (1000 * 60 * 60 * 24));

    return 31 - dayDifference > 0 ? 31 - dayDifference : 0;
  };

  const onCancelConfirm = async () => {
    try {
      setIsCancelLoading(true);
      const token = await user.getIdToken();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/cancel`,
        {
          username: user.email,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      setIsCancelLoading(false);
      onCancelClose();
      window.location.reload(false);
    } catch (error) {
      console.error(error);
      setIsCancelLoading(false);
      toast({
        title: 'Error',
        description:
          'Please try again later or email info@roofestimatorpros.com',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const onReviewConfirm = async () => {
    try {
      setIsReviewLoading(true);
      const token = await user.getIdToken();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/review_prices`,
        {
          username: user.email,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      // await wait(5000);
      setIsReviewLoading(false);
      onReviewClose();
      dispatch(updatePrice({ hasBeenSaved: [] }));
      dispatch(updateLabor({ hasBeenSaved: [] }));
      toast({
        title: 'Success',
        description: 'Please review prices',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
      setIsReviewLoading(false);
      toast({
        title: 'Error',
        description: 'Please try again later',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (user && user.profile === 'admin') {
      // fetch related users
      fetchSalespersons();
    }
    console.log(user);
  }, [user]);

  return (
    <Center m="20px">
      <VStack width="100%">
        <HStack width="100%" justify="center" align="center">
          {user?.profile === 'admin' && (
            <Button variant="solid" colorScheme="blue" onClick={onReviewOpen}>
              <Text fontSize="sm">Review Pricing</Text>
            </Button>
          )}
          <LogoutButton />
        </HStack>
        {isUserLoading === false && (
          <Tabs width="700px">
            <TabList>
              {user?.profile === 'admin' && (
                <>
                  <Tab>
                    <Text as="b">Billing</Text>
                  </Tab>
                  <Tab>
                    <Text as="b">Users</Text>
                  </Tab>
                </>
              )}
              <Tab>
                <Text as="b">About</Text>
              </Tab>
            </TabList>
            <TabPanels>
              {user?.profile === 'admin' && (
                <TabPanel>
                  <VStack>
                    {user?.payment === 'expired' && (
                      <>
                        <Alert status="error">
                          <AlertIcon />
                          <Box>
                            <AlertTitle>Your free trial has expired</AlertTitle>
                            <AlertDescription>Sign up below!</AlertDescription>
                          </Box>
                        </Alert>
                      </>
                    )}
                    {user?.payment === 'free' && (
                      <>
                        <Alert status="warning" mb="30px">
                          <AlertIcon />
                          <Box>
                            <AlertTitle>
                              You are on a 31-day free trial
                            </AlertTitle>
                            <AlertDescription>
                              {calculateDaysRemaining(user?.createdAt) ?? '??'}{' '}
                              days remaining
                            </AlertDescription>
                          </Box>
                        </Alert>
                      </>
                    )}
                    {(user?.payment === 'free' ||
                      user?.payment === 'expired') &&
                      true && (
                        <HStack spacing="30px">
                          <Card maxW="sm" variant="outline" padding="10px">
                            <CardBody>
                              <Stack spacing="3">
                                <Heading size="md">Roof Estimator</Heading>
                                <Text
                                  fontSize="sm"
                                  style={{ visibility: 'hidden' }}
                                >
                                  One user
                                </Text>
                                <Text fontSize="sm">Unlimited estimates</Text>
                                <Text
                                  fontSize="sm"
                                  style={{ visibility: 'hidden' }}
                                >
                                  0 salespersons
                                </Text>
                                <Text color="blue.600" fontSize="2xl">
                                  $1,157 per month
                                </Text>
                              </Stack>
                            </CardBody>
                            <CardFooter>
                              <Button
                                variant="solid"
                                colorScheme="blue"
                                onClick={handleUpgradeClick}
                              >
                                {upgradeLoading ? (
                                  <Spinner />
                                ) : (
                                  <Text>Purchase</Text>
                                )}
                              </Button>
                            </CardFooter>
                          </Card>
                          {/* <Card maxW="sm" variant="outline" padding="10px">
                          <CardBody>
                            <Stack spacing="3">
                              <Heading size="md">Premium</Heading>
                              <Text fontSize="sm">Unlimited estimates</Text>
                              <Text fontSize="sm">One user</Text>
                              <Text
                                fontSize="sm"
                                style={{ visibility: 'hidden' }}
                              >
                                0 salespersons
                              </Text>
                              <Text color="blue.600" fontSize="2xl">
                                $97 per month
                              </Text>
                            </Stack>
                          </CardBody>
                          <CardFooter>
                            <Button
                              variant="solid"
                              colorScheme="blue"
                              onClick={handleUpgradeClick}
                            >
                              {upgradeLoading ? (
                                <Spinner />
                              ) : (
                                <Text>Upgrade</Text>
                              )}
                            </Button>
                          </CardFooter>
                        </Card>
                        <Card maxW="sm" variant="outline" padding="10px">
                          <CardBody>
                            <Stack spacing="3">
                              <Heading size="md">Advanced</Heading>
                              <Text fontSize="sm">Unlimited estimates</Text>
                              <Text fontSize="sm">1 administrator</Text>
                              <Text fontSize="sm">2 salespersons</Text>
                              <Text color="blue.600" fontSize="2xl">
                                $297 per month
                              </Text>
                            </Stack>
                          </CardBody>
                          <CardFooter>
                            <Button
                              variant="solid"
                              colorScheme="blue"
                              onClick={handleUpgradeClick}
                            >
                              {upgradeLoading ? (
                                <Spinner />
                              ) : (
                                <Text>Upgrade</Text>
                              )}
                            </Button>
                          </CardFooter>
                        </Card>
                        <Card maxW="md" variant="outline" padding="10px">
                          <CardBody>
                            <Stack spacing="3">
                              <Heading size="md">Professional</Heading>
                              <Text fontSize="sm">Unlimited estimates</Text>
                              <Text fontSize="sm">1 administrator</Text>
                              <Text fontSize="sm">4 salespersons</Text>
                              <Text color="blue.600" fontSize="2xl">
                                $497 per month
                              </Text>
                            </Stack>
                          </CardBody>
                          <CardFooter>
                            <Button
                              variant="solid"
                              colorScheme="blue"
                              onClick={handleUpgradeClick}
                            >
                              {upgradeLoading ? (
                                <Spinner />
                              ) : (
                                <Text>Upgrade</Text>
                              )}
                            </Button>
                          </CardFooter>
                        </Card> */}
                        </HStack>
                      )}
                    {user?.payment === 'premium' && (
                      <>
                        <Alert status="success">
                          <AlertIcon />
                          <Box>
                            <AlertTitle>
                              Your subscription was successfully activated on{' '}
                              {user.subscribeDate ?? '??-??-????'}
                            </AlertTitle>
                            <AlertDescription>
                              You will be billed monthly
                            </AlertDescription>
                          </Box>
                        </Alert>
                        <HStack pb="5px">
                          <Text>Current Plan:</Text>
                          <Text as="b">Premium</Text>
                        </HStack>
                        <Button colorScheme="red" onClick={onCancelOpen}>
                          Cancel Subscription
                        </Button>
                      </>
                    )}
                  </VStack>
                </TabPanel>
              )}
              {user?.profile === 'admin' && (
                <TabPanel>
                  <VStack>
                    <form onSubmit={handleAddUser}>
                      <FormControl id="email" isRequired mb={3}>
                        <FormLabel fontSize="sm">Email address</FormLabel>
                        <Input
                          maxWidth="200px"
                          size="xs"
                          value={salespersonEmail}
                          onChange={e => setSalespersonEmail(e.target.value)}
                        />
                      </FormControl>
                      <FormControl id="password" isRequired mb={3}>
                        <FormLabel fontSize="sm">Password</FormLabel>
                        <Input
                          maxWidth="200px"
                          size="xs"
                          value={salespersonPassword}
                          minLength={6}
                          onChange={e => setSalespersonPassword(e.target.value)}
                        />
                      </FormControl>
                      <FormControl id="isRestricted" isRequired>
                        <HStack align="center">
                          <FormLabel fontSize="sm">Is Restricted?</FormLabel>
                        </HStack>
                        <RadioGroup
                          value={salespersonIsRestricted}
                          onChange={value => {
                            setSalespersonIsRestricted(value);
                          }}
                        >
                          <HStack spacing="24px">
                            <Radio value="true">Yes</Radio>
                            <Radio value="false">No</Radio>
                          </HStack>
                        </RadioGroup>
                      </FormControl>
                      <Box textAlign="center" pb={4}>
                        <Button
                          type="submit"
                          colorScheme="blue"
                          mt={4}
                          w="100%"
                        >
                          {addUserLoading ? (
                            <Spinner />
                          ) : (
                            <Text>Add Salesperson</Text>
                          )}
                        </Button>
                      </Box>
                    </form>
                    {isLoading ? (
                      <Spinner
                        thickness="6px"
                        speed="1.25s"
                        size="lg"
                        emptyColor="gray.200"
                        color="black.500"
                      />
                    ) : (
                      <UnorderedList spacing={2} pb={3}>
                        {salespersons?.map((item, index) => (
                          <ListItem key={index}>
                            <HStack spacing="50px">
                              <HStack>
                                <Text as="b">Email: </Text>
                                <Text>{item.email}</Text>
                              </HStack>
                              <HStack>
                                <Text as="b">Password:</Text>
                                <Text> {item.password ?? '******'}</Text>
                              </HStack>
                              <HStack>
                                <Text as="b" fontSize="sm" whiteSpace="nowrap">
                                  Is Restricted:
                                </Text>
                                {/* <Text>{item.isRestricted ?? 'No'}</Text> */}
                                <Select
                                  size="xs"
                                  minWidth="70px"
                                  value={item.isRestricted}
                                  onChange={event => {
                                    handleIsRestrictedChange(
                                      item.email,
                                      event.target.value
                                    );
                                  }}
                                >
                                  <option value={true}>Yes</option>
                                  <option value={false}>No</option>
                                </Select>
                              </HStack>
                              <SmallCloseIcon
                                onClick={() => {
                                  setSalespersonToDelete(item.email);
                                  onOpen();
                                }}
                              />
                            </HStack>
                          </ListItem>
                        ))}
                      </UnorderedList>
                    )}
                  </VStack>
                </TabPanel>
              )}
              <TabPanel>
                <VStack align="flex-start" ml="50px">
                  <HStack>
                    <Text as="b">Email:</Text>
                    <Text> {user?.email}</Text>
                  </HStack>
                  <HStack>
                    <Text as="b">Profile: </Text>
                    <Text>{user?.profile}</Text>
                  </HStack>
                  {user?.profile === 'salesperson' && (
                    <HStack>
                      <Text as="b">Administrator: </Text>
                      <Text>{user?.administrator}</Text>
                    </HStack>
                  )}
                </VStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Salesperson User
              </AlertDialogHeader>
              <AlertDialogBody>
                <VStack align="flex-start" spacing="20px">
                  <Text>Are you sure? You can't undo this action.</Text>
                </VStack>
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={onDeleteConfirm} ml={3}>
                  {isDeleteLoading ? (
                    <Spinner
                      thickness="2px"
                      speed="1.25s"
                      size="sm"
                      color="black.500"
                    />
                  ) : (
                    <Text>Delete</Text>
                  )}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        <AlertDialog
          isOpen={isReviewOpen}
          leastDestructiveRef={cancelReviewRef}
          onClose={onReviewClose}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Review Pricing
              </AlertDialogHeader>
              <AlertDialogBody>
                <UnorderedList spacing={3}>
                  <ListItem fontSize="sm">
                    <Text>Sets all Material and Labor tabs to yellow</Text>
                  </ListItem>
                  <ListItem fontSize="sm">
                    <Text>Does not delete any pricing data</Text>
                  </ListItem>
                </UnorderedList>
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelReviewRef} onClick={onReviewClose}>
                  Cancel
                </Button>
                <Button colorScheme="blue" onClick={onReviewConfirm} ml={3}>
                  {isReviewLoading ? (
                    <Spinner
                      thickness="2px"
                      speed="1.25s"
                      size="sm"
                      color="black.500"
                    />
                  ) : (
                    <Text>Continue</Text>
                  )}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        <AlertDialog
          isOpen={isCancelOpen}
          leastDestructiveRef={cancelSubscriptionRef}
          onClose={onCancelOpen}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Cancel Subscription
              </AlertDialogHeader>
              <AlertDialogBody>
                <Text>Are you sure you want to cancel? </Text>
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelSubscriptionRef} onClick={onCancelClose}>
                  No
                </Button>
                <Button colorScheme="red" onClick={onCancelConfirm} ml={3}>
                  {isCancelLoading ? (
                    <Spinner
                      thickness="2px"
                      speed="1.25s"
                      size="sm"
                      color="black.500"
                    />
                  ) : (
                    <Text>Yes</Text>
                  )}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </VStack>
    </Center>
  );
};

export default Settings;
