import {
  Box,
  Button,
  Text,
  HStack,
  Flex,
  NumberInput,
  NumberInputField,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Select,
  useDisclosure,
  Alert,
  AlertIcon,
  VStack,
  AlertTitle,
} from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateDeckingExisting,
  updateDeckingExistingThickness,
  updateDeckingRedeck,
  updateDeckingRedeckThickness,
  updateDeckingNumReplacementSheets,
  updateDeckingLengthReplacementRoofBoards,
  updateDeckingIsCompleted,
} from '../features/calculator/calculatorSlice';
import useUser from '../hooks/useUser';
import { LockIcon, UnlockIcon } from '@chakra-ui/icons';
import mixpanel from 'mixpanel-browser';

const Decking = ({
  checkDeckingCompletion,
  isDeckingComplete,
  isDeckingLocked,
  isDeckingLockedList,
  setCalculateButtonClicked,
  isMobileDevice,
}) => {
  const { user } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const decking = useSelector(state => state.calculator.decking);
  const [unsavedChanged, setUnsavedChanges] = useState(false);
  const [isLocked, setIsLocked] = useState(isDeckingLocked);
  const [isLockedList, setIsLockedList] = useState(isDeckingLockedList);

  const handleOpen = () => {
    // if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    //   mixpanel.track('User opened the Decking modal.');
    // }
    onOpen();
  };

  const handleSave = async () => {
    try {
      const token = await user.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/roofs/decking`,
        {
          username: user.email,
          decking,
          isCompleted: false,
          isLocked: isLocked,
          isLockedList: isLockedList,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      setUnsavedChanges(false);
      checkDeckingCompletion(decking);
      setCalculateButtonClicked(true);
      onClose();
    } catch (error) {
      console.log('error: ' + error);
    }
  };

  return (
    <Box
      minW={isMobileDevice ? '90vw' : ''}
      maxW="sm"
      maxH="70px"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={isMobileDevice ? '3' : '6'}
      boxShadow="base"
      borderColor="gray.400"
      bg={isDeckingComplete ? 'green.300' : 'yellow.200'}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      position="relative"
      cursor="pointer"
      onClick={isMobileDevice ? handleOpen : null}
    >
      <Flex alignItems="center">
        {isLockedList?.length > 0 && !isMobileDevice && (
          <LockIcon boxSize={4} color="gray.900" />
        )}
        <Text fontWeight="bold" fontSize="l" pl="20px" mr="4">
          Decking
        </Text>
        {!isMobileDevice && (
          <Button
            size="sm"
            border="1px"
            borderColor="gray.400"
            ml="30px"
            onClick={handleOpen}
          >
            Edit
          </Button>
        )}
        <Modal isOpen={isOpen} size="xl" onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <HStack justify="space-between">
                <Text>Roof Decking</Text>
                {isLockedList?.length > 0 ? (
                  <LockIcon
                    boxSize={5}
                    color="gray.500"
                    onClick={() => {
                      setUnsavedChanges(true);
                      setIsLockedList([]);
                    }}
                  />
                ) : (
                  <UnlockIcon
                    boxSize={5}
                    color="gray.500"
                    onClick={() => {
                      setUnsavedChanges(true);
                      let tempList = [];
                      if (decking.existing !== '' && decking.existing != null) {
                        tempList.push('existing');
                      }
                      if (
                        decking.existingThickness !== '' &&
                        decking.existingThickness != null
                      ) {
                        tempList.push('existingThickness');
                      }
                      if (decking.redeck !== '' && decking.redeck != null) {
                        tempList.push('redeck');
                      }
                      if (
                        decking.redeckThickness !== '' &&
                        decking.redeckThickness != null
                      ) {
                        tempList.push('redeckThickness');
                      }
                      if (
                        decking.numReplacementSheets !== '' &&
                        decking.numReplacementSheets != null
                      ) {
                        tempList.push('numsheets');
                      }
                      if (
                        decking.lengthReplacementRoofBoards !== '' &&
                        decking.lengthReplacementRoofBoards != null
                      ) {
                        tempList.push('roofboards');
                      }

                      setIsLockedList([...isLockedList, ...tempList]);
                    }}
                  />
                )}
              </HStack>
            </ModalHeader>
            <ModalBody>
              <Flex direction="column" gap={isMobileDevice ? '5' : '5'}>
                <HStack>
                  <Text fontSize={isMobileDevice ? 'xs' : 'sm'}>
                    Existing Decking
                  </Text>
                  <Select
                    width="30%"
                    size={isMobileDevice ? 'xs' : 'sm'}
                    variant={
                      isLockedList?.includes('existing') ? 'filled' : 'outline'
                    }
                    css={
                      isLockedList?.includes('existing')
                        ? { pointerEvents: 'none' }
                        : {}
                    }
                    value={decking.existing}
                    onChange={event => {
                      setUnsavedChanges(true);
                      dispatch(updateDeckingExisting(event.target.value));
                      if (event.target.value !== 'boards') {
                        dispatch(updateDeckingLengthReplacementRoofBoards(''));
                      }
                    }}
                  >
                    <option value=""></option>
                    <option value="boards">Boards</option>
                    <option value="plywood">Plywood</option>
                    <option value="osb">OSB</option>
                  </Select>
                  <Text fontSize={isMobileDevice ? 'xs' : 'sm'}>Thickness</Text>
                  <Select
                    width="20%"
                    size={isMobileDevice ? 'xs' : 'sm'}
                    variant={
                      isLockedList?.includes('existingThickness')
                        ? 'filled'
                        : 'outline'
                    }
                    css={
                      isLockedList?.includes('existingThickness')
                        ? { pointerEvents: 'none' }
                        : {}
                    }
                    value={decking.existingThickness}
                    onChange={event => {
                      setUnsavedChanges(true);
                      dispatch(
                        updateDeckingExistingThickness(event.target.value)
                      );
                    }}
                  >
                    <option value=""></option>
                    <option value="3/8">3/8"</option>
                    <option value="1/2">1/2"</option>
                    <option value="5/8">5/8"</option>
                    <option value="3/4">3/4"</option>
                    <option value="1">1"</option>
                  </Select>
                </HStack>
                <HStack>
                  <Text fontSize={isMobileDevice ? 'xs' : 'sm'}>
                    Number of sheets needing replacement
                  </Text>
                  <NumberInput
                    size={isMobileDevice ? 'xs' : 'sm'}
                    maxWidth="100px"
                    isReadOnly={isLockedList?.includes('numsheets')}
                    variant={
                      isLockedList?.includes('numsheets') ? 'filled' : 'outline'
                    }
                    value={decking.numReplacementSheets ?? ''}
                    onChange={value => {
                      setUnsavedChanges(true);
                      dispatch(updateDeckingNumReplacementSheets(value));
                    }}
                  >
                    <NumberInputField />
                  </NumberInput>
                </HStack>
                {decking.existing === 'boards' && (
                  <>
                    <HStack>
                      <Text fontSize={isMobileDevice ? 'xs' : 'sm'}>
                        Linear feet of roof boards needing replacement
                      </Text>
                      <NumberInput
                        size={isMobileDevice ? 'xs' : 'sm'}
                        maxWidth="100px"
                        isReadOnly={isLockedList?.includes('roofboards')}
                        variant={
                          isLockedList?.includes('roofboards')
                            ? 'filled'
                            : 'outline'
                        }
                        value={decking.lengthReplacementRoofBoards ?? ''}
                        onChange={value => {
                          setUnsavedChanges(true);
                          dispatch(
                            updateDeckingLengthReplacementRoofBoards(value)
                          );
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                    </HStack>
                  </>
                )}
                <HStack mt="30px">
                  <VStack spacing="1" align="start">
                    <Text fontSize={isMobileDevice ? 'xs' : 'sm'}>
                      Redeck Material
                    </Text>
                    <Text fontSize={isMobileDevice ? 'xs' : 'sm'} as="i">
                      (If Needed)
                    </Text>
                  </VStack>
                  <Select
                    width="30%"
                    size={isMobileDevice ? 'xs' : 'sm'}
                    variant={
                      isLockedList?.includes('redeck') ? 'filled' : 'outline'
                    }
                    css={
                      isLockedList?.includes('redeck')
                        ? { pointerEvents: 'none' }
                        : {}
                    }
                    value={decking.redeck}
                    onChange={event => {
                      setUnsavedChanges(true);
                      dispatch(updateDeckingRedeck(event.target.value));
                    }}
                  >
                    <option value=""></option>
                    <option value="plywood">Plywood</option>
                    <option value="osb">OSB</option>
                  </Select>
                  <Text fontSize={isMobileDevice ? 'xs' : 'sm'}>Thickness</Text>
                  <Select
                    width="20%"
                    size={isMobileDevice ? 'xs' : 'sm'}
                    variant={
                      isLockedList?.includes('redeckThickness')
                        ? 'filled'
                        : 'outline'
                    }
                    css={
                      isLockedList?.includes('redeckThickness')
                        ? { pointerEvents: 'none' }
                        : {}
                    }
                    value={decking.redeckThickness}
                    onChange={event => {
                      setUnsavedChanges(true);
                      dispatch(
                        updateDeckingRedeckThickness(event.target.value)
                      );
                    }}
                  >
                    <option value=""></option>
                    <option value="3/8">3/8"</option>
                    <option value="1/2">1/2"</option>
                    <option value="5/8">5/8"</option>
                    <option value="3/4">3/4"</option>
                    <option value="1">1"</option>
                  </Select>
                </HStack>
              </Flex>
            </ModalBody>
            <ModalFooter>
              {unsavedChanged && (
                <Box>
                  <Alert status="warning" maxWidth="sm">
                    <AlertIcon />
                    <AlertTitle>You have unsaved changes!</AlertTitle>
                  </Alert>
                </Box>
              )}
              <Button onClick={handleSave} colorScheme="blue" ml="60px">
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </Box>
  );
};

export default Decking;
