import {
  Box,
  Button,
  Text,
  HStack,
  Flex,
  NumberInput,
  NumberInputField,
  Modal,
  Stack,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Select,
  RadioGroup,
  Radio,
  Spacer,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateGuttersHasExistingAprons,
  updateGuttersIsReset,
  updateGuttersResetLength,
  updateGuttersIsRemoval,
  updateGuttersRemovalLength,
  updateGuttersHasNewGutters,
  updateGuttersNewGuttersLength,
  updateGuttersHasNewAprons,
  updateGuttersNewApronsLength,
  updateGuttersNewApronsColor,
  updateGuttersIsCompleted,
} from '../features/calculator/calculatorSlice';
import useUser from '../hooks/useUser';
import { LockIcon, UnlockIcon } from '@chakra-ui/icons';
import mixpanel from 'mixpanel-browser';

const Gutters = ({
  checkGuttersCompletion,
  isGuttersComplete,
  isGuttersLocked,
  isGuttersLockedList,
  setCalculateButtonClicked,
  isMobileDevice,
}) => {
  const { user } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const gutters = useSelector(state => state.calculator.gutters);
  const hasExistingAprons = useSelector(
    state => state.calculator.gutters.hasExistingAprons
  );
  const isReset = useSelector(state => state.calculator.gutters.isReset);
  const isRemoval = useSelector(state => state.calculator.gutters.isRemoval);
  const hasNewGutters = useSelector(
    state => state.calculator.gutters.hasNewGutters
  );
  const hasNewAprons = useSelector(
    state => state.calculator.gutters.hasNewAprons
  );
  const resetLength = useSelector(
    state => state.calculator.gutters.resetLength
  );
  const removalLength = useSelector(
    state => state.calculator.gutters.removalLength
  );
  const newGuttersLength = useSelector(
    state => state.calculator.gutters.newGuttersLength
  );
  const newApronsLength = useSelector(
    state => state.calculator.gutters.newApronsLength
  );
  const newApronsColor = useSelector(
    state => state.calculator.gutters.newApronsColor
  );
  const [isLocked, setIsLocked] = useState(isGuttersLocked);
  const [isLockedList, setIsLockedList] = useState(isGuttersLockedList);

  const handleIsResetRadioChange = value => {
    setUnsavedChanges(true);
    dispatch(updateGuttersIsReset(value === 'true'));
  };

  const handleExistingApronsRadioChange = value => {
    setUnsavedChanges(true);
    dispatch(updateGuttersHasExistingAprons(value === 'true'));
  };

  const handleIsRemovalRadioChange = value => {
    setUnsavedChanges(true);
    dispatch(updateGuttersIsRemoval(value === 'true'));
  };

  const handleHasNewGuttersRadioChange = value => {
    setUnsavedChanges(true);
    dispatch(updateGuttersHasNewGutters(value === 'true'));
  };

  const handleHasNewApronsRadioChange = value => {
    setUnsavedChanges(true);
    dispatch(updateGuttersHasNewAprons(value === 'true'));
  };

  const handleSelectChange = event => {
    setUnsavedChanges(true);
    dispatch(updateGuttersNewApronsColor(event.target.value));
  };

  const [unsavedChanged, setUnsavedChanges] = useState(false);

  const handleOpen = () => {
    // if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    //   mixpanel.track('User opened the General modal.');
    // }
    onOpen();
  };

  const handleSave = async () => {
    try {
      const token = await user.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/roofs/gutters`,
        {
          username: user.email,
          hasExistingAprons: gutters.hasExistingAprons,
          isReset: gutters.isReset,
          resetLength: gutters.resetLength,
          isRemoval: gutters.isRemoval,
          removalLength: gutters.removalLength ?? '',
          hasNewAprons: gutters.hasNewAprons,
          newApronsLength: gutters.newApronsLength ?? '',
          newApronsColor: gutters.newApronsColor,
          isCompleted: false,
          isLocked: isLocked,
          isLockedList: isLockedList,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      setUnsavedChanges(false);
      checkGuttersCompletion(gutters);
      setCalculateButtonClicked(true);
      onClose();
    } catch (error) {
      console.log('error: ' + error);
    }
  };

  return (
    <Box
      minW={isMobileDevice ? '90vw' : ''}
      maxH="70px"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={isMobileDevice ? '3' : '6'}
      boxShadow="base"
      borderColor="gray.400"
      bg={isGuttersComplete ? 'green.300' : 'yellow.200'}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      position="relative"
      cursor="pointer"
      onClick={isMobileDevice ? handleOpen : null}
    >
      <Flex alignItems="center">
        {isLockedList?.length > 0 && !isMobileDevice && (
          <LockIcon boxSize={4} color="gray.900" />
        )}
        <Text fontWeight="bold" fontSize="l" mr="4" ml="20px">
          Gutters
        </Text>
        {!isMobileDevice && (
          <Button
            size="sm"
            border="1px"
            borderColor="gray.400"
            ml="30px"
            onClick={handleOpen}
          >
            Edit
          </Button>
        )}
        <Modal isOpen={isOpen} size="xl" onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <HStack justify="space-between">
                <Text>Gutters</Text>
                {isLockedList?.length > 0 ? (
                  <LockIcon
                    boxSize={5}
                    color="gray.500"
                    onClick={() => {
                      setUnsavedChanges(true);
                      setIsLockedList([]);
                    }}
                  />
                ) : (
                  <UnlockIcon
                    boxSize={5}
                    color="gray.500"
                    onClick={() => {
                      setUnsavedChanges(true);
                      let tempList = [];
                      if (
                        hasExistingAprons !== '' &&
                        hasExistingAprons != null
                      ) {
                        tempList.push('hasExistingAprons');
                      }
                      if (isReset !== '' && isReset != null) {
                        tempList.push('isReset');
                      }
                      if (resetLength !== '' && resetLength != null) {
                        tempList.push('resetLength');
                      }
                      if (isRemoval !== '' && isRemoval != null) {
                        tempList.push('isRemoval');
                      }
                      if (removalLength !== '' && removalLength != null) {
                        tempList.push('removalLength');
                      }
                      if (hasNewAprons !== '' && hasNewAprons != null) {
                        tempList.push('hasNewAprons');
                      }
                      if (newApronsLength !== '' && newApronsLength != null) {
                        tempList.push('newApronsLength');
                      }

                      setIsLockedList([...isLockedList, ...tempList]);
                    }}
                  />
                )}
              </HStack>
            </ModalHeader>
            <ModalBody>
              <Flex direction="column" gap="4">
                {/* <RadioGroup
                  isDisabled={isLockedList?.includes('hasExistingAprons')}
                  value={hasExistingAprons}
                  onChange={handleExistingApronsRadioChange}
                >
                  <Stack direction="row">
                    <Text mr="3">Existing Gutter Apron:</Text>
                    <Radio value={true}>
                      <Text as="b">Yes</Text>
                    </Radio>
                    <Radio value={false}>
                      <Text as="b">No</Text>
                    </Radio>
                  </Stack>
                </RadioGroup> */}
                <HStack>
                  <RadioGroup
                    isDisabled={isLockedList?.includes('isReset')}
                    value={isReset}
                    onChange={handleIsResetRadioChange}
                  >
                    <Stack direction="row">
                      <Text fontSize={isMobileDevice ? 'xs' : 'sm'}>
                        Gutter Detach/Reset
                      </Text>
                      <Spacer />
                      <Radio value={true}>
                        <Text fontSize={isMobileDevice ? 'xs' : 'sm'} as="b">
                          Yes
                        </Text>
                      </Radio>
                      <Radio value={false}>
                        <Text fontSize={isMobileDevice ? 'xs' : 'sm'} as="b">
                          No
                        </Text>
                      </Radio>
                    </Stack>
                  </RadioGroup>
                  <Spacer />
                  <NumberInput
                    size={isMobileDevice ? 'xs' : 'sm'}
                    maxWidth={isMobileDevice ? '70px' : '120px'}
                    isReadOnly={isLockedList?.includes('resetLength')}
                    variant={
                      isLockedList?.includes('resetLength')
                        ? 'filled'
                        : 'outline'
                    }
                    value={resetLength ?? ''}
                    onChange={value => {
                      setUnsavedChanges(true);
                      dispatch(updateGuttersResetLength(value));
                    }}
                  >
                    <NumberInputField />
                  </NumberInput>
                  <Text as="b" fontSize={isMobileDevice ? 'xs' : 'sm'}>
                    Ft.
                  </Text>
                </HStack>

                <HStack>
                  <RadioGroup
                    isDisabled={isLockedList?.includes('isRemoval')}
                    value={isRemoval}
                    onChange={handleIsRemovalRadioChange}
                  >
                    <Stack direction="row">
                      <Text fontSize={isMobileDevice ? 'xs' : 'sm'}>
                        Remove Gutters/Down Spouts
                      </Text>
                      <Spacer />
                      <Radio value={true}>
                        <Text as="b" fontSize={isMobileDevice ? 'xs' : 'sm'}>
                          Yes
                        </Text>
                      </Radio>
                      <Radio value={false}>
                        <Text as="b" fontSize={isMobileDevice ? 'xs' : 'sm'}>
                          No
                        </Text>
                      </Radio>
                    </Stack>
                  </RadioGroup>
                  <Spacer />
                  <NumberInput
                    size={isMobileDevice ? 'xs' : 'sm'}
                    maxWidth={isMobileDevice ? '70px' : '120px'}
                    isReadOnly={isLockedList?.includes('removalLength')}
                    variant={
                      isLockedList?.includes('removalLength')
                        ? 'filled'
                        : 'outline'
                    }
                    value={removalLength ?? ''}
                    onChange={value => {
                      setUnsavedChanges(true);
                      dispatch(updateGuttersRemovalLength(value));
                    }}
                  >
                    <NumberInputField />
                  </NumberInput>
                  <Text as="b" fontSize={isMobileDevice ? 'xs' : 'sm'}>
                    Ft.
                  </Text>
                </HStack>
                {/* <HStack>
                  <RadioGroup
                    value={hasNewGutters}
                    onChange={handleHasNewGuttersRadioChange}
                  >
                    <Stack direction="row">
                      <Text>New Gutters/Down Spouts:</Text>
                      <Spacer />
                      <Radio value={true}>
                        <Text as="b">Yes</Text>
                      </Radio>
                      <Radio value={false}>
                        <Text as="b">No</Text>
                      </Radio>
                    </Stack>
                  </RadioGroup>
                  <Spacer />
                  <NumberInput
                    size="sm"
                    width="120px"
                    value={newGuttersLength ?? ''}
                    onChange={value => {
                      setUnsavedChanges(true);
                      dispatch(updateGuttersNewGuttersLength(value));
                    }}
                  >
                    <NumberInputField />
                  </NumberInput>
                  <Text as="b">Ft.</Text>
                </HStack> */}
                {/* <HStack>
                  <Text>Apron Color:</Text>
                  <Select
                    size="sm"
                    width="100px"
                    variant={isLocked ? 'filled' : 'outline'}
                    css={isLocked ? { pointerEvents: 'none' } : {}}
                    value={newApronsColor}
                    onChange={handleSelectChange}
                  >
                    <option value=""></option>
                    <option value="white">White</option>
                    <option value="brown">Brown</option>
                  </Select>
                </HStack> */}
                <HStack>
                  <RadioGroup
                    isDisabled={isLockedList?.includes('hasNewAprons')}
                    value={hasNewAprons}
                    onChange={handleHasNewApronsRadioChange}
                  >
                    <Stack direction="row">
                      <Text fontSize={isMobileDevice ? 'xs' : 'sm'}>
                        Install New Aprons on All Eaves
                      </Text>
                      <Spacer />
                      <Radio value={true}>
                        <Text as="b" fontSize={isMobileDevice ? 'xs' : 'sm'}>
                          Yes
                        </Text>
                      </Radio>
                      <Radio value={false}>
                        <Text as="b" fontSize={isMobileDevice ? 'xs' : 'sm'}>
                          No
                        </Text>
                      </Radio>
                    </Stack>
                  </RadioGroup>
                  <Spacer />
                </HStack>
                {!hasNewAprons && (
                  <HStack spacing="30">
                    <Text fontSize={isMobileDevice ? 'xs' : 'sm'}>
                      Linear Feet of Aprons to Install
                    </Text>
                    <NumberInput
                      size={isMobileDevice ? 'xs' : 'sm'}
                      maxWidth="100px"
                      isReadOnly={isLockedList?.includes('newApronsLength')}
                      variant={
                        isLockedList?.includes('newApronsLength')
                          ? 'filled'
                          : 'outline'
                      }
                      value={newApronsLength ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(updateGuttersNewApronsLength(value));
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </HStack>
                )}
              </Flex>
            </ModalBody>
            <ModalFooter>
              {unsavedChanged && (
                <Box>
                  <Alert status="warning" maxWidth="sm">
                    <AlertIcon />
                    <AlertTitle>You have unsaved changes!</AlertTitle>
                  </Alert>
                </Box>
              )}
              <Button onClick={handleSave} colorScheme="blue" ml="60px">
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </Box>
  );
};

export default Gutters;
