import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  NumberInput,
  NumberInputField,
  Center,
  Text,
  VStack,
  Stack,
  Radio,
  RadioGroup,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  useToast,
} from '@chakra-ui/react';
import { useState, useEffect, React } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateShinglesField,
  updateWarrantyField,
  updateHasBeenSaved,
} from '../../features/price/priceSlice';
import axios from 'axios';
import useUser from '../../hooks/useUser';
import mixpanel from 'mixpanel-browser';

const ShinglesPrices = ({ checkShinglePriceCompletion }) => {
  const dispatch = useDispatch();
  const shingles = useSelector(state => state.price.shingles);
  const warranty = useSelector(state => state.price.warranty);
  const savedList = useSelector(state => state.price.hasBeenSaved);
  const toast = useToast();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const { user } = useUser();
  const [manufacturer, setManufacturer] = useState(shingles.manufacturer ?? '');
  const [shingleType, setShingleType] = useState(shingles.shingleType ?? '');
  const [line, setLine] = useState(shingles.line ?? '');
  const [color, setColor] = useState(shingles.color ?? '');
  const [unit, setUnit] = useState(shingles.unit ?? '');
  const [pricePer, setPricePer] = useState(shingles.pricePer ?? '');
  const [warrantyName, setWarrantyName] = useState(warranty.name ?? '');
  const [warrantyPrice, setWarrantyPrice] = useState(warranty.price ?? '');
  const [isPerJob, setIsPerJob] = useState(warranty.isPerJob ?? false);
  const [isPerSq, setIsPerSq] = useState(warranty.isPerSq ?? false);

  const handleSave = async () => {
    try {
      // if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
      //   mixpanel.track('User saved the Shingles Material prices.');
      // }
      dispatch(
        updateShinglesField({
          newValue: manufacturer,
          fieldToUpdate: 'manufacturer',
        })
      );
      const token = await user.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/prices/shingles`,
        {
          username: user.email,
          shingles: {
            manufacturer: manufacturer,
            shingleType: shingleType,
            line: line,
            unit: unit,
            pricePer: pricePer,
            color: color,
          },
          warranty: {
            isPerJob: isPerJob,
            isPerSq: isPerSq,
            price: warrantyPrice,
            name: warrantyName,
          },
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );

      dispatch(updateHasBeenSaved('shingles'));
      checkShinglePriceCompletion(
        {
          manufacturer: manufacturer,
          shingleType: shingleType,
          line: line,
          unit: unit,
          pricePer: pricePer,
          color: color,
        },
        {
          isPerJob: isPerJob,
          isPerSq: isPerSq,
          price: warrantyPrice,
          name: warrantyName,
        },
        [...savedList, 'shingles']
      );
      setUnsavedChanges(false);
    } catch (error) {
      console.log('error: ' + error);
    }

    toast({
      title: 'Changes saved',
      description: 'Your pricing was updated.',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  // useEffect(() => {
  //   checkShinglePriceCompletion(
  //     {
  //       manufacturer: manufacturer,
  //       shingleType: shingleType,
  //       line: line,
  //       unit: unit,
  //       pricePer: pricePer,
  //       color: color,
  //     },
  //     {
  //       isPerJob: isPerJob,
  //       isPerSq: isPerSq,
  //       price: warrantyPrice,
  //       name: warrantyName,
  //     },
  //     savedList
  //   );
  // }, [savedList]);

  // useEffect(() => {
  //   console.log('useEffect');
  //   console.log(hasBeenSavedListTwo);
  //   checkShinglePriceCompletion(
  //     {
  //       manufacturer: manufacturer,
  //       shingleType: shingleType,
  //       line: line,
  //       unit: unit,
  //       pricePer: pricePer,
  //       color: color,
  //     },
  //     {
  //       isPerJob: isPerJob,
  //       isPerSq: isPerSq,
  //       price: warrantyPrice,
  //       name: warrantyName,
  //     }
  //   );
  // }, [hasBeenSavedListTwo]);

  return (
    <Box>
      <VStack spacing="50px">
        <Table size="sm">
          <Thead>
            <Tr>
              <Th fontSize="xs">Manufacturer</Th>
              <Th fontSize="xs">Type</Th>
              <Th fontSize="xs">Line</Th>
              <Th fontSize="xs">Color</Th>
              <Th fontSize="xs">Sqs per Bundle</Th>
              <Th fontSize="xs">$ per Bundle</Th>
              {/* <Th fontSize="xs">Warranty Type</Th>
              <Th fontSize="xs">Warranty Price</Th>
              <Th fontSize="xs">Warranty</Th> */}
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>
                <Input
                  maxWidth="150px"
                  // value={shingles.manufacturer}
                  value={manufacturer}
                  onChange={event => {
                    setUnsavedChanges(true);
                    setManufacturer(event.target.value);
                    // dispatch(
                    //   updateShinglesField({
                    //     newValue: event.target.value,
                    //     fieldToUpdate: 'manufacturer',
                    //   })
                    // );
                  }}
                />
              </Td>
              <Td>
                <Input
                  maxWidth="150px"
                  // value={shingles.shingleType}
                  value={shingleType}
                  onChange={event => {
                    setUnsavedChanges(true);
                    setShingleType(event.target.value);
                    // dispatch(
                    //   updateShinglesField({
                    //     newValue: event.target.value,
                    //     fieldToUpdate: 'shingleType',
                    //   })
                    // );
                  }}
                />
              </Td>
              <Td>
                <Input
                  maxWidth="200px"
                  value={line}
                  // value={shingles.line}
                  onChange={event => {
                    setUnsavedChanges(true);
                    setLine(event.target.value);
                    // dispatch(
                    //   updateShinglesField({
                    //     newValue: event.target.value,
                    //     fieldToUpdate: 'line',
                    //   })
                    // );
                  }}
                />
              </Td>
              <Td>
                <Input
                  maxWidth="150px"
                  value={color}
                  // value={shingles.color}
                  onChange={event => {
                    setUnsavedChanges(true);
                    setColor(event.target.value);
                    // dispatch(
                    //   updateShinglesField({
                    //     newValue: event.target.value,
                    //     fieldToUpdate: 'color',
                    //   })
                    // );
                  }}
                />
              </Td>
              <Td>
                <NumberInput
                  maxWidth="100px"
                  // value={shingles.unit ?? ''}
                  value={unit}
                  onChange={value => {
                    setUnsavedChanges(true);
                    setUnit(value);
                    // dispatch(
                    //   updateShinglesField({
                    //     newValue: value,
                    //     fieldToUpdate: 'unit',
                    //   })
                    // );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </Td>
              <Td>
                <NumberInput
                  maxWidth="100px"
                  // value={shingles.pricePer ?? ''}
                  value={pricePer}
                  onChange={value => {
                    setUnsavedChanges(true);
                    setPricePer(value);
                    // dispatch(
                    //   updateShinglesField({
                    //     newValue: value,
                    //     fieldToUpdate: 'pricePer',
                    //   })
                    // );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </Td>
              {/* <Td>
                <Input size="sm" />
              </Td>
              <Td>
                <NumberInput maxWidth="150px">
                  <NumberInputField />
                </NumberInput>
              </Td>
              <Td>
                <RadioGroup>
                  <Stack>
                    <Radio value="isPerJob">
                      <Text whiteSpace="nowrap">Per Job</Text>
                    </Radio>
                    <Radio value="isPerSq">
                      <Text whiteSpace="nowrap">Per Sq</Text>
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Td> */}
            </Tr>
          </Tbody>
        </Table>
        <div>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th fontSize="xs">Warranty Name</Th>
                <Th fontSize="xs">Warranty Price</Th>
                <Th fontSize="xs">Warranty</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>
                  <Input
                    maxWidth="200px"
                    size="sm"
                    // value={warranty.name}
                    value={warrantyName}
                    onChange={event => {
                      setUnsavedChanges(true);
                      setWarrantyName(event.target.value);
                      // dispatch(
                      //   updateWarrantyField({
                      //     newValue: event.target.value,
                      //     fieldToUpdate: 'name',
                      //   })
                      // );
                    }}
                  />
                </Td>
                <Td>
                  <NumberInput
                    maxWidth="150px"
                    value={warrantyPrice}
                    // value={warranty.price ?? ''}
                    onChange={value => {
                      setUnsavedChanges(true);
                      setWarrantyPrice(value);
                      // dispatch(
                      //   updateWarrantyField({
                      //     newValue: value,
                      //     fieldToUpdate: 'price',
                      //   })
                      // );
                    }}
                  >
                    <NumberInputField />
                  </NumberInput>
                </Td>
                <Td>
                  <RadioGroup
                    // value={
                    //   warranty.isPerJob
                    //     ? 'isPerJob'
                    //     : warranty.isPerSq
                    //     ? 'isPerSq'
                    //     : null
                    // }
                    value={isPerJob ? 'isPerJob' : isPerSq ? 'isPerSq' : null}
                    onChange={value => {
                      // dispatch(
                      //   updateWarrantyField({
                      //     newValue: true,
                      //     fieldToUpdate: value,
                      //   })
                      // )
                      if (value === 'isPerJob') {
                        setIsPerJob(true);
                        setIsPerSq(false);
                      } else if (value === 'isPerSq') {
                        setIsPerSq(true);
                        setIsPerJob(false);
                      }
                    }}
                  >
                    <Stack>
                      <Radio value="isPerJob">
                        <Text whiteSpace="nowrap">Per Job</Text>
                      </Radio>
                      <Radio value="isPerSq">
                        <Text whiteSpace="nowrap">Per Sq</Text>
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </div>
      </VStack>
      <Center>
        <VStack>
          {unsavedChanges && (
            <Alert status="warning" marginTop="5">
              <AlertIcon />
              <AlertTitle>You have unsaved changes!</AlertTitle>
            </Alert>
          )}
          {!savedList.includes('shingles') && (
            <Alert status="info" marginTop="5">
              <AlertIcon />
              <AlertTitle>Review and Save</AlertTitle>
            </Alert>
          )}
          <Button colorScheme="blue" marginTop="3" onClick={handleSave}>
            Save
          </Button>
        </VStack>
      </Center>
    </Box>
  );
};

export default ShinglesPrices;
