import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  value: 0,
  jobName: '',
  addressLineOne: '',
  addressLineTwo: '',
  roofNumber: '',
  discountPercent: null,
  financeOption: '',
  hasPaymentOptionCash: false,
  hasPaymentOptionOne: false,
  hasPaymentOptionThree: false,
  hasPaymentOptionSeven: false,
  roofType: '',
  wastePercent: null,
  tearOff: null,
  newConstruction: null,
  roofOver: null,
  dumpsterPlacement: '', // offstreet
  difficultDumpsterAccess: null,
  closeNeighboringHomes: null,
  isGeneralCompleted: false,
  ridgeLF: null,
  valleysLF: null,
  dormerFlashingLF: null,
  eavesLF: null,
  rakeEdgesLF: null,
  counterFlashingLF: null,
  hipsLF: null,
  stepFlashingLF: null,
  easeOverhangInches: null,
  profitMargin: 0,
  isMeasurementsCompleted: false,
  permitFee: '',
  permitFeePercent: null,
  permitFeeFlat: null,
  dripEdge: {
    hasNone: null,
    isReuse: null,
    isInstallNew: null,
    hasRakeEdges: null,
    hasEaves: null,
    fasciaColor: '',
    isCompleted: false,
  },
  gutters: {
    hasExistingAprons: null,
    isReset: null,
    resetLength: '',
    isRemoval: null,
    removalLength: '',
    hasNewGutters: null,
    newGuttersLength: '',
    hasNewAprons: null,
    newApronsLength: '',
    newApronsColor: 'white',
    isCompleted: false,
  },
  details: {
    roofList: [
      {
        roofNumber: 1,
        squares: '',
        pitch: '',
        stories: '',
        layers: '',
        shakes: false,
        redeck: false,
        handSeal: false,
      },
    ],
    isCompleted: false,
  },
  base: {
    iceWaterShield: '',
    hasEaves: null,
    coursesFromEave: '',
    eavesLengthNot: '',
    hasValleys: null,
    hasHipsAndRidges: null,
    hasRakeEdges: null,
    hasSidewall: null,
    additionalLength: '',
    additionalSqFt: '',
    starterStrip: '',
    isCompleted: false,
  },
  decking: {
    existing: '',
    existingThickness: '',
    redeck: '',
    redeckThickness: '',
    numReplacementSheets: '',
    lengthReplacementRoofBoards: '',
    isCompleted: false,
  },
  flashing: {
    chimneyFlashingList: [
      {
        chimneyNumber: 1,
        chimneyCount: '',
        flashingType: '',
        size: '',
        isReuse: false,
        isReplace: false,
        isRemoveChimney: false,
        isAddCricket: false,
        color: '',
      },
    ],
    pipeFlashingList: [
      {
        pipeNumber: 1,
        pipeCount: '',
        flashingType: '',
        size: '',
        isReuse: false,
        isReplace: false,
        color: '',
      },
    ],
    hasValleyFlashing: true,
    valleyFlashingLength: '',
    valleyFlashingType: '',
    valleyFlashingColor: '',
    stepFlashing: '',
    stepFlashingResetSiding: false,
    stepFlashingResetSidingLength: '',
    regletFlashing: '',
    oneByFourFlashing: '',
    dormerFlashing: '',
    stepFlashingLength: '',
    stepFlashingColor: '',
    regletLength: '',
    regletColor: '',
    oneByLength: '',
    oneByColor: '',
    dormerFlashingLength: '',
    dormerFlashingColor: '',
    dormerFlashingResetSiding: false,
    dormerFlashingResetSidingLength: '',
    isCompleted: false,
  },
  features: {
    skyLightsList: [
      {
        lightNumber: 1,
        count: '',
        lightType: '',
        size: '',
        flashing: '',
        isReplaceCompleteUnit: false,
        isRemoveFillIn: false,
      },
    ],
    corniceReturnsList: [
      {
        corniceNumber: 1,
        count: '',
        stories: '',
        size: '',
        story: '',
      },
    ],
    bayWindowsList: [
      {
        windowNumber: 1,
        count: '',
        stories: '',
        size: '',
        story: '',
      },
    ],
    roofMountsList: [
      {
        mountNumber: 1,
        count: '',
        isReset: false,
        isRemove: false,
        mountType: '',
      },
    ],
    isCompleted: false,
  },
  venting: {
    isNoneVenting: null,
    hasRidgeVent: null,
    ridgeVentLength: '',
    hasCutIn: null,
    cutInLength: '',
    isCompleted: false,
    roofLouversList: [
      {
        louverNumber: 1,
        count: '',
        action: '',
        color: ',',
      },
    ],
    turbineVentsList: [
      {
        turbineVentNumber: 1,
        count: '',
        action: '',
        color: '',
      },
    ],
    exhaustVentsList: [
      {
        exhaustVentNumber: 1,
        count: '',
        action: '',
        size: '',
      },
    ],
    powerVentsList: [
      {
        powerVentNumber: 1,
        action: '',
        count: '',
      },
    ],
    midroofIntakeVentsList: [
      {
        midroofIntakeVentNumber: 1,
        action: '',
        length: '',
      },
    ],
  },
};

export const calculatorSlice = createSlice({
  name: 'calculator',
  initialState,
  reducers: {
    reset: state => {
      state = initialState;
    },
    updatePermitFee: (state, action) => {
      state.permitFee = action.payload;
    },
    updatePermitFeePercent: (state, action) => {
      state.permitFeePercent = action.payload;
    },
    updatePermitFeeFlat: (state, action) => {
      state.permitFeeFlat = action.payload;
    },
    updateLoading: (state, action) => {
      state.loading = action.payload;
    },
    updateDiscountPercent: (state, action) => {
      state.discountPercent = action.payload;
    },
    updateJobName: (state, action) => {
      state.jobName = action.payload;
    },
    updateAddressLineOne: (state, action) => {
      state.addressLineOne = action.payload;
    },
    updateAddressLineTwo: (state, action) => {
      state.addressLineTwo = action.payload;
    },
    updateRoofNumber: (state, action) => {
      state.roofNumber = action.payload;
    },
    updateRoofType: (state, action) => {
      state.roofType = action.payload;
    },
    updatePaymentOptionCash: (state, action) => {
      state.hasPaymentOptionCash = action.payload;
    },
    updatePaymentOptionOne: (state, action) => {
      state.hasPaymentOptionOne = action.payload;
    },
    updatePaymentOptionThree: (state, action) => {
      state.hasPaymentOptionThree = action.payload;
    },
    updatePaymentOptionSeven: (state, action) => {
      state.hasPaymentOptionSeven = action.payload;
    },
    updateFinanceOption: (state, action) => {
      state.financeOption = action.payload;
    },
    updateWastePercent: (state, action) => {
      state.wastePercent = action.payload;
    },
    updateTearOff: (state, action) => {
      state.tearOff = true;
      state.newConstruction = false;
      state.roofOver = false;
    },
    updateNewConstruction: (state, action) => {
      state.newConstruction = true;
      state.roofOver = false;
      state.tearOff = false;
    },
    updateRoofOver: (state, action) => {
      state.roofOver = true;
      state.newConstruction = false;
      state.tearOff = false;
    },
    updateDumpsterPlacement: (state, action) => {
      state.dumpsterPlacement = action.payload;
    },
    updateDifficultDumpsterAccess: (state, action) => {
      state.difficultDumpsterAccess = action.payload;
    },
    updateCloseNeighboringHomes: (state, action) => {
      state.closeNeighboringHomes = action.payload;
    },
    updateIsGeneralCompleted: (state, action) => {
      state.isGeneralCompleted = action.payload;
    },
    updateRidgeLF: (state, action) => {
      state.ridgeLF = action.payload;
    },
    updateValleysLF: (state, action) => {
      state.valleysLF = action.payload;
    },
    updateDormerFlashingLF: (state, action) => {
      state.dormerFlashingLF = action.payload;
    },
    updateEavesLF: (state, action) => {
      state.eavesLF = action.payload;
    },
    updateRakeEdgesLF: (state, action) => {
      state.rakeEdgesLF = action.payload;
    },
    updateCounterFlashingLF: (state, action) => {
      state.counterFlashingLF = action.payload;
    },
    updateHipsLF: (state, action) => {
      state.hipsLF = action.payload;
    },
    updateProfitMargin: (state, action) => {
      state.profitMargin = action.payload;
    },
    updateStepFlashingLF: (state, action) => {
      state.stepFlashingLF = action.payload;
    },
    updateEaseOverhangInches: (state, action) => {
      state.easeOverhangInches = action.payload;
    },
    updateIsMeasurementsCompleted: (state, action) => {
      state.isMeasurementsCompleted = action.payload;
    },
    updateDripEdge: (state, action) => {
      state.dripEdge.hasNone = action.payload.hasNone;
      state.dripEdge.isReuse = action.payload.isReuse;
      state.dripEdge.isInstallNew = action.payload.isInstallNew;
      state.dripEdge.hasRakeEdges = action.payload.hasRakeEdges;
      state.dripEdge.hasEaves = action.payload.hasEaves;
      state.dripEdge.fasciaColor = action.payload.fasciaColor;
      state.dripEdge.isCompleted = action.payload.isCompleted;
    },
    updateDripEdgeHasNone: (state, action) => {
      state.dripEdge.hasNone = action.payload;
    },
    updateDripEdgeIsReuse: (state, action) => {
      state.dripEdge.isReuse = action.payload;
    },
    updateDripEdgeIsInstallNew: (state, action) => {
      state.dripEdge.isInstallNew = action.payload;
    },
    updateDripEdgeHasRakeEdges: (state, action) => {
      state.dripEdge.hasRakeEdges = action.payload;
    },
    updateDripEdgeHasEaves: (state, action) => {
      state.dripEdge.hasEaves = action.payload;
    },
    updateDripEdgeFasciaColor: (state, action) => {
      state.dripEdge.fasciaColor = action.payload;
    },
    updateDripEdgeIsCompleted: (state, action) => {
      state.dripEdge.isCompleted = action.payload;
    },
    updateGutters: (state, action) => {
      state.gutters.hasExistingAprons = action.payload.hasExistingAprons;
      state.gutters.isReset = action.payload.isReset;
      state.gutters.resetLength = action.payload.resetLength;
      state.gutters.isRemoval = action.payload.isRemoval;
      state.gutters.removalLength = action.payload.removalLength;
      state.gutters.hasNewAprons = action.payload.hasNewAprons;
      state.gutters.newApronsLength = action.payload.newApronsLength;
      state.gutters.newApronsColor = action.payload.newApronsColor;
      state.gutters.isCompleted = action.payload.isCompleted;
    },
    updateGuttersHasExistingAprons: (state, action) => {
      state.gutters.hasExistingAprons = action.payload;
    },
    updateGuttersIsReset: (state, action) => {
      state.gutters.isReset = action.payload;
    },
    updateGuttersResetLength: (state, action) => {
      state.gutters.resetLength = action.payload;
    },
    updateGuttersIsRemoval: (state, action) => {
      state.gutters.isRemoval = action.payload;
    },
    updateGuttersRemovalLength: (state, action) => {
      state.gutters.removalLength = action.payload;
    },
    updateGuttersHasNewGutters: (state, action) => {
      state.gutters.hasNewGutters = action.payload;
    },
    updateGuttersNewGuttersLength: (state, action) => {
      state.gutters.newGuttersLength = action.payload;
    },
    updateGuttersHasNewAprons: (state, action) => {
      state.gutters.hasNewAprons = action.payload;
    },
    updateGuttersNewApronsLength: (state, action) => {
      state.gutters.newApronsLength = action.payload;
    },
    updateGuttersNewApronsColor: (state, action) => {
      state.gutters.newApronsColor = action.payload;
    },
    updateGuttersIsCompleted: (state, action) => {
      state.gutters.isCompleted = action.payload;
    },
    updateDetails: (state, action) => {
      state.details.roofList = action.payload.roofList;
      state.details.isCompleted = action.payload.isCompleted;
    },
    updateDetailsRoofListSquares: (state, action) => {
      const { newValue, roofNumber } = action.payload;
      const roofToModify = state.details.roofList.find(
        item => item.roofNumber === roofNumber
      );
      if (roofToModify) {
        roofToModify.squares = newValue;
      }
    },
    updateDetailsRoofListPitch: (state, action) => {
      const { newValue, roofNumber } = action.payload;
      const roofToModify = state.details.roofList.find(
        item => item.roofNumber === roofNumber
      );
      if (roofToModify) {
        roofToModify.pitch = newValue;
      }
    },
    updateDetailsRoofListStories: (state, action) => {
      const { newValue, roofNumber } = action.payload;
      const roofToModify = state.details.roofList.find(
        item => item.roofNumber === roofNumber
      );
      if (roofToModify) {
        roofToModify.stories = newValue;
      }
    },
    updateDetailsRoofListLayers: (state, action) => {
      const { newValue, roofNumber } = action.payload;
      const roofToModify = state.details.roofList.find(
        item => item.roofNumber === roofNumber
      );
      if (roofToModify) {
        roofToModify.layers = newValue;
      }
    },
    updateDetailsRoofListShakes: (state, action) => {
      const { newValue, roofNumber } = action.payload;
      const roofToModify = state.details.roofList.find(
        item => item.roofNumber === roofNumber
      );
      if (roofToModify) {
        roofToModify.shakes = newValue;
      }
    },
    updateDetailsRoofListRedeck: (state, action) => {
      const { newValue, roofNumber } = action.payload;
      const roofToModify = state.details.roofList.find(
        item => item.roofNumber === roofNumber
      );
      if (roofToModify) {
        roofToModify.redeck = newValue;
      }
    },
    updateDetailsRoofListHandSeal: (state, action) => {
      const { newValue, roofNumber } = action.payload;
      const roofToModify = state.details.roofList.find(
        item => item.roofNumber === roofNumber
      );
      if (roofToModify) {
        roofToModify.handSeal = newValue;
      }
    },
    addToDetailsRoofList: (state, action) => {
      state.details.roofList.push(action.payload);
    },
    removeFromDetailsRoofList: (state, action) => {
      state.details.roofList = state.details.roofList.filter(
        item => item.roofNumber !== action.payload
      );
      // TODO: Update the count of the roofs
    },
    updateDetailsIsCompleted: (state, action) => {
      state.details.isCompleted = action.payload;
    },
    updateBase: (state, action) => {
      state.base = action.payload;
    },
    updateBaseIceWaterShield: (state, action) => {
      state.base.iceWaterShield = action.payload;
    },
    updateBaseHasEaves: (state, action) => {
      state.base.hasEaves = action.payload;
    },
    updateBaseCoursesFromEave: (state, action) => {
      state.base.coursesFromEave = action.payload;
    },
    updateBaseEavesLengthNot: (state, action) => {
      state.base.eavesLengthNot = action.payload;
    },
    updateBaseHasHipsAndRidges: (state, action) => {
      state.base.hasHipsAndRidges = action.payload;
    },
    updateBaseHasRakeEdges: (state, action) => {
      state.base.hasRakeEdges = action.payload;
    },
    updateBaseHasSidewall: (state, action) => {
      state.base.hasSidewall = action.payload;
    },
    updateBaseHasValleys: (state, action) => {
      state.base.hasValleys = action.payload;
    },
    updateBaseAdditionalLength: (state, action) => {
      state.base.additionalLength = action.payload;
    },
    updateBaseAdditionalSqFt: (state, action) => {
      state.base.additionalSqFt = action.payload;
    },
    updateBaseStarterStrip: (state, action) => {
      state.base.starterStrip = action.payload;
    },
    updateBaseIsCompleted: (state, action) => {
      state.base.isCompleted = action.payload;
    },
    updateDecking: (state, action) => {
      state.decking = action.payload;
    },
    updateDeckingExisting: (state, action) => {
      state.decking.existing = action.payload;
    },
    updateDeckingExistingThickness: (state, action) => {
      state.decking.existingThickness = action.payload;
    },
    updateDeckingRedeck: (state, action) => {
      state.decking.redeck = action.payload;
    },
    updateDeckingRedeckThickness: (state, action) => {
      state.decking.redeckThickness = action.payload;
    },
    updateDeckingNumReplacementSheets: (state, action) => {
      state.decking.numReplacementSheets = action.payload;
    },
    updateDeckingLengthReplacementRoofBoards: (state, action) => {
      state.decking.lengthReplacementRoofBoards = action.payload;
    },
    updateDeckingIsCompleted: (state, action) => {
      state.decking.isCompleted = action.payload;
    },
    updateFlashing: (state, action) => {
      state.flashing = action.payload;
    },
    updateFlashingHasValleyFlashing: (state, action) => {
      state.flashing.hasValleyFlashing = action.payload;
    },
    updateFlashingHasValleyFlashingExisting: (state, action) => {
      state.flashing.hasValleyFlashingExisting = action.payload;
    },
    updateFlashingValleyFlashingLength: (state, action) => {
      state.flashing.valleyFlashingLength = action.payload;
    },
    updateFlashingValleyFlashingType: (state, action) => {
      state.flashing.valleyFlashingType = action.payload;
    },
    updateFlashingValleyFlashingColor: (state, action) => {
      state.flashing.valleyFlashingColor = action.payload;
    },
    updateFlashingStepFlashing: (state, action) => {
      state.flashing.stepFlashing = action.payload;
    },
    updateFlashingRegletFlashing: (state, action) => {
      state.flashing.regletFlashing = action.payload;
    },
    updateFlashingOneByFourFlashing: (state, action) => {
      state.flashing.oneByFourFlashing = action.payload;
    },
    updateFlashingDormerFlashing: (state, action) => {
      state.flashing.dormerFlashing = action.payload;
    },
    updateFlashingHasStepFlashing: (state, action) => {
      state.flashing.hasStepFlashing = action.payload;
    },
    updateFlashingIsStepFlashingReuse: (state, action) => {
      state.flashing.isStepFlashingReuse = action.payload;
    },
    updateFlashingIsStepFlashingInstallNew: (state, action) => {
      state.flashing.isStepFlashingInstallNew = action.payload;
    },
    updateFlashingStepFlashingLength: (state, action) => {
      state.flashing.stepFlashingLength = action.payload;
    },
    updateFlashingStepFlashingResetSiding: (state, action) => {
      state.flashing.stepFlashingResetSiding = action.payload;
    },
    updateFlashingStepFlashingResetSidingLength: (state, action) => {
      state.flashing.stepFlashingResetSidingLength = action.payload;
    },
    updateFlashingStepFlashingColor: (state, action) => {
      state.flashing.stepFlashingColor = action.payload;
    },
    updateFlashingHasSidewallCounterFlashing: (state, action) => {
      state.flashing.hasSidewallCounterFlashing = action.payload;
    },
    updateFlashingIsRegletReuse: (state, action) => {
      state.flashing.isRegletReuse = action.payload;
    },
    updateFlashingIsRegletInstallNew: (state, action) => {
      state.flashing.isRegletInstallNew = action.payload;
    },
    updateFlashingRegletLength: (state, action) => {
      state.flashing.regletLength = action.payload;
    },
    updateFlashingRegletColor: (state, action) => {
      state.flashing.regletColor = action.payload;
    },
    updateFlashingIsOneByReuse: (state, action) => {
      state.flashing.isOneByReuse = action.payload;
    },
    updateFlashingIsOneByInstallNew: (state, action) => {
      state.flashing.isOneByInstallNew = action.payload;
    },
    updateFlashingOneByLength: (state, action) => {
      state.flashing.oneByLength = action.payload;
    },
    updateFlashingOneByColor: (state, action) => {
      state.flashing.oneByColor = action.payload;
    },
    updateFlashingHasDormerFlashing: (state, action) => {
      state.flashing.hasDormerFlashing = action.payload;
    },
    updateFlashingIsDormerFlashingReuse: (state, action) => {
      state.flashing.isDormerFlashingReuse = action.payload;
    },
    updateFlashingIsDormerFlashingInstallNew: (state, action) => {
      state.flashing.isDormerFlashingInstallNew = action.payload;
    },
    updateFlashingDormerFlashingLength: (state, action) => {
      state.flashing.dormerFlashingLength = action.payload;
    },
    updateFlashingDormerFlashingColor: (state, action) => {
      state.flashing.dormerFlashingColor = action.payload;
    },
    updateFlashingDormerFlashingResetSiding: (state, action) => {
      state.flashing.dormerFlashingResetSiding = action.payload;
    },
    updateFlashingDormerFlashingResetSidingLength: (state, action) => {
      state.flashing.dormerFlashingResetSidingLength = action.payload;
    },
    updateFlashingIsCompleted: (state, action) => {
      state.flashing.isCompleted = action.payload;
    },
    updateChimneyFlashingList: (state, action) => {
      const { newValue, chimneyNumber, fieldToUpdate } = action.payload;
      const itemToModify = state.flashing.chimneyFlashingList.find(
        item => item.chimneyNumber === chimneyNumber
      );
      switch (fieldToUpdate) {
        case 'chimneyCount':
          itemToModify.chimneyCount = newValue;
          break;
        case 'flashingType':
          itemToModify.flashingType = newValue;
          break;
        case 'size':
          itemToModify.size = newValue;
          break;
        case 'isReuse':
          itemToModify.isReuse = newValue;
          break;
        case 'isReplace':
          itemToModify.isReplace = newValue;
          break;
        case 'isRemoveChimney':
          itemToModify.isRemoveChimney = newValue;
          break;
        case 'color':
          itemToModify.color = newValue;
          break;
        case 'isAddCricket':
          itemToModify.isAddCricket = newValue;
        default:
          break;
      }
    },
    clearChimneyFlashingList: (state, action) => {
      state.flashing.chimneyFlashingList = [];
    },
    addToChimneyFlashingList: (state, action) => {
      state.flashing.chimneyFlashingList.push(action.payload);
    },
    removeFromChimneyFlashingList: (state, action) => {
      state.flashing.chimneyFlashingList =
        state.flashing.chimneyFlashingList.filter(
          item => item.chimneyNumber !== action.payload
        );
      // TODO: Update the count of the roofs
    },
    updatePipeFlashingList: (state, action) => {
      const { newValue, pipeNumber, fieldToUpdate } = action.payload;
      const itemToModify = state.flashing.pipeFlashingList.find(
        item => item.pipeNumber === pipeNumber
      );
      switch (fieldToUpdate) {
        case 'pipeCount':
          itemToModify.pipeCount = newValue;
          break;
        case 'flashingType':
          itemToModify.flashingType = newValue;
          break;
        case 'size':
          itemToModify.size = newValue;
          break;
        case 'isReuse':
          itemToModify.isReuse = newValue;
          break;
        case 'isReplace':
          itemToModify.isReplace = newValue;
          break;
        case 'color':
          itemToModify.color = newValue;
          break;
        default:
          break;
      }
    },
    clearPipeFlashingList: (state, action) => {
      state.flashing.pipeFlashingList = [];
    },
    addToPipeFlashingList: (state, action) => {
      state.flashing.pipeFlashingList.push(action.payload);
    },
    removeFromPipeFlashingList: (state, action) => {
      state.flashing.pipeFlashingList = state.flashing.pipeFlashingList.filter(
        item => item.pipeNumber !== action.payload
      );
    },
    updateFeatures: (state, action) => {
      state.features = action.payload;
    },
    updateFeaturesIsCompleted: (state, action) => {
      state.features.isCompleted = action.payload;
    },
    updateSkyLightsList: (state, action) => {
      const { newValue, lightNumber, fieldToUpdate } = action.payload;
      const itemToModify = state.features.skyLightsList.find(
        item => item.lightNumber === lightNumber
      );
      switch (fieldToUpdate) {
        case 'count':
          itemToModify.count = newValue;
          break;
        case 'lightType':
          itemToModify.lightType = newValue;
          break;
        case 'size':
          itemToModify.size = newValue;
          break;
        case 'flashing':
          itemToModify.flashing = newValue;
          break;
        case 'isReplaceCompleteUnit':
          itemToModify.isReplaceCompleteUnit = newValue;
          break;
        case 'isRemoveFillIn':
          itemToModify.isRemoveFillIn = newValue;
          break;
        default:
          break;
      }
    },
    addToSkyLightsList: (state, action) => {
      state.features.skyLightsList.push(action.payload);
    },
    removeFromSkyLightsList: (state, action) => {
      state.features.skyLightsList = state.features.skyLightsList.filter(
        item => item.lightNumber !== action.payload
      );
    },
    clearSkyLightsList: (state, action) => {
      state.features.skyLightsList = [];
    },
    updateCorniceReturnsList: (state, action) => {
      const { newValue, corniceNumber, fieldToUpdate } = action.payload;
      const itemToModify = state.features.corniceReturnsList.find(
        item => item.corniceNumber === corniceNumber
      );
      switch (fieldToUpdate) {
        case 'count':
          itemToModify.count = newValue;
          break;
        case 'stories':
          itemToModify.stories = newValue;
          break;
        case 'size':
          itemToModify.size = newValue;
          break;
        case 'story':
          itemToModify.story = newValue;
          break;
        default:
          break;
      }
    },
    clearCorniceReturnsList: (state, action) => {
      state.features.corniceReturnsList = [];
    },
    addToCorniceReturnsList: (state, action) => {
      state.features.corniceReturnsList.push(action.payload);
    },
    removeFromCorniceReturnsList: (state, action) => {
      state.features.corniceReturnsList =
        state.features.corniceReturnsList.filter(
          item => item.corniceNumber !== action.payload
        );
    },
    updateBayWindowsList: (state, action) => {
      const { newValue, windowNumber, fieldToUpdate } = action.payload;
      const itemToModify = state.features.bayWindowsList.find(
        item => item.windowNumber === windowNumber
      );
      switch (fieldToUpdate) {
        case 'count':
          itemToModify.count = newValue;
          break;
        case 'stories':
          itemToModify.stories = newValue;
          break;
        case 'size':
          itemToModify.size = newValue;
          break;
        case 'story':
          itemToModify.story = newValue;
          break;
        default:
          break;
      }
    },
    clearBayWindowsList: (state, action) => {
      state.features.bayWindowsList = [];
    },
    addToBayWindowsList: (state, action) => {
      state.features.bayWindowsList.push(action.payload);
    },
    removeFromBayWindowsList: (state, action) => {
      state.features.bayWindowsList = state.features.bayWindowsList.filter(
        item => item.windowNumber !== action.payload
      );
    },
    updateRoofMountsList: (state, action) => {
      const { newValue, mountNumber, fieldToUpdate } = action.payload;
      const itemToModify = state.features.roofMountsList.find(
        item => item.mountNumber === mountNumber
      );
      switch (fieldToUpdate) {
        case 'isReset':
          itemToModify.isReset = newValue;
          break;
        case 'isRemove':
          itemToModify.isRemove = newValue;
          break;
        case 'mountType':
          itemToModify.mountType = newValue;
          break;
        case 'count':
          itemToModify.count = newValue;
          break;
        default:
          break;
      }
    },
    clearRoofMountsList: (state, action) => {
      state.features.roofMountsList = [];
    },
    addToRoofMountsList: (state, action) => {
      state.features.roofMountsList.push(action.payload);
    },
    removeFromRoofMountsList: (state, action) => {
      state.features.roofMountsList = state.features.roofMountsList.filter(
        item => item.mountNumber !== action.payload
      );
    },
    updateVenting: (state, action) => {
      state.venting = action.payload;
    },
    updateVentingField: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      switch (fieldToUpdate) {
        case 'isNoneVenting':
          state.venting.isNoneVenting = newValue;
          break;
        case 'hasRidgeVent':
          state.venting.hasRidgeVent = newValue;
          break;
        case 'ridgeVentLength':
          state.venting.ridgeVentLength = newValue;
          break;
        case 'hasCutIn':
          state.venting.hasCutIn = newValue;
          break;
        case 'cutInLength':
          state.venting.cutInLength = newValue;
          break;
        default:
          break;
      }
    },
    updateVentingIsCompleted: (state, action) => {
      state.venting.isCompleted = action.payload;
    },
    updateRoofLouversList: (state, action) => {
      const { newValue, louverNumber, fieldToUpdate } = action.payload;
      const itemToModify = state.venting.roofLouversList.find(
        item => item.louverNumber === louverNumber
      );
      switch (fieldToUpdate) {
        case 'count':
          itemToModify.count = newValue;
          break;
        case 'action':
          itemToModify.action = newValue;
          break;
        case 'color':
          itemToModify.color = newValue;
          break;
        default:
          break;
      }
    },
    clearRoofLouversList: (state, action) => {
      state.venting.roofLouversList = [];
    },
    addToRoofLouversList: (state, action) => {
      state.venting.roofLouversList.push(action.payload);
    },
    removeFromRoofLouversList: (state, action) => {
      state.venting.roofLouversList = state.venting.roofLouversList.filter(
        item => item.louverNumber !== action.payload
      );
    },
    updateTurbineVentsList: (state, action) => {
      const { newValue, turbineVentNumber, fieldToUpdate } = action.payload;
      const itemToModify = state.venting.turbineVentsList.find(
        item => item.turbineVentNumber === turbineVentNumber
      );
      switch (fieldToUpdate) {
        case 'count':
          itemToModify.count = newValue;
          break;
        case 'action':
          itemToModify.action = newValue;
          break;
        case 'color':
          itemToModify.color = newValue;
          break;
        default:
          break;
      }
    },
    clearTurbineVentsList: (state, action) => {
      state.venting.turbineVentsList = [];
    },
    addToTurbineVentsList: (state, action) => {
      state.venting.turbineVentsList.push(action.payload);
    },
    removeFromTurbineVentsList: (state, action) => {
      state.venting.turbineVentsList = state.venting.turbineVentsList.filter(
        item => item.turbineVentNumber !== action.payload
      );
    },
    updateExhaustVentsList: (state, action) => {
      const { newValue, exhaustVentNumber, fieldToUpdate } = action.payload;
      const itemToModify = state.venting.exhaustVentsList.find(
        item => item.exhaustVentNumber === exhaustVentNumber
      );
      switch (fieldToUpdate) {
        case 'count':
          itemToModify.count = newValue;
          break;
        case 'action':
          itemToModify.action = newValue;
          break;
        case 'size':
          itemToModify.size = newValue;
          break;
        default:
          break;
      }
    },
    clearExhaustVentsList: (state, action) => {
      state.venting.exhaustVentsList = [];
    },
    addToExhaustVentsList: (state, action) => {
      state.venting.exhaustVentsList.push(action.payload);
    },
    removeFromExhaustVentsList: (state, action) => {
      state.venting.exhaustVentsList = state.venting.exhaustVentsList.filter(
        item => item.exhaustVentNumber !== action.payload
      );
    },
    updatePowerVentsList: (state, action) => {
      const { newValue, powerVentNumber, fieldToUpdate } = action.payload;
      const itemToModify = state.venting.powerVentsList.find(
        item => item.powerVentNumber === powerVentNumber
      );
      switch (fieldToUpdate) {
        case 'count':
          itemToModify.count = newValue;
          break;
        case 'action':
          itemToModify.action = newValue;
          break;
        default:
          break;
      }
    },
    clearPowerVentsList: (state, action) => {
      state.venting.powerVentsList = [];
    },
    addToPowerVentsList: (state, action) => {
      state.venting.powerVentsList.push(action.payload);
    },
    removeFromPowerVentsList: (state, action) => {
      state.venting.powerVentsList = state.venting.powerVentsList.filter(
        item => item.powerVentNumber !== action.payload
      );
    },
    updateMidroofIntakeVentsList: (state, action) => {
      const { newValue, midroofIntakeVentNumber, fieldToUpdate } =
        action.payload;
      const itemToModify = state.venting.midroofIntakeVentsList.find(
        item => item.midroofIntakeVentNumber === midroofIntakeVentNumber
      );
      switch (fieldToUpdate) {
        case 'action':
          itemToModify.action = newValue;
          break;
        case 'length':
          itemToModify.length = newValue;
          break;
        default:
          break;
      }
    },
    clearMidroofIntakeVentList: (state, action) => {
      state.venting.midroofIntakeVentsList = [];
    },
    addToMidroofIntakeVentsList: (state, action) => {
      state.venting.midroofIntakeVentsList.push(action.payload);
    },
    removeFromMidroofIntakeVentsList: (state, action) => {
      state.venting.midroofIntakeVentsList =
        state.venting.midroofIntakeVentsList.filter(
          item => item.midroofIntakeVentNumber !== action.payload
        );
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  increment,
  decrement,
  updatePermitFee,
  updateDumpsterPlacement, // General
  updateRoofOver, // checkbox
  updateNewConstruction, // checkbox
  incrementByAmount,
  updateTearOff, // checkbox
  updateWastePercent,
  updateRoofType,
  updateDifficultDumpsterAccess, // checkbox
  updateCloseNeighboringHomes,
  updateIsGeneralCompleted,
  updateRidgeLF, // Measurements
  updateValleysLF,
  updateDormerFlashingLF,
  updateEavesLF,
  updateRakeEdgesLF,
  updateCounterFlashingLF,
  updateHipsLF,
  updateStepFlashingLF,
  updateEaseOverhangInches,
  updateIsMeasurementsCompleted,
  updateDripEdge,
  updateDripEdgeHasNone,
  updateDripEdgeIsReuse,
  updateDripEdgeIsInstallNew,
  updateDripEdgeHasRakeEdges,
  updateDripEdgeHasEaves,
  updateDripEdgeFasciaColor,
  updateDripEdgeIsCompleted,
  updateLoading,
  updateGutters,
  updateGuttersHasExistingAprons,
  updateGuttersIsReset,
  updateGuttersResetLength,
  updateGuttersRemovalLength,
  updateGuttersHasNewGutters,
  updateGuttersNewGuttersLength,
  updateGuttersHasNewAprons,
  updateGuttersNewApronsLength,
  updateGuttersNewApronsColor,
  updateGuttersIsRemoval,
  updateGuttersIsCompleted,
  updateDetails,
  addToDetailsRoofList,
  removeFromDetailsRoofList,
  updateDetailsRoofListPitch,
  updateDetailsRoofListStories,
  updateDetailsIsCompleted,
  updateDetailsRoofListLayers,
  updateDetailsRoofListShakes,
  updateBase,
  updateBaseIceWaterShield,
  updateBaseHasEaves,
  updateBaseCoursesFromEave,
  updateBaseEavesLengthNot,
  updateBaseHasHipsAndRidges,
  updateBaseHasRakeEdges,
  updateBaseHasSidewall,
  updateBaseAdditionalLength,
  updateBaseStarterStrip,
  updateBaseIsCompleted,
  updateBaseHasValleys,
  updateDecking,
  updateProfitMargin,
  updateDeckingExisting,
  updateDeckingExistingThickness,
  updateDeckingRedeck,
  updateDeckingRedeckThickness,
  updateDeckingNumReplacementSheets,
  updateDeckingIsCompleted,
  updateDetailsRoofListRedeck,
  updateDetailsRoofListHandSeal,
  updateFlashing,
  updateFlashingHasValleyFlashing,
  updateFlashingHasValleyFlashingExisting,
  updateFlashingValleyFlashingLength,
  updateFlashingValleyFlashingType,
  updateFlashingValleyFlashingColor,
  updateFlashingStepFlashing,
  updateFlashingRegletFlashing,
  updateFlashingOneByFourFlashing,
  updateFlashingDormerFlashing,
  updateFlashingHasStepFlashing,
  updateFlashingIsStepFlashingReuse,
  updateFlashingIsStepFlashingInstallNew,
  updateFlashingStepFlashingLength,
  updateFlashingStepFlashingColor,
  updateRoofNumber,
  updateFlashingHasSidewallCounterFlashing,
  updateFlashingIsRegletReuse,
  updateFlashingIsRegletInstallNew,
  updateFlashingRegletLength,
  updateFlashingRegletColor,
  updateFlashingIsOneByReuse,
  updateFlashingIsOneByInstallNew,
  updateFlashingOneByLength,
  updateFlashingOneByColor,
  updateFlashingHasDormerFlashing,
  updateFlashingIsDormerFlashingReuse,
  updateFlashingIsDormerFlashingInstallNew,
  updateFlashingDormerFlashingLength,
  updateFlashingDormerFlashingColor,
  updateFlashingIsCompleted,
  removeFromPipeFlashingList,
  addToPipeFlashingList,
  removeFromChimneyFlashingList,
  addToChimneyFlashingList,
  updateChimneyFlashingList,
  updatePipeFlashingList,
  updateFeatures,
  addToSkyLightsList,
  removeFromSkyLightsList,
  addToCorniceReturnsList,
  removeFromCorniceReturnsList,
  addToBayWindowsList,
  removeFromBayWindowsList,
  updatePermitFeeFlat,
  addToRoofMountsList,
  removeFromRoofMountsList,
  updateSkyLightsList,
  updateCorniceReturnsList,
  updateBayWindowsList,
  updateRoofMountsList,
  updateFeaturesIsCompleted,
  updateVenting,
  updateVentingIsCompleted,
  addToRoofLouversList,
  removeFromRoofLouversList,
  addToTurbineVentsList,
  removeFromTurbineVentsList,
  addToExhaustVentsList,
  removeFromExhaustVentsList,
  addToPowerVentsList,
  removeFromPowerVentsList,
  addToMidroofIntakeVentsList,
  removeFromMidroofIntakeVentsList,
  updateVentingField,
  updateRoofLouversList,
  updateTurbineVentsList,
  updateExhaustVentsList,
  updatePowerVentsList,
  updateMidroofIntakeVentsList,
  updateDetailsRoofListSquares,
  reset,
  updateJobName,
  updateAddressLineOne,
  updateAddressLineTwo,
  updateDiscountPercent,
  updateFinanceOption,
  updatePaymentOptionOne,
  updatePaymentOptionThree,
  updatePaymentOptionSeven,
  clearSkyLightsList,
  clearCorniceReturnsList,
  clearBayWindowsList,
  clearRoofMountsList,
  clearRoofLouversList,
  clearTurbineVentsList,
  clearExhaustVentsList,
  clearPowerVentsList,
  clearMidroofIntakeVentList,
  clearChimneyFlashingList,
  clearPipeFlashingList,
  updatePaymentOptionCash,
  updateBaseAdditionalSqFt,
  updatePermitFeePercent,
  updateDeckingLengthReplacementRoofBoards,
  updateFlashingStepFlashingResetSiding,
  updateFlashingStepFlashingResetSidingLength,
  updateFlashingDormerFlashingResetSiding,
  updateFlashingDormerFlashingResetSidingLength,
} = calculatorSlice.actions;

export default calculatorSlice.reducer;
