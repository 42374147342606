import {
  Box,
  Button,
  Center,
  VStack,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Spinner,
} from '@chakra-ui/react';
import { useState, useEffect, memo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AdditionalLabor from './pricing/AdditionalLabor';
import ShinglesLabor from './pricing/ShinglesLabor';
import SteepPitch from './pricing/SteepPitch';
import BaseRates from './pricing/BaseRates';
import PaymentOptions from './pricing/PaymentOptions';
import ItemizedPricing from './ItemizedPricing';
import useUser from '../hooks/useUser';
import mixpanel from 'mixpanel-browser';
import axios from 'axios';
import {
  updateLabor,
  loadShingles,
  loadAdditional,
  loadItemized,
} from '../features/labor/laborSlice';
import {
  loadOptions,
  loadGeneral,
  loadCommission,
} from '../features/labor/paymentSlice';
import { updateRate, loadSteepPitchRate } from '../features/labor/rateSlice';
import { updateShingles } from '../features/price/priceSlice';

const Labor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useUser();
  const [isRatesComplete, setIsRatesComplete] = useState(false);
  const [isBaseComplete, setIsBaseComplete] = useState(false);
  const [isPartTwoComplete, setIsPartTwoComplete] = useState(false);
  const [isPartThreeComplete, setIsPartThreeComplete] = useState(false);
  const [isPartFourComplete, setIsPartFourComplete] = useState(false);
  const [isSteepPitchComplete, setIsSteepPitchComplete] = useState(false);
  const [isTearOffComplete, setIsTearOffComplete] = useState(false);
  const [isNewComplete, setIsNewComplete] = useState(false);
  const [isRedeckComplete, setIsRedeckComplete] = useState(false);
  const [isShinglesComplete, setIsShinglesComplete] = useState(false);
  const [isAdditionalComplete, setIsAdditionalComplete] = useState(false);
  const [isPaymentComplete, setIsPaymentComplete] = useState(false);
  const [isItemizedComplete, setIsItemizedComplete] = useState(false);
  const [isPaymentOptionsComplete, setIsPaymentOptionsComplete] =
    useState(false);
  const [isPaymentGeneralComplete, setIsPaymentGeneralComplete] =
    useState(false);
  const [isPaymentCommissionComplete, setIsPaymentCommissionComplete] =
    useState(false);

  const handleClick = () => {
    navigate('/estimator');
  };

  const checkItemizedCompletion = (itemized, savedList) => {
    const isComplete =
      savedList?.includes('itemized') &&
      itemized &&
      itemized.tearOff != null &&
      itemized.tearOff !== '' &&
      itemized.addLayersAsphalt != null &&
      itemized.addLayersAsphalt !== '' &&
      itemized.addLayersShake != null &&
      itemized.addLayersShake !== '' &&
      itemized.addStories != null &&
      itemized.addStories !== '' &&
      itemized.redeckThreeToSix != null &&
      itemized.redeckThreeToSix !== '' &&
      itemized.redeckSevenToNine != null &&
      itemized.redeckSevenToNine !== '' &&
      itemized.redeckTenToTwelve != null &&
      itemized.redeckTenToTwelve !== '' &&
      itemized.redeckThirteenPlus != null &&
      itemized.redeckThirteenPlus !== '' &&
      itemized.dripEdge != null &&
      itemized.dripEdge !== '' &&
      itemized.iceAndWater != null &&
      itemized.iceAndWater !== '' &&
      itemized.underlayment != null &&
      itemized.underlayment !== '' &&
      itemized.shinglesWithWaste != null &&
      itemized.shinglesWithWaste !== '' &&
      itemized.steepChargeThreeToSix != null &&
      itemized.steepChargeThreeToSix !== '' &&
      itemized.steepChargeSevenToNine != null &&
      itemized.steepChargeSevenToNine !== '' &&
      itemized.steepChargeTenToTwelve != null &&
      itemized.steepChargeTenToTwelve !== '' &&
      itemized.steepChargeThirteenPlus != null &&
      itemized.steepChargeThirteenPlus !== '' &&
      itemized.starter != null &&
      itemized.starter !== '' &&
      itemized.pipeFlashing != null &&
      itemized.pipeFlashing !== '' &&
      itemized.splitPipeFlashing != null &&
      itemized.splitPipeFlashing !== '' &&
      itemized.roofLouver != null &&
      itemized.roofLouver !== '' &&
      itemized.turbineVent != null &&
      itemized.turbineVent !== '' &&
      itemized.powerVent != null &&
      itemized.powerVent !== '' &&
      itemized.smallKitBathExhaust != null &&
      itemized.smallKitBathExhaust !== '' &&
      itemized.largeKitBathExhaust != null &&
      itemized.largeKitBathExhaust !== '' &&
      itemized.ridgeVent != null &&
      itemized.ridgeVent !== '' &&
      itemized.ridgeCap != null &&
      itemized.ridgeCap !== '' &&
      itemized.valleyFlashing != null &&
      itemized.valleyFlashing !== '' &&
      itemized.stepFlashing != null &&
      itemized.stepFlashing !== '' &&
      itemized.regletFlashing != null &&
      itemized.regletFlashing !== '' &&
      itemized.oneByFourFlashing != null &&
      itemized.oneByFourFlashing !== '' &&
      itemized.skylightFlashing != null &&
      itemized.skylightFlashing !== '' &&
      itemized.skylight != null &&
      itemized.skylight !== '' &&
      itemized.smallChimney != null &&
      itemized.smallChimney !== '' &&
      itemized.mediumChimney != null &&
      itemized.mediumChimney !== '' &&
      itemized.largeChimney != null &&
      itemized.largeChimney !== '' &&
      itemized.smallChimneyPipe != null &&
      itemized.smallChimneyPipe !== '' &&
      itemized.mediumChimneyPipe != null &&
      itemized.mediumChimneyPipe !== '' &&
      itemized.largeChimneyPipe != null &&
      itemized.largeChimneyPipe !== '' &&
      itemized.gutterDetachReset != null &&
      itemized.gutterDetachReset !== '' &&
      itemized.dormerFlashing != null &&
      itemized.dormerFlashing !== '' &&
      itemized.detachResetSiding != null &&
      itemized.detachResetSiding !== '' &&
      itemized.smallCorniceReturn != null &&
      itemized.smallCorniceReturn !== '' &&
      itemized.mediumCorniceReturn != null &&
      itemized.mediumCorniceReturn !== '' &&
      itemized.largeCorniceReturn != null &&
      itemized.largeCorniceReturn !== '' &&
      itemized.smallBayWindow != null &&
      itemized.smallBayWindow !== '' &&
      itemized.mediumBayWindow != null &&
      itemized.mediumBayWindow !== '' &&
      itemized.largeBayWindow != null &&
      itemized.largeBayWindow !== '' &&
      itemized.satelliteDishReset != null &&
      itemized.satelliteDishReset !== '' &&
      // itemized.roofingPermit != null &&
      // itemized.roofingPermit !== '' &&
      itemized.debrisRemoval != null &&
      itemized.debrisRemoval !== '';
    setIsItemizedComplete(isComplete);
  };

  const checkPaymentCompletion = (payment, savedList) => {
    const isPaymentOptionsComplete =
      savedList?.includes('options') &&
      payment &&
      payment.options &&
      payment.options.oneYearFee != null &&
      payment.options.oneYearFee !== '' &&
      payment.options.oneYearRate != null &&
      payment.options.oneYearRate !== '' &&
      payment.options.threeYearFee != null &&
      payment.options.threeYearFee !== '' &&
      payment.options.threeYearRate != null &&
      payment.options.threeYearRate !== '' &&
      payment.options.sevenYearFee != null &&
      payment.options.sevenYearFee !== '' &&
      payment.options.sevenYearRate != null &&
      payment.options.sevenYearRate !== '' &&
      payment.options.cashFee != null &&
      payment.options.cashFee !== '' &&
      payment.options.cashRate != null &&
      payment.options.cashRate !== '';
    setIsPaymentOptionsComplete(isPaymentOptionsComplete);
    const isPaymentGeneralComplete =
      savedList?.includes('general') &&
      payment &&
      payment.general &&
      payment.general.companyName &&
      payment.general.companyName !== '' &&
      payment.general.companyAddressLineOne &&
      payment.general.companyAddressLineOne !== '' &&
      payment.general.companyAddressLineTwo &&
      payment.general.companyAddressLineTwo !== '' &&
      payment.general.companyPhoneNumber &&
      payment.general.companyPhoneNumber !== '' &&
      payment.general.companyEmail &&
      payment.general.companyEmail !== '' &&
      payment.general.salesTax != null &&
      payment.general.salesTax !== '' &&
      payment.general.unforeseenExpenses != null &&
      payment.general.unforeseenExpenses !== '' &&
      payment.general.laborDeduction != null &&
      payment.general.laborDeduction !== '' &&
      payment.general.overhead != null &&
      payment.general.overhead !== '' &&
      payment.general.profitMargin != null &&
      payment.general.profitMargin !== '' &&
      payment.general.salesIncomeTax != null &&
      payment.general.salesIncomeTax !== '' &&
      payment.general.profitIncomeTax != null &&
      payment.general.profitIncomeTax !== '';
    setIsPaymentGeneralComplete(isPaymentGeneralComplete);
    const isPaymentCommissionComplete =
      savedList?.includes('commission') &&
      payment &&
      payment.commission &&
      (payment.commission.isFlatRate ||
        payment.commission.isPercentageOfSale ||
        payment.commission.isPercentageOfProfit) &&
      (payment.commission.isFlatRate
        ? payment.commission.flatRate != null &&
          payment.commission.flatRate !== ''
        : true) &&
      (payment.commission.isPercentageOfSale
        ? payment.commission.percentageOfSale != null &&
          payment.commission.percentageOfSale !== ''
        : true) &&
      (payment.commission.isPercentageOfProfit
        ? payment.commission.percentageOfProfit != null &&
          payment.commission.percentageOfProfit !== ''
        : true);
    setIsPaymentCommissionComplete(isPaymentCommissionComplete);
    setIsPaymentComplete(
      isPaymentOptionsComplete &&
        isPaymentGeneralComplete &&
        isPaymentCommissionComplete
    );
  };

  const checkSteepPitchCompletion = (steepPitchRates, savedList) => {
    const isTearOffComplete =
      savedList?.includes('tearOff') &&
      steepPitchRates &&
      steepPitchRates.tearOff &&
      steepPitchRates.tearOff.seven != null &&
      steepPitchRates.tearOff.seven !== '' &&
      steepPitchRates.tearOff.eight != null &&
      steepPitchRates.tearOff.eight !== '' &&
      steepPitchRates.tearOff.nine != null &&
      steepPitchRates.tearOff.nine !== '' &&
      steepPitchRates.tearOff.ten != null &&
      steepPitchRates.tearOff.ten !== '' &&
      steepPitchRates.tearOff.eleven != null &&
      steepPitchRates.tearOff.eleven !== '' &&
      steepPitchRates.tearOff.twelve != null &&
      steepPitchRates.tearOff.twelve !== '' &&
      steepPitchRates.tearOff.thirteen != null &&
      steepPitchRates.tearOff.thirteen !== '' &&
      steepPitchRates.tearOff.fourteen != null &&
      steepPitchRates.tearOff.fourteen !== '' &&
      steepPitchRates.tearOff.fifteen != null &&
      steepPitchRates.tearOff.fifteen !== '' &&
      steepPitchRates.tearOff.sixteen != null &&
      steepPitchRates.tearOff.sixteen !== '' &&
      steepPitchRates.tearOff.seventeen != null &&
      steepPitchRates.tearOff.seventeen !== '' &&
      steepPitchRates.tearOff.eighteen != null &&
      steepPitchRates.tearOff.eighteen !== '';
    setIsTearOffComplete(isTearOffComplete);
    const isNewComplete =
      savedList?.includes('newRecover') &&
      steepPitchRates &&
      steepPitchRates.recover &&
      steepPitchRates.recover.seven != null &&
      steepPitchRates.recover.seven !== '' &&
      steepPitchRates.recover.eight != null &&
      steepPitchRates.recover.eight !== '' &&
      steepPitchRates.recover.nine != null &&
      steepPitchRates.recover.nine !== '' &&
      steepPitchRates.recover.ten != null &&
      steepPitchRates.recover.ten !== '' &&
      steepPitchRates.recover.eleven != null &&
      steepPitchRates.recover.eleven !== '' &&
      steepPitchRates.recover.twelve != null &&
      steepPitchRates.recover.twelve !== '' &&
      steepPitchRates.recover.thirteen != null &&
      steepPitchRates.recover.thirteen !== '' &&
      steepPitchRates.recover.fourteen != null &&
      steepPitchRates.recover.fourteen !== '' &&
      steepPitchRates.recover.fifteen != null &&
      steepPitchRates.recover.fifteen !== '' &&
      steepPitchRates.recover.sixteen != null &&
      steepPitchRates.recover.sixteen !== '' &&
      steepPitchRates.recover.seventeen != null &&
      steepPitchRates.recover.seventeen !== '' &&
      steepPitchRates.recover.eighteen != null &&
      steepPitchRates.recover.eighteen !== '';
    setIsNewComplete(isNewComplete);
    const isRedeckComplete =
      savedList?.includes('redeck') &&
      steepPitchRates &&
      steepPitchRates.redeck &&
      steepPitchRates.redeck.seven != null &&
      steepPitchRates.redeck.seven !== '' &&
      steepPitchRates.redeck.eight != null &&
      steepPitchRates.redeck.eight !== '' &&
      steepPitchRates.redeck.nine != null &&
      steepPitchRates.redeck.nine !== '' &&
      steepPitchRates.redeck.ten != null &&
      steepPitchRates.redeck.ten !== '' &&
      steepPitchRates.redeck.eleven != null &&
      steepPitchRates.redeck.eleven !== '' &&
      steepPitchRates.redeck.twelve != null &&
      steepPitchRates.redeck.twelve !== '' &&
      steepPitchRates.redeck.thirteen != null &&
      steepPitchRates.redeck.thirteen !== '' &&
      steepPitchRates.redeck.fourteen != null &&
      steepPitchRates.redeck.fourteen !== '' &&
      steepPitchRates.redeck.fifteen != null &&
      steepPitchRates.redeck.fifteen !== '' &&
      steepPitchRates.redeck.sixteen != null &&
      steepPitchRates.redeck.sixteen !== '' &&
      steepPitchRates.redeck.seventeen != null &&
      steepPitchRates.redeck.seventeen !== '' &&
      steepPitchRates.redeck.eighteen != null &&
      steepPitchRates.redeck.eighteen !== '';
    setIsRedeckComplete(isRedeckComplete);
    setIsSteepPitchComplete(
      isTearOffComplete && isNewComplete && isRedeckComplete
    );
  };

  const checkRatesCompletion = (rates, savedList) => {
    const isBaseComplete =
      savedList?.includes('baserates') &&
      rates &&
      rates.tearOff !== undefined &&
      rates.tearOff != null &&
      rates.tearOff !== '' &&
      rates.recover != null &&
      rates.recover !== '' &&
      rates.newConstruction != null &&
      rates.newConstruction !== '';
    setIsBaseComplete(isBaseComplete);
    const isPartTwoComplete =
      savedList?.includes('part2') &&
      rates &&
      rates.shingleLift != null &&
      rates.shingleLift !== '' &&
      rates.starter != null &&
      rates.starter !== '' &&
      rates.ridgeCap != null &&
      rates.ridgeCap !== '' &&
      rates.difficultDumpAccess != null &&
      rates.difficultDumpAccess !== '' &&
      rates.cutInRidgeVent != null &&
      rates.cutInRidgeVent !== '' &&
      rates.installRidgeVent != null &&
      rates.installRidgeVent !== '' &&
      rates.removeGutters != null &&
      rates.removeGutters !== '' &&
      rates.resetGutters != null &&
      rates.resetGutters !== '' &&
      rates.resetSiding != null &&
      rates.resetSiding !== '' &&
      rates.stepFlashing != null &&
      rates.stepFlashing !== '' &&
      rates.sidewallCounterFlashing != null &&
      rates.sidewallCounterFlashing !== '' &&
      rates.dormerFlashing != null &&
      rates.dormerFlashing !== '' &&
      rates.replaceMidroofVenting != null &&
      rates.replaceMidroofVenting !== '' &&
      rates.cutInNewMidroofVenting != null &&
      rates.cutInNewMidroofVenting !== '' &&
      rates.plywoodReplace != null &&
      rates.plywoodReplace !== '' &&
      rates.roofBoardRepair != null &&
      rates.roofBoardRepair !== '';
    setIsPartTwoComplete(isPartTwoComplete);
    const isPartThreeComplete =
      savedList?.includes('part3') &&
      rates &&
      rates.iceAndWater != null &&
      rates.iceAndWater !== '' &&
      rates.story != null &&
      rates.story !== '' &&
      rates.layerTearOff != null &&
      rates.layerTearOff !== '' &&
      rates.woodShakeTearOff != null &&
      rates.woodShakeTearOff !== '' &&
      rates.woodshakeDumpFee != null &&
      rates.woodshakeDumpFee !== '' &&
      rates.removeChimney != null &&
      rates.removeChimney !== '' &&
      rates.removeDecking != null &&
      rates.removeDecking !== '' &&
      rates.newVents != null &&
      rates.newVents !== '' &&
      rates.baseRedeck != null &&
      rates.baseRedeck !== '' &&
      rates.storyRedeck != null &&
      rates.storyRedeck !== '' &&
      rates.closeProximityHomes != null &&
      rates.closeProximityHomes !== '';
    setIsPartThreeComplete(isPartThreeComplete);
    const isPartFourComplete =
      savedList?.includes('part4') &&
      rates &&
      rates.smallChimneyStepFlashing != null &&
      rates.smallChimneyStepFlashing !== '' &&
      rates.mediumChimneyStepFlashing != null &&
      rates.mediumChimneyStepFlashing !== '' &&
      rates.largeChimneyStepFlashing != null &&
      rates.largeChimneyStepFlashing !== '' &&
      rates.smallChimneyNewFlashing != null &&
      rates.smallChimneyNewFlashing !== '' &&
      rates.mediumChimneyNewFlashing != null &&
      rates.mediumChimneyNewFlashing !== '' &&
      rates.largeChimneyNewFlashing != null &&
      rates.largeChimneyNewFlashing !== '' &&
      rates.smallRemoveChimney != null &&
      rates.smallRemoveChimney !== '' &&
      rates.mediumRemoveChimney != null &&
      rates.mediumRemoveChimney !== '' &&
      rates.largeRemoveChimney != null &&
      rates.largeRemoveChimney !== '' &&
      rates.satelliteDish != null &&
      rates.satelliteDish !== '' &&
      rates.acUnit != null &&
      rates.acUnit !== '' &&
      rates.antenna != null &&
      rates.antenna !== '' &&
      rates.solarPanel != null &&
      rates.solarPanel !== '' &&
      rates.smallSkylightFlashing != null &&
      rates.smallSkylightFlashing !== '' &&
      rates.mediumSkylightFlashing != null &&
      rates.mediumSkylightFlashing !== '' &&
      rates.largeSkylightFlashing != null &&
      rates.largeSkylightFlashing !== '' &&
      rates.smallChimneyCricket != null &&
      rates.smallChimneyCricket !== '' &&
      rates.mediumChimneyCricket != null &&
      rates.mediumChimneyCricket !== '' &&
      rates.largeChimneyCricket != null &&
      rates.largeChimneyCricket !== '' &&
      rates.smallNewSkylight != null &&
      rates.smallNewSkylight !== '' &&
      rates.mediumNewSkylight != null &&
      rates.mediumNewSkylight !== '' &&
      rates.largeNewSkylight != null &&
      rates.largeNewSkylight !== '' &&
      rates.smallCorniceReturnPerStory != null &&
      rates.smallCorniceReturnPerStory !== '' &&
      rates.mediumCorniceReturnPerStory != null &&
      rates.mediumCorniceReturnPerStory !== '' &&
      rates.largeCorniceReturnPerStory != null &&
      rates.largeCorniceReturnPerStory !== '' &&
      rates.smallCorniceReturn != null &&
      rates.smallCorniceReturn !== '' &&
      rates.mediumCorniceReturn != null &&
      rates.mediumCorniceReturn !== '' &&
      rates.largeCorniceReturn != null &&
      rates.largeCorniceReturn !== '' &&
      rates.smallBayWindow != null &&
      rates.smallBayWindow !== '' &&
      rates.mediumBayWindow != null &&
      rates.mediumBayWindow !== '' &&
      rates.largeBayWindow != null &&
      rates.largeBayWindow !== '' &&
      rates.nineToThirteenHandSealing != null &&
      rates.nineToThirteenHandSealing !== '' &&
      rates.eightOrLessHandSealing != null &&
      rates.eightOrLessHandSealing !== '' &&
      rates.fourteenPlusHandSealing != null &&
      rates.fourteenPlusHandSealing !== '';
    setIsPartFourComplete(isPartFourComplete);
    setIsRatesComplete(
      isBaseComplete &&
        isPartTwoComplete &&
        isPartThreeComplete &&
        isPartFourComplete
    );
  };

  const checkShinglesCompletion = shingleRates => {
    const isComplete =
      shingleRates &&
      shingleRates.additionalLaborCost != null &&
      shingleRates.additionalLaborCost !== '';
    setIsShinglesComplete(isComplete);
  };

  const checkAdditionalCompletion = (additional, savedList) => {
    const isComplete =
      savedList?.includes('additional') &&
      additional &&
      additional.hipWasteAllowance != null &&
      additional.hipWasteAllowance !== '' &&
      additional.gableWasteAllowance != null &&
      additional.gableWasteAllowance !== '' &&
      additional.dumpMinimumFee != null &&
      additional.dumpMinimumFee !== '' &&
      additional.dumpPerSquareFee != null &&
      additional.dumpPerSquareFee !== '' &&
      additional.onStreetPermitFee != null &&
      additional.onStreetPermitFee !== '';
    // && (additional.isFlatRatePermit
    //   ? additional.flatRatePermit != null && additional.flatRatePermit !== ''
    //   : true) &&
    // (additional.isPercentOfContractPermit
    //   ? additional.percentOfContractPermit != null &&
    //     additional.percentOfContractPermit !== ''
    //   : true);
    setIsAdditionalComplete(isComplete);
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const token = await user.getIdToken();
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/labors`,
          {
            headers: {
              authorization: `${token}`,
            },
            params: {
              username: user.email,
            },
          }
        );
        console.log(response.data);
        if (response.data) {
          dispatch(updateLabor(response.data));
        }
        if (response.data?.rates) {
          dispatch(updateRate(response.data.rates));
          checkRatesCompletion(
            response.data.rates,
            response.data?.hasBeenSaved
          );
        }
        if (response.data?.steepPitchRates) {
          dispatch(loadSteepPitchRate(response.data.steepPitchRates));
          checkSteepPitchCompletion(
            response.data.steepPitchRates,
            response.data?.hasBeenSaved
          );
        }
        if (response.data?.itemized) {
          dispatch(loadItemized(response.data.itemized));
          checkItemizedCompletion(
            response.data.itemized,
            response.data?.hasBeenSaved
          );
        }
        if (response.data?.shingleRates) {
          dispatch(loadShingles(response.data.shingleRates));
          checkShinglesCompletion(
            response.data.shingleRates,
            response.data?.hasBeenSaved
          );
        }
        if (response.data?.additional) {
          dispatch(loadAdditional(response.data.additional));
          checkAdditionalCompletion(
            response.data.additional,
            response.data?.hasBeenSaved
          );
        }
        if (response.data?.payment?.options) {
          dispatch(loadOptions(response.data.payment.options));
        }
        if (response.data?.payment?.general) {
          dispatch(loadGeneral(response.data.payment.general));
        }
        if (response.data?.payment?.commission) {
          dispatch(loadCommission(response.data.payment.commission));
        }
        if (response.data?.shingles) {
          dispatch(updateShingles(response.data.shingles));
        }
        checkPaymentCompletion(
          response.data?.payment,
          response.data?.hasBeenSaved
        );
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setIsLoading(false);
    };

    if (user) {
      if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
        mixpanel.track('User navigated to Labor page.');
      }
      fetchData();
    }
  }, [user]);

  return (
    <Center m="20px">
      {isLoading ? (
        <Center>
          <Spinner
            thickness="7px"
            speed="1.25s"
            size="xl"
            emptyColor="gray.200"
            color="black.500"
            marginTop="100px"
          />
        </Center>
      ) : (
        <Box>
          <Tabs variant="enclosed" align="center">
            <TabList>
              <Tab
                as="b"
                bg={isRatesComplete ? 'green.200' : 'yellow.200'}
                color="black"
                _selected={{
                  border: '1px solid black',
                  bg: isRatesComplete ? 'green.400' : 'yellow.400',
                }}
              >
                Rates
              </Tab>
              <Tab
                as="b"
                bg={isSteepPitchComplete ? 'green.200' : 'yellow.200'}
                color="black"
                _selected={{
                  border: '1px solid black',
                  bg: isSteepPitchComplete ? 'green.400' : 'yellow.400',
                }}
              >
                Steep Pitch
              </Tab>
              {/* <Tab
                as="b"
                bg={isShinglesComplete ? 'green.200' : 'yellow.200'}
                color="black"
                _selected={{
                  border: '1px solid black',
                  bg: isShinglesComplete ? 'green.400' : 'yellow.400',
                }}
              >
                Shingles
              </Tab> */}
              <Tab
                as="b"
                bg={isAdditionalComplete ? 'green.200' : 'yellow.200'}
                color="black"
                _selected={{
                  border: '1px solid black',
                  bg: isAdditionalComplete ? 'green.400' : 'yellow.400',
                }}
              >
                Additional
              </Tab>
              <Tab
                as="b"
                bg={isPaymentComplete ? 'green.200' : 'yellow.200'}
                color="black"
                _selected={{
                  border: '1px solid black',
                  bg: isPaymentComplete ? 'green.400' : 'yellow.400',
                }}
              >
                Payment
              </Tab>
              <Tab
                as="b"
                bg={isItemizedComplete ? 'green.200' : 'yellow.200'}
                color="black"
                _selected={{
                  border: '1px solid black',
                  bg: isItemizedComplete ? 'green.400' : 'yellow.400',
                }}
              >
                Itemized
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <BaseRates
                  checkRatesCompletion={checkRatesCompletion}
                  isBaseComplete={isBaseComplete}
                  isPartTwoComplete={isPartTwoComplete}
                  isPartThreeComplete={isPartThreeComplete}
                  isPartFourComplete={isPartFourComplete}
                />
              </TabPanel>
              <TabPanel>
                <SteepPitch
                  checkSteepPitchCompletion={checkSteepPitchCompletion}
                  isRedeckComplete={isRedeckComplete}
                  isNewComplete={isNewComplete}
                  isTearOffComplete={isTearOffComplete}
                />
              </TabPanel>
              {/* <TabPanel>
                <ShinglesLabor
                  checkShinglesCompletion={checkShinglesCompletion}
                />
              </TabPanel> */}
              <TabPanel>
                <AdditionalLabor
                  checkAdditionalCompletion={checkAdditionalCompletion}
                />
              </TabPanel>
              <TabPanel>
                <PaymentOptions
                  checkPaymentCompletion={checkPaymentCompletion}
                  isPaymentGeneralComplete={isPaymentGeneralComplete}
                  isPaymentOptionsComplete={isPaymentOptionsComplete}
                  isPaymentCommissionComplete={isPaymentCommissionComplete}
                />
              </TabPanel>
              <TabPanel>
                <ItemizedPricing
                  checkItemizedCompletion={checkItemizedCompletion}
                ></ItemizedPricing>
              </TabPanel>
            </TabPanels>
          </Tabs>
          <Center>
            <VStack>
              <Button colorScheme="purple" onClick={handleClick}>
                Go to Estimator
              </Button>
            </VStack>
          </Center>
        </Box>
      )}
    </Center>
  );
};

export default memo(Labor);
