import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Center,
  Text,
  HStack,
  VStack,
  TableContainer,
  Flex,
  Stack,
  Checkbox,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberDecrementStepper,
  NumberIncrementStepper,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Radio,
  RadioGroup,
  Select,
  Spacer,
  Spinner,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateFlashingHasValleyFlashing,
  updateFlashingValleyFlashingLength,
  updateFlashingValleyFlashingType,
  updateFlashingValleyFlashingColor,
  updateFlashingStepFlashing,
  updateFlashingRegletFlashing,
  updateFlashingOneByFourFlashing,
  updateFlashingDormerFlashing,
  updateFlashingStepFlashingLength,
  updateFlashingStepFlashingColor,
  updateFlashingRegletLength,
  updateFlashingRegletColor,
  updateFlashingOneByLength,
  updateFlashingOneByColor,
  updateFlashingDormerFlashingLength,
  updateFlashingDormerFlashingColor,
  updateFlashingIsCompleted,
  updateChimneyFlashingList,
  removeFromPipeFlashingList,
  addToPipeFlashingList,
  removeFromChimneyFlashingList,
  addToChimneyFlashingList,
  updatePipeFlashingList,
  clearChimneyFlashingList,
  clearPipeFlashingList,
  updateFlashingDormerFlashingResetSiding,
  updateFlashingDormerFlashingResetSidingLength,
  updateFlashingStepFlashingResetSiding,
  updateFlashingStepFlashingResetSidingLength,
} from '../features/calculator/calculatorSlice';
import useUser from '../hooks/useUser';
import mixpanel from 'mixpanel-browser';

const Flashing = ({
  checkFlashingCompletion,
  isFlashingComplete,
  setCalculateButtonClicked,
  isMobileDevice,
}) => {
  const { user } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();

  const counterFlashingLF = useSelector(
    state => state.calculator.counterFlashingLF
  );
  const flashing = useSelector(state => state.calculator.flashing);
  const chimneyFlashingList = useSelector(
    state => state.calculator.flashing.chimneyFlashingList
  );
  const pipeFlashingList = useSelector(
    state => state.calculator.flashing.pipeFlashingList
  );
  const [openLoading, setOpenLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [unsavedChanged, setUnsavedChanges] = useState(false);

  const handleAddChimneyRow = () => {
    setUnsavedChanges(true);
    let tempChimneyNumber;
    if (chimneyFlashingList.length > 0) {
      tempChimneyNumber =
        chimneyFlashingList[chimneyFlashingList.length - 1].chimneyNumber + 1;
    } else {
      tempChimneyNumber = 1;
    }
    const newRow = {
      chimneyNumber: tempChimneyNumber,
      chimneyCount: '',
      flashingType: '',
      isReuse: false,
      isReplace: false,
      isRemoveChimney: false,
      isAddCricket: false,
      color: '',
    };
    dispatch(addToChimneyFlashingList(newRow));
  };

  const handleDeleteChimneyRow = id => {
    setUnsavedChanges(true);
    dispatch(removeFromChimneyFlashingList(id));
  };

  const handleAddPipeRow = () => {
    setUnsavedChanges(true);
    let tempPipeNumber;
    if (pipeFlashingList.length > 0) {
      tempPipeNumber =
        pipeFlashingList[pipeFlashingList.length - 1].pipeNumber + 1;
    } else {
      tempPipeNumber = 1;
    }
    const newRow = {
      pipeNumber: tempPipeNumber,
      pipeCount: '',
      flashingType: '',
      size: '',
      isReuse: false,
      isReplace: false,
      color: '',
    };
    dispatch(addToPipeFlashingList(newRow));
  };

  const handleDeletePipeRow = id => {
    setUnsavedChanges(true);
    dispatch(removeFromPipeFlashingList(id));
  };

  const handleOpen = async () => {
    setOpenLoading(true);
    // if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    //   mixpanel.track('User opened the Flashing modal.');
    // }
    onOpen();
  };

  const handleSave = async () => {
    setSaveLoading(true);
    try {
      const token = await user.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/roofs/flashing`,
        {
          username: user.email,
          flashing,
          isCompleted: false,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      setUnsavedChanges(false);
      checkFlashingCompletion(flashing);
      setCalculateButtonClicked(true);
      onClose();
    } catch (error) {
      console.log('error: ' + error);
    }
    setOpenLoading(false);
    setSaveLoading(false);
  };

  return (
    <Box
      minW={isMobileDevice ? '90vw' : ''}
      maxW="sm"
      maxH="70px"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={isMobileDevice ? '3' : '6'}
      boxShadow="base"
      borderColor="gray.400"
      bg={isFlashingComplete ? 'green.300' : 'yellow.200'}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      position="relative"
      cursor="pointer"
      onClick={isMobileDevice ? handleOpen : null}
    >
      <Flex alignItems="center">
        <Text fontWeight="bold" fontSize="l" mr="4">
          Flashing
        </Text>
        {!isMobileDevice && (
          <Button
            size="sm"
            border="1px"
            borderColor="gray.400"
            ml="30px"
            minWidth="53px"
            onClick={handleOpen}
          >
            {false ? (
              <Spinner
                thickness="2px"
                speed="1.25s"
                size="sm"
                emptyColor="gray.200"
                color="black.500"
              />
            ) : (
              <Text>Edit</Text>
            )}
          </Button>
        )}
        <Modal isOpen={isOpen} size="custom" onClose={onClose}>
          <ModalOverlay />
          <ModalContent maxW={isMobileDevice ? '100vw' : '80vw'}>
            <ModalHeader>Flashing</ModalHeader>
            <ModalBody>
              <Box
                border={isMobileDevice ? '' : '1px solid #e2e8f0'}
                borderRadius="md"
                padding={isMobileDevice ? '0' : '3'}
              >
                <HStack paddingBottom="10px">
                  <Text
                    fontSize="sm"
                    fontWeight="bold"
                    mb="2"
                    paddingRight="50px"
                  >
                    Chimney Flashing
                  </Text>
                  <Button
                    size="sm"
                    colorScheme="blue"
                    variant={
                      chimneyFlashingList.length > 0 ? 'outline' : 'solid'
                    }
                    onClick={() => {
                      setUnsavedChanges(true);
                      if (chimneyFlashingList.length > 0) {
                        dispatch(clearChimneyFlashingList());
                      } else {
                        handleAddChimneyRow();
                      }
                    }}
                  >
                    None
                  </Button>
                </HStack>
                {chimneyFlashingList.length > 0 && (
                  <Box overflowX="auto">
                    <TableContainer>
                      <Table size="xs">
                        <Thead>
                          <Tr>
                            <Th fontSize="xs">Count</Th>
                            <Th fontSize="xs">Type</Th>
                            <Th fontSize="xs">Size</Th>
                            <Th fontSize="xs">
                              <HStack justify="center">
                                <Text fontSize="xs">Reuse</Text>
                              </HStack>
                            </Th>
                            <Th fontSize="xs">
                              <HStack justify="center">
                                <Text fontSize="xs">Replace Flashing</Text>
                              </HStack>
                            </Th>
                            <Th fontSize="xs">
                              <HStack justify="center">
                                <Text fontSize="xs">Remove Chimney</Text>
                              </HStack>
                            </Th>
                            <Th fontSize="xs">
                              <HStack justify="center">
                                <Text fontSize="xs">Add Cricket</Text>
                              </HStack>
                            </Th>
                            {/* <Th fontSize="xs">Color</Th> */}
                          </Tr>
                        </Thead>
                        <Tbody>
                          {chimneyFlashingList.map((row, index) => (
                            <Tr key={index}>
                              <Td>
                                <NumberInput
                                  size="xs"
                                  width={isMobileDevice ? '70px' : '100px'}
                                  value={row.chimneyCount ?? ''}
                                  onChange={value => {
                                    setUnsavedChanges(true);
                                    dispatch(
                                      updateChimneyFlashingList({
                                        newValue: value,
                                        chimneyNumber: row.chimneyNumber,
                                        fieldToUpdate: 'chimneyCount',
                                      })
                                    );
                                  }}
                                >
                                  <NumberInputField />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </Td>
                              <Td>
                                <Select
                                  size="xs"
                                  width={isMobileDevice ? '100px' : '150px'}
                                  value={row.flashingType}
                                  onChange={event => {
                                    setUnsavedChanges(true);
                                    dispatch(
                                      updateChimneyFlashingList({
                                        newValue: event.target.value,
                                        chimneyNumber: row.chimneyNumber,
                                        fieldToUpdate: 'flashingType',
                                      })
                                    );
                                  }}
                                >
                                  <option value=""></option>
                                  <option value="metal-pipe">Metal Pipe</option>
                                  <option value="masonry">Masonry</option>
                                  <option value="metal-wood">
                                    Metal/Wood Siding
                                  </option>
                                </Select>
                              </Td>
                              <Td>
                                {/* // TODO: dynamically render these options based on selected type */}
                                <Select
                                  size="xs"
                                  width="130px"
                                  value={row.size}
                                  onChange={event => {
                                    setUnsavedChanges(true);
                                    dispatch(
                                      updateChimneyFlashingList({
                                        newValue: event.target.value,
                                        chimneyNumber: row.chimneyNumber,
                                        fieldToUpdate: 'size',
                                      })
                                    );
                                  }}
                                >
                                  {row.flashingType === 'metal-pipe' ? (
                                    <>
                                      <option value=""></option>
                                      <option value="4">4"</option>
                                      <option value="5">5"</option>
                                      <option value="6">6"</option>
                                      <option value="8">8"</option>
                                      <option value="10">10"</option>
                                      <option value="12">12"</option>
                                    </>
                                  ) : (
                                    <>
                                      <option value=""></option>
                                      <option value="small">
                                        Small 24"x24"
                                      </option>
                                      <option value="medium">
                                        Medium 36"x36"
                                      </option>
                                      <option value="large">
                                        Large 48"x48"
                                      </option>
                                    </>
                                  )}
                                </Select>
                              </Td>
                              <Td>
                                <HStack align="center" justify="center">
                                  <Checkbox
                                    isChecked={row.isReuse}
                                    disabled={
                                      row.isReplace || row.isRemoveChimney
                                    }
                                    onChange={event => {
                                      setUnsavedChanges(true);
                                      dispatch(
                                        updateChimneyFlashingList({
                                          newValue: event.target.checked,
                                          chimneyNumber: row.chimneyNumber,
                                          fieldToUpdate: 'isReuse',
                                        })
                                      );
                                    }}
                                  ></Checkbox>
                                </HStack>
                              </Td>
                              <Td>
                                <HStack align="center" justify="center">
                                  <Checkbox
                                    isChecked={row.isReplace}
                                    disabled={
                                      row.isRemoveChimney || row.isReuse
                                    }
                                    onChange={event => {
                                      setUnsavedChanges(true);
                                      dispatch(
                                        updateChimneyFlashingList({
                                          newValue: event.target.checked,
                                          chimneyNumber: row.chimneyNumber,
                                          fieldToUpdate: 'isReplace',
                                        })
                                      );
                                    }}
                                  ></Checkbox>
                                </HStack>
                              </Td>
                              <Td>
                                <HStack align="center" justify="center">
                                  <Checkbox
                                    isChecked={row.isRemoveChimney}
                                    disabled={row.isReplace || row.isReuse}
                                    onChange={event => {
                                      setUnsavedChanges(true);
                                      dispatch(
                                        updateChimneyFlashingList({
                                          newValue: event.target.checked,
                                          chimneyNumber: row.chimneyNumber,
                                          fieldToUpdate: 'isRemoveChimney',
                                        })
                                      );
                                    }}
                                  ></Checkbox>
                                </HStack>
                              </Td>
                              <Td>
                                <HStack align="center" justify="center">
                                  <Checkbox
                                    isChecked={row.isAddCricket}
                                    disabled={row.flashingType === 'metal-pipe'}
                                    onChange={event => {
                                      setUnsavedChanges(true);
                                      dispatch(
                                        updateChimneyFlashingList({
                                          newValue: event.target.checked,
                                          chimneyNumber: row.chimneyNumber,
                                          fieldToUpdate: 'isAddCricket',
                                        })
                                      );
                                    }}
                                  ></Checkbox>
                                </HStack>
                              </Td>
                              {/* <Td>
                            {row.flashingType === 'masonry' &&
                              row.isReplace && (
                                <Select
                                  size="xs"
                                  maxWidth="100px"
                                  value={row.color}
                                  onChange={event => {
                                    setUnsavedChanges(true);
                                    dispatch(
                                      updateChimneyFlashingList({
                                        newValue: event.target.value,
                                        chimneyNumber: row.chimneyNumber,
                                        fieldToUpdate: 'color',
                                      })
                                    );
                                  }}
                                >
                                  <option value=""></option>
                                  <option value="brown">Brown</option>
                                  <option value="black">Black</option>
                                  <option value="galvanized">Galvanized</option>
                                </Select>
                              )}
                          </Td> */}
                              <Td>
                                <Button
                                  variant="outline"
                                  size="xs"
                                  onClick={() =>
                                    handleDeleteChimneyRow(row.chimneyNumber)
                                  }
                                >
                                  Delete
                                </Button>
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
                <Button mt="2" size="xs" onClick={handleAddChimneyRow}>
                  Add
                </Button>
              </Box>
              <Box>
                <Box
                  border={isMobileDevice ? '' : '1px solid #e2e8f0'}
                  borderRadius="md"
                  padding={isMobileDevice ? '0' : '3'}
                  my="4"
                >
                  <HStack paddingBottom="10px">
                    <Text
                      fontSize="sm"
                      fontWeight="bold"
                      mb="2"
                      paddingRight="50px"
                    >
                      Pipe Flashing
                    </Text>
                    <Button
                      size="sm"
                      colorScheme="blue"
                      variant={
                        pipeFlashingList.length > 0 ? 'outline' : 'solid'
                      }
                      onClick={() => {
                        setUnsavedChanges(true);
                        if (pipeFlashingList.length > 0) {
                          dispatch(clearPipeFlashingList());
                        } else {
                          handleAddPipeRow();
                        }
                      }}
                    >
                      None
                    </Button>
                  </HStack>
                  {pipeFlashingList.length > 0 && (
                    <Box overflowX="auto">
                      <TableContainer>
                        <Table size="xs">
                          <Thead>
                            <Tr>
                              <Th fontSize="xs">Count</Th>
                              <Th fontSize="xs">Size</Th>
                              <Th fontSize="xs">Type</Th>
                              <Th fontSize="xs">
                                <HStack justify="center">
                                  <Text fontSize="xs">Re-Use</Text>
                                </HStack>
                              </Th>
                              <Th fontSize="xs">
                                <HStack justify="center">
                                  <Text fontSize="xs">Replace</Text>
                                </HStack>
                              </Th>
                              {/* <Th fontSize="xs">Color</Th> */}
                            </Tr>
                          </Thead>
                          <Tbody>
                            {pipeFlashingList.map((row, index) => (
                              <Tr key={index}>
                                <Td>
                                  <NumberInput
                                    size="xs"
                                    width={isMobileDevice ? '70px' : '100px'}
                                    value={row.pipeCount ?? ''}
                                    onChange={value => {
                                      setUnsavedChanges(true);
                                      dispatch(
                                        updatePipeFlashingList({
                                          newValue: value,
                                          pipeNumber: row.pipeNumber,
                                          fieldToUpdate: 'pipeCount',
                                        })
                                      );
                                    }}
                                  >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                      <NumberIncrementStepper />
                                      <NumberDecrementStepper />
                                    </NumberInputStepper>
                                  </NumberInput>
                                </Td>
                                <Td>
                                  <Select
                                    size="xs"
                                    width={isMobileDevice ? '80px' : '130px'}
                                    value={row.size}
                                    onChange={event => {
                                      setUnsavedChanges(true);
                                      dispatch(
                                        updatePipeFlashingList({
                                          newValue: event.target.value,
                                          pipeNumber: row.pipeNumber,
                                          fieldToUpdate: 'size',
                                        })
                                      );
                                    }}
                                  >
                                    <option value=""></option>
                                    <option value="1">1"</option>
                                    <option value="1.5">1 1/2"</option>
                                    <option value="2">2"</option>
                                    <option value="2.5">2 1/2"</option>
                                    <option value="3">3"</option>
                                    <option value="4">4"</option>
                                  </Select>
                                </Td>
                                <Td>
                                  <Select
                                    size="xs"
                                    width="130px"
                                    value={row.flashingType}
                                    onChange={event => {
                                      setUnsavedChanges(true);
                                      dispatch(
                                        updatePipeFlashingList({
                                          newValue: event.target.value,
                                          pipeNumber: row.pipeNumber,
                                          fieldToUpdate: 'flashingType',
                                        })
                                      );
                                    }}
                                  >
                                    <option value=""></option>
                                    <option value="neoprene">Neoprene</option>
                                    <option value="frost-free">
                                      Frost Free
                                    </option>
                                    <option value="split-boot">
                                      Split Boot
                                    </option>
                                  </Select>
                                </Td>
                                <Td>
                                  <HStack align="center" justify="center">
                                    <Checkbox
                                      isChecked={row.isReuse}
                                      disabled={row.isReplace}
                                      onChange={event => {
                                        setUnsavedChanges(true);
                                        dispatch(
                                          updatePipeFlashingList({
                                            newValue: event.target.checked,
                                            pipeNumber: row.pipeNumber,
                                            fieldToUpdate: 'isReuse',
                                          })
                                        );
                                      }}
                                    ></Checkbox>
                                  </HStack>
                                </Td>
                                <Td>
                                  <HStack align="center" justify="center">
                                    <Checkbox
                                      isChecked={row.isReplace}
                                      disabled={row.isReuse}
                                      onChange={event => {
                                        setUnsavedChanges(true);
                                        dispatch(
                                          updatePipeFlashingList({
                                            newValue: event.target.checked,
                                            pipeNumber: row.pipeNumber,
                                            fieldToUpdate: 'isReplace',
                                          })
                                        );
                                      }}
                                    ></Checkbox>
                                  </HStack>
                                </Td>
                                <Td>
                                  <Button
                                    variant="outline"
                                    size="xs"
                                    onClick={() =>
                                      handleDeletePipeRow(row.pipeNumber)
                                    }
                                  >
                                    Delete
                                  </Button>
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </Box>
                  )}
                  <Button mt="2" mb="2" size="xs" onClick={handleAddPipeRow}>
                    Add
                  </Button>
                </Box>
                <Flex direction="column" gap="5">
                  <Box
                    border={isMobileDevice ? '' : '1px solid #e2e8f0'}
                    borderRadius="md"
                    padding={isMobileDevice ? '0' : '3'}
                  >
                    <HStack mt="2" spacing={isMobileDevice ? '10px' : '50px'}>
                      <Text as="b" fontSize={isMobileDevice ? 'xs' : 'med'}>
                        {isMobileDevice ? 'Valley' : 'Valley Flashing'}
                      </Text>
                      <Button
                        size={isMobileDevice ? 'xs' : 'sm'}
                        colorScheme="blue"
                        variant={
                          flashing.hasValleyFlashing === false
                            ? 'solid'
                            : 'outline'
                        }
                        onClick={() => {
                          setUnsavedChanges(true);
                          if (flashing.hasValleyFlashing) {
                            dispatch(updateFlashingHasValleyFlashing(false));
                            dispatch(updateFlashingValleyFlashingLength(''));
                            dispatch(updateFlashingValleyFlashingType(''));
                            dispatch(updateFlashingValleyFlashingColor(''));
                          } else {
                            dispatch(updateFlashingHasValleyFlashing(true));
                          }
                        }}
                      >
                        <Text fontSize={isMobileDevice ? 'xs' : 'sm'}>
                          None
                        </Text>
                      </Button>
                      {/* <RadioGroup
                        marginLeft="20px"
                        value={flashing.hasValleyFlashing}
                        onChange={value => {
                          setUnsavedChanges(true);
                          dispatch(
                            updateFlashingHasValleyFlashing(value === 'true')
                          );
                        }}
                      >
                        <Stack direction="row" gap="3" mr="10">
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Stack>
                      </RadioGroup> */}
                      {flashing.hasValleyFlashing !== false && (
                        <>
                          <HStack>
                            <Text fontSize={isMobileDevice ? 'xs' : 'sm'}>
                              {isMobileDevice ? 'LF' : 'Linear Feet'}
                            </Text>
                            <NumberInput
                              size={isMobileDevice ? 'xs' : 'sm'}
                              width={isMobileDevice ? '70px' : '100px'}
                              value={flashing.valleyFlashingLength ?? ''}
                              onChange={value => {
                                setUnsavedChanges(true);
                                dispatch(
                                  updateFlashingValleyFlashingLength(value)
                                );
                                dispatch(updateFlashingHasValleyFlashing(true));
                              }}
                            >
                              <NumberInputField />
                            </NumberInput>
                          </HStack>
                          <HStack>
                            <Text fontSize={isMobileDevice ? 'xs' : 'sm'}>
                              Type
                            </Text>
                            <Select
                              size={isMobileDevice ? 'xs' : 'sm'}
                              value={flashing.valleyFlashingType}
                              onChange={event => {
                                setUnsavedChanges(true);
                                dispatch(
                                  updateFlashingValleyFlashingType(
                                    event.target.value
                                  )
                                );
                                dispatch(updateFlashingHasValleyFlashing(true));
                              }}
                            >
                              <option value=""></option>
                              <option value="w-metal">W-Metal</option>
                              <option value="roll-metal">Roll Metal</option>
                            </Select>
                          </HStack>
                          {/* <HStack>
                            <Text ml="10">Color:</Text>
                            <Select
                              size="sm"
                              value={flashing.valleyFlashingColor}
                              onChange={event => {
                                setUnsavedChanges(true);
                                dispatch(
                                  updateFlashingValleyFlashingColor(
                                    event.target.value
                                  )
                                );
                                dispatch(updateFlashingHasValleyFlashing(true));
                              }}
                            >
                              {flashing.valleyFlashingType === 'roll-metal' ? (
                                <option value="galvanized">Galvanized</option>
                              ) : (
                                <>
                                  <option value=""></option>
                                  <option value="brown">Brown</option>
                                  <option value="black">Black</option>
                                  <option value="gray">Gray</option>
                                  <option value="galvanized">Galvanized</option>
                                </>
                              )}
                            </Select>
                          </HStack> */}
                        </>
                      )}
                    </HStack>
                  </Box>
                  <Box
                    border={isMobileDevice ? '' : '1px solid #e2e8f0'}
                    borderRadius="md"
                    padding={isMobileDevice ? '0' : '3'}
                  >
                    <HStack
                      align="start"
                      spacing={isMobileDevice ? '10px' : '80px'}
                    >
                      <Text as="b" fontSize={isMobileDevice ? 'xs' : 'med'}>
                        {isMobileDevice ? 'Step' : 'Step Flashing'}
                      </Text>
                      <Button
                        size={isMobileDevice ? 'xs' : 'sm'}
                        colorScheme="blue"
                        variant={
                          flashing.stepFlashing === 'none' ? 'solid' : 'outline'
                        }
                        onClick={() => {
                          setUnsavedChanges(true);
                          if (flashing.stepFlashing === 'none') {
                            dispatch(updateFlashingStepFlashing(''));
                          } else {
                            dispatch(updateFlashingStepFlashing('none'));
                            dispatch(updateFlashingStepFlashingLength(''));
                            dispatch(
                              updateFlashingStepFlashingResetSidingLength('')
                            );
                            dispatch(
                              updateFlashingStepFlashingResetSiding(false)
                            );
                          }
                        }}
                      >
                        <Text fontSize={isMobileDevice ? 'xs' : 'sm'}>
                          None
                        </Text>
                      </Button>
                      {flashing.stepFlashing !== 'none' && (
                        <>
                          <VStack>
                            <HStack
                              width="100%"
                              spacing={isMobileDevice ? '10px' : '100px'}
                            >
                              <RadioGroup
                                value={flashing.stepFlashing}
                                onChange={value => {
                                  setUnsavedChanges(true);
                                  dispatch(updateFlashingStepFlashing(value));
                                }}
                              >
                                <Stack direction="row" gap="3">
                                  {/* <Radio value="none">None</Radio> */}
                                  <Radio value="reuse">
                                    <Text
                                      fontSize={isMobileDevice ? 'xs' : 'med'}
                                    >
                                      Re-use
                                    </Text>
                                  </Radio>
                                  <Radio value="install-new">
                                    <Text
                                      fontSize={isMobileDevice ? 'xs' : 'med'}
                                    >
                                      Install New
                                    </Text>
                                  </Radio>
                                </Stack>
                              </RadioGroup>
                              <HStack>
                                <Text fontSize={isMobileDevice ? 'xs' : 'med'}>
                                  {isMobileDevice ? 'LF' : 'Linear Feet'}
                                </Text>
                                <NumberInput
                                  size={isMobileDevice ? 'xs' : 'med'}
                                  width={isMobileDevice ? '70px' : '100px'}
                                  value={flashing.stepFlashingLength ?? ''}
                                  onChange={value => {
                                    setUnsavedChanges(true);
                                    dispatch(
                                      updateFlashingStepFlashingLength(value)
                                    );
                                  }}
                                >
                                  <NumberInputField />
                                </NumberInput>
                              </HStack>
                            </HStack>
                            {/* <HStack>
                            <Text ml="10">Color:</Text>
                            <Select
                              size="sm"
                              value={flashing.stepFlashingColor}
                              onChange={event => {
                                setUnsavedChanges(true);
                                dispatch(
                                  updateFlashingStepFlashingColor(
                                    event.target.value
                                  )
                                );
                              }}
                            >
                              <option value=""></option>
                              <option value="brown">Brown</option>
                              <option value="black">Black</option>
                              <option value="galvanized">Galvanized</option>
                            </Select>
                          </HStack> */}
                            <HStack
                              width="100%"
                              spacing={isMobileDevice ? '10px' : '100px'}
                            >
                              <HStack>
                                <Checkbox
                                  isChecked={flashing.stepFlashingResetSiding}
                                  onChange={event => {
                                    setUnsavedChanges(true);
                                    if (event.target.checked === true) {
                                      dispatch(
                                        updateFlashingStepFlashingResetSidingLength(
                                          flashing.stepFlashingLength
                                        )
                                      );
                                    } else {
                                      dispatch(
                                        updateFlashingStepFlashingResetSidingLength(
                                          ''
                                        )
                                      );
                                    }
                                    dispatch(
                                      updateFlashingStepFlashingResetSiding(
                                        event.target.checked
                                      )
                                    );
                                  }}
                                ></Checkbox>
                                <Text fontSize={isMobileDevice ? 'xs' : 'med'}>
                                  Detach/Reset Siding
                                </Text>
                              </HStack>
                              <HStack>
                                <Text fontSize={isMobileDevice ? 'xs' : 'med'}>
                                  {isMobileDevice ? 'LF' : 'Linear Feet'}
                                </Text>
                                <NumberInput
                                  size={isMobileDevice ? 'xs' : 'med'}
                                  width={isMobileDevice ? '70px' : '100px'}
                                  value={
                                    flashing.stepFlashingResetSidingLength ?? ''
                                  }
                                  onChange={value => {
                                    setUnsavedChanges(true);
                                    dispatch(
                                      updateFlashingStepFlashingResetSidingLength(
                                        value
                                      )
                                    );
                                  }}
                                >
                                  <NumberInputField />
                                </NumberInput>
                              </HStack>
                            </HStack>
                          </VStack>
                        </>
                      )}
                    </HStack>
                  </Box>
                  <Box
                    border={isMobileDevice ? '' : '1px solid #e2e8f0'}
                    borderRadius="md"
                    padding={isMobileDevice ? '0' : '3'}
                  >
                    <HStack paddingBottom="10px" spacing="40px">
                      <Text as="b" fontSize={isMobileDevice ? 'xs' : 'med'}>
                        {isMobileDevice
                          ? 'Sidewall'
                          : 'Sidewall Counter Flashing'}
                      </Text>
                      <Button
                        size={isMobileDevice ? 'xs' : 'sm'}
                        colorScheme="blue"
                        variant={
                          flashing.regletFlashing === 'none' &&
                          flashing.oneByFourFlashing === 'none'
                            ? 'solid'
                            : 'outline'
                        }
                        onClick={() => {
                          setUnsavedChanges(true);
                          if (
                            flashing.regletFlashing === 'none' &&
                            flashing.oneByFourFlashing === 'none'
                          ) {
                            dispatch(updateFlashingRegletFlashing(''));
                            dispatch(updateFlashingOneByFourFlashing(''));
                          } else {
                            dispatch(updateFlashingRegletFlashing('none'));
                            dispatch(updateFlashingOneByFourFlashing('none'));
                          }
                        }}
                      >
                        <Text fontSize={isMobileDevice ? 'xs' : 'sm'}>
                          None
                        </Text>
                      </Button>
                      <HStack>
                        <Text fontSize={isMobileDevice ? 'xs' : 'sm'}>
                          {isMobileDevice ? 'LF' : 'Linear Feet'}
                        </Text>
                        <NumberInput
                          size="xs"
                          width="75px"
                          isReadOnly={true}
                          variant="filled"
                          value={counterFlashingLF ?? ''}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </HStack>
                    </HStack>
                    {(flashing.regletFlashing !== 'none' ||
                      flashing.oneByFourFlashing !== 'none') && (
                      <>
                        <HStack justify="space-between" width="80%">
                          <Text fontSize={isMobileDevice ? 'xs' : 'med'}>
                            Reglet
                          </Text>
                          <RadioGroup
                            value={flashing.regletFlashing}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(updateFlashingRegletFlashing(value));
                            }}
                          >
                            <Stack
                              direction="row"
                              gap={isMobileDevice ? '2' : '5'}
                              mr={isMobileDevice ? '5' : '10'}
                            >
                              <Radio value="none">
                                <Text fontSize={isMobileDevice ? 'xs' : 'med'}>
                                  None
                                </Text>
                              </Radio>
                              <Radio value="reuse">
                                <Text fontSize={isMobileDevice ? 'xs' : 'med'}>
                                  Re-use
                                </Text>
                              </Radio>
                              <Radio value="install-new">
                                <Text fontSize={isMobileDevice ? 'xs' : 'med'}>
                                  Install New
                                </Text>
                              </Radio>
                            </Stack>
                          </RadioGroup>
                          <HStack>
                            <Text fontSize={isMobileDevice ? 'xs' : 'med'}>
                              {isMobileDevice ? 'LF' : 'Linear Feet'}
                            </Text>
                            <NumberInput
                              size={isMobileDevice ? 'xs' : 'sm'}
                              width={isMobileDevice ? '60px' : '100px'}
                              value={flashing.regletLength ?? ''}
                              onChange={value => {
                                setUnsavedChanges(true);
                                dispatch(updateFlashingRegletLength(value));
                              }}
                            >
                              <NumberInputField />
                            </NumberInput>
                          </HStack>
                          {/* <HStack>
                            <Text>Color:</Text>
                            <Select
                              size="sm"
                              value={flashing.regletColor}
                              onChange={event => {
                                setUnsavedChanges(true);
                                dispatch(
                                  updateFlashingRegletColor(event.target.value)
                                );
                              }}
                            >
                              <option value=""></option>
                              <option value="brown">Brown</option>
                              <option value="black">Black</option>
                              <option value="galvanized">Galvanized</option>
                            </Select>
                          </HStack> */}
                        </HStack>
                        <HStack justify="space-between" width="80%">
                          <Text fontSize={isMobileDevice ? 'xs' : 'med'}>
                            1X4
                          </Text>
                          <RadioGroup
                            value={flashing.oneByFourFlashing}
                            onChange={value => {
                              setUnsavedChanges(true);
                              dispatch(updateFlashingOneByFourFlashing(value));
                            }}
                          >
                            <Stack
                              direction="row"
                              gap={isMobileDevice ? '2' : '5'}
                              mr={isMobileDevice ? '5' : '10'}
                            >
                              <Radio value="none">
                                <Text fontSize={isMobileDevice ? 'xs' : 'med'}>
                                  None
                                </Text>
                              </Radio>
                              <Radio value="reuse">
                                <Text fontSize={isMobileDevice ? 'xs' : 'med'}>
                                  Re-use
                                </Text>
                              </Radio>
                              <Radio value="install-new">
                                <Text fontSize={isMobileDevice ? 'xs' : 'med'}>
                                  Install New
                                </Text>
                              </Radio>
                            </Stack>
                          </RadioGroup>
                          <HStack>
                            <Text fontSize={isMobileDevice ? 'xs' : 'med'}>
                              {isMobileDevice ? 'LF' : 'Linear Feet'}
                            </Text>
                            <NumberInput
                              size={isMobileDevice ? 'xs' : 'sm'}
                              width={isMobileDevice ? '60px' : '100px'}
                              value={flashing.oneByLength ?? ''}
                              onChange={value => {
                                setUnsavedChanges(true);
                                dispatch(updateFlashingOneByLength(value));
                              }}
                            >
                              <NumberInputField />
                            </NumberInput>
                          </HStack>
                          {/* <HStack>
                            <Text>Color:</Text>
                            <Select
                              size="sm"
                              value={flashing.oneByColor}
                              onChange={event => {
                                setUnsavedChanges(true);
                                dispatch(
                                  updateFlashingOneByColor(event.target.value)
                                );
                              }}
                            >
                              <option value=""></option>
                              <option value="pine">Pine</option>
                              <option value="azek">Azek</option>
                              <option value="smart-side">Smart Side</option>
                            </Select>
                          </HStack> */}
                        </HStack>
                      </>
                    )}
                  </Box>
                  {/* <Divider mt="2" mb="2" /> */}
                  <Box
                    border={isMobileDevice ? '' : '1px solid #e2e8f0'}
                    borderRadius="md"
                    padding={isMobileDevice ? '0' : '3'}
                  >
                    <HStack
                      spacing={isMobileDevice ? '10px' : '50px'}
                      align="start"
                    >
                      <Text as="b" fontSize={isMobileDevice ? 'xs' : 'med'}>
                        {isMobileDevice ? 'Dormer' : 'Dormer Flashing'}
                      </Text>
                      <Button
                        size={isMobileDevice ? 'xs' : 'sm'}
                        colorScheme="blue"
                        variant={
                          flashing.dormerFlashing === 'none'
                            ? 'solid'
                            : 'outline'
                        }
                        onClick={() => {
                          setUnsavedChanges(true);
                          if (flashing.dormerFlashing === 'none') {
                            dispatch(updateFlashingDormerFlashing(''));
                          } else {
                            dispatch(updateFlashingDormerFlashing('none'));
                            dispatch(updateFlashingDormerFlashingLength(''));
                            dispatch(
                              updateFlashingDormerFlashingResetSidingLength('')
                            );
                            dispatch(
                              updateFlashingDormerFlashingResetSiding(false)
                            );
                          }
                        }}
                      >
                        <Text fontSize={isMobileDevice ? 'xs' : 'sm'}>
                          None
                        </Text>
                      </Button>
                      {flashing.dormerFlashing !== 'none' && (
                        <>
                          <VStack>
                            <HStack>
                              <RadioGroup
                                value={flashing.dormerFlashing}
                                onChange={value => {
                                  setUnsavedChanges(true);
                                  dispatch(updateFlashingDormerFlashing(value));
                                }}
                              >
                                <Stack
                                  direction="row"
                                  gap={isMobileDevice ? '2' : '5'}
                                  mr={isMobileDevice ? '5' : '10'}
                                >
                                  {/* <Radio value="none">None</Radio> */}
                                  <Radio value="reuse">
                                    <Text
                                      fontSize={isMobileDevice ? 'xs' : 'med'}
                                    >
                                      Re-use
                                    </Text>
                                  </Radio>
                                  <Radio value="install-new">
                                    <Text
                                      fontSize={isMobileDevice ? 'xs' : 'med'}
                                    >
                                      Install New
                                    </Text>
                                  </Radio>
                                </Stack>
                              </RadioGroup>
                              <HStack>
                                <Text fontSize={isMobileDevice ? 'xs' : 'med'}>
                                  {isMobileDevice ? 'LF' : 'Linear Feet'}
                                </Text>
                                <NumberInput
                                  size={isMobileDevice ? 'xs' : 'sm'}
                                  width={isMobileDevice ? '60px' : '100px'}
                                  value={flashing.dormerFlashingLength ?? ''}
                                  onChange={value => {
                                    setUnsavedChanges(true);
                                    dispatch(
                                      updateFlashingDormerFlashingLength(value)
                                    );
                                  }}
                                >
                                  <NumberInputField />
                                </NumberInput>
                              </HStack>
                            </HStack>
                            {/* <HStack>
                            <Text>Color:</Text>
                            <Select
                              size="sm"
                              value={flashing.dormerFlashingColor}
                              onChange={event => {
                                setUnsavedChanges(true);
                                dispatch(
                                  updateFlashingDormerFlashingColor(
                                    event.target.value
                                  )
                                );
                              }}
                            >
                              <option value=""></option>
                              <option value="brown">Brown</option>
                              <option value="black">Black</option>
                              <option value="galvanized">Galvanized</option>
                              <option value="gray">Gray</option>
                            </Select>
                          </HStack> */}
                            <HStack width="100%" justify="end">
                              <HStack mr="10">
                                <Checkbox
                                  isChecked={flashing.dormerFlashingResetSiding}
                                  onChange={event => {
                                    setUnsavedChanges(true);
                                    if (event.target.checked === true) {
                                      dispatch(
                                        updateFlashingDormerFlashingResetSidingLength(
                                          flashing.dormerFlashingLength
                                        )
                                      );
                                    } else {
                                      dispatch(
                                        updateFlashingDormerFlashingResetSidingLength(
                                          ''
                                        )
                                      );
                                    }
                                    dispatch(
                                      updateFlashingDormerFlashingResetSiding(
                                        event.target.checked
                                      )
                                    );
                                  }}
                                ></Checkbox>
                                <Text fontSize={isMobileDevice ? 'xs' : 'med'}>
                                  Detach/Reset Siding
                                </Text>
                              </HStack>
                              <HStack>
                                <Text fontSize={isMobileDevice ? 'xs' : 'med'}>
                                  {isMobileDevice ? 'LF' : 'Linear Feet'}
                                </Text>
                                <NumberInput
                                  size={isMobileDevice ? 'xs' : 'sm'}
                                  width={isMobileDevice ? '60px' : '100px'}
                                  value={
                                    flashing.dormerFlashingResetSidingLength ??
                                    ''
                                  }
                                  onChange={value => {
                                    setUnsavedChanges(true);
                                    dispatch(
                                      updateFlashingDormerFlashingResetSidingLength(
                                        value
                                      )
                                    );
                                  }}
                                >
                                  <NumberInputField />
                                </NumberInput>
                              </HStack>
                            </HStack>
                          </VStack>
                        </>
                      )}
                    </HStack>
                  </Box>
                </Flex>
              </Box>
            </ModalBody>
            <ModalFooter>
              {/* {unsavedChanged && (
                <Box
                  width="30%"
                  mr="200px"
                  mb="10px"
                  position="fixed"
                  bottom="10px"
                  right="10px"
                  backgroundColor="white"
                  p="10px"
                >
                  <Alert status="warning">
                    <AlertIcon />
                    <AlertTitle>You have unsaved changes!</AlertTitle>
                  </Alert>
                </Box>
              )}
              <Box
                position="fixed"
                bottom="10px" // Adjust the positioning as per your requirement
                right="10px" // Adjust the positioning as per your requirement
                // zIndex="999" // Set a higher z-index to ensure the button stays on top of other content
                backgroundColor="white" // Set the background color for the container
                padding="1rem" // Add padding to create some space around the button
                borderRadius="md" // Add border radius for rounded corners
                // ml="60px"
              >
                <Button onClick={handleSave} colorScheme="blue" ml="5px">
                  {saveLoading ? (
                    <Spinner
                      thickness="4px"
                      speed="1.25s"
                      size="md"
                      emptyColor="gray.200"
                      color="gray.800"
                    />
                  ) : (
                    <Text>Save</Text>
                  )}
                </Button>
              </Box> */}
              {unsavedChanged && (
                <Box>
                  <Alert status="warning" maxWidth="sm">
                    <AlertIcon />
                    <AlertTitle>You have unsaved changes!</AlertTitle>
                  </Alert>
                </Box>
              )}
              <Button onClick={handleSave} colorScheme="blue" ml="60px">
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </Box>
  );
};

export default Flashing;
