import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  NumberInput,
  NumberInputField,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  useToast,
  Center,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateStarterStripField,
  updateHasBeenSaved,
} from '../../features/price/priceSlice';
import axios from 'axios';
import useUser from '../../hooks/useUser';
import mixpanel from 'mixpanel-browser';

const StarterStrip = ({ checkStarterStripCompletion }) => {
  const dispatch = useDispatch();
  const starterStrip = useSelector(state => state.price.starterStrip);
  const toast = useToast();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const { user } = useUser();
  const savedList = useSelector(state => state.price.hasBeenSaved);

  const handleSave = async () => {
    try {
      // if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
      //   mixpanel.track('User saved the Starter Strip prices.');
      // }
      const token = await user.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/prices/starterStrip`,
        {
          username: user.email,
          starterStrip,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );

      dispatch(updateHasBeenSaved('starterStrip'));
      setUnsavedChanges(false);
      checkStarterStripCompletion(starterStrip, [...savedList, 'starterStrip']);
    } catch (error) {
      console.log('error: ' + error);
    }

    toast({
      title: 'Changes saved',
      description: 'Your pricing was updated.',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Box>
      <Table>
        <Thead>
          <Tr>
            <Th fontSize="xs">Manufacturer</Th>
            <Th fontSize="xs">Type</Th>
            <Th fontSize="xs">Line</Th>
            <Th fontSize="xs">Unit Description</Th>
            <Th fontSize="xs">Measurement (LF)</Th>
            <Th fontSize="xs">Price per unit</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>
              <Input
                value={starterStrip.manufacturer}
                onChange={event => {
                  setUnsavedChanges(true);
                  dispatch(
                    updateStarterStripField({
                      newValue: event.target.value,
                      fieldToUpdate: 'manufacturer',
                    })
                  );
                }}
              />
            </Td>
            <Td>
              <Input
                value={starterStrip.starterStripType}
                onChange={event => {
                  setUnsavedChanges(true);
                  dispatch(
                    updateStarterStripField({
                      newValue: event.target.value,
                      fieldToUpdate: 'starterStripType',
                    })
                  );
                }}
              />
            </Td>
            <Td>
              <Input
                value={starterStrip.line}
                onChange={event => {
                  setUnsavedChanges(true);
                  dispatch(
                    updateStarterStripField({
                      newValue: event.target.value,
                      fieldToUpdate: 'line',
                    })
                  );
                }}
              />
            </Td>
            <Td>
              <Input
                value={starterStrip.description}
                onChange={event => {
                  setUnsavedChanges(true);
                  dispatch(
                    updateStarterStripField({
                      newValue: event.target.value,
                      fieldToUpdate: 'description',
                    })
                  );
                }}
              />
            </Td>
            <Td>
              <NumberInput
                value={starterStrip.unit ?? ''}
                onChange={value => {
                  setUnsavedChanges(true);
                  dispatch(
                    updateStarterStripField({
                      newValue: value,
                      fieldToUpdate: 'unit',
                    })
                  );
                }}
              >
                <NumberInputField />
              </NumberInput>
            </Td>
            <Td>
              <NumberInput
                value={starterStrip.pricePer ?? ''}
                onChange={value => {
                  setUnsavedChanges(true);
                  dispatch(
                    updateStarterStripField({
                      newValue: value,
                      fieldToUpdate: 'pricePer',
                    })
                  );
                }}
              >
                <NumberInputField />
              </NumberInput>
            </Td>
          </Tr>
        </Tbody>
      </Table>
      <Center>
        <VStack>
          {unsavedChanges && (
            <Alert status="warning" marginTop="5">
              <AlertIcon />
              <AlertTitle>You have unsaved changes!</AlertTitle>
            </Alert>
          )}
          {!savedList.includes('starterStrip') && (
            <Alert status="info" marginTop="5">
              <AlertIcon />
              <AlertTitle>Review and Save</AlertTitle>
            </Alert>
          )}
          <Button colorScheme="blue" marginTop="3" onClick={handleSave}>
            Save
          </Button>
        </VStack>
      </Center>
    </Box>
  );
};

export default StarterStrip;
