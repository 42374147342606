import {
  SimpleGrid,
  Button,
  Center,
  Text,
  VStack,
  Alert,
  Flex,
  AlertIcon,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertTitle,
  useDisclosure,
  Spinner,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
  AlertDescription,
  Box,
  Stat,
  StatLabel,
  StatNumber,
  HStack,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  useToast,
  IconButton,
} from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useMediaQuery } from '@chakra-ui/react';
import { FaWrench } from 'react-icons/fa';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateDumpsterPlacement,
  updateRoofOver,
  updateNewConstruction,
  updateTearOff,
  updateWastePercent,
  updateRoofType,
  updateDifficultDumpsterAccess,
  updateCloseNeighboringHomes,
  updateIsGeneralCompleted,
  updateRidgeLF,
  updateValleysLF,
  updateDormerFlashingLF,
  updateEavesLF,
  updateRakeEdgesLF,
  updateCounterFlashingLF,
  updateHipsLF,
  updateStepFlashingLF,
  updateEaseOverhangInches,
  updateIsMeasurementsCompleted,
  updateDripEdge,
  updateDripEdgeIsCompleted,
  updateGutters,
  updateGuttersIsCompleted,
  updateDetails,
  updateDetailsIsCompleted,
  updateBase,
  updateBaseIsCompleted,
  updateDecking,
  updateDeckingIsCompleted,
  updateFlashing,
  updateFlashingIsCompleted,
  updateFeatures,
  updateFeaturesIsCompleted,
  updateVenting,
  updateVentingIsCompleted,
  updateLoading,
  updateJobName,
  updateAddressLineOne,
  updateAddressLineTwo,
  reset,
  updateDiscountPercent,
  updateFinanceOption,
  updatePaymentOptionOne,
  updatePaymentOptionCash,
  updatePaymentOptionThree,
  updatePaymentOptionSeven,
  updateProfitMargin,
  updatePermitFee,
  updatePermitFeePercent,
  updatePermitFeeFlat,
  updateRoofNumber,
} from '../features/calculator/calculatorSlice';
import { updateAdditional, updateLabor } from '../features/labor/laborSlice';
import { updatePrice } from '../features/price/priceSlice';
import {
  loadOptions,
  updateGeneralPayment,
} from '../features/labor/paymentSlice';
import General from './General';
import Measurements from './Measurements';
import Accessories from './Accessories';
import Details from './Details';
import Base from './Base';
import Flashing from './Flashing';
import Actions from './Actions';
import Features from './Features';
import Venting from './Venting';
import Decking from './Decking';
import Gutters from './Gutters';
import Calculate from './Calculate';
import LogoutButton from './LogoutButton';
import React from 'react';
import useUser from '../hooks/useUser';
import mixpanel from 'mixpanel-browser';
import { useNavigate } from 'react-router-dom';
import { ViewIcon, PlusSquareIcon } from '@chakra-ui/icons';

const Estimator = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const {
    isOpen: isClearOpen,
    onOpen: onClearOpen,
    onClose: onClearClose,
  } = useDisclosure();
  const {
    isOpen: isSendOpen,
    onOpen: onSendOpen,
    onClose: onSendClose,
  } = useDisclosure();
  const toast = useToast();
  const dispatch = useDispatch();
  const cancelClearRef = React.useRef();
  const cancelSendRef = React.useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [isSendLoading, setIsSendLoading] = useState(false);
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFeaturesComplete, setIsFeaturesComplete] = useState(false);
  const [isVentingComplete, setIsVentingComplete] = useState(false);
  const [isFlashingComplete, setIsFlashingComplete] = useState(false);
  const [isDeckingComplete, setIsDeckingComplete] = useState(false);
  const [isBaseComplete, setIsBaseComplete] = useState(false);
  const [isDetailsComplete, setIsDetailsComplete] = useState(false);
  const [isGuttersComplete, setIsGuttersComplete] = useState(false);
  const [isAccessoriesComplete, setIsAccessoriesComplete] = useState(false);
  const [isMeasurementsComplete, setIsMeasurementsComplete] = useState(false);
  const [isGeneralComplete, setIsGeneralComplete] = useState(false);
  const [jobName, setJobName] = useState('---');
  const [roofNumber, setRoofNumber] = useState('');
  const [availableDiscount, setAvailableDiscount] = useState(0);
  const [availableDollarDiscount, setAvailableDollarDiscount] =
    useState('$00.00');
  const [appliedDiscount, setAppliedDiscount] = useState(0);
  const [appliedDollarDiscount, setAppliedDollarDiscount] = useState(0);
  const [isGeneralLocked, setIsGeneralLocked] = useState(false);
  const [isGeneralLockedList, setIsGeneralLockedList] = useState([]);
  const [isDripEdgeLocked, setIsDripEdgeLocked] = useState(false);
  const [isDripEdgeLockedList, setIsDripEdgeLockedList] = useState([]);
  const [isGuttersLocked, setIsGuttersLocked] = useState(false);
  const [isGuttersLockedList, setIsGuttersLockedList] = useState([]);
  const [isBaseLocked, setIsBaseLocked] = useState(false);
  const [isBaseLockedList, setIsBaseLockedList] = useState([]);
  const [isDeckingLocked, setIsDeckingLocked] = useState(false);
  const [isDeckingLockedList, setIsDeckingLockedList] = useState([]);
  const [totalPrice, setTotalPrice] = useState('$00.00');
  const [grossProfit, setGrossProfit] = useState('$00.00');
  const [overhead, setOverhead] = useState('$00.00');
  const [netProfit, setNetProfit] = useState('$00.00');
  const [afterTaxNetProfit, setAfterTaxNetProfit] = useState('$00.00');
  const [margin, setMargin] = useState('$00.00');
  const [ifRedeckRequired, setIfRedeckRequired] = useState('$00.00');
  const [marginPercent, setMarginPercent] = useState('00.00%');
  const [salesCommission, setSalesCommission] = useState('$00.00');
  const [afterTaxSalesCommission, setAfterTaxSalesCommission] =
    useState('$00.00');
  const [companyNet, setCompanyNet] = useState('$00.00');
  const [pricePerSq, setPricePerSq] = useState('$00.00');
  const [afterTaxCompanyNet, setAfterTaxCompanyNet] = useState('$00.00');
  const [grossProfitPercent, setGrossProfitPercent] = useState('00.00%');
  const [overheadPercent, setOverheadPercent] = useState('00.00%');
  const [netProfitPercent, setNetProfitPercent] = useState('00.00%');
  const [afterTaxNetProfitPercent, setAfterTaxNetProfitPercent] =
    useState('00.00%');
  const [salesCommissionPercent, setSalesCommissionPercent] =
    useState('00.00%');
  const [afterTaxSalesCommissionPercent, setAfterTaxSalesCommissionPercent] =
    useState('00.00%');
  const [companyNetPercent, setCompanyNetPercent] = useState('00.00%');
  const [afterTaxCompanyNetPercent, setAfterTaxCompanyNetPercent] =
    useState('00.00%');
  const [calculateButtonClicked, setCalculateButtonClicked] = useState(false);
  const [isCalculateLoading, setIsCalculateLoading] = useState(false);
  const [pricePerMonth, setPricePerMonth] = useState('$00.00');
  const financeOption = useSelector(state => state.calculator.financeOption);
  const oneYearDescription = useSelector(
    state => state.payment.options.oneYearDescription
  );
  const threeYearDescription = useSelector(
    state => state.payment.options.threeYearDescription
  );
  const sevenYearDescription = useSelector(
    state => state.payment.options.sevenYearDescription
  );
  const cashDescription = useSelector(
    state => state.payment.options.cashDescription
  );

  const [totalSquares, setTotalSquares] = useState(0);
  const [totalSquaresWithWaste, setTotalSquaresWithWaste] = useState(0);
  const roofList = useSelector(state => state.calculator.details.roofList);
  const wastePercent = useSelector(state => state.calculator.wastePercent);

  function roundThree(number) {
    return parseFloat(number).toFixed(3);
  }

  const calculateTotalSquares = () => {
    setTotalSquares(0);
    let temp = 0;
    for (const tempRoof of roofList) {
      if (tempRoof.squares != '' && tempRoof.squares != null) {
        temp += parseFloat(tempRoof.squares);
      }
    }
    setTotalSquares(roundThree(temp));
    setTotalSquaresWithWaste(roundThree(temp + (temp * wastePercent) / 100));
  };

  useEffect(() => {
    calculateTotalSquares();
  }, [roofList, wastePercent]);

  const convertUSDToNumber = input => {
    const beginning = input.slice(0, -3);
    const end = input.slice(-3);
    const filteredBeginning = beginning.replace(/\D/g, '');
    const returnNumber = parseFloat(filteredBeginning + end);
    return returnNumber;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      dispatch(updateLoading(true));
      try {
        const token = await user.getIdToken();
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/roofs`,
          {
            headers: {
              authorization: `${token}`,
            },
            params: {
              username: user.email,
            },
          }
        );
        if (
          response.data.general?.wastePercent ||
          response.data.general?.wastePercent === 0
        ) {
          dispatch(updateWastePercent(response.data.general.wastePercent));
        }
        if (response.data.general?.roofType) {
          dispatch(updateRoofType(response.data.general.roofType));
        }
        if (response.data.general?.dumpsterPlacement) {
          dispatch(
            updateDumpsterPlacement(response.data.general.dumpsterPlacement)
          );
        }
        if (response.data.general?.jobName) {
          dispatch(updateJobName(response.data.general.jobName));
          setJobName(response.data.general.jobName);
        }
        if (response.data.general?.addressLineOne) {
          dispatch(updateAddressLineOne(response.data.general.addressLineOne));
        }
        if (response.data.general?.addressLineTwo) {
          dispatch(updateAddressLineTwo(response.data.general.addressLineTwo));
        }
        if (
          response.data?.discountPercent ||
          response.data?.discountPercent === 0
        ) {
          dispatch(updateDiscountPercent(response.data.discountPercent));
        }
        if (response.data?.financeOption) {
          dispatch(updateFinanceOption(response.data.financeOption));
        }

        if (response.data.general?.roofOver) {
          dispatch(updateRoofOver());
        }
        if (response.data.general?.tearOff) {
          dispatch(updateTearOff());
        }
        if (response.data.general?.newConstruction) {
          dispatch(updateNewConstruction());
        }
        if (response.data.general?.hasPaymentOptionCash) {
          dispatch(updatePaymentOptionCash(true));
        }
        if (response.data.general?.hasPaymentOptionOne) {
          dispatch(updatePaymentOptionOne(true));
        }
        if (response.data.general?.hasPaymentOptionThree) {
          dispatch(updatePaymentOptionThree(true));
        }
        if (response.data.general?.hasPaymentOptionSeven) {
          dispatch(updatePaymentOptionSeven(true));
        }
        if (response.data.general?.difficultDumpsterAccess != null) {
          dispatch(
            updateDifficultDumpsterAccess(
              response.data.general.difficultDumpsterAccess
            )
          );
        }
        if (response.data.general?.closeNeighboringHomes != null) {
          dispatch(
            updateCloseNeighboringHomes(
              response.data.general.closeNeighboringHomes
            )
          );
        }
        if (response.data?.profitMargin != null) {
          dispatch(updateProfitMargin(response.data?.profitMargin));
        }
        if (response.data.general?.permitFee) {
          dispatch(updatePermitFee(response.data.general?.permitFee));
        }
        if (response.data?.roofNumber) {
          dispatch(updateRoofNumber(response.data?.roofNumber));
          setRoofNumber('#' + response.data?.roofNumber.toString());
        }
        if (response.data.general?.permitFeePercent != null) {
          dispatch(
            updatePermitFeePercent(response.data.general?.permitFeePercent)
          );
        }
        if (response.data.general?.permitFeeFlat != null) {
          dispatch(updatePermitFeeFlat(response.data.general?.permitFeeFlat));
        }
        if (response.data.general?.isLocked) {
          setIsGeneralLocked(response.data.general.isLocked);
        }
        if (response.data.general?.isLockedList) {
          setIsGeneralLockedList(response.data.general.isLockedList);
        }
        if (response.data?.discountPercent) {
          setAppliedDiscount(response.data.discountPercent);
        }
        if (response.data?.discountDollar) {
          setAppliedDollarDiscount(response.data.discountDollar);
        }
        checkGeneralCompletion(response.data?.general);
        if (
          response.data.measurements?.ridgeLF ||
          response.data.measurements?.ridgeLF === 0
        ) {
          dispatch(updateRidgeLF(response.data.measurements.ridgeLF));
        }
        if (
          response.data.measurements?.valleysLF ||
          response.data.measurements?.valleysLF === 0
        ) {
          dispatch(updateValleysLF(response.data.measurements.valleysLF));
        }
        if (
          response.data.measurements?.dormerFlashingLF ||
          response.data.measurements?.dormerFlashingLF === 0
        ) {
          dispatch(
            updateDormerFlashingLF(response.data.measurements.dormerFlashingLF)
          );
        }
        if (
          response.data.measurements?.eavesLF ||
          response.data.measurements?.eavesLF === 0
        ) {
          dispatch(updateEavesLF(response.data.measurements.eavesLF));
        }
        if (
          response.data.measurements?.rakeEdgesLF ||
          response.data.measurements?.rakeEdgesLF === 0
        ) {
          dispatch(updateRakeEdgesLF(response.data.measurements.rakeEdgesLF));
        }
        if (
          response.data.measurements?.counterFlashingLF ||
          response.data.measurements?.counterFlashingLF === 0
        ) {
          dispatch(
            updateCounterFlashingLF(
              response.data.measurements.counterFlashingLF
            )
          );
        }
        if (
          response.data.measurements?.hipsLF ||
          response.data.measurements?.hipsLF === 0
        ) {
          dispatch(updateHipsLF(response.data.measurements.hipsLF));
        }
        if (
          response.data.measurements?.stepFlashingLF ||
          response.data.measurements?.stepFlashingLF === 0
        ) {
          dispatch(
            updateStepFlashingLF(response.data.measurements.stepFlashingLF)
          );
        }
        if (
          response.data.measurements?.easeOverhangInches ||
          response.data.measurements?.easeOverhangInches === 0
        ) {
          dispatch(
            updateEaseOverhangInches(
              response.data.measurements.easeOverhangInches
            )
          );
        }
        checkMeasurementsCompletion(response.data?.measurements);
        if (response.data.dripEdge) {
          dispatch(updateDripEdge(response.data.dripEdge));
        }
        if (response.data.dripEdge?.isLocked) {
          setIsDripEdgeLocked(response.data.dripEdge.isLocked);
        }
        if (response.data.dripEdge?.isLockedList) {
          setIsDripEdgeLockedList(response.data.dripEdge.isLockedList);
        }
        checkAccessoriesCompletion(response.data?.dripEdge);
        if (response.data.gutters) {
          dispatch(updateGutters(response.data.gutters));
        }
        if (response.data.gutters?.isLocked) {
          setIsGuttersLocked(response.data.gutters.isLocked);
        }
        if (response.data.gutters?.isLockedList) {
          setIsGuttersLockedList(response.data.gutters.isLockedList);
        }
        checkGuttersCompletion(response.data?.gutters);
        if (response.data.details) {
          dispatch(updateDetails(response.data.details));
        }
        checkDetailsCompletion(response.data?.details);
        if (response.data.base) {
          dispatch(updateBase(response.data.base));
        }
        if (response.data.base?.isLocked) {
          setIsBaseLocked(response.data.base.isLocked);
        }
        if (response.data.base?.isLockedList) {
          setIsBaseLockedList(response.data.base.isLockedList);
        }
        checkBaseCompletion(response.data?.base);
        if (response.data.decking) {
          dispatch(updateDecking(response.data.decking));
        }
        if (response.data.decking?.isLocked) {
          setIsDeckingLocked(response.data.decking.isLocked);
        }
        if (response.data.decking?.isLockedList) {
          setIsDeckingLockedList(response.data.decking.isLockedList);
        }
        checkDeckingCompletion(response.data?.decking);
        if (response.data.flashing) {
          dispatch(updateFlashing(response.data.flashing));
        }
        checkFlashingCompletion(response.data?.flashing);
        if (response.data.features) {
          dispatch(updateFeatures(response.data.features));
        }
        checkFeaturesCompletion(response.data?.features);
        if (response.data.venting) {
          dispatch(updateVenting(response.data.venting));
        }
        checkVentingCompletion(response.data?.venting);
        if (response.data?.additional?.hipWasteAllowance > 0) {
          dispatch(
            updateAdditional({
              newValue: response.data.additional.hipWasteAllowance,
              fieldToUpdate: 'hipWasteAllowance',
            })
          );
        }
        if (response.data?.additional?.gableWasteAllowance > 0) {
          dispatch(
            updateAdditional({
              newValue: response.data.additional.gableWasteAllowance,
              fieldToUpdate: 'gableWasteAllowance',
            })
          );
        }
        if (response.data?.options) {
          dispatch(loadOptions(response.data.options));
        }
        if (response.data?.labor?.profitMargin) {
          dispatch(
            updateGeneralPayment({
              newValue: response.data.labor.profitMargin,
              fieldToUpdate: 'profitMargin',
            })
          );
        }
        if (response.data?.price?.hasBeenSaved?.length > 0) {
          dispatch(
            updatePrice({ hasBeenSaved: response.data.price.hasBeenSaved })
          );
        }
        if (response.data?.labor?.hasBeenSaved?.length > 0) {
          dispatch(
            updateLabor({ hasBeenSaved: response.data.labor.hasBeenSaved })
          );
        }
        dispatch(updateLoading(false));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setIsLoading(false);
    };
    if (user) {
      if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
        mixpanel.track('User loaded the Estimator page.');
      }
      fetchData();
    }
  }, [user]);

  const checkAccessoriesCompletion = dripEdge => {
    let isComplete;
    if (
      dripEdge &&
      (dripEdge.hasNone || dripEdge.isReuse || dripEdge.isInstallNew) &&
      // dripEdge.fasciaColor !== '' &&
      // dripEdge.fasciaColor != null &&
      dripEdge.hasEaves != null &&
      dripEdge.hasRakeEdges != null
    ) {
      isComplete = true;
    } else {
      isComplete = false;
    }
    setIsAccessoriesComplete(isComplete);
  };

  const checkMeasurementsCompletion = measurements => {
    let isComplete;
    if (
      measurements &&
      measurements.ridgeLF != null &&
      measurements.valleysLF != null &&
      measurements.dormerFlashingLF != null &&
      measurements.eavesLF != null &&
      measurements.rakeEdgesLF != null &&
      measurements.counterFlashingLF != null &&
      measurements.hipsLF != null &&
      measurements.stepFlashingLF != null &&
      // measurements.easeOverhangInches != null &&
      measurements.ridgeLF !== '' &&
      measurements.valleysLF !== '' &&
      measurements.dormerFlashingLF !== '' &&
      measurements.eavesLF !== '' &&
      measurements.rakeEdgesLF !== '' &&
      measurements.counterFlashingLF !== '' &&
      measurements.hipsLF !== '' &&
      measurements.stepFlashingLF !== ''
      // && measurements.easeOverhangInches !== ''
    ) {
      isComplete = true;
    } else {
      isComplete = false;
    }
    setIsMeasurementsComplete(isComplete);
  };

  const checkGeneralCompletion = general => {
    let isComplete;
    if (
      general &&
      general.jobName !== '' &&
      general.jobName != null &&
      // general.financeOption !== '' &&
      // general.financeOption != null &&
      // general.discountPercent !== '' &&
      // general.discountPercent != null &&
      general.addressLineOne !== '' &&
      general.addressLineOne != null &&
      general.addressLineTwo !== '' &&
      general.addressLineTwo != null &&
      general.roofType !== '' &&
      general.roofType != null &&
      general.wastePercent !== '' &&
      general.wastePercent != null &&
      general.newConstruction !== '' &&
      general.newConstruction != null &&
      general.tearOff !== '' &&
      general.tearOff != null &&
      general.roofOver !== '' &&
      general.roofOver != null &&
      general.dumpsterPlacement !== '' &&
      general.difficultDumpsterAccess != null &&
      general.closeNeighboringHomes !== '' &&
      general.closeNeighboringHomes != null &&
      general.permitFee !== '' &&
      general.permitFee != null &&
      (general.permitFee === 'none' ||
        (general.permitFee === 'percent' &&
          general.permitFeePercent !== '' &&
          general.permitFeePercent !== null) ||
        (general.permitFee === 'flat' &&
          general.permitFeeFlat !== '' &&
          general.permitFeeFlat !== null))
    ) {
      isComplete = true;
    } else {
      isComplete = false;
    }
    setIsGeneralComplete(isComplete);
  };

  const checkGuttersCompletion = gutters => {
    let isComplete;
    if (
      gutters &&
      // gutters.hasExistingAprons != null &&
      gutters.isReset != null &&
      (gutters.isReset === true
        ? gutters.resetLength != null && gutters.resetLength !== ''
        : true) &&
      gutters.isRemoval != null &&
      (gutters.isRemoval === true
        ? gutters.removalLength != null && gutters.removalLength !== ''
        : true) &&
      // gutters.newApronsColor !== '' &&
      gutters.hasNewAprons != null
    ) {
      isComplete = true;
    } else {
      isComplete = false;
    }
    setIsGuttersComplete(isComplete);
  };

  const checkDetailsCompletion = details => {
    let isComplete;

    if (details && details.roofList.length >= 1) {
      isComplete = details.roofList.every(
        roof => roof.squares && roof.pitch && roof.stories && roof.layers
      );
    } else {
      isComplete = false;
    }
    setIsDetailsComplete(isComplete);
  };

  const checkBaseCompletion = base => {
    let isComplete;
    if (
      base &&
      base.hasEaves != null &&
      base.coursesFromEave != null &&
      base.coursesFromEave !== '' &&
      base.eavesLengthNot != null &&
      base.eavesLengthNot !== '' &&
      base.hasValleys != null &&
      base.hasHipsAndRidges != null &&
      base.hasRakeEdges != null &&
      base.hasSidewall != null &&
      base.additionalLength != null &&
      base.additionalLength !== '' &&
      base.additionalSqFt != null &&
      base.additionalSqFt !== '' &&
      base.starterStrip
    ) {
      isComplete = true;
    } else if (base && base.iceWaterShield && base.starterStrip) {
      isComplete = true;
    } else {
      isComplete = false;
    }
    setIsBaseComplete(isComplete);
  };

  const checkDeckingCompletion = decking => {
    let isComplete;
    if (
      decking &&
      decking.existing &&
      decking.existingThickness &&
      decking.redeck &&
      decking.redeckThickness &&
      ((decking.numReplacementSheets != null &&
        decking.numReplacementSheets !== '') ||
        (decking.lengthReplacementRoofBoards != null &&
          decking.lengthReplacementRoofBoards !== ''))
    ) {
      isComplete = true;
    } else {
      isComplete = false;
    }
    setIsDeckingComplete(isComplete);
  };

  const checkFlashingCompletion = flashing => {
    let isComplete;
    if (
      flashing &&
      flashing.hasValleyFlashing != null &&
      (flashing.hasValleyFlashing
        ? flashing.valleyFlashingLength != null &&
          flashing.valleyFlashingLength !== '' &&
          flashing.valleyFlashingType
        : // && flashing.valleyFlashingColor
          true) &&
      flashing.stepFlashing &&
      (flashing.stepFlashing !== 'none'
        ? flashing.stepFlashingLength != null &&
          flashing.stepFlashingLength !== ''
        : // && flashing.stepFlashingColor
          true) &&
      flashing.regletFlashing &&
      (flashing.regletFlashing !== 'none'
        ? flashing.regletLength != null && flashing.regletLength !== ''
        : // && flashing.regletColor
          true) &&
      flashing.oneByFourFlashing &&
      (flashing.oneByFourFlashing !== 'none'
        ? flashing.oneByLength != null && flashing.oneByLength !== ''
        : // && flashing.oneByColor
          true) &&
      flashing.dormerFlashing &&
      (flashing.dormerFlashing !== 'none'
        ? flashing.dormerFlashingLength != null &&
          flashing.dormerFlashingLength !== ''
        : // && flashing.dormerFlashingColor
          true)
    ) {
      const tempOne = flashing.chimneyFlashingList.every(
        chimney =>
          chimney.chimneyCount != null &&
          chimney.chimneyCount !== '' &&
          chimney.flashingType &&
          chimney.size &&
          (chimney.isReuse ||
            chimney.isReplace ||
            chimney.isRemoveChimney ||
            chimney.isAddCricket)
      );
      const tempTwo = flashing.pipeFlashingList.every(
        pipe =>
          pipe.pipeCount != null &&
          pipe.pipeCount !== '' &&
          pipe.flashingType &&
          pipe.size &&
          (pipe.isReuse || pipe.isReplace)
      );
      isComplete = tempOne && tempTwo;
    } else {
      isComplete = false;
    }
    setIsFlashingComplete(isComplete);
  };

  const checkVentingCompletion = venting => {
    let isComplete;
    if (
      venting && venting.isNoneVenting
        ? true
        : venting.hasRidgeVent !== '' &&
          venting.hasRidgeVent !== undefined &&
          (venting.hasRidgeVent
            ? venting.ridgeVentLength != null && venting.ridgeVentLength !== ''
            : true) &&
          venting.hasCutIn != null &&
          venting.hasCutIn !== undefined &&
          (venting.hasCutIn
            ? venting.cutInLength != null && venting.cutInLength !== ''
            : true)
    ) {
      const tempOne = venting.roofLouversList.every(
        louver => louver.count !== '' && louver.count != null && louver.action
        // && louver.color
      );
      const tempTwo = venting.turbineVentsList.every(
        turbine =>
          turbine.count != null && turbine.count !== '' && turbine.action
        // && turbine.color
      );
      const tempThree = venting.exhaustVentsList.every(
        exhaust =>
          exhaust.count != null &&
          exhaust.count !== '' &&
          exhaust.action &&
          exhaust.size
      );
      const tempFour = venting.powerVentsList.every(
        power => power.count != null && power.count !== '' && power.action
      );
      const tempFive = venting.midroofIntakeVentsList.every(
        mid => mid.action && mid.length != null && mid.length !== ''
      );
      isComplete = tempOne && tempTwo && tempThree && tempFour && tempFive;
    } else {
      isComplete = false;
    }
    setIsVentingComplete(isComplete);
  };

  const checkFeaturesCompletion = features => {
    let isComplete;
    if (
      features &&
      ((features.skyLightsList && features.skyLightsList.length > 0) ||
        (features.corniceReturnsList &&
          features.corniceReturnsList.length > 0) ||
        (features.bayWindowsList && features.bayWindowsList.length > 0) ||
        (features.roofMountsList && features.roofMountsList.length > 0))
    ) {
      const tempOne = features.skyLightsList.every(
        sky =>
          sky.count != null && sky.count !== '' && sky.lightType && sky.size
      );
      const tempTwo = features.corniceReturnsList.every(
        cornice =>
          cornice.count !== '' &&
          cornice.count != null &&
          cornice.stories &&
          cornice.size &&
          cornice.story
      );
      const tempThree = features.bayWindowsList.every(
        bay =>
          bay.count != null &&
          bay.count !== '' &&
          bay.stories &&
          bay.size &&
          bay.story
      );
      const tempFour = features.roofMountsList.every(
        mount => mount.count != null && mount.count !== '' && mount.mountType
      );
      isComplete = tempOne && tempTwo && tempThree && tempFour;
    } else {
      isComplete = true;
    }
    setIsFeaturesComplete(isComplete);
  };

  const handleCalculateClick = () => {
    // if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    //   mixpanel.track('User clicked the Calculate button.');
    // }
    // setIsModalOpen(true);
    setCalculateButtonClicked(true);
  };

  const handleClickForDetails = () => {
    if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
      mixpanel.track('User clicked to view details.');
    }
    setIsModalOpen(true);
  };

  // const handlePresetClick = async () => {
  //   const token = await user.getIdToken();
  //   await axios.post(
  //     `${process.env.REACT_APP_BACKEND_URL}/api/roofs/preset`,
  //     {
  //       username: user.email,
  //     },
  //     {
  //       headers: {
  //         authorization: `${token}`,
  //       },
  //     }
  //   );

  //   window.location.reload();
  // };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // const onSendConfirm = async () => {
  //   setIsSendLoading(true);
  //   try {
  //     const token = await user.getIdToken();
  //     await axios.post(
  //       `${process.env.REACT_APP_BACKEND_URL}/api/email/send`,
  //       {
  //         username: user.email,
  //       },
  //       {
  //         headers: {
  //           authorization: `${token}`,
  //         },
  //       }
  //     );
  //     toast({
  //       title: 'Success',
  //       description: 'Estimate has been sent to your administrator.',
  //       status: 'success',
  //       duration: 5000,
  //       isClosable: true,
  //     });
  //   } catch (e) {
  //     console.log(e);
  //     toast({
  //       title: 'Error',
  //       description: 'There was an error sending the estimate.',
  //       status: 'error',
  //       duration: 5000,
  //       isClosable: true,
  //     });
  //   }
  //   setIsSendLoading(false);

  //   onSendClose();
  // };

  const onClearConfirm = async () => {
    dispatch(reset());
    onClearClose();
    // if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    //   mixpanel.track('User cleared the estimator.');
    // }
    const token = await user.getIdToken();
    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/roofs/clear`,
      {
        username: user.email,
      },
      {
        headers: {
          authorization: `${token}`,
        },
      }
    );

    window.location.reload();
  };

  useEffect(() => {
    setCalculateButtonClicked(true);
  }, []);

  const convertToUSD = input => {
    return input.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  };

  const handleViewPastEstimates = () => {
    navigate('/history');
  };

  return (
    <Center m="20px">
      {isLoading ? (
        <Center>
          <Spinner
            thickness="7px"
            speed="1.25s"
            size="xl"
            emptyColor="gray.200"
            color="black.500"
            marginTop="200px"
          />
        </Center>
      ) : (
        <VStack width="100%">
          {isLargerThan800 && (
            <HStack
              justify="space-between"
              width="100%"
              align="start"
              pb="10px"
            >
              <Button
                variant="link"
                // colorScheme="purple"
                leftIcon={<ChevronLeftIcon />}
                size="lg"
                onClick={handleViewPastEstimates}
              >
                View Estimates
              </Button>
              <Text as="b" fontSize="lg">
                {jobName != '' ? roofNumber + ' - ' + jobName : '---'}
              </Text>
              <Text></Text>
            </HStack>
          )}
          {isLargerThan800 ? (
            <>
              <SimpleGrid
                spacing={10}
                minChildWidth="225px"
                maxW="1000px"
                m="10px"
                marginBottom="40px"
                marginTop="30px"
              >
                <General
                  isGeneralComplete={isGeneralComplete}
                  checkGeneralCompletion={checkGeneralCompletion}
                  isGeneralLocked={isGeneralLocked}
                  isGeneralLockedList={isGeneralLockedList}
                  setCalculateButtonClicked={setCalculateButtonClicked}
                  isMobileDevice={false}
                  setJobName={setJobName}
                />
                <Measurements
                  checkMeasurementsCompletion={checkMeasurementsCompletion}
                  isMeasurementsComplete={isMeasurementsComplete}
                  setCalculateButtonClicked={setCalculateButtonClicked}
                  isMobileDevice={false}
                />
                <Accessories
                  isAccessoriesComplete={isAccessoriesComplete}
                  checkAccessoriesCompletion={checkAccessoriesCompletion}
                  isDripEdgeLocked={isDripEdgeLocked}
                  isDripEdgeLockedList={isDripEdgeLockedList}
                  setCalculateButtonClicked={setCalculateButtonClicked}
                  isMobileDevice={false}
                />
                <Gutters
                  checkGuttersCompletion={checkGuttersCompletion}
                  isGuttersComplete={isGuttersComplete}
                  isGuttersLocked={isGuttersLocked}
                  isGuttersLockedList={isGuttersLockedList}
                  setCalculateButtonClicked={setCalculateButtonClicked}
                  isMobileDevice={false}
                />
                <Details
                  checkDetailsCompletion={checkDetailsCompletion}
                  isDetailsComplete={isDetailsComplete}
                  setCalculateButtonClicked={setCalculateButtonClicked}
                  totalSquares={totalSquares}
                  totalSquaresWithWaste={totalSquaresWithWaste}
                  isMobileDevice={false}
                />
                <Base
                  checkBaseCompletion={checkBaseCompletion}
                  isBaseComplete={isBaseComplete}
                  isBaseLocked={isBaseLocked}
                  isBaseLockedList={isBaseLockedList}
                  setCalculateButtonClicked={setCalculateButtonClicked}
                  isMobileDevice={false}
                />
                <Decking
                  checkDeckingCompletion={checkDeckingCompletion}
                  isDeckingComplete={isDeckingComplete}
                  isDeckingLocked={isDeckingLocked}
                  isDeckingLockedList={isDeckingLockedList}
                  setCalculateButtonClicked={setCalculateButtonClicked}
                  isMobileDevice={false}
                />
                <Flashing
                  checkFlashingCompletion={checkFlashingCompletion}
                  isFlashingComplete={isFlashingComplete}
                  setCalculateButtonClicked={setCalculateButtonClicked}
                  isMobileDevice={false}
                />
                <Features
                  checkFeaturesCompletion={checkFeaturesCompletion}
                  isFeaturesComplete={isFeaturesComplete}
                  setCalculateButtonClicked={setCalculateButtonClicked}
                  isMobileDevice={false}
                />
                <div></div>
                <Venting
                  checkVentingCompletion={checkVentingCompletion}
                  isVentingComplete={isVentingComplete}
                  setCalculateButtonClicked={setCalculateButtonClicked}
                  isMobileDevice={false}
                />
                <Actions
                  setCalculateButtonClicked={setCalculateButtonClicked}
                  availableDiscount={availableDiscount}
                  isCalculateLoading={isCalculateLoading}
                  marginPercent={marginPercent}
                  totalPrice={totalPrice}
                  availableDollarDiscount={availableDollarDiscount}
                  appliedDiscount={appliedDiscount}
                  setAppliedDiscount={setAppliedDiscount}
                  appliedDollarDiscount={appliedDollarDiscount}
                  setAppliedDollarDiscount={setAppliedDollarDiscount}
                />
              </SimpleGrid>
            </>
          ) : (
            <>
              <VStack pt="30">
                <General
                  isGeneralComplete={isGeneralComplete}
                  checkGeneralCompletion={checkGeneralCompletion}
                  isGeneralLocked={isGeneralLocked}
                  isGeneralLockedList={isGeneralLockedList}
                  setCalculateButtonClicked={setCalculateButtonClicked}
                  isMobileDevice={true}
                />
                <Measurements
                  checkMeasurementsCompletion={checkMeasurementsCompletion}
                  isMeasurementsComplete={isMeasurementsComplete}
                  setCalculateButtonClicked={setCalculateButtonClicked}
                  isMobileDevice={true}
                />
                <Accessories
                  isAccessoriesComplete={isAccessoriesComplete}
                  checkAccessoriesCompletion={checkAccessoriesCompletion}
                  isDripEdgeLocked={isDripEdgeLocked}
                  isDripEdgeLockedList={isDripEdgeLockedList}
                  setCalculateButtonClicked={setCalculateButtonClicked}
                  isMobileDevice={true}
                />
                <Gutters
                  checkGuttersCompletion={checkGuttersCompletion}
                  isGuttersComplete={isGuttersComplete}
                  isGuttersLocked={isGuttersLocked}
                  isGuttersLockedList={isGuttersLockedList}
                  setCalculateButtonClicked={setCalculateButtonClicked}
                  isMobileDevice={true}
                />
                <Details
                  checkDetailsCompletion={checkDetailsCompletion}
                  isDetailsComplete={isDetailsComplete}
                  setCalculateButtonClicked={setCalculateButtonClicked}
                  totalSquares={totalSquares}
                  totalSquaresWithWaste={totalSquaresWithWaste}
                  isMobileDevice={true}
                />
                <Base
                  checkBaseCompletion={checkBaseCompletion}
                  isBaseComplete={isBaseComplete}
                  isBaseLocked={isBaseLocked}
                  isBaseLockedList={isBaseLockedList}
                  setCalculateButtonClicked={setCalculateButtonClicked}
                  isMobileDevice={true}
                />
                <Decking
                  checkDeckingCompletion={checkDeckingCompletion}
                  isDeckingComplete={isDeckingComplete}
                  isDeckingLocked={isDeckingLocked}
                  isDeckingLockedList={isDeckingLockedList}
                  setCalculateButtonClicked={setCalculateButtonClicked}
                  isMobileDevice={true}
                />
                <Flashing
                  checkFlashingCompletion={checkFlashingCompletion}
                  isFlashingComplete={isFlashingComplete}
                  setCalculateButtonClicked={setCalculateButtonClicked}
                  isMobileDevice={true}
                />
                <Features
                  checkFeaturesCompletion={checkFeaturesCompletion}
                  isFeaturesComplete={isFeaturesComplete}
                  setCalculateButtonClicked={setCalculateButtonClicked}
                  isMobileDevice={true}
                />
                <Venting
                  checkVentingCompletion={checkVentingCompletion}
                  isVentingComplete={isVentingComplete}
                  setCalculateButtonClicked={setCalculateButtonClicked}
                  isMobileDevice={true}
                />
              </VStack>
            </>
          )}
          {true && (
            <Box width="100%">
              <HStack spacing="30" align="flex-start" justify="center">
                <Box>
                  <VStack>
                    {/* <Button
                    colorScheme="blue"
                    onClick={handleCalculateClick}
                    size="lg"
                  >
                    Calculate
                  </Button> */}
                    <Calculate
                      isOpen={isModalOpen}
                      setIfRedeckRequired={setIfRedeckRequired}
                      ifRedeckRequired={ifRedeckRequired}
                      onClose={closeModal}
                      setMargin={setMargin}
                      setMarginPercent={setMarginPercent}
                      setTotalPrice={setTotalPrice}
                      totalPrice={totalPrice}
                      setGrossProfit={setGrossProfit}
                      setOverhead={setOverhead}
                      setNetProfit={setNetProfit}
                      setAfterTaxNetProfit={setAfterTaxNetProfit}
                      setSalesCommission={setSalesCommission}
                      setAfterTaxSalesCommission={setAfterTaxSalesCommission}
                      setCompanyNet={setCompanyNet}
                      setAfterTaxCompanyNet={setAfterTaxCompanyNet}
                      setGrossProfitPercent={setGrossProfitPercent}
                      setOverheadPercent={setOverheadPercent}
                      setNetProfitPercent={setNetProfitPercent}
                      setAfterTaxNetProfitPercent={setAfterTaxNetProfitPercent}
                      setSalesCommissionPercent={setSalesCommissionPercent}
                      setAfterTaxSalesCommissionPercent={
                        setAfterTaxSalesCommissionPercent
                      }
                      setCompanyNetPercent={setCompanyNetPercent}
                      setAfterTaxCompanyNetPercent={
                        setAfterTaxCompanyNetPercent
                      }
                      calculateButtonClicked={calculateButtonClicked}
                      setCalculateButtonClicked={setCalculateButtonClicked}
                      setIsCalculateLoading={setIsCalculateLoading}
                      setPricePerMonth={setPricePerMonth}
                      setPricePerSq={setPricePerSq}
                      setAvailableDiscount={setAvailableDiscount}
                      setAvailableDollarDiscount={setAvailableDollarDiscount}
                    />
                  </VStack>
                </Box>
                <VStack>
                  {['expired', 'canceled'].includes(user?.payment) && (
                    <>
                      <Box
                        cursor="pointer"
                        onClick={() => {
                          navigate('/settings');
                        }}
                      >
                        <Alert
                          status="warning"
                          _hover={{
                            bg: 'yellow.200',
                          }}
                        >
                          <AlertIcon />
                          <AlertTitle>Your free trial has expired!</AlertTitle>
                          {user?.profile === 'salesperson' ? (
                            <AlertDescription>
                              Contact your admin to upgrade
                            </AlertDescription>
                          ) : (
                            <AlertDescription>
                              Click here to upgrade
                            </AlertDescription>
                          )}
                        </Alert>
                      </Box>
                    </>
                  )}
                  {user?.payment === 'canceled' && (
                    <>
                      <Box
                        cursor="pointer"
                        onClick={() => {
                          navigate('/settings');
                        }}
                      >
                        <Alert
                          status="warning"
                          _hover={{
                            bg: 'yellow.200',
                          }}
                        >
                          <AlertIcon />
                          <AlertTitle>
                            Your subscription has been canceled!
                          </AlertTitle>
                          <AlertDescription>
                            Click here to upgrade
                          </AlertDescription>
                        </Alert>
                      </Box>
                    </>
                  )}
                  {!isLargerThan800 && (
                    <VStack spacing="30">
                      <Box
                        minW="90vw"
                        mr="6"
                        maxH="70px"
                        borderWidth="1px"
                        borderRadius="lg"
                        overflow="hidden"
                        p="3"
                        boxShadow="base"
                        borderColor="gray.400"
                        bg={!isCalculateLoading ? 'gray.100' : 'gray.600'}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="start"
                        position="relative"
                      >
                        <Text as="b">{totalPrice}</Text>
                        {isCalculateLoading && (
                          <>
                            <Spinner
                              position="absolute"
                              thickness="6px"
                              speed="1.25s"
                              size="lg"
                              top="30%"
                              left="50%"
                              zIndex="1"
                              color="white"
                            />
                          </>
                        )}
                        <Text
                          position="absolute"
                          p="5px"
                          bottom={0}
                          right={0}
                          fontSize="xs"
                        >
                          Use larger screen to view details
                        </Text>
                      </Box>
                    </VStack>
                  )}
                  {['free', 'premium'].includes(user?.payment) &&
                    isLargerThan800 && (
                      <>
                        <Box
                          display="flex"
                          tabIndex={0}
                          onKeyDown={e => {
                            if (e.key === 'Enter') {
                              handleClickForDetails();
                            }
                          }}
                          visibility={false ? 'hidden' : 'visible'}
                          minW="400px"
                          minH="100px"
                          borderWidth="1px"
                          borderRadius="lg"
                          overflow="hidden"
                          py="20px"
                          pr="20px"
                          boxShadow="base"
                          borderColor="gray.400"
                          bg={!isCalculateLoading ? 'gray.100' : 'gray.600'}
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          position="relative"
                        >
                          {!isCalculateLoading && (
                            <VStack justify="flex-start">
                              <HStack
                                align="flex-start"
                                justify="space-between"
                                width="100%"
                              >
                                {convertUSDToNumber(totalPrice) > 0 && (
                                  <VStack align="flex-start">
                                    <HStack>
                                      <Text fontSize="xs">If Redeck</Text>
                                      <Text as="b" fontSize="xs">
                                        +{' '}
                                        {convertUSDToNumber(ifRedeckRequired) >
                                        0
                                          ? ifRedeckRequired
                                          : '$00.00'}
                                      </Text>
                                    </HStack>
                                    <HStack align="flex-start">
                                      <VStack spacing="0px">
                                        <Text fontSize="xs">Total Sqs</Text>
                                        <Text fontSize="10px">(w/ waste)</Text>
                                      </VStack>
                                      <Text as="b" fontSize="xs">
                                        {totalSquaresWithWaste}
                                      </Text>
                                    </HStack>
                                    <HStack>
                                      <Text fontSize="xs">Price per Sq</Text>
                                      <Text as="b" fontSize="xs">
                                        {pricePerSq}
                                      </Text>
                                    </HStack>
                                  </VStack>
                                )}
                                <VStack align="flex-end" spacing="5px">
                                  <Text as="b" fontSize="xl">
                                    {totalPrice}
                                  </Text>
                                  {convertUSDToNumber(pricePerMonth) > 0 && (
                                    <Text
                                      fontSize="sm"
                                      as="i"
                                      whiteSpace="nowrap"
                                    >
                                      {pricePerMonth} / mo.
                                    </Text>
                                  )}
                                  {convertUSDToNumber(totalPrice) > 0 && (
                                    <Text
                                      fontSize="sm"
                                      as="i"
                                      whiteSpace="nowrap"
                                    >
                                      {financeOption === 'cash'
                                        ? cashDescription
                                        : financeOption === 'one'
                                        ? oneYearDescription
                                        : financeOption === 'three'
                                        ? threeYearDescription
                                        : financeOption === 'seven'
                                        ? sevenYearDescription
                                        : cashDescription}
                                    </Text>
                                  )}
                                </VStack>
                              </HStack>
                              {false && (
                                <>
                                  <Alert status="warning" size="sm">
                                    <AlertIcon boxSize="12px" />
                                    <AlertTitle>
                                      <Text fontSize="xs">
                                        Price is outdated!
                                      </Text>
                                    </AlertTitle>
                                    <AlertDescription>
                                      <Text fontSize="xs">
                                        Press Calculate to update.
                                      </Text>
                                    </AlertDescription>
                                  </Alert>
                                </>
                              )}
                              {convertUSDToNumber(totalPrice) > 0 && (
                                <TableContainer pt="15px" pr="30px">
                                  <Table size="sm" variant="unstyled">
                                    <Tbody>
                                      {!user?.isRestricted && (
                                        <>
                                          <Tr>
                                            <Td>
                                              <Text fontSize="sm" as="b">
                                                Gross Profit
                                              </Text>
                                            </Td>
                                            <Td>
                                              <Text fontSize="sm" as="b">
                                                {margin}
                                              </Text>
                                            </Td>
                                            <Td fontSize="xs">
                                              {marginPercent}
                                            </Td>
                                          </Tr>
                                          <Tr>
                                            <Td fontSize="xs">Overhead</Td>
                                            <Td fontSize="xs">{overhead}</Td>
                                            <Td fontSize="xs">
                                              {overheadPercent}
                                            </Td>
                                          </Tr>
                                          <Tr
                                            borderBottomColor="black"
                                            borderBottomWidth="1.5px"
                                          >
                                            <Td>
                                              <Text fontSize="sm" as="b">
                                                Net Profit
                                              </Text>
                                            </Td>
                                            <Td>
                                              <Text fontSize="sm" as="b">
                                                {grossProfit}
                                              </Text>
                                            </Td>
                                            <Td fontSize="xs">
                                              {grossProfitPercent}
                                            </Td>
                                            {/* <Td>
                                <Text fontSize="xs">(of Total)</Text>
                              </Td> */}
                                          </Tr>
                                        </>
                                      )}
                                      {/* <Tr>
                                  <Td>Net Profit</Td>
                                  <Td>{netProfit}</Td>
                                  <Td>{netProfitPercent}</Td>
                                </Tr>
                                <Tr
                                  borderBottomColor="black"
                                  borderBottomWidth="2px"
                                >
                                  <Td>After Tax</Td>
                                  <Td>{afterTaxNetProfit}</Td>
                                  <Td>{afterTaxNetProfitPercent}</Td>
                                </Tr> */}
                                      {convertUSDToNumber(salesCommission) >
                                        0 && (
                                        <>
                                          <Tr>
                                            <Td>
                                              <Text fontSize="xs">
                                                Sales Commission
                                              </Text>
                                            </Td>
                                            <Td>
                                              <Text fontSize="xs">
                                                {salesCommission}
                                              </Text>
                                            </Td>
                                            <Td fontSize="xs">
                                              {salesCommissionPercent}
                                            </Td>
                                          </Tr>
                                          <Tr>
                                            <Td>
                                              <Text fontSize="sm" as="b">
                                                After Tax
                                              </Text>
                                            </Td>
                                            <Td>
                                              <Text fontSize="sm" as="b">
                                                {afterTaxSalesCommission}
                                              </Text>
                                            </Td>
                                            <Td fontSize="xs">
                                              {afterTaxSalesCommissionPercent}
                                            </Td>
                                          </Tr>
                                        </>
                                      )}
                                      {!user?.isRestricted && (
                                        <>
                                          <Tr
                                            borderTopColor="black"
                                            borderTopWidth="1.5px"
                                          >
                                            <Td>
                                              <Text fontSize="xs">
                                                Company Net
                                              </Text>
                                            </Td>
                                            <Td>
                                              <Text fontSize="xs">
                                                {companyNet}
                                              </Text>
                                            </Td>
                                            <Td fontSize="xs">
                                              {companyNetPercent}
                                            </Td>
                                          </Tr>
                                          <Tr>
                                            <Td>
                                              <Text fontSize="sm" as="b">
                                                After Tax
                                              </Text>
                                            </Td>
                                            <Td>
                                              <Text fontSize="sm" as="b">
                                                {afterTaxCompanyNet}
                                              </Text>
                                            </Td>
                                            <Td fontSize="xs">
                                              {afterTaxCompanyNetPercent}
                                            </Td>
                                          </Tr>
                                        </>
                                      )}
                                    </Tbody>
                                  </Table>
                                </TableContainer>
                              )}
                            </VStack>
                          )}
                          {isCalculateLoading && (
                            <>
                              <Spinner
                                position="absolute"
                                thickness="6px"
                                speed="1.25s"
                                size="lg"
                                top="30%"
                                left="50%"
                                zIndex="1"
                                color="white"
                              />
                            </>
                          )}
                        </Box>
                      </>
                    )}
                  <HStack>
                    {['free', 'premium'].includes(user?.payment) &&
                      isLargerThan800 && (
                        <Button
                          colorScheme="blue"
                          onClick={handleClickForDetails}
                          size="lg"
                        >
                          Finish
                        </Button>
                      )}
                    {true && (
                      <Button colorScheme="red" onClick={onClearOpen} size="lg">
                        Clear
                      </Button>
                    )}
                  </HStack>
                  {!isLargerThan800 && (
                    <HStack>
                      <LogoutButton />
                    </HStack>
                  )}
                </VStack>
              </HStack>
            </Box>
          )}
          <AlertDialog
            isOpen={isClearOpen}
            leastDestructiveRef={cancelClearRef}
            onClose={onClearClose}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Clear Estimator
                </AlertDialogHeader>

                <AlertDialogBody>
                  <VStack align="flex-start" spacing="20px">
                    <Text as="b">
                      This action saves the current roof and creates a blank
                      estimate.
                    </Text>

                    <Alert status="info">
                      <AlertIcon />
                      <VStack>
                        <Text fontSize="med" as="b">
                          This action does not clear:
                        </Text>
                        <UnorderedList>
                          <ListItem fontSize="sm">
                            <Text as="b">Locked Values</Text>
                          </ListItem>
                          <ListItem fontSize="sm">
                            <Text as="b">Material</Text>
                          </ListItem>
                          <ListItem fontSize="sm">
                            <Text as="b">Labor</Text>
                          </ListItem>
                        </UnorderedList>
                      </VStack>
                    </Alert>
                  </VStack>
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button ref={cancelClearRef} onClick={onClearClose}>
                    Cancel
                  </Button>
                  <Button colorScheme="red" onClick={onClearConfirm} ml={3}>
                    Clear
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
          {/* <AlertDialog
            isOpen={isSendOpen}
            leastDestructiveRef={cancelSendRef}
            onClose={onSendClose}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Send Estimate
                </AlertDialogHeader>
                <AlertDialogBody>
                  <VStack align="flex-start" spacing="20px">
                    <Text>Send current estimate to your administrator?</Text>
                  </VStack>
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button ref={cancelSendRef} onClick={onSendClose}>
                    Cancel
                  </Button>
                  <Button colorScheme="blue" onClick={onSendConfirm} ml={3}>
                    {isSendLoading ? (
                      <Spinner
                        thickness="4px"
                        speed="1.25s"
                        size="md"
                        emptyColor="gray.200"
                        color="gray.800"
                      />
                    ) : (
                      <Text>Send</Text>
                    )}
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog> */}
        </VStack>
      )}
    </Center>
  );
};

export default Estimator;
