import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../features/user/userSlice';
import axios from 'axios';

const useUser = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const dbUser = useSelector(state => state.user);
  const [isUserLoading, setIsUserLoading] = useState(true);

  useEffect(() => {
    // TODO: Memoize or use react context or redux
    const unsubscribe = onAuthStateChanged(getAuth(), async user => {
      if (user && !dbUser.isUserLoaded) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/user/search`,
            {
              headers: {
                authorization: `${user.accessToken}`,
              },
              params: {
                username: user.email,
              },
            }
          );
          dispatch(
            updateUser({
              username: response.data.email,
              payment: response.data.payment,
              profile: response.data.profile,
              administrator: response.data.administrator,
              isRestricted: response.data.isRestricted,
              subscription: response.data.subscription,
              subscribeDate: response.data.subscribeDate,
              createdAt: response.data.createdAt,
            })
          );
          user.payment = response.data.payment;
          user.profile = response.data.profile;
          user.administrator = response.data.administrator;
          user.isRestricted = response.data.isRestricted;
          user.subscription = response.data.subscription;
          user.subscribeDate = response.data.subscribeDate;
          user.createdAt = response.data.createdAt;
        } catch (e) {
          console.error(e);
        }
      } else if (user && dbUser.isUserLoaded) {
        user.payment = dbUser.payment;
        user.profile = dbUser.profile;
        user.administrator = dbUser.administrator;
        user.isRestricted = dbUser.isRestricted;
        user.subscription = dbUser.subscription;
        user.subscribeDate = dbUser.subscribeDate;
        user.createdAt = dbUser.createdAt;
      }
      // console.log(user);
      setUser(user);
      setIsUserLoading(false);
    });

    return unsubscribe;
  }, [dispatch, dbUser.isUserLoaded, dbUser.payment, dbUser.profile]);

  return { user, isUserLoading };
};

export default useUser;
