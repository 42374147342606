import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Center,
  Text,
  VStack,
  NumberInput,
  NumberInputField,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateIceWaterShieldField,
  updateUnderlaymentField,
  updateHasBeenSaved,
} from '../../features/price/priceSlice';
import axios from 'axios';
import useUser from '../../hooks/useUser';
import mixpanel from 'mixpanel-browser';

const RoofDeck = ({
  checkRoofDeckCompletion,
  isIceWaterComplete,
  isUnderlaymentComplete,
}) => {
  const dispatch = useDispatch();
  const roofDeckProtection = useSelector(
    state => state.price.roofDeckProtection
  );
  const toast = useToast();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const { user } = useUser();
  const savedList = useSelector(state => state.price.hasBeenSaved);
  const [currentTab, setCurrentTab] = useState(0);

  const handleSave = async () => {
    try {
      // if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
      //   mixpanel.track('User saved the Roof Deck prices.');
      // }
      let tab;
      if (currentTab === 0) {
        tab = 'iceWaterShield';
      } else if (currentTab === 1) {
        tab = 'underlayment';
      }

      const token = await user.getIdToken();
      setUnsavedChanges(false);
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/prices/roofDeckProtection`,
        {
          username: user.email,
          roofDeckProtection,
          tab,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      dispatch(updateHasBeenSaved(tab));
      // dispatch(updateHasBeenSaved('underlayment'));
      checkRoofDeckCompletion(roofDeckProtection, [...savedList, tab]);
    } catch (error) {
      console.log('error: ' + error);
    }

    toast({
      title: 'Changes saved',
      description: 'Your pricing was updated.',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  const handleTabChange = index => {
    setCurrentTab(index);
  };

  return (
    <Box>
      <Tabs
        align="center"
        variant="soft-rounded"
        onChange={handleTabChange}
        index={currentTab}
      >
        <TabList>
          <Tab
            as="b"
            bg={isIceWaterComplete ? 'green.200' : 'yellow.200'}
            color="black"
            _selected={{
              border: '2px solid black',
              bg: isIceWaterComplete ? 'green.400' : 'yellow.400',
            }}
          >
            Ice & Water Shield
          </Tab>
          <Tab
            as="b"
            bg={isUnderlaymentComplete ? 'green.200' : 'yellow.200'}
            color="black"
            _selected={{
              border: '2px solid black',
              bg: isUnderlaymentComplete ? 'green.400' : 'yellow.400',
            }}
          >
            Underlayment
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <VStack>
              <div>
                <Center>
                  <Text fontSize="xl" fontWeight="bold" ml="6">
                    Ice & Water Shield
                  </Text>
                </Center>
                <Table>
                  <Thead>
                    <Tr>
                      <Th fontSize="xs">Manufacturer</Th>
                      <Th fontSize="xs">Type</Th>
                      <Th fontSize="xs">Line</Th>
                      <Th fontSize="xs">Unit Description</Th>
                      <Th fontSize="xs">Unit Measurement (LF)</Th>
                      <Th fontSize="xs">Price per unit</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        <Input
                          value={roofDeckProtection.iceWaterShield.manufacturer}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateIceWaterShieldField({
                                newValue: event.target.value,
                                fieldToUpdate: 'manufacturer',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          value={
                            roofDeckProtection.iceWaterShield.iceWaterShieldType
                          }
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateIceWaterShieldField({
                                newValue: event.target.value,
                                fieldToUpdate: 'iceWaterShieldType',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          value={roofDeckProtection.iceWaterShield.line}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateIceWaterShieldField({
                                newValue: event.target.value,
                                fieldToUpdate: 'line',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          value={roofDeckProtection.iceWaterShield.description}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateIceWaterShieldField({
                                newValue: event.target.value,
                                fieldToUpdate: 'description',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <NumberInput
                          value={roofDeckProtection.iceWaterShield.unit ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateIceWaterShieldField({
                                newValue: value,
                                fieldToUpdate: 'unit',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                      <Td>
                        <NumberInput
                          value={
                            roofDeckProtection.iceWaterShield.pricePer ?? ''
                          }
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateIceWaterShieldField({
                                newValue: value,
                                fieldToUpdate: 'pricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </div>
            </VStack>
          </TabPanel>
          <TabPanel>
            <VStack>
              <div>
                <Center>
                  <Text fontSize="xl" fontWeight="bold" ml="6">
                    Underlayment
                  </Text>
                </Center>
                <Table>
                  <Thead>
                    <Tr>
                      <Th fontSize="xs">Manufacturer</Th>
                      <Th fontSize="xs">Type</Th>
                      <Th fontSize="xs">Line</Th>
                      <Th fontSize="xs">Unit Description</Th>
                      <Th fontSize="xs">Unit Measurement (Squares)</Th>
                      <Th fontSize="xs">Price per unit</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        <Input
                          value={roofDeckProtection.underlayment.manufacturer}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateUnderlaymentField({
                                newValue: event.target.value,
                                fieldToUpdate: 'manufacturer',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          value={
                            roofDeckProtection.underlayment.underlaymentType
                          }
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateUnderlaymentField({
                                newValue: event.target.value,
                                fieldToUpdate: 'underlaymentType',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          value={roofDeckProtection.underlayment.line}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateUnderlaymentField({
                                newValue: event.target.value,
                                fieldToUpdate: 'line',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          value={roofDeckProtection.underlayment.description}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateUnderlaymentField({
                                newValue: event.target.value,
                                fieldToUpdate: 'description',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <NumberInput
                          value={roofDeckProtection.underlayment.unit ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateUnderlaymentField({
                                newValue: value,
                                fieldToUpdate: 'unit',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                      <Td>
                        <NumberInput
                          value={roofDeckProtection.underlayment.pricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateUnderlaymentField({
                                newValue: value,
                                fieldToUpdate: 'pricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </div>
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Center>
        <VStack>
          {unsavedChanges && (
            <Alert status="warning" marginTop="5">
              <AlertIcon />
              <AlertTitle>You have unsaved changes!</AlertTitle>
            </Alert>
          )}
          {((currentTab === 0 && !savedList.includes('iceWaterShield')) ||
            (currentTab === 1 && !savedList.includes('underlayment'))) && (
            <Alert status="info" marginTop="5">
              <AlertIcon />
              <AlertTitle>Review and Save</AlertTitle>
            </Alert>
          )}
          <Button colorScheme="blue" marginTop="3" onClick={handleSave}>
            Save
          </Button>
        </VStack>
      </Center>
    </Box>
  );
};

export default RoofDeck;
