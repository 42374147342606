import {
  Button,
  Center,
  Text,
  VStack,
  HStack,
  Box,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  Flex,
  useDisclosure,
  Input,
  TableCaption,
  TableContainer,
  Checkbox,
  IconButton,
  Alert,
  AlertIcon,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberDecrementStepper,
  NumberIncrementStepper,
  Select,
  Spinner,
} from '@chakra-ui/react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateFinanceOption,
  updateProfitMargin,
} from '../features/calculator/calculatorSlice';

import useUser from '../hooks/useUser';
import mixpanel from 'mixpanel-browser';

const Actions = ({
  availableDiscount,
  setCalculateButtonClicked,
  isCalculateLoading,
  marginPercent,
  totalPrice,
  availableDollarDiscount,
  appliedDiscount,
  setAppliedDiscount,
  appliedDollarDiscount,
  setAppliedDollarDiscount,
}) => {
  const { user } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const financeOption = useSelector(state => state.calculator.financeOption);
  const defaultProfitMargin = useSelector(
    state => state.payment.general.profitMargin
  );
  const modifiedProfitMargin = useSelector(
    state => state.calculator.profitMargin
  );
  const hasPaymentOptionCash = useSelector(
    state => state.calculator.hasPaymentOptionCash
  );
  const hasPaymentOptionOne = useSelector(
    state => state.calculator.hasPaymentOptionOne
  );
  const hasPaymentOptionThree = useSelector(
    state => state.calculator.hasPaymentOptionThree
  );
  const hasPaymentOptionSeven = useSelector(
    state => state.calculator.hasPaymentOptionSeven
  );
  const oneYearDescription = useSelector(
    state => state.payment.options.oneYearDescription
  );
  const threeYearDescription = useSelector(
    state => state.payment.options.threeYearDescription
  );
  const sevenYearDescription = useSelector(
    state => state.payment.options.sevenYearDescription
  );
  const cashDescription = useSelector(
    state => state.payment.options.cashDescription
  );
  const dispatch = useDispatch();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [unknownAvailableDiscount, setUnknownAvailableDiscount] =
    useState(false);
  const [unknownProfitMargin, setUnknownProfitMargin] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [isResetLoading, setIsResetLoading] = useState(false);

  const convertUSDToNumber = input => {
    const beginning = input.slice(0, -3);
    const end = input.slice(-3);
    const filteredBeginning = beginning.replace(/\D/g, '');
    const returnNumber = parseFloat(filteredBeginning + end);
    return returnNumber;
  };

  const handleOpen = () => {
    // if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    //   mixpanel.track('User opened the Accessories modal.');
    // }
    onOpen();
  };

  const handleUpdate = async () => {
    try {
      setIsUpdateLoading(true);
      const token = await user.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/estimator/update/breakdown`,
        {
          username: user.email,
          appliedDiscount: appliedDiscount,
          appliedDollarDiscount: appliedDollarDiscount,
          profitMargin: modifiedProfitMargin,
          financeOption: financeOption,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      //   await handleGatherData();
      setCalculateButtonClicked(true);
      setUnsavedChanges(false);
      // if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
      //   mixpanel.track('User performed an action', {
      //     appliedDiscount: appliedDiscount,
      //     profitMargin: modifiedProfitMargin,
      //     financeOption: financeOption,
      //   });
      // }
    } catch {
      setIsUpdateLoading(false);
    }
  };

  const handleUndo = async () => {
    try {
      setIsResetLoading(true);
      const token = await user.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/estimator/undoupdate/breakdown`,
        {
          username: user.email,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      setAppliedDiscount(0);
      setAppliedDollarDiscount(0);
      dispatch(updateProfitMargin(0));
      dispatch(updateFinanceOption('cash'));
      //   await handleGatherData();
      setCalculateButtonClicked(true);
      setUnsavedChanges(false);
    } catch {
      setIsResetLoading(false);
    }
  };

  const convertToUSD = input => {
    return input.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  };

  useEffect(() => {
    setUnknownAvailableDiscount(false);
    setUnknownProfitMargin(false);
  }, [availableDiscount]);

  useEffect(() => {
    if (!isCalculateLoading) {
      setIsUpdateLoading(false);
      setIsResetLoading(false);
      setUnknownAvailableDiscount(false);
      setUnknownProfitMargin(false);
    }
  }, [isCalculateLoading]);

  return (
    <Box
      maxW="sm"
      maxH="70px"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p="6"
      boxShadow="base"
      borderColor="gray.400"
      bg={'gray.200'}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Flex alignItems="center">
        <Text fontWeight="bold" fontSize="l" mr="4" pl="10px">
          Actions
        </Text>
        <Button
          size="sm"
          border="1px"
          borderColor="gray.600"
          ml="15px"
          onClick={handleOpen}
        >
          Edit
        </Button>
        <Modal isOpen={isOpen} size="lg" onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <HStack justify="space-between">
                <Text>Actions</Text>
              </HStack>
            </ModalHeader>
            <ModalBody>
              <VStack align="flex-start">
                <Text fontSize="sm">Payment Option</Text>
                <Select
                  size="sm"
                  maxWidth="200px"
                  value={financeOption}
                  onChange={event => {
                    setUnsavedChanges(true);
                    setUnknownAvailableDiscount(true);
                    dispatch(updateFinanceOption(event.target.value));
                  }}
                >
                  <option value=""></option>
                  <option value="cash">{cashDescription}</option>
                  {hasPaymentOptionOne && (
                    <option value="one">{oneYearDescription}</option>
                  )}
                  {hasPaymentOptionThree && (
                    <option value="three">{threeYearDescription}</option>
                  )}
                  {hasPaymentOptionSeven && (
                    <option value="seven">{sevenYearDescription}</option>
                  )}
                </Select>
                <HStack pt="10px" spacing="50px">
                  <VStack align="flex-start">
                    <Text fontSize="sm">Applied Discount %</Text>
                    <NumberInput
                      size="sm"
                      maxWidth="100px"
                      step={1}
                      max={availableDiscount}
                      min={0}
                      keepWithinRange={false}
                      clampValueOnBlur={false}
                      value={appliedDiscount}
                      onChange={value => {
                        setUnsavedChanges(true);
                        setUnknownProfitMargin(true);
                        setAppliedDiscount(value);
                        setAppliedDollarDiscount(0);
                      }}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </VStack>
                  <VStack align="flex-start">
                    <Text fontSize="sm">Available Discount %</Text>
                    <Input
                      size="sm"
                      readOnly
                      maxWidth="100px"
                      variant={'filled'}
                      value={
                        unknownAvailableDiscount ? '???' : availableDiscount
                      }
                    ></Input>
                  </VStack>
                </HStack>
                <HStack pt="10px" spacing="50px">
                  <VStack align="flex-start">
                    <Text fontSize="sm">Applied Discount $</Text>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      step={1}
                      max={convertToUSD(
                        (availableDiscount / 100) *
                          convertUSDToNumber(totalPrice)
                      )}
                      min={0}
                      keepWithinRange={false}
                      clampValueOnBlur={false}
                      value={appliedDollarDiscount}
                      onChange={value => {
                        setUnsavedChanges(true);
                        setUnknownProfitMargin(true);
                        setAppliedDollarDiscount(value);
                        setAppliedDiscount(0);
                      }}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </VStack>
                  <VStack align="flex-start">
                    <Text fontSize="sm">Available Discount $</Text>
                    <Input
                      size="sm"
                      readOnly
                      maxWidth="150px"
                      variant={'filled'}
                      value={
                        unknownAvailableDiscount
                          ? '???'
                          : availableDollarDiscount
                      }
                    ></Input>
                  </VStack>
                </HStack>
                <HStack spacing="50px">
                  <VStack align="start">
                    <VStack align="start" spacing="0">
                      <Text pt="10px" fontSize="sm">
                        Profit Margin %
                      </Text>
                      <Text fontSize="xs" as="i" maxWidth="100px">
                        (before discounts and financing)
                      </Text>
                    </VStack>
                    <NumberInput
                      size="sm"
                      width="100px"
                      step={1}
                      min={0}
                      value={
                        modifiedProfitMargin > 0
                          ? modifiedProfitMargin
                          : defaultProfitMargin
                      }
                      onChange={value => {
                        setUnsavedChanges(true);
                        setUnknownAvailableDiscount(true);
                        dispatch(updateProfitMargin(value));
                      }}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </VStack>
                  <VStack align="start">
                    <VStack align="start" spacing="0">
                      <Text pt="10px" fontSize="sm">
                        Profit Margin %
                      </Text>
                      <Text fontSize="xs" as="i" maxWidth="100px">
                        (after discounts and financing)
                      </Text>
                    </VStack>
                    <Input
                      size="sm"
                      readOnly
                      maxWidth="100px"
                      variant={'filled'}
                      value={
                        unknownAvailableDiscount || unknownProfitMargin
                          ? '???'
                          : marginPercent
                      }
                    ></Input>
                  </VStack>
                </HStack>
                {unsavedChanges && (
                  <Alert status="warning">
                    <AlertIcon />
                    <VStack align="flex-start">
                      <Text fontSize="sm">Press Apply to update estimate</Text>
                    </VStack>
                  </Alert>
                )}
                <Center></Center>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack width="100%" justify="space-between">
                <HStack>
                  <Button onClick={handleUndo}>
                    {isResetLoading ? (
                      <Spinner
                        thickness="4px"
                        speed="1.25s"
                        size="md"
                        emptyColor="gray.200"
                        color="gray.800"
                      />
                    ) : (
                      <Text>Reset</Text>
                    )}
                  </Button>
                  <Button colorScheme="blue" onClick={handleUpdate}>
                    {isUpdateLoading ? (
                      <Spinner
                        thickness="4px"
                        speed="1.25s"
                        size="md"
                        emptyColor="gray.200"
                        color="gray.800"
                      />
                    ) : (
                      <Text>Apply</Text>
                    )}
                  </Button>
                </HStack>
                <Button colorScheme="red" onClick={onClose}>
                  <Text>Close</Text>
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </Box>
  );
};

export default Actions;
