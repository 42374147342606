import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hasBeenSaved: [],
  shingleRates: {
    additionalLaborCost: '',
    warrantyType: '',
    warrantyCost: '',
    warrantyPer: '',
  },
  additional: {
    hipWasteAllowance: '',
    gableWasteAllowance: '',
    dumpMinimumFee: '',
    dumpPerSquareFee: '',
    onStreetPermitFee: '',
    flatRatePermit: '',
    percentOfContractPermit: '',
    isFlatRatePermit: false,
    isPercentOfContractPermit: false,
  },
  itemized: {
    tearOff: '',
    addLayersAsphalt: '',
    addLayersShake: '',
    addStories: '',
    redeckThreeToSix: '',
    redeckSevenToNine: '',
    redeckTenToTwelve: '',
    redeckThirteenPlus: '',
    dripEdge: '',
    iceAndWater: '',
    underlayment: '',
    shinglesWithWaste: '',
    steepChargeThreeToSix: '',
    steepChargeSevenToNine: '',
    steepChargeTenToTwelve: '',
    steepChargeThirteenPlus: '',
    handSealEightOrLess: '',
    handSealNineToThirteen: '',
    handSealFourteenPlus: '',
    starter: '',
    pipeFlashing: '',
    splitPipeFlashing: '',
    roofLouver: '',
    turbineVent: '',
    powerVent: '',
    smallKitBathExhaust: '',
    largeKitBathExhaust: '',
    ridgeVent: '',
    ridgeCap: '',
    valleyFlashing: '',
    stepFlashing: '',
    regletFlashing: '',
    oneByFourFlashing: '',
    skylightFlashing: '',
    skylight: '',
    smallChimney: '',
    mediumChimney: '',
    largeChimney: '',
    smallChimneyPipe: '',
    mediumChimneyPipe: '',
    largeChimneyPipe: '',
    gutterDetachReset: '',
    dormerFlashing: '',
    smallCorniceReturn: '',
    mediumCorniceReturn: '',
    largeCorniceReturn: '',
    smallBayWindow: '',
    mediumBayWindow: '',
    largeBayWindow: '',
    satelliteDishReset: '',
    roofingPermit: '',
    debrisRemoval: '',
    detachResetSiding: '',
  },
};

export const laborSlice = createSlice({
  name: 'labor',
  initialState,
  reducers: {
    updateLabor: (state, action) => {
      const {
        username,
        rates,
        steepPitchRates,
        shingleRates,
        payment,
        additional,
        hasBeenSaved,
      } = action.payload;
      if (hasBeenSaved) {
        state.hasBeenSaved = hasBeenSaved;
      }
      if (shingleRates) {
        state.shingleRates = shingleRates;
      }
      if (additional) {
        state.additional = additional;
      }
    },
    loadShingles: (state, action) => {
      state.shingleRates = action.payload;
    },
    loadItemized: (state, action) => {
      state.itemized = action.payload;
    },
    loadAdditional: (state, action) => {
      state.additional = action.payload;
    },
    updateShingleRates: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.shingleRates[fieldToUpdate] = newValue;
    },
    updateItemized: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.itemized[fieldToUpdate] = newValue;
    },
    updateAdditional: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.additional[fieldToUpdate] = newValue;
    },
    updateHasBeenSaved: (state, action) => {
      if (!state.hasBeenSaved.includes(action.payload)) {
        state.hasBeenSaved.push(action.payload);
      }
    },
  },
});

export const {
  updateLabor,
  updateShingleRates,
  updateAdditional,
  loadShingles,
  loadAdditional,
  updateHasBeenSaved,
  updateItemized,
  loadItemized,
} = laborSlice.actions;

export default laborSlice.reducer;
