import {
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  NumberInput,
  NumberInputField,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Alert,
  AlertIcon,
  AlertTitle,
  Center,
  VStack,
  Button,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateSteepTearOffRates,
  updateSteepRecoverRates,
  updateSteepRedeckRates,
} from '../../features/labor/rateSlice';
import { updateHasBeenSaved } from '../../features/labor/laborSlice';

import axios from 'axios';
import useUser from '../../hooks/useUser';
import mixpanel from 'mixpanel-browser';

const SteepPitch = ({
  checkSteepPitchCompletion,
  isRedeckComplete,
  isNewComplete,
  isTearOffComplete,
}) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const steepPitchRates = useSelector(state => state.rate.steepPitchRates);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const { user } = useUser();
  const savedList = useSelector(state => state.labor.hasBeenSaved);
  const [currentTab, setCurrentTab] = useState(0);

  const handleSave = async () => {
    try {
      // if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
      //   mixpanel.track('User saved the steep pitch labor rates.');
      // }
      let tab;
      if (currentTab === 0) {
        tab = 'tearOff';
      } else if (currentTab === 1) {
        tab = 'newRecover';
      } else if (currentTab === 2) {
        tab = 'redeck';
      }

      const token = await user.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/labors/steep`,
        {
          username: user.email,
          steepPitchRates,
          tab,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      dispatch(updateHasBeenSaved(tab));
      checkSteepPitchCompletion(steepPitchRates, [...savedList, tab]);
      setUnsavedChanges(false);
    } catch (error) {
      console.log('error: ' + error);
    }

    toast({
      title: 'Changes saved',
      description: 'Your pricing was updated.',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  const handleTabChange = index => {
    setCurrentTab(index);
  };

  return (
    <Box>
      <Tabs
        align="center"
        variant="soft-rounded"
        colorScheme="blue"
        onChange={handleTabChange}
        index={currentTab}
      >
        <TabList>
          <Tab
            as="b"
            bg={isTearOffComplete ? 'green.200' : 'yellow.200'}
            color="black"
            _selected={{
              border: '2px solid black',
              bg: isTearOffComplete ? 'green.400' : 'yellow.400',
            }}
          >
            Tear Off
          </Tab>
          <Tab
            as="b"
            bg={isNewComplete ? 'green.200' : 'yellow.200'}
            color="black"
            _selected={{
              border: '2px solid black',
              bg: isNewComplete ? 'green.400' : 'yellow.400',
            }}
          >
            New/Recover
          </Tab>
          <Tab
            as="b"
            bg={isRedeckComplete ? 'green.200' : 'yellow.200'}
            color="black"
            _selected={{
              border: '2px solid black',
              bg: isRedeckComplete ? 'green.400' : 'yellow.400',
            }}
          >
            Redeck
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Text fontSize="sm">Steep Pitch Roofing/Tearoff</Text>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Pitch</Th>
                  <Th>Unit</Th>
                  <Th>Additional Labor Cost</Th>
                </Tr>
                {/* <Tr>
                  <Td>
                    <Text>3/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.tearOff.three ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepTearOffRates({
                            newValue: value,
                            fieldToUpdate: 'three',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>4/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.tearOff.four ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepTearOffRates({
                            newValue: value,
                            fieldToUpdate: 'four',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>5/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.tearOff.five ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepTearOffRates({
                            newValue: value,
                            fieldToUpdate: 'five',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>6/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.tearOff.six ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepTearOffRates({
                            newValue: value,
                            fieldToUpdate: 'six',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>*/}
                <Tr>
                  <Td>
                    <Text>7/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.tearOff.seven ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepTearOffRates({
                            newValue: value,
                            fieldToUpdate: 'seven',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>8/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.tearOff.eight ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepTearOffRates({
                            newValue: value,
                            fieldToUpdate: 'eight',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>9/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.tearOff.nine ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepTearOffRates({
                            newValue: value,
                            fieldToUpdate: 'nine',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>10/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.tearOff.ten ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepTearOffRates({
                            newValue: value,
                            fieldToUpdate: 'ten',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>11/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.tearOff.eleven ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepTearOffRates({
                            newValue: value,
                            fieldToUpdate: 'eleven',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>12/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.tearOff.twelve ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepTearOffRates({
                            newValue: value,
                            fieldToUpdate: 'twelve',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>13/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.tearOff.thirteen ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepTearOffRates({
                            newValue: value,
                            fieldToUpdate: 'thirteen',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>14/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.tearOff.fourteen ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepTearOffRates({
                            newValue: value,
                            fieldToUpdate: 'fourteen',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>15/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.tearOff.fifteen ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepTearOffRates({
                            newValue: value,
                            fieldToUpdate: 'fifteen',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>16/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.tearOff.sixteen ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepTearOffRates({
                            newValue: value,
                            fieldToUpdate: 'sixteen',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>17/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.tearOff.seventeen ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepTearOffRates({
                            newValue: value,
                            fieldToUpdate: 'seventeen',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>18/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.tearOff.eighteen ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepTearOffRates({
                            newValue: value,
                            fieldToUpdate: 'eighteen',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
              </Thead>
            </Table>
          </TabPanel>
          <TabPanel>
            <Text fontSize="sm">
              Steep Pitch Roofing/New Construction - Recover
            </Text>
            {/* <Text fontSize="sm">
              NEW CONSTRUCTION/RECOVER - Steep Pitch Labor Fee Per Pitch Per Sq
              Above Base Per Sq Labor Rate
            </Text> */}
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Pitch</Th>
                  <Th>Unit</Th>
                  <Th>Additional Labor Cost</Th>
                </Tr>
                {/* <Tr>
                  <Td>
                    <Text>3/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.recover.three ?? ''}
                      onChange={value =>
                        dispatch(
                          updateSteepRecoverRates({
                            newValue: value,
                            fieldToUpdate: 'three',
                          })
                        )
                      }
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>4/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.recover.four ?? ''}
                      onChange={value =>
                        dispatch(
                          updateSteepRecoverRates({
                            newValue: value,
                            fieldToUpdate: 'four',
                          })
                        )
                      }
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>5/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.recover.five ?? ''}
                      onChange={value =>
                        dispatch(
                          updateSteepRecoverRates({
                            newValue: value,
                            fieldToUpdate: 'five',
                          })
                        )
                      }
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>6/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.recover.six ?? ''}
                      onChange={value =>
                        dispatch(
                          updateSteepRecoverRates({
                            newValue: value,
                            fieldToUpdate: 'six',
                          })
                        )
                      }
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>*/}
                <Tr>
                  <Td>
                    <Text>7/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.recover.seven ?? ''}
                      onChange={value =>
                        dispatch(
                          updateSteepRecoverRates({
                            newValue: value,
                            fieldToUpdate: 'seven',
                          })
                        )
                      }
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>8/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.recover.eight ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepRecoverRates({
                            newValue: value,
                            fieldToUpdate: 'eight',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>9/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.recover.nine ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepRecoverRates({
                            newValue: value,
                            fieldToUpdate: 'nine',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>10/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.recover.ten ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepRecoverRates({
                            newValue: value,
                            fieldToUpdate: 'ten',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>11/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.recover.eleven ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepRecoverRates({
                            newValue: value,
                            fieldToUpdate: 'eleven',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>12/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.recover.twelve ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepRecoverRates({
                            newValue: value,
                            fieldToUpdate: 'twelve',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>13/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.recover.thirteen ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepRecoverRates({
                            newValue: value,
                            fieldToUpdate: 'thirteen',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>14/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.recover.fourteen ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepRecoverRates({
                            newValue: value,
                            fieldToUpdate: 'fourteen',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>15/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.recover.fifteen ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepRecoverRates({
                            newValue: value,
                            fieldToUpdate: 'fifteen',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>16/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.recover.sixteen ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepRecoverRates({
                            newValue: value,
                            fieldToUpdate: 'sixteen',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>17/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.recover.seventeen ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepRecoverRates({
                            newValue: value,
                            fieldToUpdate: 'seventeen',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>18/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.recover.eighteen ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepRecoverRates({
                            newValue: value,
                            fieldToUpdate: 'eighteen',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
              </Thead>
            </Table>
          </TabPanel>
          <TabPanel>
            <Text fontSize="sm">Steep Pitch Redeck</Text>
            {/* <Text fontSize="sm">
              Steep Pitch Labor Fee Per Pitch Per Sq Above Base Per Sq Re Deck
              Cost
            </Text> */}
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Pitch</Th>
                  <Th>Unit</Th>
                  <Th>Additional Labor Cost</Th>
                </Tr>
                {/* <Tr>
                  <Td>
                    <Text>3/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.redeck.three ?? ''}
                      onChange={value =>
                        dispatch(
                          updateSteepRedeckRates({
                            newValue: value,
                            fieldToUpdate: 'three',
                          })
                        )
                      }
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>4/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.redeck.four ?? ''}
                      onChange={value =>
                        dispatch(
                          updateSteepRedeckRates({
                            newValue: value,
                            fieldToUpdate: 'four',
                          })
                        )
                      }
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>5/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.redeck.five ?? ''}
                      onChange={value =>
                        dispatch(
                          updateSteepRedeckRates({
                            newValue: value,
                            fieldToUpdate: 'five',
                          })
                        )
                      }
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>6/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.redeck.six ?? ''}
                      onChange={value =>
                        dispatch(
                          updateSteepRedeckRates({
                            newValue: value,
                            fieldToUpdate: 'six',
                          })
                        )
                      }
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>*/}
                <Tr>
                  <Td>
                    <Text>7/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.redeck.seven ?? ''}
                      onChange={value =>
                        dispatch(
                          updateSteepRedeckRates({
                            newValue: value,
                            fieldToUpdate: 'seven',
                          })
                        )
                      }
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>8/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.redeck.eight ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepRedeckRates({
                            newValue: value,
                            fieldToUpdate: 'eight',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>9/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.redeck.nine ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepRedeckRates({
                            newValue: value,
                            fieldToUpdate: 'nine',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>10/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.redeck.ten ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepRedeckRates({
                            newValue: value,
                            fieldToUpdate: 'ten',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>11/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.redeck.eleven ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepRedeckRates({
                            newValue: value,
                            fieldToUpdate: 'eleven',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>12/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.redeck.twelve ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepRedeckRates({
                            newValue: value,
                            fieldToUpdate: 'twelve',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>13/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.redeck.thirteen ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepRedeckRates({
                            newValue: value,
                            fieldToUpdate: 'thirteen',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>14/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.redeck.fourteen ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepRedeckRates({
                            newValue: value,
                            fieldToUpdate: 'fourteen',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>15/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.redeck.fifteen ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepRedeckRates({
                            newValue: value,
                            fieldToUpdate: 'fifteen',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>16/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.redeck.sixteen ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepRedeckRates({
                            newValue: value,
                            fieldToUpdate: 'sixteen',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>17/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.redeck.seventeen ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepRedeckRates({
                            newValue: value,
                            fieldToUpdate: 'seventeen',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text>18/12</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">Per Sq</Text>
                  </Td>
                  <Td>
                    <NumberInput
                      size="sm"
                      maxWidth="150px"
                      value={steepPitchRates.redeck.eighteen ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateSteepRedeckRates({
                            newValue: value,
                            fieldToUpdate: 'eighteen',
                          })
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
              </Thead>
            </Table>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Center>
        <VStack>
          {unsavedChanges && (
            <Alert status="warning" marginTop="5">
              <AlertIcon />
              <AlertTitle>You have unsaved changes!</AlertTitle>
            </Alert>
          )}
          {((currentTab === 0 && !savedList.includes('tearOff')) ||
            (currentTab === 1 && !savedList.includes('newRecover')) ||
            (currentTab === 2 && !savedList.includes('redeck'))) && (
            <Alert status="info" marginTop="5">
              <AlertIcon />
              <AlertTitle>Review and Save</AlertTitle>
            </Alert>
          )}
          <Button colorScheme="blue" marginTop="3" onClick={handleSave}>
            Save
          </Button>
        </VStack>
      </Center>
    </Box>
  );
};

export default SteepPitch;
