import {
  Box,
  Button,
  Text,
  HStack,
  Flex,
  NumberInput,
  NumberInputField,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateRidgeLF,
  updateValleysLF,
  updateDormerFlashingLF,
  updateEavesLF,
  updateRakeEdgesLF,
  updateCounterFlashingLF,
  updateHipsLF,
  updateStepFlashingLF,
  updateEaseOverhangInches,
  updateFlashingStepFlashingLength,
  updateFlashingValleyFlashingLength,
  updateFlashingDormerFlashingLength,
  updateIsMeasurementsCompleted,
} from '../features/calculator/calculatorSlice';
import useUser from '../hooks/useUser';
import mixpanel from 'mixpanel-browser';

const Measurements = ({
  checkMeasurementsCompletion,
  isMeasurementsComplete,
  setCalculateButtonClicked,
  isMobileDevice,
}) => {
  const { user } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ridgeLF = useSelector(state => state.calculator.ridgeLF);
  const valleysLF = useSelector(state => state.calculator.valleysLF);
  const dormerFlashingLF = useSelector(
    state => state.calculator.dormerFlashingLF
  );
  const eavesLF = useSelector(state => state.calculator.eavesLF);
  const rakeEdgesLF = useSelector(state => state.calculator.rakeEdgesLF);
  const counterFlashingLF = useSelector(
    state => state.calculator.counterFlashingLF
  );
  const hipsLF = useSelector(state => state.calculator.hipsLF);
  const stepFlashingLF = useSelector(state => state.calculator.stepFlashingLF);
  const easeOverhangInches = useSelector(
    state => state.calculator.easeOverhangInches
  );
  const isMeasurementsCompleted = useSelector(
    state => state.calculator.isMeasurementsCompleted
  );
  const [unsavedChanged, setUnsavedChanges] = useState(false);
  const dispatch = useDispatch();

  const handleOpen = () => {
    // if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    //   mixpanel.track('User opened the Measurements modal.');
    // }
    onOpen();
  };

  const handleSave = async () => {
    try {
      const token = await user.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/roofs/measurements`,
        {
          username: user.email,
          ridgeLF: ridgeLF,
          valleysLF: valleysLF,
          dormerFlashingLF: dormerFlashingLF,
          eavesLF: eavesLF,
          rakeEdgesLF: rakeEdgesLF,
          counterFlashingLF: counterFlashingLF,
          hipsLF: hipsLF,
          stepFlashingLF: stepFlashingLF,
          easeOverhangInches: easeOverhangInches,
          isMeasurementsCompleted: false,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      setUnsavedChanges(false);
      checkMeasurementsCompletion({
        ridgeLF,
        valleysLF,
        dormerFlashingLF,
        eavesLF,
        rakeEdgesLF,
        counterFlashingLF,
        counterFlashingLF,
        hipsLF,
        stepFlashingLF,
        easeOverhangInches,
      });
      setCalculateButtonClicked(true);
      onClose();
    } catch (error) {
      console.log('error: ' + error);
    }
  };

  return (
    <Box
      minW={isMobileDevice ? '90vw' : ''}
      maxH="70px"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p="3"
      boxShadow="base"
      borderColor="gray.400"
      bg={isMeasurementsComplete ? 'green.300' : 'yellow.200'}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      cursor="pointer"
      onClick={isMobileDevice ? handleOpen : null}
    >
      <Flex alignItems="center">
        <Text fontWeight="bold" fontSize="l">
          Measurements
        </Text>
        {!isMobileDevice && (
          <Button
            size="sm"
            border="1px"
            borderColor="gray.400"
            ml="30px"
            onClick={handleOpen}
          >
            Edit
          </Button>
        )}
        <Modal isOpen={isOpen} size="custom" onClose={onClose}>
          <ModalOverlay />
          <ModalContent maxW={isMobileDevice ? '100vw' : '40vw'}>
            <ModalHeader>Measurements</ModalHeader>
            <ModalBody>
              <Flex direction="column" gap="3">
                <HStack spacing="24px">
                  <div>
                    <Text fontSize="sm">Ridge LF</Text>
                    <NumberInput
                      size="sm"
                      name="ridge-lf"
                      value={ridgeLF ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(updateRidgeLF(value));
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </div>
                  <div>
                    <Text fontSize="sm">Valleys LF</Text>
                    <NumberInput
                      size="sm"
                      name="valleys-lf"
                      value={valleysLF ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(updateValleysLF(value));
                        dispatch(updateFlashingValleyFlashingLength(value));
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </div>
                </HStack>
                <HStack spacing="24px">
                  <div>
                    <Text fontSize="sm">Dormer Flashing LF</Text>
                    <NumberInput
                      size="sm"
                      name="dormerflashing-lf"
                      value={dormerFlashingLF ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(updateDormerFlashingLF(value));
                        dispatch(updateFlashingDormerFlashingLength(value));
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </div>
                  <div>
                    <Text fontSize="sm">Eaves LF</Text>
                    <NumberInput
                      size="sm"
                      name="eaves-lf"
                      value={eavesLF ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(updateEavesLF(value));
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </div>
                </HStack>
                <HStack spacing="24px">
                  <div>
                    <Text fontSize="sm">Rake Edges LF</Text>
                    <NumberInput
                      size="sm"
                      name="rakeedges-lf"
                      value={rakeEdgesLF ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(updateRakeEdgesLF(value));
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </div>
                  <div>
                    <Text fontSize="sm">Counter Flashing LF</Text>
                    <NumberInput
                      size="sm"
                      name="counterflashing-lf"
                      value={counterFlashingLF ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(updateCounterFlashingLF(value));
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </div>
                </HStack>
                <HStack spacing="24px">
                  <div>
                    <Text fontSize="sm">Hips LF</Text>
                    <NumberInput
                      size="sm"
                      name="hips-lf"
                      value={hipsLF ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(updateHipsLF(value));
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </div>
                  <div>
                    <Text fontSize="sm">Step Flashing LF</Text>
                    <NumberInput
                      size="sm"
                      name="stepflashing-lf"
                      value={stepFlashingLF ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(updateFlashingStepFlashingLength(value));
                        dispatch(updateStepFlashingLF(value));
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </div>
                </HStack>
                {/* <HStack spacing="24px">
                  <div>
                    <Text fontSize="sm">Eave Overhang Inches</Text>
                    <NumberInput
                      size="sm"
                      name="ease-overhang-inches"
                      value={easeOverhangInches ?? ''}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(updateEaseOverhangInches(value));
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </div>
                </HStack> */}
              </Flex>
            </ModalBody>
            <ModalFooter>
              {unsavedChanged && (
                <Box>
                  <Alert status="warning" maxWidth="sm">
                    <AlertIcon />
                    <AlertTitle>You have unsaved changes!</AlertTitle>
                  </Alert>
                </Box>
              )}
              <Button onClick={handleSave} colorScheme="blue" ml="60px">
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </Box>
  );
};

export default Measurements;
