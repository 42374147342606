import {
  Button,
  Center,
  Text,
  VStack,
  HStack,
  Box,
  Modal,
  ModalBody,
  ModalFooter,
  IconButton,
  Input,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  Spinner,
  useToast,
  Stat,
  StatLabel,
  StatNumber,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  useDisclosure,
  TableCaption,
  TableContainer,
  Checkbox,
  Alert,
  AlertIcon,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberDecrementStepper,
  NumberIncrementStepper,
  Select,
} from '@chakra-ui/react';
import {
  updateFinanceOption,
  updateProfitMargin,
} from '../features/calculator/calculatorSlice';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { updateGeneralPayment } from '../features/labor/paymentSlice';
import axios from 'axios';
import useUser from '../hooks/useUser';
import { FaDownload, FaPlus } from 'react-icons/fa';
import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Papa from 'papaparse';
import { debounce } from 'lodash';
import { useMediaQuery } from '@chakra-ui/react';
import mixpanel from 'mixpanel-browser';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.mjs',
//   import.meta.url
// ).toString();

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const Calculate = ({
  isOpen,
  onClose,
  setTotalPrice,
  totalPrice,
  setMargin,
  setGrossProfit,
  setMarginPercent,
  setOverhead,
  setNetProfit,
  setAfterTaxNetProfit,
  setSalesCommission,
  setAfterTaxSalesCommission,
  setCompanyNet,
  setAfterTaxCompanyNet,
  setGrossProfitPercent,
  setOverheadPercent,
  setNetProfitPercent,
  setAfterTaxNetProfitPercent,
  setAfterTaxSalesCommissionPercent,
  setSalesCommissionPercent,
  setCompanyNetPercent,
  setAfterTaxCompanyNetPercent,
  calculateButtonClicked,
  setCalculateButtonClicked,
  setIsCalculateLoading,
  setPricePerMonth,
  setPricePerSq,
  setAvailableDiscount,
  setIfRedeckRequired,
  ifRedeckRequired,
  setAvailableDollarDiscount,
}) => {
  const [downloadError, setDownloadError] = useState(false);
  const [breakdownError, setBreakdownError] = useState(false);
  const [crewBillError, setCrewBillError] = useState(false);
  const [materialListError, setMaterialListError] = useState(false);
  const [calculateLoading, setCalculateLoading] = useState(false);
  const financeOption = useSelector(state => state.calculator.financeOption);
  const cancelCalculateRef = React.useRef();
  const [crewBillLoading, setCrewBillLoading] = useState(false);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [allLoading, setAllLoading] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [customItemDescription, setCustomItemDescription] = useState('');
  const [customItemQuantity, setCustomItemQuantity] = useState(0);
  const [customItemRate, setCustomItemRate] = useState(0);
  const [getItemizedPdfLoading, setGetItemizedPdfLoading] = useState(false);
  const [materialListLoading, setMaterialListLoading] = useState(false);
  const [itemizedListLoading, setItemizedListLoading] = useState(false);
  const [crewBillData, setCrewBillData] = useState([]);
  const [materialListData, setMaterialListData] = useState([]);
  const [itemizedData, setItemizedData] = useState([]);
  const [breakdownData, setBreakdownData] = useState([]);
  const [profitMargin, setProfitMargin] = useState(0);
  const [appliedDiscount, setAppliedDiscount] = useState(0);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isSendLoading, setIsSendLoading] = useState(false);
  const [isLargerThan1200] = useMediaQuery('(min-width: 1200px)');
  const toast = useToast();
  const dispatch = useDispatch();
  const hasPaymentOptionCash = useSelector(
    state => state.calculator.hasPaymentOptionCash
  );
  const hasPaymentOptionOne = useSelector(
    state => state.calculator.hasPaymentOptionOne
  );
  const hasPaymentOptionThree = useSelector(
    state => state.calculator.hasPaymentOptionThree
  );
  const hasPaymentOptionSeven = useSelector(
    state => state.calculator.hasPaymentOptionSeven
  );
  const oneYearDescription = useSelector(
    state => state.payment.options.oneYearDescription
  );
  const threeYearDescription = useSelector(
    state => state.payment.options.threeYearDescription
  );
  const sevenYearDescription = useSelector(
    state => state.payment.options.sevenYearDescription
  );
  const cashDescription = useSelector(
    state => state.payment.options.cashDescription
  );
  const defaultProfitMargin = useSelector(
    state => state.payment.general.profitMargin
  );
  const modifiedProfitMargin = useSelector(
    state => state.calculator.profitMargin
  );
  const { user } = useUser();
  const {
    isOpen: isSendOpen,
    onOpen: onSendOpen,
    onClose: onSendClose,
  } = useDisclosure();
  const cancelSendRef = React.useRef();
  const [pdfBuffer, setPdfBuffer] = useState(null);
  const [pdfBlobUrl, setPdfBlobUrl] = useState('');
  // const {
  //   isOpen: isAddToCrewBillOpen,
  //   onOpen: onAddToCrewBillOpen,
  //   onClose: onAddToCrewBillClose,
  // } = useDisclosure();
  // const {
  //   isOpen: isAddToMaterialListOpen,
  //   onOpen: onAddToMaterialListOpen,
  //   onClose: onAddToMaterialListClose,
  // } = useDisclosure();
  const jobName = useSelector(state => state.calculator.jobName);
  const boldList = [
    'Price',
    'Price Per Square',
    'Commission After Tax',
    'Company Profit After Tax',
  ];

  const getCurrentDate = () => {
    const currentDate = new Date();
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const month = monthNames[currentDate.getMonth()];
    const day = currentDate.getDate();
    const year = currentDate.getFullYear();
    const formattedDate = `${month} ${day}, ${year}`;
    return formattedDate;
  };

  const convertUSDToNumber = input => {
    const beginning = input.slice(0, -3);
    const end = input.slice(-3);
    const filteredBeginning = beginning.replace(/\D/g, '');
    const returnNumber = parseFloat(filteredBeginning + end);
    return returnNumber;
  };

  const convertToUSD = input => {
    return input.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  };

  function convertPercentToNumber(percentageString) {
    const number = parseFloat(percentageString.replace('%', ''));

    if (isNaN(number)) {
      return 0;
    }

    return number;
  }

  const onSendConfirm = async () => {
    setIsSendLoading(true);
    try {
      const token = await user.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/email/send`,
        {
          username: user.email,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      toast({
        title: 'Success',
        description: 'Estimate has been sent to your administrator.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (e) {
      console.log(e);
      toast({
        title: 'Error',
        description:
          'There was an error sending the estimate. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    setIsSendLoading(false);
    onSendClose();
    onClose();
  };

  const downloadCrewBill = async () => {
    setCrewBillLoading(true);
    try {
      // Create a CSV-formatted string
      const csvData = Papa.unparse(crewBillData);
      // Create a Blob from the CSV data
      const blob = new Blob([csvData], { type: 'text/csv' });
      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);
      // Create a temporary anchor element to trigger the download
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      let fileName = jobName;
      if (fileName == '' || fileName == null) {
        fileName = 'Unnamed Job';
      }
      a.download = `${fileName} - Crew Bill.csv`;
      // Trigger a click event to download the file
      document.body.appendChild(a);
      a.click();
      // Clean up
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch {
      setDownloadError(true);
      setCrewBillError(true);
    }
    setCrewBillLoading(false);
  };

  const downloadMaterialList = async () => {
    setMaterialListLoading(true);
    try {
      // Create a CSV-formatted string
      const csvData = Papa.unparse(materialListData);
      // Create a Blob from the CSV data
      const blob = new Blob([csvData], { type: 'text/csv' });
      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);
      // Create a temporary anchor element to trigger the download
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      let fileName = jobName;
      if (fileName == '' || fileName == null) {
        fileName = 'Unnamed Job';
      }
      a.download = `${fileName} - Material List.csv`;
      // Trigger a click event to download the file
      document.body.appendChild(a);
      a.click();
      // Clean up
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch {
      setDownloadError(true);
      setMaterialListError(true);
    }
    setMaterialListLoading(false);
  };

  const downloadItemizedList = async () => {
    setItemizedListLoading(true);
    try {
      // Create a CSV-formatted string
      const csvData = Papa.unparse(itemizedData);
      // Create a Blob from the CSV data
      const blob = new Blob([csvData], { type: 'text/csv' });
      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);
      // Create a temporary anchor element to trigger the download
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      let fileName = jobName;
      if (fileName == '' || fileName == null) {
        fileName = 'Unnamed Job';
      }
      a.download = `${fileName} - Itemized Estimate.csv`;
      // Trigger a click event to download the file
      document.body.appendChild(a);
      a.click();
      // Clean up
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
      setDownloadError(true);
    }
    setItemizedListLoading(false);
  };

  const downloadBreakdown = () => {
    setSummaryLoading(true);
    try {
      // Create a CSV-formatted string
      const csvData = Papa.unparse(breakdownData);
      // Create a Blob from the CSV data
      const blob = new Blob([csvData], { type: 'text/csv' });
      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);
      // Create a temporary anchor element to trigger the download
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      let fileName = jobName;
      if (fileName == '' || fileName == null) {
        fileName = 'Unnamed Job';
      }
      a.download = `${fileName} - Breakdown.csv`;
      // Trigger a click event to download the file
      document.body.appendChild(a);
      a.click();
      // Clean up
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch {
      setDownloadError(true);
      setBreakdownError(true);
    }
    setSummaryLoading(false);
  };

  const downloadAll = () => {
    setAllLoading(true);
    downloadCrewBill();
    downloadMaterialList();
    downloadItemizedList();
    downloadBreakdown();
    setAllLoading(false);
  };

  const handleSend = () => {
    // if (unsavedChanges) {
    //   handleUpdate();
    // }
    onSendOpen();
  };

  const handleUpdate = async () => {
    try {
      const token = await user.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/estimator/update/breakdown`,
        {
          username: user.email,
          appliedDiscount: appliedDiscount,
          profitMargin: modifiedProfitMargin,
          financeOption: financeOption,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      await handleGatherData();
      setUnsavedChanges(false);
      if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
        mixpanel.track('User performed an action');
      }
    } catch {}
  };

  const handleUndo = async () => {
    try {
      const token = await user.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/estimator/undoupdate/breakdown`,
        {
          username: user.email,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      dispatch(updateProfitMargin(0));
      dispatch(updateFinanceOption('cash'));
      await handleGatherData();
      setUnsavedChanges(false);
    } catch {}
  };

  const getItemizedPdf = async inputData => {
    try {
      setGetItemizedPdfLoading(true);
      const token = await user.getIdToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/pdf/generate`,
        {
          headers: {
            authorization: `${token}`,
          },
          params: {
            username: user.email,
            csv: inputData.csv,
            stringCsv: JSON.stringify(inputData.csv),
          },
        }
      );
      setPdfBuffer(response.data.data);
      setGetItemizedPdfLoading(false);
      // downloadPdf(response.data.data);
    } catch (error) {
      setGetItemizedPdfLoading(false);
      console.error(error);
    }
  };

  const downloadPdf = () => {
    setPdfLoading(true);
    // Create a Blob from the PDF buffer
    const blob = new Blob([new Uint8Array(pdfBuffer)], {
      type: 'application/pdf',
    });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement('a');
    link.href = url;
    let fileName = jobName;
    if (fileName == '' || fileName == null) {
      fileName = 'Unnamed Job';
    }
    link.download = `${fileName} - Itemized Estimate`;

    // Append the link to the document body
    document.body.appendChild(link);

    // Trigger the download by programmatically clicking the link
    link.click();

    // Clean up and remove the link
    document.body.removeChild(link);

    // Revoke the URL after the download
    URL.revokeObjectURL(url);
    setPdfLoading(false);
  };

  useEffect(() => {
    if (pdfBuffer) {
      // const blob = new Blob([pdfBuffer], { type: 'application/pdf' });
      const blob = new Blob([new Uint8Array(pdfBuffer)], {
        type: 'application/pdf',
      });
      const url = URL.createObjectURL(blob);
      setPdfBlobUrl(url);

      // Cleanup function to revoke the object URL
      return () => URL.revokeObjectURL(url);
    }
  }, [pdfBuffer]);

  const handleAddCustomItem = async () => {
    try {
      const token = await user.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/roofs/add_itemized_custom_item`,
        {
          username: user.email,
          description: customItemDescription,
          quantity: customItemQuantity,
          rate: customItemRate,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      setCustomItemDescription('');
      setCustomItemQuantity(0);
      setCustomItemRate(0);
      await handleGatherData();
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeleteCustomItems = async () => {
    try {
      const token = await user.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/roofs/delete_itemized_custom_items`,
        { username: user.email },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      await handleGatherData();
    } catch (e) {
      console.error(e);
    }
  };

  const handleGatherDataRedeck = async () => {
    try {
      const token = await user.getIdToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/estimator/crew_redeck`,
        {
          headers: {
            authorization: `${token}`,
          },
          params: {
            username: user.email,
          },
        }
      );
      const tempCrewBillTotal = response.data.total;

      const responseTwo = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/estimator/material_redeck`,
        {
          headers: {
            authorization: `${token}`,
          },
          params: {
            username: user.email,
          },
        }
      );
      const tempMaterialListTotal = responseTwo.data.total;

      const responseThree = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/estimator/breakdown`,
        {
          headers: {
            authorization: `${token}`,
          },
          params: {
            username: user.email,
            materialCost: tempMaterialListTotal ?? 0,
            laborCost: tempCrewBillTotal ?? 0,
          },
        }
      );
      let redeckTotal = '$00.00';
      responseThree.data.forEach(item => {
        if (item[0] === 'Price') {
          redeckTotal = item[1];
        }
      });
      return redeckTotal;
    } catch (e) {
      return '$00.00';
    }
  };

  const handleGatherData = async () => {
    setDownloadError(false);
    setCalculateLoading(true);
    setIsCalculateLoading(true);
    try {
      const token = await user.getIdToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/estimator/crew`,
        {
          headers: {
            authorization: `${token}`,
          },
          params: {
            username: user.email,
          },
        }
      );
      setCrewBillData(response.data.csv);
      const tempCrewBillTotal = response.data.total;

      const responseTwo = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/estimator/material`,
        {
          headers: {
            authorization: `${token}`,
          },
          params: {
            username: user.email,
          },
        }
      );
      setMaterialListData(responseTwo.data.csv);
      const tempMaterialListTotal = responseTwo.data.total;

      const responseThree = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/estimator/breakdown`,
        {
          headers: {
            authorization: `${token}`,
          },
          params: {
            username: user.email,
            materialCost: tempMaterialListTotal ?? 0,
            laborCost: tempCrewBillTotal ?? 0,
          },
        }
      );
      setBreakdownData(responseThree.data);
      const responseFour = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/estimator/itemized`,
        {
          headers: {
            authorization: `${token}`,
          },
          params: {
            username: user.email,
          },
        }
      );
      setItemizedData(responseFour.data.csv);
      setPricePerMonth('$00.00');
      let tempTotalPrice = '$00.00';
      responseThree.data.forEach(item => {
        if (item[0] === 'Margin') {
          setMargin(item[1]);
          setMarginPercent(item[2]);
          setProfitMargin(convertPercentToNumber(item[2]));
        } else if (item[0] === 'Applied Discount') {
          setAppliedDiscount(convertPercentToNumber(item[2]));
        } else if (item[0] === 'Available Discount') {
          setAvailableDiscount(convertPercentToNumber(item[2]));
          setAvailableDollarDiscount(item[1]);
        } else if (item[0] === 'Price') {
          tempTotalPrice = item[1];
          setTotalPrice(tempTotalPrice);
        } else if (item[0] === 'Profit') {
          setGrossProfit(item[1]);
          setGrossProfitPercent(item[2]);
        } else if (item[0] === 'Overhead') {
          setOverhead(item[1]);
          setOverheadPercent(item[2]);
        } else if (item[0] === 'Commission') {
          setSalesCommission(item[1]);
          setSalesCommissionPercent(item[2]);
        } else if (item[0] === 'Commission After Tax') {
          setAfterTaxSalesCommission(item[1]);
          setAfterTaxSalesCommissionPercent(item[2]);
        } else if (item[0] === 'Company Profit') {
          setCompanyNet(item[1]);
          setCompanyNetPercent(item[2]);
        } else if (item[0] === 'Company Profit After Tax') {
          setAfterTaxCompanyNet(item[1]);
          setAfterTaxCompanyNetPercent(item[2]);
        } else if (item[0].includes('Price per month')) {
          setPricePerMonth(item[1]);
        } else if (item[0] === 'Price Per Square') {
          setPricePerSq(item[1]);
        }
      });
      const redeckTotal = await handleGatherDataRedeck();
      const redeckRequired = convertToUSD(
        convertUSDToNumber(redeckTotal) - convertUSDToNumber(tempTotalPrice)
      );
      setIfRedeckRequired(redeckRequired);
      getItemizedPdf(responseFour.data);
    } catch (e) {
      console.error(e);
      setDownloadError(true);
    }
    setIsCalculateLoading(false);
    setCalculateLoading(false);
  };

  const debouncedHandleGatherData = useCallback(
    debounce(() => {
      if (user) {
        handleGatherData();
      }
    }, 2000),
    [user]
  );

  const handleTabChange = index => {
    setSelectedTab(index);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        debouncedHandleGatherData();
        setCalculateButtonClicked(false);
      } catch (e) {
        console.error(e);
      }
    };

    if (calculateButtonClicked && user) {
      fetchData();
    }
  }, [calculateButtonClicked, user]);

  useEffect(() => {
    return () => {
      debouncedHandleGatherData.cancel();
    };
  }, [debouncedHandleGatherData]);

  useEffect(() => {
    if (isOpen) {
      debouncedHandleGatherData.cancel();
      handleGatherData();
    }
  }, [isOpen]);

  return (
    <>
      <Modal isOpen={isOpen} size="custom" onClose={onClose}>
        <ModalOverlay>
          <ModalContent maxW="80vw">
            <ModalHeader fontSize="lg" fontWeight="bold"></ModalHeader>
            <ModalBody marginBottom="50px">
              {calculateLoading ? (
                <Center>
                  <VStack>
                    <Spinner
                      thickness="6px"
                      speed="1.25s"
                      size="lg"
                      emptyColor="gray.200"
                      color="black.500"
                    />
                    <Text>Calculating...</Text>
                  </VStack>
                </Center>
              ) : (
                <Center>
                  <VStack spacing="20px">
                    <Tabs
                      variant="enclosed"
                      // variant="soft-rounded"
                      align="center"
                      index={selectedTab}
                      onChange={handleTabChange}
                    >
                      <TabList>
                        <Tab>All</Tab>
                        {true && (
                          <>
                            <Tab>
                              <Box
                                px="2"
                                py="1"
                                bg="gray.100"
                                _hover={{ bg: 'gray.200' }}
                                color="black"
                                borderRadius="md"
                                fontWeight="semibold"
                              >
                                <Text fontSize="sm">Breakdown</Text>
                              </Box>
                            </Tab>
                            <Tab>
                              <Box
                                px="2"
                                py="1"
                                bg="gray.100"
                                _hover={{ bg: 'gray.200' }}
                                color="black"
                                borderRadius="md"
                                fontWeight="semibold"
                              >
                                <Text fontSize="sm">Crew Bill</Text>
                              </Box>
                            </Tab>
                            <Tab>
                              <Box
                                px="2"
                                py="1"
                                bg="gray.100"
                                _hover={{ bg: 'gray.200' }}
                                color="black"
                                borderRadius="md"
                                fontWeight="semibold"
                              >
                                <Text fontSize="sm">Material List</Text>
                              </Box>
                            </Tab>
                          </>
                        )}
                        <Tab>
                          <Box
                            px="2"
                            py="1"
                            bg="gray.100"
                            _hover={{ bg: 'gray.200' }}
                            color="black"
                            borderRadius="md"
                            fontWeight="semibold"
                          >
                            <Text fontSize="sm">Itemized</Text>
                          </Box>
                        </Tab>
                        <Tab>
                          <Box
                            px="2"
                            py="1"
                            bg="gray.100"
                            _hover={{ bg: 'gray.200' }}
                            color="black"
                            borderRadius="md"
                            fontWeight="semibold"
                          >
                            <Text fontSize="sm">Itemized PDF</Text>
                          </Box>
                        </Tab>
                      </TabList>
                      <TabPanels>
                        <TabPanel>
                          <VStack>
                            {true && (
                              <Button
                                marginY="10px"
                                leftIcon={<FaDownload />}
                                colorScheme="green"
                                size="sm"
                                onClick={downloadAll}
                                isLoading={allLoading}
                              >
                                <Text fontSize="xs">Download All</Text>
                              </Button>
                            )}
                          </VStack>
                        </TabPanel>
                        {true && (
                          <TabPanel>
                            <HStack align="flex-start">
                              <TableContainer>
                                <Button
                                  marginY="10px"
                                  leftIcon={<FaDownload />}
                                  colorScheme="green"
                                  size="sm"
                                  onClick={downloadBreakdown}
                                  isLoading={summaryLoading}
                                >
                                  <Text fontSize="xs">Download Breakdown</Text>
                                </Button>
                                <Table
                                  variant="striped"
                                  size="sm"
                                  maxWidth="50%"
                                  marginBottom="50px"
                                >
                                  <Thead>
                                    <Tr>
                                      {breakdownData[0]?.map(
                                        (header, index) => (
                                          <Th key={index}>{header}</Th>
                                        )
                                      )}
                                    </Tr>
                                  </Thead>
                                  <Tbody>
                                    {breakdownData
                                      .slice(1)
                                      ?.map((row, rowIndex) => (
                                        <Tr key={rowIndex}>
                                          {row?.map((cell, cellIndex) => (
                                            <Td key={cellIndex}>
                                              {boldList.includes(cell) ? (
                                                <Text as="b">{cell}</Text>
                                              ) : (
                                                <>{cell}</>
                                              )}
                                            </Td>
                                          ))}
                                        </Tr>
                                      ))}
                                  </Tbody>
                                </Table>
                              </TableContainer>
                            </HStack>
                          </TabPanel>
                        )}
                        {true && (
                          <TabPanel>
                            <TableContainer>
                              <Button
                                marginY="10px"
                                leftIcon={<FaDownload />}
                                colorScheme="green"
                                size="sm"
                                onClick={downloadCrewBill}
                                isLoading={crewBillLoading}
                              >
                                <Text fontSize="xs">Download Crew Bill</Text>
                              </Button>
                              <Table
                                variant="striped"
                                size={isLargerThan1200 ? 'sm' : 'xs'}
                                marginBottom="50px"
                              >
                                <Thead>
                                  <Tr>
                                    {crewBillData[0]?.map((header, index) => (
                                      <Th key={index}>
                                        <Text fontSize="xs">{header}</Text>
                                      </Th>
                                    ))}
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {crewBillData
                                    .slice(1)
                                    ?.map((row, rowIndex) => (
                                      <Tr key={rowIndex}>
                                        {row?.map((cell, cellIndex) => (
                                          <Td key={cellIndex}>
                                            <Text fontSize="xs">{cell}</Text>
                                          </Td>
                                        ))}
                                      </Tr>
                                    ))}
                                </Tbody>
                              </Table>
                            </TableContainer>
                          </TabPanel>
                        )}
                        {true && (
                          <TabPanel>
                            <TableContainer>
                              <Button
                                marginY="10px"
                                leftIcon={<FaDownload />}
                                colorScheme="green"
                                size="sm"
                                onClick={downloadMaterialList}
                                isLoading={materialListLoading}
                              >
                                <Text fontSize="xs">
                                  Download Material List
                                </Text>
                              </Button>
                              <Table
                                variant="striped"
                                size={isLargerThan1200 ? 'sm' : 'xs'}
                              >
                                <Thead>
                                  <Tr>
                                    {materialListData[0]?.map(
                                      (header, index) => (
                                        <Th key={index}>
                                          <Text fontSize="xs">{header}</Text>
                                        </Th>
                                      )
                                    )}
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {materialListData
                                    ?.slice(1)
                                    ?.map((row, rowIndex) => (
                                      <Tr key={rowIndex}>
                                        {row?.map((cell, cellIndex) => (
                                          <Td key={cellIndex}>
                                            <Text fontSize="xs">{cell}</Text>
                                          </Td>
                                        ))}
                                      </Tr>
                                    ))}
                                </Tbody>
                              </Table>
                            </TableContainer>
                          </TabPanel>
                        )}
                        <TabPanel>
                          <TableContainer>
                            <Button
                              marginY="10px"
                              leftIcon={<FaDownload />}
                              colorScheme="green"
                              size="sm"
                              onClick={downloadItemizedList}
                              isLoading={itemizedListLoading}
                            >
                              <Text fontSize="xs">Download Itemized</Text>
                            </Button>
                            <Table variant="striped" size="sm">
                              <Thead>
                                <Tr>
                                  {itemizedData[0]?.map((header, index) => (
                                    <Th key={index}>{header}</Th>
                                  ))}
                                </Tr>
                              </Thead>
                              <Tbody>
                                {itemizedData
                                  ?.slice(1)
                                  ?.map((row, rowIndex) => (
                                    <Tr key={rowIndex}>
                                      {row?.map((cell, cellIndex) => (
                                        <Td key={cellIndex}>{cell} </Td>
                                      ))}
                                    </Tr>
                                  ))}
                              </Tbody>
                            </Table>
                            <HStack py="5">
                              <Text fontSize="sm">Description</Text>
                              <Input
                                size="sm"
                                value={customItemDescription}
                                onChange={event => {
                                  setCustomItemDescription(event.target.value);
                                }}
                              ></Input>
                              <Text fontSize="sm">Qty</Text>
                              <NumberInput
                                size="sm"
                                maxWidth="120px"
                                step={1}
                                min={0}
                                value={customItemQuantity}
                                onChange={value => {
                                  setCustomItemQuantity(value);
                                }}
                              >
                                <NumberInputField />
                                <NumberInputStepper>
                                  <NumberIncrementStepper />
                                  <NumberDecrementStepper />
                                </NumberInputStepper>
                              </NumberInput>
                              <Text fontSize="sm">Rate</Text>
                              <NumberInput
                                size="sm"
                                maxWidth="120px"
                                step={1}
                                min={0}
                                value={customItemRate}
                                onChange={value => {
                                  setCustomItemRate(value);
                                }}
                              >
                                <NumberInputField />
                                <NumberInputStepper>
                                  <NumberIncrementStepper />
                                  <NumberDecrementStepper />
                                </NumberInputStepper>
                              </NumberInput>
                              <IconButton
                                colorScheme="blue"
                                size="sm"
                                aria-label="Add"
                                onClick={() => {
                                  handleAddCustomItem();
                                }}
                                icon={<AddIcon />}
                              />
                            </HStack>
                            <Button
                              rightIcon={<DeleteIcon />}
                              colorScheme="red"
                              size="sm"
                              onClick={handleDeleteCustomItems}
                            >
                              <Text fontSize="xs">Clear</Text>
                            </Button>
                          </TableContainer>
                        </TabPanel>
                        <TabPanel>
                          <VStack>
                            <Button
                              marginY="10px"
                              leftIcon={<FaDownload />}
                              colorScheme="blue"
                              size="sm"
                              onClick={downloadPdf}
                              isLoading={pdfLoading}
                            >
                              <Text fontSize="xs">Download Itemized PDF</Text>
                            </Button>

                            {pdfBlobUrl && (
                              <Box
                                border="2px solid black"
                                display="inline-block"
                              >
                                {getItemizedPdfLoading ? (
                                  <Center>
                                    <VStack>
                                      <Spinner
                                        thickness="6px"
                                        speed="1.25s"
                                        size="lg"
                                        emptyColor="gray.200"
                                        color="black.500"
                                      />
                                      <Text>Calculating...</Text>
                                    </VStack>
                                  </Center>
                                ) : (
                                  <Document file={pdfBlobUrl}>
                                    <Page pageNumber={1} />
                                  </Document>
                                )}
                              </Box>
                            )}
                          </VStack>
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </VStack>
                </Center>
              )}
            </ModalBody>
            <ModalFooter>
              {/* <Box
              position="fixed"
              bottom="10px" // Adjust the positioning as per your requirement
              right="10px" // Adjust the positioning as per your requirement
              zIndex="999" // Set a higher z-index to ensure the button stays on top of other content
              backgroundColor="gray.100" // Set the background color for the container
              padding="1rem" // Add padding to create some space around the button
              borderRadius="md" // Add border radius for rounded corners
            > */}
              <Button onClick={onClose}>Close</Button>
              <Button colorScheme="blue" onClick={handleSend} ml={3}>
                Send
              </Button>
              {/* </Box> */}
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
      <AlertDialog
        isOpen={isSendOpen}
        leastDestructiveRef={cancelSendRef}
        onClose={onSendClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Send Estimate
            </AlertDialogHeader>
            <AlertDialogBody>
              <VStack align="flex-start" spacing="20px">
                <Text>Send current estimate to your administrator?</Text>
              </VStack>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelSendRef} onClick={onSendClose}>
                Cancel
              </Button>
              <Button colorScheme="blue" onClick={onSendConfirm} ml={3}>
                {isSendLoading ? (
                  <Spinner
                    thickness="4px"
                    speed="1.25s"
                    size="md"
                    emptyColor="gray.200"
                    color="gray.800"
                  />
                ) : (
                  <Text>Send</Text>
                )}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default Calculate;
