import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Text,
  HStack,
  Flex,
  Link,
  Stack,
  Divider,
  Checkbox,
  NumberInput,
  NumberInputField,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Radio,
  RadioGroup,
  Select,
  Spacer,
  Spinner,
  TableContainer,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useDisclosure,
  Alert,
  AlertIcon,
  VStack,
  AlertTitle,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateVentingIsCompleted,
  addToRoofLouversList,
  removeFromRoofLouversList,
  addToTurbineVentsList,
  removeFromTurbineVentsList,
  addToExhaustVentsList,
  removeFromExhaustVentsList,
  addToPowerVentsList,
  removeFromPowerVentsList,
  addToMidroofIntakeVentsList,
  removeFromMidroofIntakeVentsList,
  updateVentingField,
  updateRoofLouversList,
  updateTurbineVentsList,
  updateExhaustVentsList,
  updatePowerVentsList,
  clearRoofLouversList,
  clearTurbineVentsList,
  updateMidroofIntakeVentsList,
  clearExhaustVentsList,
  clearPowerVentsList,
  clearMidroofIntakeVentList,
} from '../features/calculator/calculatorSlice';
import useUser from '../hooks/useUser';
import mixpanel from 'mixpanel-browser';

const Venting = ({
  checkVentingCompletion,
  isVentingComplete,
  setCalculateButtonClicked,
  isMobileDevice,
}) => {
  const { user } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();

  const venting = useSelector(state => state.calculator.venting);
  const roofLouversList = useSelector(
    state => state.calculator.venting.roofLouversList
  );
  const turbineVentsList = useSelector(
    state => state.calculator.venting.turbineVentsList
  );
  const exhaustVentsList = useSelector(
    state => state.calculator.venting.exhaustVentsList
  );
  const powerVentsList = useSelector(
    state => state.calculator.venting.powerVentsList
  );
  const midroofIntakeVentsList = useSelector(
    state => state.calculator.venting.midroofIntakeVentsList
  );
  const [openLoading, setOpenLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [unsavedChanged, setUnsavedChanges] = useState(false);

  const handleAddRoofLouverRow = () => {
    setUnsavedChanges(true);
    let tempNumber;
    if (roofLouversList.length > 0) {
      tempNumber = roofLouversList[roofLouversList.length - 1].louverNumber + 1;
    } else {
      tempNumber = 1;
    }
    const newRow = {
      louverNumber: tempNumber,
      count: '',
      action: '',
      color: '',
    };
    dispatch(addToRoofLouversList(newRow));
  };

  const handleDeleteRoofLouverRow = id => {
    setUnsavedChanges(true);
    dispatch(removeFromRoofLouversList(id));
  };

  const handleAddTurbineVentRow = () => {
    setUnsavedChanges(true);
    let tempNumber;
    if (turbineVentsList.length > 0) {
      tempNumber =
        turbineVentsList[turbineVentsList.length - 1].turbineVentNumber + 1;
    } else {
      tempNumber = 1;
    }
    const newRow = {
      turbineVentNumber: tempNumber,
      count: '',
      action: '',
      color: '',
    };
    dispatch(addToTurbineVentsList(newRow));
  };

  const handleDeleteTurbineVentRow = id => {
    setUnsavedChanges(true);
    dispatch(removeFromTurbineVentsList(id));
  };

  const handleAddExhaustVentRow = () => {
    setUnsavedChanges(true);
    let tempNumber;
    if (exhaustVentsList.length > 0) {
      tempNumber =
        exhaustVentsList[exhaustVentsList.length - 1].exhaustVentNumber + 1;
    } else {
      tempNumber = 1;
    }
    const newRow = {
      exhaustVentNumber: tempNumber,
      count: '',
      action: '',
      size: '',
    };
    dispatch(addToExhaustVentsList(newRow));
  };

  const handleDeleteExhaustVentRow = id => {
    setUnsavedChanges(true);
    dispatch(removeFromExhaustVentsList(id));
  };

  const handleAddPowerVentRow = () => {
    setUnsavedChanges(true);
    let tempNumber;
    if (powerVentsList.length > 0) {
      tempNumber =
        powerVentsList[powerVentsList.length - 1].powerVentNumber + 1;
    } else {
      tempNumber = 1;
    }
    const newRow = {
      powerVentNumber: tempNumber,
      count: '',
      action: '',
    };
    dispatch(addToPowerVentsList(newRow));
  };

  const handleDeletePowerVentRow = id => {
    setUnsavedChanges(true);
    dispatch(removeFromPowerVentsList(id));
  };

  const handleAddIntakeVentRow = () => {
    setUnsavedChanges(true);
    let tempNumber;
    if (midroofIntakeVentsList.length > 0) {
      tempNumber =
        midroofIntakeVentsList[midroofIntakeVentsList.length - 1]
          .midroofIntakeVentNumber + 1;
    } else {
      tempNumber = 1;
    }
    const newRow = {
      midroofIntakeVentNumber: tempNumber,
      action: '',
      length: '',
    };
    dispatch(addToMidroofIntakeVentsList(newRow));
  };

  const handleDeleteIntakeVentRow = id => {
    setUnsavedChanges(true);
    dispatch(removeFromMidroofIntakeVentsList(id));
  };

  const handleOpen = async () => {
    setOpenLoading(true);
    // if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    //   mixpanel.track('User opened the Venting modal.');
    // }
    onOpen();
  };

  const handleSave = async () => {
    setSaveLoading(true);
    try {
      const token = await user.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/roofs/venting`,
        {
          username: user.email,
          venting,
          isCompleted: false,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      setUnsavedChanges(false);
      checkVentingCompletion(venting);
      console.log('here');
      setCalculateButtonClicked(true);
      console.log('there');
      onClose();
    } catch (error) {
      console.log('error: ' + error);
    }
    setOpenLoading(false);
    setSaveLoading(false);
  };

  return (
    <Box
      minW={isMobileDevice ? '90vw' : ''}
      maxW="sm"
      maxH="70px"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={isMobileDevice ? '3' : '6'}
      boxShadow="base"
      borderColor="gray.400"
      bg={isVentingComplete ? 'green.300' : 'yellow.200'}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      position="relative"
      cursor="pointer"
      onClick={isMobileDevice ? handleOpen : null}
    >
      <Flex alignItems="center">
        <Text fontWeight="bold" fontSize="l" mr="4">
          Venting
        </Text>
        {!isMobileDevice && (
          <Button
            size="sm"
            border="1px"
            borderColor="gray.400"
            ml="30px"
            minWidth="53px"
            onClick={handleOpen}
          >
            {false ? (
              <Spinner
                thickness="2px"
                speed="1.25s"
                size="sm"
                emptyColor="gray.200"
                color="black.500"
              />
            ) : (
              <Text>Edit</Text>
            )}
          </Button>
        )}
        <Modal isOpen={isOpen} size="custom" onClose={onClose}>
          <ModalOverlay />
          <ModalContent maxW={isMobileDevice ? '100vw' : '80vw'}>
            <ModalHeader>
              <HStack>
                <Text>Venting</Text>
                <Link
                  href="http://www.gaf.com/roofing/residential/products/roof_vents/ventilation_calculator"
                  isExternal
                >
                  <ExternalLinkIcon mx="2" />
                </Link>
              </HStack>
            </ModalHeader>
            <ModalBody>
              <HStack marginBottom="20px">
                <Text
                  fontSize="sm"
                  fontWeight="bold"
                  mb="1"
                  paddingRight="50px"
                >
                  Ridge Vent
                </Text>
                <Button
                  size="sm"
                  colorScheme="blue"
                  variant={venting.isNoneVenting ? 'solid' : 'outline'}
                  onClick={() => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateVentingField({
                        newValue: false,
                        fieldToUpdate: 'hasRidgeVent',
                      })
                    );
                    dispatch(
                      updateVentingField({
                        newValue: false,
                        fieldToUpdate: 'hasCutIn',
                      })
                    );
                    dispatch(
                      updateVentingField({
                        newValue: '',
                        fieldToUpdate: 'ridgeVentLength',
                      })
                    );
                    dispatch(
                      updateVentingField({
                        newValue: '',
                        fieldToUpdate: 'cutInLength',
                      })
                    );

                    dispatch(
                      updateVentingField({
                        newValue: !venting.isNoneVenting,
                        fieldToUpdate: 'isNoneVenting',
                      })
                    );
                  }}
                >
                  None
                </Button>
              </HStack>
              {!venting.isNoneVenting && (
                <VStack align="flex-start">
                  <HStack
                    marginBottom="10px"
                    spacing={isMobileDevice ? '10px' : '50px'}
                  >
                    <RadioGroup
                      value={venting.hasRidgeVent}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateVentingField({
                            newValue: value === 'true',
                            fieldToUpdate: 'hasRidgeVent',
                          })
                        );
                      }}
                    >
                      <Stack direction="row" gap="3" mr="10">
                        <Text fontSize={isMobileDevice ? 'xs' : 'med'}>
                          Replace Existing
                        </Text>
                        <Radio value={true}>
                          <Text fontSize={isMobileDevice ? 'xs' : 'med'} as="b">
                            Yes
                          </Text>
                        </Radio>
                        <Radio value={false}>
                          <Text fontSize={isMobileDevice ? 'xs' : 'med'} as="b">
                            No
                          </Text>
                        </Radio>
                      </Stack>
                    </RadioGroup>
                    <HStack>
                      <NumberInput
                        size="xs"
                        width={isMobileDevice ? '80px' : '100px'}
                        value={venting.ridgeVentLength ?? ''}
                        onChange={value => {
                          setUnsavedChanges(true);
                          dispatch(
                            updateVentingField({
                              newValue: value,
                              fieldToUpdate: 'ridgeVentLength',
                            })
                          );
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                      <Text
                        fontSize={isMobileDevice ? 'xs' : 'sm'}
                        // paddingRight="150px"
                      >
                        {isMobileDevice ? 'LF' : ' Linear Feet'}
                      </Text>
                    </HStack>
                  </HStack>
                  <HStack spacing={isMobileDevice ? '10px' : '50px'}>
                    <RadioGroup
                      value={venting.hasCutIn}
                      onChange={value => {
                        setUnsavedChanges(true);
                        dispatch(
                          updateVentingField({
                            newValue: value === 'true',
                            fieldToUpdate: 'hasCutIn',
                          })
                        );
                      }}
                    >
                      <Stack direction="row" gap="3" mr="10">
                        <Text fontSize={isMobileDevice ? 'xs' : 'med'}>
                          Cut In New
                        </Text>
                        <Radio value={true}>
                          <Text as="b" fontSize={isMobileDevice ? 'xs' : 'med'}>
                            Yes
                          </Text>
                        </Radio>
                        <Radio value={false}>
                          <Text as="b" fontSize={isMobileDevice ? 'xs' : 'med'}>
                            No
                          </Text>
                        </Radio>
                      </Stack>
                    </RadioGroup>
                    <HStack>
                      <NumberInput
                        size="xs"
                        width={isMobileDevice ? '80px' : '100px'}
                        value={venting.cutInLength ?? ''}
                        onChange={value => {
                          setUnsavedChanges(true);
                          dispatch(
                            updateVentingField({
                              newValue: value,
                              fieldToUpdate: 'cutInLength',
                            })
                          );
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                      <Text fontSize={isMobileDevice ? 'xs' : 'sm'}>
                        {isMobileDevice ? 'LF' : ' Linear Feet'}
                      </Text>
                    </HStack>
                  </HStack>
                </VStack>
              )}
              <Divider mt="5" mb="5" borderColor="gray.600" />
              <HStack marginBottom="10px">
                <Text
                  fontSize="sm"
                  fontWeight="bold"
                  mb="1"
                  paddingRight="50px"
                >
                  Roof Louvers
                </Text>
                <Button
                  size="sm"
                  colorScheme="blue"
                  variant={roofLouversList.length > 0 ? 'outline' : 'solid'}
                  onClick={() => {
                    setUnsavedChanges(true);
                    if (roofLouversList.length > 0) {
                      dispatch(clearRoofLouversList());
                    } else {
                      handleAddRoofLouverRow();
                    }
                  }}
                >
                  None
                </Button>
              </HStack>
              {roofLouversList.length > 0 && (
                <Box overflowX="auto">
                  <TableContainer>
                    <Table size="xs">
                      <Thead>
                        <Tr>
                          <Th fontSize="xs">Count</Th>
                          <Th fontSize="xs">Action</Th>
                          {/* <Th fontSize="xs">Color</Th> */}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {roofLouversList.map((row, index) => (
                          <Tr key={index}>
                            <Td>
                              <NumberInput
                                size="xs"
                                width="100px"
                                value={row.count ?? ''}
                                onChange={value => {
                                  setUnsavedChanges(true);
                                  dispatch(
                                    updateRoofLouversList({
                                      newValue: value,
                                      louverNumber: row.louverNumber,
                                      fieldToUpdate: 'count',
                                    })
                                  );
                                }}
                              >
                                <NumberInputField />
                                <NumberInputStepper>
                                  <NumberIncrementStepper />
                                  <NumberDecrementStepper />
                                </NumberInputStepper>
                              </NumberInput>
                            </Td>
                            <Td>
                              <Select
                                size="xs"
                                width="150px"
                                value={row.action}
                                onChange={event => {
                                  setUnsavedChanges(true);
                                  dispatch(
                                    updateRoofLouversList({
                                      newValue: event.target.value,
                                      louverNumber: row.louverNumber,
                                      fieldToUpdate: 'action',
                                    })
                                  );
                                }}
                              >
                                <option value=""></option>
                                <option value="replace">Replace</option>
                                <option value="reuse">Reuse</option>
                                <option value="remove">Remove/fill in</option>
                                <option value="cut-in">Cut in new</option>
                              </Select>
                            </Td>
                            {/* <Td>
                          <Select
                            size="xs"
                            width="150px"
                            value={row.color}
                            onChange={event => {
                              setUnsavedChanges(true);
                              dispatch(
                                updateRoofLouversList({
                                  newValue: event.target.value,
                                  louverNumber: row.louverNumber,
                                  fieldToUpdate: 'color',
                                })
                              );
                            }}
                          >
                            <option value=""></option>
                            <option value="brown">Black</option>
                            <option value="black">Brown</option>
                            <option value="gray">Gray</option>
                          </Select>
                        </Td> */}
                            <Td>
                              <Button
                                size="xs"
                                variant="outline"
                                onClick={() =>
                                  handleDeleteRoofLouverRow(row.louverNumber)
                                }
                              >
                                Delete
                              </Button>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
              <Button mt="2" size="xs" onClick={handleAddRoofLouverRow}>
                Add
              </Button>
              <Divider mt="5" mb="5" borderColor="gray.600" />
              <Box>
                <HStack marginBottom="10px">
                  <Text
                    fontSize="sm"
                    fontWeight="bold"
                    mb="2"
                    paddingRight="50px"
                  >
                    Turbine Vents
                  </Text>
                  <Button
                    size="sm"
                    colorScheme="blue"
                    variant={turbineVentsList.length > 0 ? 'outline' : 'solid'}
                    onClick={() => {
                      setUnsavedChanges(true);
                      if (turbineVentsList.length > 0) {
                        dispatch(clearTurbineVentsList());
                      } else {
                        handleAddTurbineVentRow();
                      }
                    }}
                  >
                    None
                  </Button>
                </HStack>
                {turbineVentsList.length > 0 && (
                  <Box overflowX="auto">
                    <TableContainer>
                      <Table size="xs">
                        <Thead>
                          <Tr>
                            <Th fontSize="xs">Count</Th>
                            <Th fontSize="xs">Action</Th>
                            {/* <Th fontSize="xs">Color</Th> */}
                          </Tr>
                        </Thead>
                        <Tbody>
                          {turbineVentsList.map((row, index) => (
                            <Tr key={index}>
                              <Td>
                                <NumberInput
                                  size="xs"
                                  width="100px"
                                  value={row.count ?? ''}
                                  onChange={value => {
                                    setUnsavedChanges(true);
                                    dispatch(
                                      updateTurbineVentsList({
                                        newValue: value,
                                        turbineVentNumber:
                                          row.turbineVentNumber,
                                        fieldToUpdate: 'count',
                                      })
                                    );
                                  }}
                                >
                                  <NumberInputField />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </Td>
                              <Td>
                                <Select
                                  size="xs"
                                  width="150px"
                                  value={row.action}
                                  onChange={event => {
                                    setUnsavedChanges(true);
                                    dispatch(
                                      updateTurbineVentsList({
                                        newValue: event.target.value,
                                        turbineVentNumber:
                                          row.turbineVentNumber,
                                        fieldToUpdate: 'action',
                                      })
                                    );
                                  }}
                                >
                                  <option value=""></option>
                                  <option value="replace">Replace</option>
                                  <option value="reuse">Reuse</option>
                                  <option value="remove">Remove/fill in</option>
                                  <option value="cut-in">Cut in new</option>
                                </Select>
                              </Td>
                              {/* <Td>
                            <Select
                              size="xs"
                              width="150px"
                              value={row.color}
                              onChange={event => {
                                setUnsavedChanges(true);
                                dispatch(
                                  updateTurbineVentsList({
                                    newValue: event.target.value,
                                    turbineVentNumber: row.turbineVentNumber,
                                    fieldToUpdate: 'color',
                                  })
                                );
                              }}
                            >
                              <option value=""></option>
                              <option value="black">Black</option>
                              <option value="brown">Brown</option>
                              <option value="gray">Gray</option>
                              <option value="galvanized">Galvanized</option>
                            </Select>
                          </Td> */}
                              <Td>
                                <Button
                                  size="xs"
                                  variant="outline"
                                  onClick={() =>
                                    handleDeleteTurbineVentRow(
                                      row.turbineVentNumber
                                    )
                                  }
                                >
                                  Delete
                                </Button>
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
                <Button mt="2" size="xs" onClick={handleAddTurbineVentRow}>
                  Add
                </Button>
              </Box>
              <Divider mt="5" mb="5" borderColor="gray.600" />
              <Box>
                <HStack marginBottom="10px">
                  <Text
                    fontSize="sm"
                    fontWeight="bold"
                    mb="2"
                    paddingRight="50px"
                  >
                    Kitchen/Bath Exhaust Vents
                  </Text>
                  <Button
                    size="sm"
                    colorScheme="blue"
                    variant={exhaustVentsList.length > 0 ? 'outline' : 'solid'}
                    onClick={() => {
                      setUnsavedChanges(true);
                      if (exhaustVentsList.length > 0) {
                        dispatch(clearExhaustVentsList());
                      } else {
                        handleAddExhaustVentRow();
                      }
                    }}
                  >
                    None
                  </Button>
                </HStack>
                {exhaustVentsList.length > 0 && (
                  <Box overflowX="auto">
                    <TableContainer>
                      <Table size="xs">
                        <Thead>
                          <Tr>
                            <Th fontSize="xs">Count</Th>
                            <Th fontSize="xs">Size</Th>
                            <Th fontSize="xs">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {exhaustVentsList.map((row, index) => (
                            <Tr key={index}>
                              <Td>
                                <NumberInput
                                  size="xs"
                                  width={isMobileDevice ? '80px' : '100px'}
                                  value={row.count ?? ''}
                                  onChange={value => {
                                    setUnsavedChanges(true);
                                    dispatch(
                                      updateExhaustVentsList({
                                        newValue: value,
                                        exhaustVentNumber:
                                          row.exhaustVentNumber,
                                        fieldToUpdate: 'count',
                                      })
                                    );
                                  }}
                                >
                                  <NumberInputField />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </Td>
                              <Td>
                                <Select
                                  size="xs"
                                  width={isMobileDevice ? '100px' : '150px'}
                                  value={row.size}
                                  onChange={event => {
                                    setUnsavedChanges(true);
                                    dispatch(
                                      updateExhaustVentsList({
                                        newValue: event.target.value,
                                        exhaustVentNumber:
                                          row.exhaustVentNumber,
                                        fieldToUpdate: 'size',
                                      })
                                    );
                                  }}
                                >
                                  <option value=""></option>
                                  <option value="small">Small</option>
                                  <option value="large">Large</option>
                                </Select>
                              </Td>
                              <Td>
                                <Select
                                  size="xs"
                                  width={isMobileDevice ? '100px' : '150px'}
                                  value={row.action}
                                  onChange={event => {
                                    setUnsavedChanges(true);
                                    dispatch(
                                      updateExhaustVentsList({
                                        newValue: event.target.value,
                                        exhaustVentNumber:
                                          row.exhaustVentNumber,
                                        fieldToUpdate: 'action',
                                      })
                                    );
                                  }}
                                >
                                  <option value=""></option>
                                  <option value="replace">Replace</option>
                                  <option value="reuse">Reuse</option>
                                  <option value="remove">Remove/fill in</option>
                                  <option value="cut-in">Cut in new</option>
                                </Select>
                              </Td>
                              <Td>
                                <Button
                                  size="xs"
                                  variant="outline"
                                  onClick={() =>
                                    handleDeleteExhaustVentRow(
                                      row.exhaustVentNumber
                                    )
                                  }
                                >
                                  Delete
                                </Button>
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
                <Button mt="2" size="xs" onClick={handleAddExhaustVentRow}>
                  Add
                </Button>
              </Box>
              <Divider mt="5" mb="5" borderColor="gray.600" />
              <Box>
                <HStack marginBottom="10px">
                  <Text
                    fontSize="sm"
                    fontWeight="bold"
                    mb="2"
                    paddingRight="50px"
                  >
                    Power Vents
                  </Text>
                  <Button
                    size="sm"
                    colorScheme="blue"
                    variant={powerVentsList.length > 0 ? 'outline' : 'solid'}
                    onClick={() => {
                      setUnsavedChanges(true);
                      if (powerVentsList.length > 0) {
                        dispatch(clearPowerVentsList());
                      } else {
                        handleAddPowerVentRow();
                      }
                    }}
                  >
                    None
                  </Button>
                </HStack>
                {powerVentsList.length > 0 && (
                  <Box overflowX="auto">
                    <TableContainer>
                      <Table size="xs">
                        <Thead>
                          <Tr>
                            <Th fontSize="xs">Count</Th>
                            <Th fontSize="xs">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {powerVentsList.map((row, index) => (
                            <Tr key={index}>
                              <Td>
                                <NumberInput
                                  size="xs"
                                  width="100px"
                                  value={row.count ?? ''}
                                  onChange={value => {
                                    setUnsavedChanges(true);
                                    dispatch(
                                      updatePowerVentsList({
                                        newValue: value,
                                        powerVentNumber: row.powerVentNumber,
                                        fieldToUpdate: 'count',
                                      })
                                    );
                                  }}
                                >
                                  <NumberInputField />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </Td>
                              <Td>
                                <Select
                                  size="xs"
                                  width="150px"
                                  value={row.action}
                                  onChange={event => {
                                    setUnsavedChanges(true);
                                    dispatch(
                                      updatePowerVentsList({
                                        newValue: event.target.value,
                                        powerVentNumber: row.powerVentNumber,
                                        fieldToUpdate: 'action',
                                      })
                                    );
                                  }}
                                >
                                  <option value=""></option>
                                  <option value="replace">Replace</option>
                                  <option value="reuse">Reuse</option>
                                  <option value="remove">Remove/fill in</option>
                                  <option value="cut-in">Cut in new</option>
                                </Select>
                              </Td>
                              <Td>
                                <Button
                                  size="xs"
                                  variant="outline"
                                  onClick={() =>
                                    handleDeletePowerVentRow(
                                      row.powerVentNumber
                                    )
                                  }
                                >
                                  Delete
                                </Button>
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
                <Button mt="2" size="xs" onClick={handleAddPowerVentRow}>
                  Add
                </Button>
              </Box>
              <Divider mt="5" mb="5" borderColor="gray.600" />
              <Box>
                <HStack marginBottom="10px">
                  <Text
                    fontSize="sm"
                    fontWeight="bold"
                    mb="2"
                    paddingRight="50px"
                  >
                    Midroof Intake Venting
                  </Text>
                  <Button
                    size="sm"
                    colorScheme="blue"
                    variant={
                      midroofIntakeVentsList.length > 0 ? 'outline' : 'solid'
                    }
                    onClick={() => {
                      setUnsavedChanges(true);
                      if (midroofIntakeVentsList.length > 0) {
                        dispatch(clearMidroofIntakeVentList());
                      } else {
                        handleAddIntakeVentRow();
                      }
                    }}
                  >
                    None
                  </Button>
                </HStack>
                {midroofIntakeVentsList.length > 0 && (
                  <Box overflowX="auto">
                    <TableContainer>
                      <Table size="xs">
                        <Thead>
                          <Tr>
                            <Th fontSize="xs">Linear Feet</Th>
                            <Th fontSize="xs">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {midroofIntakeVentsList.map((row, index) => (
                            <Tr key={index}>
                              <Td>
                                <NumberInput
                                  size="xs"
                                  width={isMobileDevice ? '80px' : '150px'}
                                  value={row.length ?? ''}
                                  onChange={value => {
                                    setUnsavedChanges(true);
                                    dispatch(
                                      updateMidroofIntakeVentsList({
                                        newValue: value,
                                        midroofIntakeVentNumber:
                                          row.midroofIntakeVentNumber,
                                        fieldToUpdate: 'length',
                                      })
                                    );
                                  }}
                                >
                                  <NumberInputField />
                                </NumberInput>
                              </Td>
                              <Td>
                                <Select
                                  size="xs"
                                  width={isMobileDevice ? '100px' : '150px'}
                                  value={row.action ?? ''}
                                  onChange={event => {
                                    setUnsavedChanges(true);
                                    dispatch(
                                      updateMidroofIntakeVentsList({
                                        newValue: event.target.value,
                                        midroofIntakeVentNumber:
                                          row.midroofIntakeVentNumber,
                                        fieldToUpdate: 'action',
                                      })
                                    );
                                  }}
                                >
                                  <option value=""></option>
                                  <option value="replace">Replace</option>
                                  <option value="cut-in">Cut in new</option>
                                </Select>
                              </Td>
                              <Td>
                                <Button
                                  size="xs"
                                  variant="outline"
                                  onClick={() =>
                                    handleDeleteIntakeVentRow(
                                      row.midroofIntakeVentNumber
                                    )
                                  }
                                >
                                  Delete
                                </Button>
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
                <Button mt="2" size="xs" onClick={handleAddIntakeVentRow}>
                  Add
                </Button>
              </Box>
            </ModalBody>
            <ModalFooter>
              {/* {unsavedChanged && (
                <Box
                  width="30%"
                  mr="200px"
                  mb="10px"
                  position="fixed"
                  bottom="10px"
                  right="10px"
                  backgroundColor="white"
                  p="10px"
                >
                  <Alert status="warning">
                    <AlertIcon />
                    <AlertTitle>You have unsaved changes!</AlertTitle>
                  </Alert>
                </Box>
              )}
              <Box
                position="fixed"
                bottom="10px" // Adjust the positioning as per your requirement
                right="10px" // Adjust the positioning as per your requirement
                zIndex="999" // Set a higher z-index to ensure the button stays on top of other content
                backgroundColor="white" // Set the background color for the container
                padding="1rem" // Add padding to create some space around the button
                borderRadius="md" // Add border radius for rounded corners
                ml="60px"
              >
                <Button onClick={handleSave} colorScheme="blue" ml="5px">
                  {saveLoading ? (
                    <Spinner
                      thickness="4px"
                      speed="1.25s"
                      size="md"
                      emptyColor="gray.200"
                      color="gray.800"
                    />
                  ) : (
                    <Text>Save</Text>
                  )}
                </Button>
              </Box> */}
              {unsavedChanged && (
                <Box>
                  <Alert status="warning" maxWidth="sm">
                    <AlertIcon />
                    <AlertTitle>You have unsaved changes!</AlertTitle>
                  </Alert>
                </Box>
              )}
              <Button onClick={handleSave} colorScheme="blue" ml="60px">
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </Box>
  );
};

export default Venting;
