import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Heading,
  Text,
  Link,
} from '@chakra-ui/react';
import { registerWithEmailAndPassword } from '../firebase';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import axios from 'axios';
import useUser from '../hooks/useUser';
import { useMediaQuery } from '@chakra-ui/react';

function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { user } = useUser();
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)');

  const handleSubmit = async event => {
    try {
      event.preventDefault();
      await registerWithEmailAndPassword(email, password);
      // window.location.reload();
      if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
        mixpanel.identify(email);
        mixpanel.track('Sign Up');
      }
      navigate('/estimator');
    } catch (e) {
      console.error(e);
      alert('There was an error. Please try again.');
    }
  };

  return (
    <Box>
      <Box
        bg="gray.200"
        maxW="xs"
        mx="auto"
        mt={isLargerThan800 ? 200 : 150}
        p={isLargerThan800 ? 4 : 2}
        borderRadius={20}
      >
        <Box textAlign="center">
          <Heading mb={4} size={isLargerThan800 ? 'lg' : 'sm'}>
            Sign Up
          </Heading>
        </Box>
        <form onSubmit={handleSubmit} autocomplete="off">
          <FormControl id="email" isRequired>
            <FormLabel>Email address</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="Enter your email"
              bg="white"
            />
          </FormControl>
          <FormControl mt={4} isRequired autoComplete="off">
            <FormLabel>Password</FormLabel>
            <Input
              // type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder="Enter your password"
              bg="white"
              autoComplete="off"
            />
          </FormControl>
          <Box textAlign="center">
            <Button type="submit" colorScheme="blue" mt={4} w="100%">
              Sign Up
            </Button>
          </Box>
        </form>
        <Text mt={4} textAlign="center">
          <Link href="/login" color="blue.600">
            Already have an account? Log in
          </Link>
        </Text>
      </Box>
    </Box>
  );
}

export default SignUp;
