import {
  Box,
  Button,
  Center,
  VStack,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  HStack,
  Text,
  Spinner,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { updatePrice } from '../features/price/priceSlice';
import { CheckIcon, SmallCloseIcon } from '@chakra-ui/icons';

import ShinglesPrices from './pricing/ShinglesPrices';
import HipRidgeCap from './pricing/HipRidgeCap';
import StarterStrip from './pricing/StarterStrip';
import RoofDeck from './pricing/RoofDeck';
import VentingPrices from './pricing/VentingPrices';
import FlashingPrices from './pricing/FlashingPrices';
import OtherMaterials from './pricing/OtherMaterials';
import useUser from '../hooks/useUser';
import mixpanel from 'mixpanel-browser';

const Pricing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useUser();
  const [isShinglesComplete, setIsShinglesComplete] = useState(false);
  const [isHipRidgeCapComplete, setIsHipRidgeCapComplete] = useState(false);
  const [isStarterStripComplete, setIsStarterStripComplete] = useState(false);
  const [isRoofDeckComplete, setIsRoofDeckComplete] = useState(false);
  const [isIceWaterComplete, setIsIceWaterComplete] = useState(false);
  const [isUnderlaymentComplete, setIsUnderlaymentComplete] = useState(false);
  const [isVentingComplete, setIsVentingComplete] = useState(false);
  const [isRoofLouversComplete, setIsRoofLouversComplete] = useState(false);
  const [isTurbineVentsComplete, setIsTurbineVentsComplete] = useState(false);
  const [isPowerVentsComplete, setIsPowerVentsComplete] = useState(false);
  const [isRidgeVentsComplete, setIsRidgeVentsComplete] = useState(false);
  const [isMidRoofIntakeVentsComplete, setIsMidRoofIntakeVentsComplete] =
    useState(false);
  const [isFlashingComplete, setIsFlashingComplete] = useState(false);
  const [isKitchenComplete, setIsKitchenComplete] = useState(false);
  const [isPipeFlashingComplete, setIsPipeFlashingComplete] = useState(false);
  const [isMetalFlashingComplete, setIsMetalFlashingComplete] = useState(false);
  const [isGuttersFlashingComplete, setIsGuttersFlashingComplete] =
    useState(false);
  const [isOtherFlashingComplete, setIsOtherFlashingComplete] = useState(false);
  const [isOtherComplete, setIsOtherComplete] = useState(false);
  const [isSkylightKitComplete, setIsSkylightKitComplete] = useState(false);
  const [isTrimCoilComplete, setIsTrimCoilComplete] = useState(false);
  const [isNailsComplete, setIsNailsComplete] = useState(false);
  const [isCaulkComplete, setIsCaulkComplete] = useState(false);
  const [isDeckingComplete, setIsDeckingComplete] = useState(false);
  const [isPaintComplete, setIsPaintComplete] = useState(false);
  const [isSkylightsComplete, setIsSkylightsComplete] = useState(false);
  const shingles = useSelector(state => state.price.shingles);
  const warranty = useSelector(state => state.price.warranty);

  const handleClick = () => {
    navigate('/estimator');
  };

  const checkOtherCompletion = (other, savedList) => {
    const trimCoil = other?.trimCoil;
    const skylightKit = other?.skylightFlashing;
    const nails = other?.nails;
    const staples = other?.staples;
    const caulking = other?.caulking;
    const decking = other?.decking;
    const paint = other?.paint;
    const skylights = other?.skylights;
    const isTrimCoilComplete =
      trimCoil &&
      trimCoil.size &&
      trimCoil.size !== '' &&
      trimCoil.unit != null &&
      trimCoil.unit !== '' &&
      trimCoil.pricePer != null &&
      trimCoil.pricePer !== '';
    setIsTrimCoilComplete(isTrimCoilComplete);
    const isSkylightKitComplete =
      savedList?.includes('skylightFlashingKit') &&
      skylightKit &&
      skylightKit.smallPricePer != null &&
      skylightKit.smallPricePer !== '' &&
      skylightKit.mediumPricePer != null &&
      skylightKit.mediumPricePer !== '' &&
      skylightKit.largePricePer != null &&
      skylightKit.largePricePer !== '';
    setIsSkylightKitComplete(isSkylightKitComplete);
    const isNailsComplete =
      savedList?.includes('fasteners') &&
      nails &&
      nails.coil.manufacturer &&
      nails.coil.manufacturer !== '' &&
      nails.coil.size &&
      nails.coil.size !== '' &&
      nails.coil.perBox != null &&
      nails.coil.perBox !== '' &&
      nails.coil.pricePer != null &&
      nails.coil.pricePer !== '' &&
      nails.hand.manufacturer &&
      nails.hand.manufacturer !== '' &&
      nails.hand.size &&
      nails.hand.size !== '' &&
      nails.hand.perBox != null &&
      nails.hand.perBox !== '' &&
      nails.hand.pricePer != null &&
      nails.hand.pricePer !== '' &&
      nails.cap.manufacturer &&
      nails.cap.manufacturer !== '' &&
      nails.cap.size &&
      nails.cap.size !== '' &&
      nails.cap.perBox != null &&
      nails.cap.perBox !== '' &&
      nails.cap.pricePer != null &&
      nails.cap.pricePer !== '' &&
      staples &&
      staples.manufacturer &&
      staples.manufacturer !== '' &&
      staples.stapleType &&
      staples.stapleType !== '' &&
      staples.size &&
      staples.size !== '' &&
      staples.perBox != null &&
      staples.perBox !== '' &&
      staples.pricePer != null &&
      staples.pricePer !== '';
    setIsNailsComplete(isNailsComplete);
    const isCaulkComplete =
      savedList?.includes('roofCaulking') &&
      caulking &&
      caulking.manufacturer &&
      caulking.manufacturer !== '' &&
      caulking.line &&
      caulking.line !== '' &&
      caulking.color &&
      caulking.color !== '' &&
      caulking.tubesPer != null &&
      caulking.tubesPer !== '' &&
      caulking.pricePer != null &&
      caulking.pricePer !== '';
    setIsCaulkComplete(isCaulkComplete);
    const isDeckingComplete =
      savedList?.includes('decking') &&
      decking &&
      decking.plugPricePer != null &&
      decking.plugPricePer !== '' &&
      decking.oneBySixRoofBoards != null &&
      decking.oneBySixRoofBoards !== '' &&
      decking.eightFootTwoByFour != null &&
      decking.eightFootTwoByFour !== '' &&
      decking.plywood &&
      decking.osb &&
      decking.plywood.threeEighthsPerSheet != null &&
      decking.plywood.threeEighthsPerSheet !== '' &&
      decking.plywood.threeEighthsPricePer != null &&
      decking.plywood.threeEighthsPricePer !== '' &&
      decking.plywood.oneHalfPerSheet != null &&
      decking.plywood.oneHalfPerSheet !== '' &&
      decking.plywood.oneHalfPricePer != null &&
      decking.plywood.oneHalfPricePer !== '' &&
      decking.plywood.fiveEighthsPerSheet != null &&
      decking.plywood.fiveEighthsPerSheet !== '' &&
      decking.plywood.fiveEighthsPricePer != null &&
      decking.plywood.fiveEighthsPricePer !== '' &&
      decking.plywood.threeFourthsPerSheet != null &&
      decking.plywood.threeFourthsPerSheet !== '' &&
      decking.plywood.threeFourthsPricePer != null &&
      decking.plywood.threeFourthsPricePer !== '' &&
      decking.plywood.onePerSheet != null &&
      decking.plywood.onePerSheet !== '' &&
      decking.plywood.onePricePer != null &&
      decking.plywood.onePricePer !== '' &&
      decking.osb.threeEighthsPerSheet != null &&
      decking.osb.threeEighthsPerSheet !== '' &&
      decking.osb.threeEighthsPricePer != null &&
      decking.osb.threeEighthsPricePer !== '' &&
      decking.osb.oneHalfPerSheet != null &&
      decking.osb.oneHalfPerSheet !== '' &&
      decking.osb.oneHalfPricePer != null &&
      decking.osb.oneHalfPricePer !== '' &&
      decking.osb.fiveEighthsPerSheet != null &&
      decking.osb.fiveEighthsPerSheet !== '' &&
      decking.osb.fiveEighthsPricePer != null &&
      decking.osb.fiveEighthsPricePer !== '' &&
      decking.osb.threeFourthsPerSheet != null &&
      decking.osb.threeFourthsPerSheet !== '' &&
      decking.osb.threeFourthsPricePer != null &&
      decking.osb.threeFourthsPricePer !== '' &&
      decking.osb.onePerSheet != null &&
      decking.osb.onePerSheet !== '' &&
      decking.osb.onePricePer != null &&
      decking.osb.onePricePer !== '';
    setIsDeckingComplete(isDeckingComplete);
    const isPaintComplete =
      savedList?.includes('paint') &&
      paint &&
      paint.manufacturer &&
      paint.manufacturer !== '' &&
      paint.color &&
      paint.color !== '' &&
      paint.cansPer != null &&
      paint.cansPer !== '' &&
      paint.pricePer != null &&
      paint.pricePer !== '';
    setIsPaintComplete(isPaintComplete);
    const isSkylightsComplete =
      savedList?.includes('skylights') &&
      skylights &&
      skylights.curbMount &&
      skylights.deckMount &&
      skylights.bubble &&
      skylights.manufacturer &&
      skylights.manufacturer !== '' &&
      skylights.curbMount.smallPricePer != null &&
      skylights.curbMount.smallPricePer !== '' &&
      skylights.curbMount.mediumPricePer != null &&
      skylights.curbMount.mediumPricePer !== '' &&
      skylights.curbMount.largePricePer != null &&
      skylights.curbMount.largePricePer !== '' &&
      skylights.deckMount.smallPricePer != null &&
      skylights.deckMount.smallPricePer !== '' &&
      skylights.deckMount.mediumPricePer != null &&
      skylights.deckMount.mediumPricePer !== '' &&
      skylights.deckMount.largePricePer != null &&
      skylights.deckMount.largePricePer !== '' &&
      skylights.bubble.smallPricePer != null &&
      skylights.bubble.smallPricePer !== '' &&
      skylights.bubble.mediumPricePer != null &&
      skylights.bubble.mediumPricePer !== '' &&
      skylights.bubble.largePricePer != null &&
      skylights.bubble.largePricePer !== '';
    setIsSkylightsComplete(isSkylightsComplete);
    setIsOtherComplete(
      isSkylightKitComplete &&
        isNailsComplete &&
        isCaulkComplete &&
        isDeckingComplete &&
        isPaintComplete &&
        isSkylightsComplete
    );
  };

  const checkFlashingCompletion = (flashing, savedList) => {
    const kitchen = flashing?.exhaust;
    const pipe = flashing?.pipe;
    const metalPipeRoof = flashing?.metalPipeRoof;
    const gutters = flashing?.gutters;
    const isKitchenComplete =
      savedList?.includes('bathExhaust') &&
      kitchen &&
      kitchen.smallManufacturer &&
      kitchen.smallManufacturer !== '' &&
      kitchen.smallPricePer != null &&
      kitchen.smallPricePer !== '' &&
      kitchen.largeManufacturer &&
      kitchen.largeManufacturer !== '' &&
      kitchen.largePricePer != null &&
      kitchen.largePricePer !== '' &&
      kitchen.smallColor &&
      kitchen.smallColor !== '' &&
      kitchen.largeColor &&
      kitchen.largeColor !== '';
    setIsKitchenComplete(isKitchenComplete);
    const isPipeFlashingComplete =
      savedList?.includes('pipeFlashing') &&
      pipe &&
      pipe.neoprene &&
      pipe.frostFree &&
      pipe.splitBoot &&
      pipe.neoprene.color &&
      pipe.neoprene.color !== '' &&
      pipe.neoprene.onePricePer != null &&
      pipe.neoprene.onePricePer !== '' &&
      pipe.neoprene.oneAndHalfPricePer != null &&
      pipe.neoprene.oneAndHalfPricePer !== '' &&
      pipe.neoprene.twoPricePer != null &&
      pipe.neoprene.twoPricePer !== '' &&
      pipe.neoprene.twoAndHalfPricePer != null &&
      pipe.neoprene.twoAndHalfPricePer !== '' &&
      pipe.neoprene.threePricePer != null &&
      pipe.neoprene.threePricePer !== '' &&
      pipe.neoprene.fourPricePer != null &&
      pipe.neoprene.fourPricePer !== '' &&
      pipe.frostFree.color &&
      pipe.frostFree.color !== '' &&
      pipe.frostFree.onePricePer != null &&
      pipe.frostFree.onePricePer !== '' &&
      pipe.frostFree.oneAndHalfPricePer != null &&
      pipe.frostFree.oneAndHalfPricePer !== '' &&
      pipe.frostFree.twoPricePer != null &&
      pipe.frostFree.twoPricePer !== '' &&
      pipe.frostFree.twoAndHalfPricePer != null &&
      pipe.frostFree.twoAndHalfPricePer !== '' &&
      pipe.frostFree.threePricePer != null &&
      pipe.frostFree.threePricePer !== '' &&
      pipe.frostFree.fourPricePer != null &&
      pipe.frostFree.fourPricePer !== '' &&
      pipe.splitBoot.color &&
      pipe.splitBoot.color !== '' &&
      pipe.splitBoot.onePricePer != null &&
      pipe.splitBoot.onePricePer !== '' &&
      pipe.splitBoot.oneAndHalfPricePer != null &&
      pipe.splitBoot.oneAndHalfPricePer !== '' &&
      pipe.splitBoot.twoPricePer != null &&
      pipe.splitBoot.twoPricePer !== '' &&
      pipe.splitBoot.twoAndHalfPricePer != null &&
      pipe.splitBoot.twoAndHalfPricePer !== '' &&
      pipe.splitBoot.threePricePer != null &&
      pipe.splitBoot.threePricePer !== '' &&
      pipe.splitBoot.fourPricePer != null &&
      pipe.splitBoot.fourPricePer !== '';
    setIsPipeFlashingComplete(isPipeFlashingComplete);
    const isMetalFlashingComplete =
      savedList?.includes('metalPipeRoofFlashing') &&
      metalPipeRoof &&
      metalPipeRoof.manufacturer &&
      metalPipeRoof.manufacturer !== '' &&
      metalPipeRoof.color &&
      metalPipeRoof.color !== '' &&
      metalPipeRoof.fourPricePer != null &&
      metalPipeRoof.fourPricePer !== '' &&
      metalPipeRoof.fivePricePer != null &&
      metalPipeRoof.fivePricePer !== '' &&
      metalPipeRoof.sixPricePer != null &&
      metalPipeRoof.sixPricePer !== '' &&
      metalPipeRoof.eightPricePer != null &&
      metalPipeRoof.eightPricePer !== '' &&
      metalPipeRoof.tenPricePer != null &&
      metalPipeRoof.tenPricePer !== '' &&
      metalPipeRoof.twelvePricePer != null &&
      metalPipeRoof.twelvePricePer !== '';
    setIsMetalFlashingComplete(isMetalFlashingComplete);
    const isGuttersFlashingComplete =
      savedList?.includes('gutters') &&
      gutters &&
      gutters.dripEdge &&
      gutters.apron &&
      gutters.dripEdge.manufacturer &&
      gutters.dripEdge.manufacturer !== '' &&
      gutters.dripEdge.dripEdgeType &&
      gutters.dripEdge.dripEdgeType !== '' &&
      gutters.dripEdge.unit != null &&
      gutters.dripEdge.unit !== '' &&
      gutters.dripEdge.pricePer != null &&
      gutters.dripEdge.pricePer !== '' &&
      gutters.apron.manufacturer &&
      gutters.apron.manufacturer !== '' &&
      gutters.apron.apronType &&
      gutters.apron.apronType !== '' &&
      gutters.apron.unit != null &&
      gutters.apron.unit !== '' &&
      gutters.apron.whitePricePer != null &&
      gutters.apron.whitePricePer !== '' &&
      gutters.apron.brownPricePer != null &&
      gutters.apron.brownPricePer !== '';
    setIsGuttersFlashingComplete(isGuttersFlashingComplete);
    const isOtherFlashingComplete =
      savedList?.includes('otherFlashing') &&
      flashing &&
      flashing.step &&
      flashing.reglet &&
      flashing.oneByFour &&
      flashing.dormer &&
      flashing.valley &&
      flashing.step.manufacturer &&
      flashing.step.manufacturer !== '' &&
      flashing.step.size &&
      flashing.step.size !== '' &&
      flashing.step.description &&
      flashing.step.description !== '' &&
      flashing.step.unit != null &&
      flashing.step.unit !== '' &&
      flashing.step.galvanizedPricePer != null &&
      flashing.step.galvanizedPricePer !== '' &&
      flashing.step.blackPricePer != null &&
      flashing.step.blackPricePer !== '' &&
      flashing.step.brownPricePer != null &&
      flashing.step.brownPricePer !== '' &&
      flashing.reglet.manufacturer &&
      flashing.reglet.manufacturer !== '' &&
      flashing.reglet.size &&
      flashing.reglet.size !== '' &&
      flashing.reglet.unit != null &&
      flashing.reglet.unit !== '' &&
      flashing.reglet.pricePerBrown != null &&
      flashing.reglet.pricePerBrown !== '' &&
      flashing.reglet.pricePerBlack != null &&
      flashing.reglet.pricePerBlack !== '' &&
      flashing.reglet.pricePerGalvanized != null &&
      flashing.reglet.pricePerGalvanized !== '' &&
      flashing.oneByFour.manufacturer &&
      flashing.oneByFour.manufacturer !== '' &&
      flashing.oneByFour.size &&
      flashing.oneByFour.size !== '' &&
      flashing.oneByFour.unit != null &&
      flashing.oneByFour.unit !== '' &&
      flashing.oneByFour.pricePerPine != null &&
      flashing.oneByFour.pricePerPine !== '' &&
      flashing.oneByFour.pricePerAzek != null &&
      flashing.oneByFour.pricePerAzek !== '' &&
      flashing.oneByFour.pricePerSmartside != null &&
      flashing.oneByFour.pricePerSmartside !== '' &&
      flashing.dormer.manufacturer &&
      flashing.dormer.manufacturer !== '' &&
      flashing.dormer.size &&
      flashing.dormer.size !== '' &&
      flashing.dormer.unit != null &&
      flashing.dormer.unit !== '' &&
      flashing.dormer.pricePer != null &&
      flashing.dormer.pricePer !== '' &&
      flashing.valley.wManufacturer != null &&
      flashing.valley.wManufacturer !== '' &&
      flashing.valley.wUnit != null &&
      flashing.valley.wUnit !== '' &&
      flashing.valley.wPricePer != null &&
      flashing.valley.wPricePer !== '' &&
      flashing.valley.rollManufacturer != null &&
      flashing.valley.rollManufacturer !== '' &&
      flashing.valley.rollUnit != null &&
      flashing.valley.rollUnit !== '' &&
      flashing.valley.rollPricePer != null &&
      flashing.valley.rollPricePer !== '';
    setIsOtherFlashingComplete(isOtherFlashingComplete);
    setIsFlashingComplete(
      isKitchenComplete &&
        isPipeFlashingComplete &&
        isMetalFlashingComplete &&
        isGuttersFlashingComplete &&
        isOtherFlashingComplete
    );
  };

  const checkVentingCompletion = (venting, savedList) => {
    const roofLouvers = venting?.roofLouvers;
    const turbineVents = venting?.turbineVents;
    const powerVents = venting?.powerVents;
    const ridgeVents = venting?.ridgeVents;
    const midRoofIntakeVents = venting?.midRoofIntakeVents;
    const isRoofLouversComplete =
      savedList?.includes('roofLouvers') &&
      roofLouvers &&
      roofLouvers.manufacturer &&
      roofLouvers.manufacturer !== '' &&
      roofLouvers.roofLouverType &&
      roofLouvers.roofLouverType !== '' &&
      roofLouvers.line &&
      roofLouvers.line !== '' &&
      roofLouvers.description &&
      roofLouvers.description !== '' &&
      roofLouvers.pricePer != null &&
      roofLouvers.pricePer !== '';
    setIsRoofLouversComplete(isRoofLouversComplete);
    const isTurbineVentsComplete =
      savedList?.includes('turbineVents') &&
      turbineVents &&
      turbineVents.manufacturer &&
      turbineVents.manufacturer !== '' &&
      turbineVents.turbineVentType &&
      turbineVents.turbineVentType !== '' &&
      turbineVents.line &&
      turbineVents.line !== '' &&
      turbineVents.description &&
      turbineVents.description !== '' &&
      turbineVents.pricePer != null &&
      turbineVents.pricePer !== '';
    setIsTurbineVentsComplete(isTurbineVentsComplete);
    const isPowerVentsComplete =
      savedList?.includes('powerVents') &&
      powerVents &&
      powerVents.manufacturer &&
      powerVents.manufacturer !== '' &&
      powerVents.powerVentType &&
      powerVents.powerVentType !== '' &&
      powerVents.line &&
      powerVents.line !== '' &&
      powerVents.description &&
      powerVents.description !== '' &&
      powerVents.pricePer != null &&
      powerVents.pricePer !== '' &&
      powerVents.color &&
      powerVents.color !== '';
    setIsPowerVentsComplete(isPowerVentsComplete);
    const isRidgeVentsComplete =
      savedList?.includes('ridgeVents') &&
      ridgeVents &&
      ridgeVents.manufacturer &&
      ridgeVents.manufacturer !== '' &&
      ridgeVents.line &&
      ridgeVents.line !== '' &&
      ridgeVents.description &&
      ridgeVents.description !== '' &&
      ridgeVents.unit != null &&
      ridgeVents.unit !== '' &&
      ridgeVents.pricePer != null &&
      ridgeVents.pricePer !== '';
    setIsRidgeVentsComplete(isRidgeVentsComplete);
    const isMidRoofIntakeVentsComplete =
      savedList?.includes('midroofIntakeVents') &&
      midRoofIntakeVents &&
      midRoofIntakeVents.manufacturer &&
      midRoofIntakeVents.manufacturer !== '' &&
      midRoofIntakeVents.line &&
      midRoofIntakeVents.line !== '' &&
      midRoofIntakeVents.description &&
      midRoofIntakeVents.description !== '' &&
      midRoofIntakeVents.unit != null &&
      midRoofIntakeVents.unit !== '' &&
      midRoofIntakeVents.pricePer != null &&
      midRoofIntakeVents.pricePer !== '';
    setIsMidRoofIntakeVentsComplete(isMidRoofIntakeVentsComplete);
    setIsVentingComplete(
      isRoofLouversComplete &&
        isTurbineVentsComplete &&
        isPowerVentsComplete &&
        isRidgeVentsComplete &&
        isMidRoofIntakeVentsComplete
    );
  };

  const checkShinglePriceCompletion = (shingles, warranty, savedList) => {
    const isComplete =
      savedList?.includes('shingles') &&
      shingles &&
      shingles.manufacturer &&
      shingles.manufacturer !== '' &&
      shingles.shingleType &&
      shingles.shingleType !== '' &&
      shingles.line &&
      shingles.line !== '' &&
      shingles.color &&
      shingles.color !== '' &&
      shingles.unit != null &&
      shingles.unit !== '' &&
      shingles.pricePer != null &&
      shingles.pricePer !== '' &&
      warranty &&
      warranty.name &&
      warranty.name !== '' &&
      warranty.price != null &&
      warranty.price !== '' &&
      (warranty.isPerJob || warranty.isPerSq);
    setIsShinglesComplete(isComplete ?? false);
  };

  const checkHipRidgeCapCompletion = (hipRidgeCap, savedList) => {
    const isComplete =
      savedList?.includes('hipRidgeCap') &&
      hipRidgeCap &&
      hipRidgeCap.manufacturer &&
      hipRidgeCap.manufacturer !== '' &&
      hipRidgeCap.hipRidgeCapType &&
      hipRidgeCap.hipRidgeCapType !== '' &&
      hipRidgeCap.line &&
      hipRidgeCap.line !== '' &&
      hipRidgeCap.description &&
      hipRidgeCap.description !== '' &&
      hipRidgeCap.unit != null &&
      hipRidgeCap.unit !== '' &&
      hipRidgeCap.pricePer != null &&
      hipRidgeCap.pricePer !== '';
    setIsHipRidgeCapComplete(isComplete ?? false);
  };

  const checkStarterStripCompletion = (starterStrip, savedList) => {
    const isComplete =
      savedList?.includes('starterStrip') &&
      starterStrip &&
      starterStrip.manufacturer &&
      starterStrip.manufacturer !== '' &&
      starterStrip.starterStripType &&
      starterStrip.starterStripType !== '' &&
      starterStrip.line &&
      starterStrip.line !== '' &&
      starterStrip.description &&
      starterStrip.description !== '' &&
      starterStrip.unit != null &&
      starterStrip.unit !== '' &&
      starterStrip.pricePer != null &&
      starterStrip.pricePer !== '';
    setIsStarterStripComplete(isComplete ?? false);
  };

  const checkRoofDeckCompletion = (roofDeckProtection, savedList) => {
    const iceWaterShield = roofDeckProtection?.iceWaterShield;
    const underlayment = roofDeckProtection?.underlayment;
    const isIceWaterComplete =
      savedList?.includes('iceWaterShield') &&
      iceWaterShield &&
      iceWaterShield.manufacturer &&
      iceWaterShield.manufacturer !== '' &&
      iceWaterShield.iceWaterShieldType &&
      iceWaterShield.iceWaterShieldType !== '' &&
      iceWaterShield.line &&
      iceWaterShield.line !== '' &&
      iceWaterShield.description &&
      iceWaterShield.description !== '' &&
      iceWaterShield.unit != null &&
      iceWaterShield.unit !== '' &&
      iceWaterShield.pricePer != null &&
      iceWaterShield.pricePer !== '';
    setIsIceWaterComplete(isIceWaterComplete);
    const isUnderlaymentComplete =
      savedList?.includes('underlayment') &&
      underlayment &&
      underlayment.manufacturer &&
      underlayment.manufacturer !== '' &&
      underlayment.underlaymentType &&
      underlayment.underlaymentType !== '' &&
      underlayment.line &&
      underlayment.line !== '' &&
      underlayment.description &&
      underlayment.description !== '' &&
      underlayment.unit != null &&
      underlayment.unit !== '' &&
      underlayment.pricePer != null &&
      underlayment.pricePer !== '';
    setIsUnderlaymentComplete(isUnderlaymentComplete);
    setIsRoofDeckComplete(isIceWaterComplete && isUnderlaymentComplete);
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const token = await user.getIdToken();
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/prices`,
          {
            headers: {
              authorization: `${token}`,
            },
            params: {
              username: user.email,
            },
          }
        );
        if (response.data) {
          dispatch(updatePrice(response.data));
          checkShinglePriceCompletion(
            response.data?.shingles,
            response.data?.warranty,
            response.data?.hasBeenSaved
          );
          checkHipRidgeCapCompletion(
            response.data?.hipRidgeCap,
            response.data?.hasBeenSaved
          );
          checkStarterStripCompletion(
            response.data?.starterStrip,
            response.data?.hasBeenSaved
          );
          checkRoofDeckCompletion(
            response.data?.roofDeckProtection,
            response.data?.hasBeenSaved
          );
          checkVentingCompletion(
            response.data?.venting,
            response.data?.hasBeenSaved
          );
          checkFlashingCompletion(
            response.data?.flashing,
            response.data?.hasBeenSaved
          );
          checkOtherCompletion(
            response.data?.other,
            response.data?.hasBeenSaved
          );
        } else {
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }

      setIsLoading(false);
    };

    if (user) {
      if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
        mixpanel.track('User navigated to Material page.');
      }
      fetchData();
    }
  }, [user]);

  return (
    <Center m="20px">
      {isLoading ? (
        <Center>
          <Spinner
            thickness="7px"
            speed="1.25s"
            size="xl"
            emptyColor="gray.200"
            color="black.500"
            marginTop="100px"
          />
        </Center>
      ) : (
        <Box>
          <Tabs variant="enclosed-colored" align="center">
            <TabList>
              <Tab
                as="b"
                bg={isShinglesComplete ? 'green.200' : 'yellow.200'}
                color="black"
                _selected={{
                  border: '1px solid black',
                  bg: isShinglesComplete ? 'green.400' : 'yellow.400',
                }}
              >
                <HStack>
                  <Text>Shingles</Text>
                  {isShinglesComplete && (
                    <CheckIcon boxSize={4} color="gray.900" />
                  )}
                  {!isShinglesComplete && (
                    <SmallCloseIcon boxSize={5} color="gray.900" />
                  )}
                </HStack>
              </Tab>
              <Tab
                as="b"
                bg={isHipRidgeCapComplete ? 'green.200' : 'yellow.200'}
                color="black"
                _selected={{
                  border: '1px solid black',
                  bg: isHipRidgeCapComplete ? 'green.400' : 'yellow.400',
                }}
              >
                Hip/Ridge Cap
              </Tab>
              <Tab
                as="b"
                bg={isStarterStripComplete ? 'green.200' : 'yellow.200'}
                color="black"
                _selected={{
                  border: '1px solid black',
                  bg: isStarterStripComplete ? 'green.400' : 'yellow.400',
                }}
              >
                Starter Strip
              </Tab>
              <Tab
                as="b"
                bg={isRoofDeckComplete ? 'green.200' : 'yellow.200'}
                color="black"
                _selected={{
                  border: '1px solid black',
                  bg: isRoofDeckComplete ? 'green.400' : 'yellow.400',
                }}
              >
                Roof Deck Protection
              </Tab>
              <Tab
                as="b"
                bg={isVentingComplete ? 'green.200' : 'yellow.200'}
                color="black"
                _selected={{
                  border: '1px solid black',
                  bg: isVentingComplete ? 'green.400' : 'yellow.400',
                }}
              >
                Venting
              </Tab>
              <Tab
                as="b"
                bg={isFlashingComplete ? 'green.200' : 'yellow.200'}
                color="black"
                _selected={{
                  border: '1px solid black',
                  bg: isFlashingComplete ? 'green.400' : 'yellow.400',
                }}
              >
                Flashing
              </Tab>
              <Tab
                as="b"
                bg={isOtherComplete ? 'green.200' : 'yellow.200'}
                color="black"
                _selected={{
                  border: '1px solid black',
                  bg: isOtherComplete ? 'green.400' : 'yellow.400',
                }}
              >
                Other Materials
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <ShinglesPrices
                  checkShinglePriceCompletion={checkShinglePriceCompletion}
                />
              </TabPanel>
              <TabPanel>
                <HipRidgeCap
                  checkHipRidgeCapCompletion={checkHipRidgeCapCompletion}
                />
              </TabPanel>
              <TabPanel>
                <StarterStrip
                  checkStarterStripCompletion={checkStarterStripCompletion}
                />
              </TabPanel>
              <TabPanel>
                <RoofDeck
                  checkRoofDeckCompletion={checkRoofDeckCompletion}
                  isIceWaterComplete={isIceWaterComplete}
                  isUnderlaymentComplete={isUnderlaymentComplete}
                />
              </TabPanel>
              <TabPanel>
                <VentingPrices
                  checkVentingCompletion={checkVentingCompletion}
                  isRoofLouversComplete={isRoofLouversComplete}
                  isTurbineVentsComplete={isTurbineVentsComplete}
                  isPowerVentsComplete={isPowerVentsComplete}
                  isRidgeVentsComplete={isRidgeVentsComplete}
                  isMidRoofIntakeVentsComplete={isMidRoofIntakeVentsComplete}
                />
              </TabPanel>
              <TabPanel>
                <FlashingPrices
                  checkFlashingCompletion={checkFlashingCompletion}
                  isKitchenComplete={isKitchenComplete}
                  isPipeFlashingComplete={isPipeFlashingComplete}
                  isMetalFlashingComplete={isMetalFlashingComplete}
                  isGuttersFlashingComplete={isGuttersFlashingComplete}
                  isOtherFlashingComplete={isOtherFlashingComplete}
                />
              </TabPanel>
              <TabPanel>
                <OtherMaterials
                  checkOtherCompletion={checkOtherCompletion}
                  isTrimCoilComplete={isTrimCoilComplete}
                  isSkylightKitComplete={isSkylightKitComplete}
                  isNailsComplete={isNailsComplete}
                  isCaulkComplete={isCaulkComplete}
                  isDeckingComplete={isDeckingComplete}
                  isPaintComplete={isPaintComplete}
                  isSkylightsComplete={isSkylightsComplete}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
          <Center>
            <VStack>
              <Button colorScheme="purple" onClick={handleClick}>
                Go to Estimator
              </Button>
              {/* <Button colorScheme="red" onClick={() => {}}>
                Clear
              </Button> */}
            </VStack>
          </Center>
        </Box>
      )}
    </Center>
  );
};

export default Pricing;
