import { useState } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  NumberInput,
  NumberInputField,
  Text,
  HStack,
  VStack,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Center,
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Checkbox,
  useToast,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateItemized,
  updateHasBeenSaved,
} from '../features/labor/laborSlice';
import axios from 'axios';
import useUser from '../hooks/useUser';

const ItemizedPricing = ({ checkItemizedCompletion }) => {
  const savedList = useSelector(state => state.labor.hasBeenSaved);
  const itemized = useSelector(state => state.labor.itemized);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const dispatch = useDispatch();
  const { user } = useUser();
  const toast = useToast();

  const handleSave = async () => {
    try {
      const token = await user.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/labors/itemized`,
        {
          username: user.email,
          itemized,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      dispatch(updateHasBeenSaved('itemized'));
      checkItemizedCompletion(itemized, [...savedList, 'itemized']);
      setUnsavedChanges(false);
      toast({
        title: 'Changes saved',
        description: 'Your pricing was updated.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      console.log('error: ' + error);
    }
  };

  return (
    <Box>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th>Item</Th>
            <Th>Unit</Th>
            <Th>Price per unit</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>
              <Text fontSize="sm">Tear Off</Text>
            </Td>
            <Td>SQ</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.tearOff ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'tearOff',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Additional Layers Asphalt</Text>
            </Td>
            <Td>SQ</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.addLayersAsphalt ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'addLayersAsphalt',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Additional Layers Shake</Text>
            </Td>
            <Td>SQ</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.addLayersShake ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'addLayersShake',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Additional Stories</Text>
            </Td>
            <Td>SQ</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.addStories ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'addStories',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Redeck: 3/12 - 6/12</Text>
            </Td>
            <Td>SQ</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.redeckThreeToSix ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'redeckThreeToSix',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Redeck: 7/12 - 9/12</Text>
            </Td>
            <Td>SQ</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.redeckSevenToNine ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'redeckSevenToNine',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Redeck: 10/12 - 12/12</Text>
            </Td>
            <Td>SQ</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.redeckTenToTwelve ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'redeckTenToTwelve',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Redeck: 13/12 +</Text>
            </Td>
            <Td>SQ</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.redeckThirteenPlus ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'redeckThirteenPlus',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Drip Edge</Text>
            </Td>
            <Td>LF</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.dripEdge ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'dripEdge',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Ice and Water</Text>
            </Td>
            <Td>SF</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.iceAndWater ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'iceAndWater',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Felt (Underlayment)</Text>
            </Td>
            <Td>SQ</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.underlayment ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'underlayment',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Shingles with Waste</Text>
            </Td>
            <Td>SQ</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.shinglesWithWaste ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'shinglesWithWaste',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Steep Charge: 3/12 - 6/12</Text>
            </Td>
            <Td>SQ</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.steepChargeThreeToSix ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'steepChargeThreeToSix',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Steep Charge: 7/12 - 9/12</Text>
            </Td>
            <Td>SQ</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.steepChargeSevenToNine ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'steepChargeSevenToNine',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Steep Charge: 10/12 - 12/12</Text>
            </Td>
            <Td>SQ</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.steepChargeTenToTwelve ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'steepChargeTenToTwelve',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Steep Charge: 13/12 +</Text>
            </Td>
            <Td>SQ</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.steepChargeThirteenPlus ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'steepChargeThirteenPlus',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Hand Seal: 8/12 or less</Text>
            </Td>
            <Td>SQ</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.handSealEightOrLess ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'handSealEightOrLess',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Hand Seal: 9/12 - 13/12</Text>
            </Td>
            <Td>SQ</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.handSealNineToThirteen ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'handSealNineToThirteen',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Hand Seal: 14/12 +</Text>
            </Td>
            <Td>SQ</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.handSealFourteenPlus ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'handSealFourteenPlus',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Starter</Text>
            </Td>
            <Td>LF</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.starter ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'starter',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Pipe Flashing</Text>
            </Td>
            <Td>Unit</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.pipeFlashing ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'pipeFlashing',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Split Pipe Flashing</Text>
            </Td>
            <Td>Unit</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.splitPipeFlashing ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'splitPipeFlashing',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Roof Louver</Text>
            </Td>
            <Td>Unit</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.roofLouver ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'roofLouver',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Turbine Vent</Text>
            </Td>
            <Td>Unit</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.turbineVent ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'turbineVent',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Power Vent</Text>
            </Td>
            <Td>Unit</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.powerVent ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'powerVent',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Kit/Bath Exhaust - Small</Text>
            </Td>
            <Td>Unit</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.smallKitBathExhaust ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'smallKitBathExhaust',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Kit/Bath Exhaust - Large</Text>
            </Td>
            <Td>Unit</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.largeKitBathExhaust ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'largeKitBathExhaust',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Ridge Vent</Text>
            </Td>
            <Td>LF</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.ridgeVent ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'ridgeVent',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Ridge Cap</Text>
            </Td>
            <Td>LF</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.ridgeCap ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'ridgeCap',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Valley Flashing</Text>
            </Td>
            <Td>LF</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.valleyFlashing ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'valleyFlashing',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Step Flashing</Text>
            </Td>
            <Td>LF</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.stepFlashing ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'stepFlashing',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Sidewall Counter Flashing (Reglet)</Text>
            </Td>
            <Td>LF</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.regletFlashing ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'regletFlashing',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Sidewall Counter Flashing (1X4)</Text>
            </Td>
            <Td>LF</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.oneByFourFlashing ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'oneByFourFlashing',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Skylight - Flashing</Text>
            </Td>
            <Td>Unit</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.skylightFlashing ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'skylightFlashing',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Skylight</Text>
            </Td>
            <Td>Unit</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.skylight ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'skylight',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Chimney - Small</Text>
            </Td>
            <Td>Unit</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.smallChimney ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'smallChimney',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Chimney - Medium</Text>
            </Td>
            <Td>Unit</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.mediumChimney ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'mediumChimney',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Chimney - Large</Text>
            </Td>
            <Td>Unit</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.largeChimney ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'largeChimney',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Chimney Pipe - Small</Text>
            </Td>
            <Td>Unit</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.smallChimneyPipe ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'smallChimneyPipe',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Chimney Pipe - Medium</Text>
            </Td>
            <Td>Unit</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.mediumChimneyPipe ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'mediumChimneyPipe',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Chimney Pipe - Large</Text>
            </Td>
            <Td>Unit</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.largeChimneyPipe ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'largeChimneyPipe',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Gutter Detach/Reset</Text>
            </Td>
            <Td>LF</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.gutterDetachReset ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'gutterDetachReset',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Dormer Flashing</Text>
            </Td>
            <Td>LF</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.dormerFlashing ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'dormerFlashing',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Detach/Reset Siding</Text>
            </Td>
            <Td>LF</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.detachResetSiding ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'detachResetSiding',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Cornice Return - Small</Text>
            </Td>
            <Td>Unit</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.smallCorniceReturn ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'smallCorniceReturn',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Cornice Return - Medium</Text>
            </Td>
            <Td>Unit</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.mediumCorniceReturn ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'mediumCorniceReturn',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Cornice Return - Large</Text>
            </Td>
            <Td>Unit</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.largeCorniceReturn ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'largeCorniceReturn',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Bay Window - Small</Text>
            </Td>
            <Td>Unit</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.smallBayWindow ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'smallBayWindow',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Bay Window - Medium</Text>
            </Td>
            <Td>Unit</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.mediumBayWindow ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'mediumBayWindow',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Bay Window - Large</Text>
            </Td>
            <Td>Unit</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.largeBayWindow ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'largeBayWindow',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text fontSize="sm">Sat Dish detach/reset</Text>
            </Td>
            <Td>Unit</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.satelliteDishReset ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'satelliteDishReset',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
          {/* <Tr>
            <Td>
              <Text fontSize="sm">Res ReRoofing Permit</Text>
            </Td>
            <Td>Unit</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.roofingPermit ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'roofingPermit',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr> */}
          <Tr>
            <Td>
              <Text fontSize="sm">Debris Removal/Dumpster</Text>
            </Td>
            <Td>Unit</Td>
            <Td>
              <HStack>
                <Text as="b">$</Text>
                <NumberInput
                  size="sm"
                  maxWidth="100px"
                  value={itemized.debrisRemoval ?? ''}
                  onChange={value => {
                    setUnsavedChanges(true);
                    dispatch(
                      updateItemized({
                        newValue: value,
                        fieldToUpdate: 'debrisRemoval',
                      })
                    );
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </Td>
          </Tr>
        </Tbody>
      </Table>
      <Center>
        <VStack>
          {unsavedChanges && (
            <Alert status="warning" marginTop="5">
              <AlertIcon />
              <AlertTitle>You have unsaved changes!</AlertTitle>
            </Alert>
          )}
          {!savedList.includes('itemized') && (
            <Alert status="info" marginTop="5">
              <AlertIcon />
              <AlertTitle>Review and Save</AlertTitle>
            </Alert>
          )}
          <Button colorScheme="blue" marginTop="3" onClick={handleSave}>
            Save
          </Button>
        </VStack>
      </Center>
    </Box>
  );
};

export default ItemizedPricing;
