import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  username: '',
  administrator: '',
  payment: '',
  profile: '',
  isRestricted: false,
  isUserLoaded: false,
  subscription: '',
  subscribeDate: '',
  createdAt: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (state, action) => {
      state.username = action.payload.username;
      state.payment = action.payload.payment;
      state.profile = action.payload.profile;
      state.administrator = action.payload.administrator;
      state.isRestricted = action.payload.isRestricted;
      state.subscription = action.payload?.subscription ?? '';
      state.subscribeDate = action.payload?.subscribeDate ?? '';
      state.createdAt = action.payload?.createdAt ?? '';
      state.isUserLoaded = true;
    },
  },
});

export const { updateUser } = userSlice.actions;

export default userSlice.reducer;
