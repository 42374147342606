import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  NumberInput,
  NumberInputField,
  VStack,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useToast,
  Center,
} from '@chakra-ui/react';
import { useState, useEffect, React } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateHipRidgeCapField,
  updateHasBeenSaved,
} from '../../features/price/priceSlice';
import axios from 'axios';
import useUser from '../../hooks/useUser';
import mixpanel from 'mixpanel-browser';

const HipRidgeCap = ({ checkHipRidgeCapCompletion }) => {
  const dispatch = useDispatch();
  const hipRidgeCap = useSelector(state => state.price.hipRidgeCap);
  const toast = useToast();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const savedList = useSelector(state => state.price.hasBeenSaved);
  const { user } = useUser();

  const handleSave = async () => {
    try {
      // if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
      //   mixpanel.track('User saved the Hip/Ridge Cap prices.');
      // }
      const token = await user.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/prices/hipRidgeCap`,
        {
          username: user.email,
          hipRidgeCap,
          savedList,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      dispatch(updateHasBeenSaved('hipRidgeCap'));
      setUnsavedChanges(false);
      checkHipRidgeCapCompletion(hipRidgeCap, [...savedList, 'hipRidgeCap']);
    } catch (error) {
      console.log('error: ' + error);
    }

    toast({
      title: 'Changes saved',
      description: 'Your pricing was updated.',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  // useEffect(() => {
  //   console.log('HipRidgeCap useEffect()');
  // });

  return (
    <Box>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th fontSize="xs">Manufacturer</Th>
            <Th fontSize="xs">Type</Th>
            <Th fontSize="xs">Line</Th>
            <Th fontSize="xs">Unit Description</Th>
            <Th fontSize="xs">Measurement (LF)</Th>
            <Th fontSize="xs">Price per unit</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>
              <Input
                value={hipRidgeCap?.manufacturer}
                onChange={event => {
                  setUnsavedChanges(true);
                  dispatch(
                    updateHipRidgeCapField({
                      newValue: event.target.value,
                      fieldToUpdate: 'manufacturer',
                    })
                  );
                }}
              />
            </Td>
            <Td>
              <Input
                value={hipRidgeCap.hipRidgeCapType}
                onChange={event => {
                  setUnsavedChanges(true);
                  dispatch(
                    updateHipRidgeCapField({
                      newValue: event.target.value,
                      fieldToUpdate: 'hipRidgeCapType',
                    })
                  );
                }}
              />
            </Td>
            <Td>
              <Input
                value={hipRidgeCap.line}
                onChange={event => {
                  setUnsavedChanges(true);
                  dispatch(
                    updateHipRidgeCapField({
                      newValue: event.target.value,
                      fieldToUpdate: 'line',
                    })
                  );
                }}
              />
            </Td>
            <Td>
              <Input
                value={hipRidgeCap.description}
                onChange={event => {
                  setUnsavedChanges(true);
                  dispatch(
                    updateHipRidgeCapField({
                      newValue: event.target.value,
                      fieldToUpdate: 'description',
                    })
                  );
                }}
              />
            </Td>
            <Td>
              <NumberInput
                value={hipRidgeCap.unit ?? ''}
                onChange={value => {
                  setUnsavedChanges(true);
                  dispatch(
                    updateHipRidgeCapField({
                      newValue: value,
                      fieldToUpdate: 'unit',
                    })
                  );
                }}
              >
                <NumberInputField />
              </NumberInput>
            </Td>
            <Td>
              <NumberInput
                value={hipRidgeCap.pricePer ?? ''}
                onChange={value => {
                  setUnsavedChanges(true);
                  dispatch(
                    updateHipRidgeCapField({
                      newValue: value,
                      fieldToUpdate: 'pricePer',
                    })
                  );
                }}
              >
                <NumberInputField />
              </NumberInput>
            </Td>
          </Tr>
        </Tbody>
      </Table>
      <Center>
        <VStack>
          {unsavedChanges && (
            <Alert status="warning" marginTop="5">
              <AlertIcon />
              <AlertTitle>You have unsaved changes!</AlertTitle>
            </Alert>
          )}
          {!savedList.includes('hipRidgeCap') && (
            <Alert status="info" marginTop="5">
              <AlertIcon />
              <VStack>
                <AlertTitle>Review and Save</AlertTitle>
                {/* <AlertDescription>
                  Your application has been received. We will review your
                  application and respond within the next 48 hours.
                </AlertDescription> */}
              </VStack>
            </Alert>
          )}
          <Button colorScheme="blue" marginTop="3" onClick={handleSave}>
            Save
          </Button>
        </VStack>
      </Center>
    </Box>
  );
};

export default HipRidgeCap;
