import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Center,
  Text,
  VStack,
  NumberInput,
  NumberInputField,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Alert,
  AlertIcon,
  AlertTitle,
  useToast,
  Button,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateRoofLouversField,
  updateTurbineVentsField,
  updatePowerVentsField,
  updateRidgeVentsField,
  updateMidRoofIntakeVentsField,
  updateHasBeenSaved,
} from '../../features/price/priceSlice';
import axios from 'axios';
import useUser from '../../hooks/useUser';
import mixpanel from 'mixpanel-browser';

const VentingPrices = ({
  checkVentingCompletion,
  isRoofLouversComplete,
  isTurbineVentsComplete,
  isPowerVentsComplete,
  isRidgeVentsComplete,
  isMidRoofIntakeVentsComplete,
}) => {
  const dispatch = useDispatch();
  const venting = useSelector(state => state.price.venting);
  const toast = useToast();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const { user } = useUser();
  const savedList = useSelector(state => state.price.hasBeenSaved);
  const [currentTab, setCurrentTab] = useState(0);

  const handleSave = async () => {
    try {
      // if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
      //   mixpanel.track('User saved the Venting prices.');
      // }
      let tab;
      if (currentTab === 0) {
        tab = 'roofLouvers';
      } else if (currentTab === 1) {
        tab = 'turbineVents';
      } else if (currentTab === 2) {
        tab = 'powerVents';
      } else if (currentTab === 3) {
        tab = 'ridgeVents';
      } else if (currentTab === 4) {
        tab = 'midroofIntakeVents';
      }

      const token = await user.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/prices/venting`,
        {
          username: user.email,
          venting,
          tab,
        },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      setUnsavedChanges(false);
      dispatch(updateHasBeenSaved(tab));
      checkVentingCompletion(venting, [...savedList, tab]);
    } catch (error) {
      console.log('error: ' + error);
    }

    toast({
      title: 'Changes saved',
      description: 'Your pricing was updated.',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  const handleTabChange = index => {
    setCurrentTab(index);
  };

  return (
    <Box>
      <Tabs
        align="center"
        variant="soft-rounded"
        colorScheme="blue"
        onChange={handleTabChange}
        index={currentTab}
      >
        <TabList>
          <Tab
            as="b"
            bg={isRoofLouversComplete ? 'green.200' : 'yellow.200'}
            color="black"
            _selected={{
              border: '2px solid black',
              bg: isRoofLouversComplete ? 'green.400' : 'yellow.400',
            }}
          >
            Roof Louvers
          </Tab>
          <Tab
            as="b"
            bg={isTurbineVentsComplete ? 'green.200' : 'yellow.200'}
            color="black"
            _selected={{
              border: '2px solid black',
              bg: isTurbineVentsComplete ? 'green.400' : 'yellow.400',
            }}
          >
            Turbine Vents
          </Tab>
          <Tab
            as="b"
            bg={isPowerVentsComplete ? 'green.200' : 'yellow.200'}
            color="black"
            _selected={{
              border: '2px solid black',
              bg: isPowerVentsComplete ? 'green.400' : 'yellow.400',
            }}
          >
            Power Vents
          </Tab>
          <Tab
            as="b"
            bg={isRidgeVentsComplete ? 'green.200' : 'yellow.200'}
            color="black"
            _selected={{
              border: '2px solid black',
              bg: isRidgeVentsComplete ? 'green.400' : 'yellow.400',
            }}
          >
            Ridge Vents
          </Tab>
          <Tab
            as="b"
            bg={isMidRoofIntakeVentsComplete ? 'green.200' : 'yellow.200'}
            color="black"
            _selected={{
              border: '2px solid black',
              bg: isMidRoofIntakeVentsComplete ? 'green.400' : 'yellow.400',
            }}
          >
            Mid Roof Intake Vents
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <VStack>
              <div>
                <Center>
                  <Text fontWeight="bold" ml="6">
                    Roof Louvers
                  </Text>
                </Center>
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th fontSize="xs">Manufacturer</Th>
                      <Th fontSize="xs">Type</Th>
                      <Th fontSize="xs">Line</Th>
                      <Th fontSize="xs">Unit Description</Th>
                      <Th fontSize="xs">Price per unit</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        <Input
                          size="xs"
                          value={venting.roofLouvers.manufacturer}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateRoofLouversField({
                                newValue: event.target.value,
                                fieldToUpdate: 'manufacturer',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          size="xs"
                          value={venting.roofLouvers.roofLouverType}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateRoofLouversField({
                                newValue: event.target.value,
                                fieldToUpdate: 'roofLouverType',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          size="xs"
                          value={venting.roofLouvers.line}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateRoofLouversField({
                                newValue: event.target.value,
                                fieldToUpdate: 'line',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          size="xs"
                          value={venting.roofLouvers.description}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateRoofLouversField({
                                newValue: event.target.value,
                                fieldToUpdate: 'description',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          value={venting.roofLouvers.pricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateRoofLouversField({
                                newValue: value,
                                fieldToUpdate: 'pricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </div>
            </VStack>
          </TabPanel>
          <TabPanel>
            <VStack>
              <div>
                <Center>
                  <Text fontWeight="bold" ml="6">
                    Turbine Vents
                  </Text>
                </Center>
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th fontSize="xs">Manufacturer</Th>
                      <Th fontSize="xs">Type</Th>
                      <Th fontSize="xs">Line</Th>
                      <Th fontSize="xs">Unit Description</Th>
                      <Th fontSize="xs">Price per unit</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        <Input
                          size="xs"
                          value={venting.turbineVents.manufacturer}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateTurbineVentsField({
                                newValue: event.target.value,
                                fieldToUpdate: 'manufacturer',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          size="xs"
                          value={venting.turbineVents.turbineVentType}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateTurbineVentsField({
                                newValue: event.target.value,
                                fieldToUpdate: 'turbineVentType',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          size="xs"
                          value={venting.turbineVents.line}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateTurbineVentsField({
                                newValue: event.target.value,
                                fieldToUpdate: 'line',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          size="xs"
                          value={venting.turbineVents.description}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateTurbineVentsField({
                                newValue: event.target.value,
                                fieldToUpdate: 'description',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          value={venting.turbineVents.pricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateTurbineVentsField({
                                newValue: value,
                                fieldToUpdate: 'pricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </div>
            </VStack>
          </TabPanel>
          <TabPanel>
            <VStack>
              <div>
                <Center>
                  <Text fontWeight="bold" ml="6">
                    Power Vents
                  </Text>
                </Center>
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th fontSize="xs">Manufacturer</Th>
                      <Th fontSize="xs">Type</Th>
                      <Th fontSize="xs">Line</Th>
                      <Th fontSize="xs">Color</Th>
                      <Th fontSize="xs">Unit Description</Th>
                      <Th fontSize="xs">Price per unit</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        <Input
                          size="xs"
                          value={venting.powerVents.manufacturer}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updatePowerVentsField({
                                newValue: event.target.value,
                                fieldToUpdate: 'manufacturer',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          size="xs"
                          value={venting.powerVents.powerVentType}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updatePowerVentsField({
                                newValue: event.target.value,
                                fieldToUpdate: 'powerVentType',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          size="xs"
                          value={venting.powerVents.line}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updatePowerVentsField({
                                newValue: event.target.value,
                                fieldToUpdate: 'line',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          size="xs"
                          value={venting.powerVents.color}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updatePowerVentsField({
                                newValue: event.target.value,
                                fieldToUpdate: 'color',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          size="xs"
                          value={venting.powerVents.description}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updatePowerVentsField({
                                newValue: event.target.value,
                                fieldToUpdate: 'description',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          value={venting.powerVents.pricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updatePowerVentsField({
                                newValue: value,
                                fieldToUpdate: 'pricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </div>
            </VStack>
          </TabPanel>
          <TabPanel>
            <VStack>
              <div>
                <Center>
                  <Text fontWeight="bold" ml="6">
                    Ridge Vent
                  </Text>
                </Center>
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th fontSize="xs">Manufacturer</Th>
                      <Th fontSize="xs">Line</Th>
                      <Th fontSize="xs">Unit Description</Th>
                      <Th fontSize="xs">Unit Measurement (LF)</Th>
                      <Th fontSize="xs">Price per unit</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        <Input
                          size="xs"
                          value={venting.ridgeVents.manufacturer}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateRidgeVentsField({
                                newValue: event.target.value,
                                fieldToUpdate: 'manufacturer',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          size="xs"
                          value={venting.ridgeVents.line}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateRidgeVentsField({
                                newValue: event.target.value,
                                fieldToUpdate: 'line',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          size="xs"
                          value={venting.ridgeVents.description}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateRidgeVentsField({
                                newValue: event.target.value,
                                fieldToUpdate: 'description',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          value={venting.ridgeVents.unit ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateRidgeVentsField({
                                newValue: value,
                                fieldToUpdate: 'unit',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          value={venting.ridgeVents.pricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateRidgeVentsField({
                                newValue: value,
                                fieldToUpdate: 'pricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </div>
            </VStack>
          </TabPanel>
          <TabPanel>
            <VStack>
              <div>
                <Center>
                  <Text fontWeight="bold" ml="6">
                    Mid Roof Intake Venting
                  </Text>
                </Center>
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th fontSize="xs">Manufacturer</Th>
                      <Th fontSize="xs">Line</Th>
                      <Th fontSize="xs">Unit Description</Th>
                      <Th fontSize="xs">Unit Measurement (LF)</Th>
                      <Th fontSize="xs">Price per unit</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        <Input
                          size="xs"
                          value={venting.midRoofIntakeVents.manufacturer}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateMidRoofIntakeVentsField({
                                newValue: event.target.value,
                                fieldToUpdate: 'manufacturer',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          size="xs"
                          value={venting.midRoofIntakeVents.line}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateMidRoofIntakeVentsField({
                                newValue: event.target.value,
                                fieldToUpdate: 'line',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          size="xs"
                          value={venting.midRoofIntakeVents.description}
                          onChange={event => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateMidRoofIntakeVentsField({
                                newValue: event.target.value,
                                fieldToUpdate: 'description',
                              })
                            );
                          }}
                        />
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          value={venting.midRoofIntakeVents.unit ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateMidRoofIntakeVentsField({
                                newValue: value,
                                fieldToUpdate: 'unit',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                      <Td>
                        <NumberInput
                          size="xs"
                          value={venting.midRoofIntakeVents.pricePer ?? ''}
                          onChange={value => {
                            setUnsavedChanges(true);
                            dispatch(
                              updateMidRoofIntakeVentsField({
                                newValue: value,
                                fieldToUpdate: 'pricePer',
                              })
                            );
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </div>
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Center>
        <VStack>
          {unsavedChanges && (
            <Alert status="warning" marginTop="5">
              <AlertIcon />
              <AlertTitle>You have unsaved changes!</AlertTitle>
            </Alert>
          )}
          {((currentTab === 0 && !savedList.includes('roofLouvers')) ||
            (currentTab === 1 && !savedList.includes('turbineVents')) ||
            (currentTab === 2 && !savedList.includes('powerVents')) ||
            (currentTab === 3 && !savedList.includes('ridgeVents')) ||
            (currentTab === 4 &&
              !savedList.includes('midroofIntakeVents'))) && (
            <Alert status="info" marginTop="5">
              <AlertIcon />
              <AlertTitle>Review and Save</AlertTitle>
            </Alert>
          )}
          <Button colorScheme="blue" marginTop="3" onClick={handleSave}>
            Save
          </Button>
        </VStack>
      </Center>
    </Box>
  );
};

export default VentingPrices;
