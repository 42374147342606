import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  options: {
    oneYearDescription: '',
    oneYearYears: '',
    oneYearFee: '',
    oneYearRate: '',
    threeYearDescription: '',
    threeYearYears: '',
    threeYearFee: '',
    threeYearRate: '',
    sevenYearFee: '',
    sevenYearRate: '',
    sevenYearDescription: '',
    sevenYearYears: '',
    cashFee: '',
    cashRate: '',
    cashDescription: '',
    cashYears: '',
  },
  general: {
    companyName: '',
    companyAddressLineOne: '',
    companyAddressLineTwo: '',
    companyPhoneNumber: '',
    companyEmail: '',
    salesTax: '',
    unforeseenExpenses: '',
    laborDeduction: '',
    overhead: '',
    profitMargin: '',
    salesIncomeTax: '',
    profitIncomeTax: '',
  },
  commission: {
    isFlatRate: false,
    flatRate: '',
    isPercentageOfSale: false,
    percentageOfSale: '',
    isPercentageOfProfit: false,
    percentageOfProfit: '',
  },
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    loadOptions: (state, action) => {
      state.options = action.payload;
    },
    loadGeneral: (state, action) => {
      state.general = action.payload;
    },
    loadCommission: (state, action) => {
      state.commission = action.payload;
    },
    updatePaymentOptions: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.options[fieldToUpdate] = newValue;
    },
    updateGeneralPayment: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.general[fieldToUpdate] = newValue;
    },
    updateCommission: (state, action) => {
      const { newValue, fieldToUpdate } = action.payload;
      state.commission[fieldToUpdate] = newValue;
    },
  },
});

export const {
  updatePaymentOptions,
  updateGeneralPayment,
  updateCommission,
  loadOptions,
  loadGeneral,
  loadCommission,
} = paymentSlice.actions;

export default paymentSlice.reducer;
