import { configureStore } from '@reduxjs/toolkit';
import calculatorReducer from '../features/calculator/calculatorSlice';
import priceReducer from '../features/price/priceSlice';
import laborReducer from '../features/labor/laborSlice';
import testReducer from '../features/test/testSlice';
import rateReducer from '../features/labor/rateSlice';
import paymentReducer from '../features/labor/paymentSlice';
import userReducer from '../features/user/userSlice';

export const store = configureStore({
  reducer: {
    calculator: calculatorReducer,
    price: priceReducer,
    labor: laborReducer,
    test: testReducer,
    rate: rateReducer,
    payment: paymentReducer,
    user: userReducer,
  },
});
